import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";
import { useTranslation } from "react-i18next";
import { getTitleFromLanguage } from "../../../../../utils";
import { useQuery } from "react-query";
import { getContentById } from "../../../../../services/content.service";
import { PhaseContent } from "../../../../../@types";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export function PhaseDetails({ className }: { className?: string }) {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const { activePhase } = useContentAndProgress();
  const { user } = useContext(AuthContext);
  const journeyId = user?.userDetails.journeyId;
  const { data: phaseDetails } = useQuery(
    ["phase", activePhase],
    () => getContentById({ id: activePhase?.content?.id }),

    {
      onSuccess: (data) => {
        setIsLoading(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          navigate("/notfound"); // Navigate to the not found page
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
      retry: false, // Disable automatic retries
    }
  );


  if (isLoading) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return (
    <>
      {activePhase ? (
        <div
          className={`flex flex-col w-full lg:p-6 text-Yellow-light lg:bg-Yellow-light lg:text-black rounded-lg ${className}`}
        >
          <h3 className="mb-4 font-medium hidden lg:flex">
            Phase{" "}
            {(activePhase?.content?.orderForProgress[journeyId ?? ""] || 0) + 1}
          </h3>
          <div className="flex justify-between mb-4 items-center">
            <div className="w-2/3">
              <h2 className="font-semibold text-xl">
                {getTitleFromLanguage(
                  activePhase?.content || null,
                  currentLang
                )}
              </h2>
            </div>
            <div
              className="progress-bar text-xs font-medium  text-black hidden lg:flex"
              style={{
                background: `radial-gradient(closest-side, #FFDA6F 80%, transparent 82% 100%), conic-gradient(black ${Math.round(
                  activePhase?.progressValue || 0
                )}%, white 0)`,
              }}
            >
              {Math.round(activePhase?.progressValue || 0)}%
            </div>
            <div
              className="progress-bar text-xs font-medium  text-black lg:hidden"
              style={{
                background: `radial-gradient(closest-side, white 80%, transparent 82% 100%), conic-gradient(#FDC300 ${Math.round(
                  activePhase?.progressValue || 0
                )}%, black 0)`,
              }}
            >
              {Math.round(activePhase?.progressValue || 0)}%
            </div>
          </div>
          <hr className="bg-Gray-dark" />
          <div className="mt-4 font-medium hidden lg:flex">
            {" "}
            {(phaseDetails as PhaseContent)?.description}
          </div>
        </div>
      ) : null}
    </>
  );
}
