import { ChangeEvent, useContext, useRef, useState } from "react";
import { IUser } from "../../../../../../@types";
import Resizer from "react-image-file-resizer";
import default_avatar from '../../../../../../assets/default_avatar.svg';
import Button from "../../../../../../components/button";
import { useMutation } from "react-query";
import { AvatarPicker } from "./AvatarPicker";
import { updateUserAvatar } from "../../../../../../services/users.service";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";


const resizeFile = (file: File): Promise<any> => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1200, 1200, 'PNG', 100, 0,
        uri => {
            resolve(uri);
        }, 'base64');
});

export function UserAvatar() {

    const {user, setUser} = useContext(AuthContext);;

    const [popupOpen, setPopupOpen] = useState(false);
const {t} = useTranslation('myArea')

    const updateAvatarMutation = useMutation(updateUserAvatar, {
        onSuccess: (data) => {
            console.log("Received data: ", data);
            setPopupOpen(false);
            setUser(data);
        },
        onError: () => {
            console.log("Error updating image");
        }
    })

    const userHasPhoto = () => {
        return user?.userDetails?.selectedAvatar?.signedUrl;
    }

    return (
        <>
            <AvatarPicker onConfirm={(imageId: number) => updateAvatarMutation.mutate({ userAvatar: user?.userDetails?.selectedAvatar, imageId })} onCancel={() => setPopupOpen(false)} isOpen={popupOpen} />

            <div id="form-pic-upload" className="flex flex-col items-center justify-center">

                <div className="overflow-hidden mt-[-10px]">
                    
                    {userHasPhoto() ?
                        <img width={300} src={user?.userDetails?.selectedAvatar?.signedUrl || ""} /> :
                        <img width={260} src={default_avatar} />
                    }
                </div>
                <Button onClick={() => setPopupOpen(true)} className="mt-[-30px] z-10 bg-white border rounded-md p-2">{t('profile.choose-avatar')}</Button>

            </div>

        </>
    )

}