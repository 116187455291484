import { useCookies } from "react-cookie";
import { useMutation } from "react-query";
import { acceptCookiePolicy } from "../services/legal.service";
import { getLoggedUser } from "../services/users.service";
import { AuthContext } from "../contexts/AuthContext";
import { useContext, useEffect } from "react";

type CookieSettings = {
  essentialCookiesAccepted: boolean;
  preferencesCookiesAccepted: boolean;
  statisticsCookiesAccepted: boolean;
  marketingCookiesAccepted: boolean;
};

function wereCookiesLocallyUpdated({
  localSettings,
  backendSettings,
}: {
  localSettings: CookieSettings | undefined;
  backendSettings: CookieSettings | undefined;
}) {
  if (!localSettings) {
    if (backendSettings) return true;
    return false;
  }

  if (!backendSettings) {
    if (localSettings) return true;
    return false;
  }

  for (const property of Object.keys(localSettings) as [
    "essentialCookiesAccepted",
    "preferencesCookiesAccepted",
    "statisticsCookiesAccepted",
    "marketingCookiesAccepted"
  ]) {
    if (localSettings[property] !== backendSettings[property]) {
      return true; // Properties are not equal
    }
  }

  return false;
}

export function useCookieUpdateConsent() {
  const [cookies] = useCookies();
  const { user, setUser } = useContext(AuthContext);;

  const cookie = cookies["restart_consent"];

  const acceptCookiesMutation = useMutation(
    ["acceptCookies"],
    () => acceptCookiePolicy(cookie),
    {
      onSuccess: () => {
        getLoggedUser().then((response) => {
          setUser(response.user);
        });
      },
    }
  );

  useEffect(() => {
    if (
      wereCookiesLocallyUpdated({
        localSettings: cookie,
        backendSettings: user?.cookieSettings,
      })
    )
      acceptCookiesMutation.mutate();
  }, []);
}
