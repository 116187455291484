
import { Dispatch, SetStateAction } from "react";
import { addDailyGoal, createDailyPlan, deleteDailyGoal, getDailyGoals, getDailyPlan, updateDailyGoal, updateDailyPlan } from "../../../../services/journal.service";
import { useMutation } from "react-query";
import { formatDate } from "../../../../utils";


export function useDailyGoals({
    dailyPlanId,
    refetchGoals,
    setNewDailyGoal,
    selectedDate
}: {
    dailyPlanId: number | null,
    refetchGoals: () => void,
    setNewDailyGoal: Dispatch<SetStateAction<string>>,
    selectedDate: Date
}) {

    const addNewDailyGoal = (data: { title: string, date: string }) => {
        return addDailyGoal(data, dailyPlanId || undefined);
    }

    const deleteGoal = async (goalId?: number) => {
        if (!dailyPlanId || !goalId) return;
        return deleteDailyGoal(dailyPlanId, goalId)
    }


    const addDailyGoalMutation = useMutation(['addDailyGoal'],
        (mutationArgs: { title: string }) => addNewDailyGoal({ ...mutationArgs, date: formatDate(selectedDate) }), {
        onSuccess: () => {
            refetchGoals();
            setNewDailyGoal("");
        }
    })

    const updateDailyGoalMutation = useMutation(['updateDailyPlan'],
        (mutationArgs: { goalId?: number, title?: string, check?: boolean, rescheduleDate?: string }) => updateDailyGoal(mutationArgs, dailyPlanId || undefined, mutationArgs.goalId), {
        onSuccess: () => {
            refetchGoals();
        }
    }
    )

    const deleteDailyMutation = useMutation(['deleteDailyMutation'],
        (mutationArgs: { goalId?: number }) => deleteGoal(mutationArgs.goalId), {
        onSuccess: () => {
            refetchGoals();
        }
    })

    return {
        addDailyGoalMutation,
        updateDailyGoalMutation,
        deleteDailyMutation
    }



}