import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useCookies } from "react-cookie";


export function useCookieConsentBanner({ displayCookieConsent, hideCookieConsent }: { displayCookieConsent: () => void, hideCookieConsent : () => void}) {

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const { user } = useContext(AuthContext);;
    const [ cookies ] = useCookies<any>();

    const restartConsentCookie = cookies['restart_consent'];


    useEffect(() => {
        
        if ((user && !user?.cookiePolicyUpToDate) || !restartConsentCookie) {
            const timeoutId = setTimeout(() => {
                displayCookieConsent();
            }, 5000);
            setTimeoutId(timeoutId);
        } else {
            if(timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
            }
            hideCookieConsent();
        }
    }, [user?.cookiePolicyUpToDate])

}