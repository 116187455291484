/* React imports */
import { useContext, useEffect, useState } from "react";

/* Third party imports */
import { useMutation } from "react-query";

/* Local imports */
import StepCareer from "./stepCareer";
import StepName from "./stepName";
import StepNeeds from "./stepNeeds";
import {
  StepCareerParameters,
  StepJourneyParameters,
  StepNameParameters,
  StepNeedsParameters,
  UserDetails,
} from "./types";
import { updateUserDetails } from "../../../services/onboarding.service";
import SubmitStep from "./submitStep";
import { AuthContext } from "../../../contexts/AuthContext";
import { PublicNavbar } from "../../../components/publicNavbar";
import { PublicFooter } from "../../../components/publicFooter";
import { useJourneyData } from "./utils/journeyUtils";

export function Onboarding() {
  const { setUser, configurations } = useContext(AuthContext);

  const renderCareerInformationStep =
    configurations?.onboardingCareerInformation;
  const renderCareerExpectationStep =
    configurations?.onboardingCareerExpectations;

    const stepRenderContent = configurations?.onboardingProductInfo;


  const [currentStep, setCurrentStep] = useState<number>(1);
  const [nameStepInfo, setNameStepInfo] = useState<StepNameParameters>({
    firstName: "",
    lastName: "",
    birthday: null,
  });
  const [journeyStepInfo, setJourneyStepInfo] = useState<StepJourneyParameters>(
    {
      journeyId: "",
    }
  );
  const [careerStepInfo, setCareerStepInfo] = useState<StepCareerParameters>({
    lastPosition: null,
    careerLevel: null,
    industry: null,
    discipline: null,
  });

  const [needsStepInfo, setNeedsStepInfo] = useState<StepNeedsParameters>({
    timingLastDayAtWork: null,
    timingAchieveRestart: null,
    need: "",
  });
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );


  const journeyData = useJourneyData();

  const handleNameNextStep = (data: StepNameParameters) => {
    setNameStepInfo(data);
    skipStep();
  };

  const handleJourneyNextStep = (data: StepJourneyParameters) => {
    setJourneyStepInfo(data);
    setSelectedOption(data?.journeyId);
    skipStep();
  };

  const handleCareerNextStep = (data: StepCareerParameters) => {
    setCareerStepInfo(data);
    skipStep();
  };

  const handleNeedsNextStep = (data: StepNeedsParameters) => {
    setNeedsStepInfo(data);
    skipStep();
  };

  const skipStep = () => setCurrentStep(currentStep + 1);

  useEffect(() => {
    if (currentStep === 4 && !stepRenderContent) {
      handleSubmit();
    }
  }, [currentStep, stepRenderContent]);  

  const handleSubmit = async () => {
    const updatedCareerStepInfo = Object.fromEntries(
      Object.entries(careerStepInfo).map(([key, value]) => {
        return [key, value === "" ? null : value];
      })
    );

    const updatedNeedsStepInfo = Object.fromEntries(
      Object.entries(needsStepInfo).map(([key, value]) => {
        return [key, value === "" ? null : value];
      })
    );

    const payload: UserDetails = {
      ...nameStepInfo,
      journeyId: journeyData.standardUserJourneyId, // ...journeyStepInfo
      ...updatedCareerStepInfo,
      ...updatedNeedsStepInfo,
      othersDiscipline: null,
      othersIndustry: null,
    };
    try {
      console.log("+++", payload);
      // handle success
      await submitNewUserDetails.mutate({ userDetails: payload });
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const submitNewUserDetails = useMutation(updateUserDetails, {
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
    },
  });

  let content;
  switch (currentStep) {
    case 1:
      content = <StepName onNext={handleNameNextStep} onSkip={skipStep} />;
      break;
    /*case 2:
      content = (
        <>
          {journeyData ? (
            <StepJourney
              onNext={handleJourneyNextStep}
              onSkip={skipStep}
              availableJourneyIds={journeyData}
            />
          ) : null}
        </>
      );
      break;*/
    case 2: // 3
      if (renderCareerInformationStep) {
        content = (
          <StepCareer onNext={handleCareerNextStep} onSkip={skipStep} />
        );
      } else {
        skipStep(); // Skip to the next step
      }
      break;
    case 3: // 4
      if (renderCareerExpectationStep) {
        content = (
          <StepNeeds
            onNext={handleNeedsNextStep}
            onSkip={skipStep}
            selectedOption={journeyData.standardUserJourneyId} // ={selectedOption}
            availableJourneyIds={journeyData}
          />
        );
      } else {
        skipStep(); // Skip to the next step
      }

      break;
    case 4: // 5
      if (stepRenderContent) {
        content = (
          <SubmitStep
            onNext={handleSubmit}
            selectedOption={journeyData.standardUserJourneyId} // ={selectedOption}
            availableJourneyIds={journeyData}
          />
        );
      }
      break;
    default:
      content = <div>Invalid step</div>;
  }

  return (
    <div className="flex flex-col h-screen overflow-auto">
      <PublicNavbar />
      <div className="flex justify-center">
        <div className="flex justify-center pt-6 px-6 md:pt-14">
          <div className="sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/3">{content}</div>
        </div>
      </div>
      <div className="mt-auto	">
        <PublicFooter />
      </div>
    </div>
  );
}
