import React from "react";
import { useState } from "react";
import { TemplateSelector } from "./pages/TemplateSelector";
import { CVUploader } from "./pages/CVUploader";
import { PopupWrapper } from "../../../../../../../components/popupWrapper";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/close.svg";
import { TemplatePreview } from "./pages/TemplatePreview";
import SuccessPage from "./pages/Success";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getAvailableTemplates } from "../../../../../../../services/selfAssessment.service";
export function CVCheckPopup({
  isOpen,
  checkType,
  close,
}: {
  isOpen: boolean;
  checkType: "Basic" | "Plus";
  close: () => void;
}) {

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [CVToUpload, setCVToUpload] = useState(null);
  const { t } = useTranslation("myArea");

  const { data: templates } = useQuery(["templates"], getAvailableTemplates);

  const generatePageTexts = (pageName: any) => {
    return {
      title: t(`assessments.cv-check.${checkType}.${pageName}.title`),
      description: t(
        `assessments.cv-check.${checkType}.${pageName}.description`
      ),
    };
  };

  const pageNames = [
    "template-selector",
    "cv-uploader",
    "template-preview",
    "success",
  ];

  const title = generatePageTexts(pageNames[currentPage]).title;
  const description = generatePageTexts(pageNames[currentPage]).description;

  const pages = [
    <TemplateSelector
      selectedTemplate={selectedTemplate}
      setSelectedTemplate={setSelectedTemplate}
      onSelect={() => setCurrentPage(1)}
      templates={templates}
    />,
    <CVUploader
      onNext={() => setCurrentPage(2)}
      onBack={() => setCurrentPage(0)}
      setCVToUpload={setCVToUpload}
      cvToUpload={CVToUpload}
    />,
    <TemplatePreview
      onNext={() => setCurrentPage(3)}
      onBack={() => setCurrentPage(1)}
      selectedTemplate={selectedTemplate}
      cvToUpload={CVToUpload}
      templates={templates}
      checkType={checkType}
      deleteFile={()=> {
        setCVToUpload(null);
        setCurrentPage(1);
      }}
      deleteTemplate={()=> {
        setCurrentPage(0);
      }}
    />,
    <SuccessPage onClose={()=> {
      close()
      setCurrentPage(0);
    }}/>,
  ];


  return (
    <PopupWrapper isOpen={isOpen}>
      <div className="bg-white rounded-md w-1/3 p-4">
        <div className="flex justify-between items-center mb-3">
          <span className="text-lg font-bold">{title}</span>
          <CloseIcon onClick={()=> {
            close();
           setCurrentPage(0);
          }} />
        </div>
        <span>{description}</span>
        <div>{pages[currentPage]}</div>
      </div>
    </PopupWrapper>
  );
}
