import { useTranslation } from "react-i18next";


export default function OAuthButton({providerLogo, providerName, onClick} : {providerLogo: any, providerName: string, onClick: () => void}) {

    const {t} = useTranslation("authentication"); 

    return (
        <button className="w-full flex justify-center p-3 border rounded font-bold items-center hover:shadow" onClick={onClick}>
            {providerLogo}
            <p>{t('third-party-prefix')} {providerName}</p>
        </button>
    )
} 