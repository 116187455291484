import { useQuery } from "react-query"
import { getBookmarkedTopics, getFeedback, getTopicsContentByIds } from "../../../../../../services/discourse.service"
import { ICommunityTopic } from "../../../../../../@types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CommunityTopic } from "../../../../community/Topic";



export function CommunityBookmarks() {

    const navigate = useNavigate();

    const { data: userBookmarks } = useQuery(['bookmarked'], ()=> getBookmarkedTopics());
    const {data: feedback, refetch} = useQuery(['feedback'], ()=> getFeedback())
    const [ openTopic, setOpenTopic] = useState<number | null>(null);

    const {data: bookmarkedContent} = useQuery(['bookmarkedContent', userBookmarks], () => getTopicsContentByIds(userBookmarks?.map((ub : any) => ub.topicId)))

    return (
        <div>
            {bookmarkedContent?.map((lt: ICommunityTopic, idx: number) => {
                const fb = feedback?.find((f : any) => f.topicId === lt.id);
                return <div className="py-2" onClick={() => setOpenTopic(lt.id)}>
                    <CommunityTopic onClick={()=> navigate(`/thread/${lt.id}`)} open={openTopic === lt.id} topic={lt} users={[]} feedback={fb} refetchFeedback={refetch} />
                </div>
            })}

        </div>
    )


}