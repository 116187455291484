import { useMutation } from "react-query";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { requestExternalAssessment } from "../../../../../../services/users.service";
import Button from "../../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../components/button/types";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { getLinc } from "../../../../../../services/legal.service";
import {
  BusinessInfoContent,
  IContentWithProgress,
} from "../../../../../../@types";
import { useContentAndProgress } from "../../../../../../contexts/UserProgressContext";

export function SterngthAndPersonalityProfile() {
  const { t } = useTranslation("myArea");
  const { user } = useContext(AuthContext);;
  const { i18n } = useTranslation();
  const requestExternalAssessmentMutation = useMutation(
    requestExternalAssessment
  );

  const assessmentAvailable = user?.externalAssessment?.link;

  const handleAssessmentButtonClick = () => {
    requestExternalAssessmentMutation.mutate();
    window.open(user?.externalAssessment?.link, "_blank");
  };

  const [content, setContent] = useState<BusinessInfoContent | null>(null);
  const { phases, milestones } = useContentAndProgress();
  const fetchData = async () => {
    try {
      const res = await getLinc();

      if (res?.renderedContent) {
        const modifiedContent = modifyLinksInHTML(res.renderedContent);

        setContent({ ...res, renderedContent: modifiedContent });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const modifyLinksInHTML = (html: string): string => {
    // Create a temporary div to manipulate the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Find and modify the links
    const anchorTags = tempDiv.querySelectorAll("a");
    anchorTags.forEach((anchorTag) => {
      const originalHref = anchorTag.getAttribute("href");
      const identifier = originalHref?.split("/").pop();
      if (identifier) {
        const milestone =
          milestones?.filter((ms: IContentWithProgress) =>
            ms.content?.childContentIds.includes(identifier)
          )[0] || null;
        const phase =
          phases?.filter((phase: IContentWithProgress) =>
            phase.content?.childContentIds.includes(
              milestone?.content.platformContentId || ""
            )
          )[0] || null;
        const newHref = `/phase/${phase?.content.platformContentId}/milestone/${milestone?.content.platformContentId}/content/${identifier}`;
        anchorTag.setAttribute("href", newHref);
      }
    });

    // Return the modified HTML
    return tempDiv.innerHTML;
  };

  return (
    <div>
      <h1 className="text-Grey-dark text-xl font-black mb-6">
        {content?.title}
      </h1>
      <div>
        <div
          dangerouslySetInnerHTML={{ __html: content?.renderedContent || "" }}
        ></div>
        <div className="flex items-center justify-between mt-4 mb-2">
          <span className="text-Grey-dark">
            {t("assessments.strength-finder.credits")}: 0 (
            {t("assessments.strength-finder.inclusive")})
          </span>
          <Button
            onClick={handleAssessmentButtonClick}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            disabled={!assessmentAvailable}
          >
            {!assessmentAvailable
              ? `${t("assessments.strength-finder.button-text-unavailable")}`
              : t("assessments.strength-finder.button-text-available")}
          </Button>
        </div>
        {assessmentAvailable ? null : (
          <span className="text-Grey-dark">
            *{t("assessments.strength-finder.not-available-message")}
          </span>
        )}
      </div>
    </div>
  );
}
