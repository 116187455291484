import { Form, Formik } from "formik";
import { useState } from "react";
import { useMutation } from "react-query";
import { resetPassword } from "../../services/auth.service";
import * as Yup from 'yup';
import { CustomFieldWithError } from "../../components/forms/CustomFieldWithError";
import { useTranslation } from 'react-i18next';
import Button from "../../components/button";
import { ButtonSize, ButtonVariant } from "../../components/button/types";


const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm password is required'),
});



export default function PasswordReset({ onSuccessfulReset }: { onSuccessfulReset: () => void }) {

    const token = new URLSearchParams(window.location.search).get("token");

    const { t } = useTranslation("authentication");

    const [message, setMessage] = useState<string | null>(null);

    const resetPasswordMutation = useMutation(resetPassword, {
        onSuccess: () => {
            setMessage("Password successfully reset. Redirecting to login page...");
            setTimeout(() => onSuccessfulReset(), 2000);
        }, onError: (error) => {
            setMessage("There was an issue resetting password");
        }
    });


    return (
        <div
            className="w-full p-9"
        >
            <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    resetPasswordMutation.mutate({ ...values, token });
                }}
            >
                {({ errors, touched, isValid, values }) => {
                    
                    const noFieldsEmpty = values['password'].length > 0 && values['confirmPassword'].length > 0;
                    const disabled = !isValid || !noFieldsEmpty;

                    return (
                        <Form>
                            <div className="w-full mt-4">
                                <label htmlFor="password">{t("register-form.password")}*</label>
                                <div>
                                    <CustomFieldWithError className="w-full h-10 mt-1" type="password" id="password" name="password" error={errors.password || null} touched={!!touched.password} />
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <label htmlFor="confirmPassword">{t("register-form.confirm-password")}*</label>
                                <div>
                                    <CustomFieldWithError className="w-full h-10 mt-1" type="password" id="confirmPassword" name="confirmPassword" error={errors.confirmPassword || null} touched={!!touched.confirmPassword} />
                                </div>
                            </div>
                            
                            <Button type="submit" className="mt-6 w-full" disabled={disabled} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                                {t("buttons.confirm-password-reset")}
                            </Button>
                        </Form>
                    )
                }}
            </Formik>

            {message ? <div>{message}</div> : null}

        </div>
    )


}