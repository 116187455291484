import { AxiosResponse } from "axios";
import { axiosInstance } from ".";
import {
  ILoginResponse,
  IResetPasswordData,
  ISignupData,
  IUser,
} from "../@types";
import { getLocaleFromLanguage } from "./content.service";

export async function login(userInfo: {
  email: string;
  password: string;
  keepMeLogged?: boolean;
}): Promise<AxiosResponse<ILoginResponse>> {
  return await axiosInstance.post(
    `/auth/signin${userInfo.keepMeLogged ? "?keep-me-signed=true" : ""}`,
    {},
    { auth: { username: userInfo.email, password: userInfo.password } }
  );
}

export function linkedinLogin(): void {
  window.location.href = `${process.env.REACT_APP_API_URL}/auth/linkedin`;
}

export async function signup(
  userInfo: ISignupData
): Promise<{ message: string }> {
  const payload = {
    email: userInfo.email,
    password: userInfo.password,
    ...(userInfo.voucherCode && { voucherCode: userInfo.voucherCode }),
  };
  return await axiosInstance.post(
    `/auth/signup?locale=${getLocaleFromLanguage()}`,
    payload
  );
}

export async function linkedinSignup({
  token,
  locale,
  voucherCode,
}: {
  token: string | null;
  locale: string | null;
  voucherCode: string | null;
}): Promise<AxiosResponse<{ user: IUser; token: string }>> {
  console.log("🚀 ~ voucherCode:", voucherCode)
  return await axiosInstance.post(
    `/auth/linkedin/signup?token=${token}&voucherCode=${voucherCode}&locale=${locale}`,
    {}
  );
}

export async function resetPasswordRequest({ email }: { email: string }) {
  return await axiosInstance.post(
    `/auth/request-password-reset?locale=${getLocaleFromLanguage()}`,
    { email }
  );
}

export async function resetPassword({
  token,
  password,
  confirmPassword,
}: IResetPasswordData) {
  return await axiosInstance.put(`/auth/password-reset`, {
    password,
    token,
    confirmPassword,
  });
}

export async function logout() {
  return await axiosInstance.post(`/auth/logout`, {});
}
