import { useNavigate } from "react-router-dom";
import { ArticleContent } from "../../../../../@types";
import { useContentActions } from "../../../../../hooks/useContentActions";

import { ReactComponent as DislikeEnabled } from "../../../../../assets/dislike_enabled.svg";
import { ReactComponent as DislikeDisabled } from "../../../../../assets/dislike_disabled.svg";
import { ReactComponent as LikeEnabled } from "../../../../../assets/like_enabled_black.svg";
import { ReactComponent as LikeDisabled } from "../../../../../assets/like_disabled.svg";
import { ReactComponent as DoneInactive } from "../../../../../assets/done_check_inactive.svg";
import { ReactComponent as DoneIrrelevant } from "../../../../../assets/done_check_irrelevant.svg";
import { ReactComponent as BookmarkInactive } from "../../../../../assets/bookmark_inactive.svg";
import { ReactComponent as DoneActive } from "../../../../../assets/done_check_active.svg";
import { ReactComponent as BookmarkActive } from "../../../../../assets/bookmark_active.svg";

import { useTranslation } from "react-i18next";
import { ContentType } from "../../../../../enums";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useContext } from "react";

export const CoachDisplay = ({ coach }: { coach: any }) => {
  const navigate = useNavigate();

  return (
    <div
      className="border p-2 rounded flex"
      onClick={() => navigate(`/coaches/${coach.platformContentId}`)}
    >
      <img width={150} src={`https:${coach.profilePicture}`} />
      <div>
        {coach.title} {coach.surname} {coach.givenName}
      </div>
    </div>
  );
};

export const AuthorsDisplay = ({
  authors,
  belowImage,
}: {
  authors: any[];
  belowImage: boolean;
}) => {
  const navigate = useNavigate();

  if (authors?.length == 0) return null;

  const linkToAuthor = (author: any) => {
    const isCoach = author.contentType === ContentType.COACH;
    const link = `/${isCoach ? "coaches" : "authors"}/${
      author.platformContentId
    }/overview`;
    navigate(link);
  };

  return (
    <div
      className={`w-full flex relative justify-between bg-white py-3 ${
        belowImage ? "border  px-5 rounded-full" : ""
      }`}
    >
      <div>
        {authors?.map((author: any) => (
          <div
            onClick={() => linkToAuthor(author)}
            key={author?.platformContentId}
            className="flex items-center font-light cursor-pointer"
          >
            <div className="mr-2 w-10 rounded-full overflow-hidden">
              <img src={author?.profilePicture} />
            </div>
            {author?.title} {author?.givenName} {author?.middleName}{" "}
            {author?.surname}
          </div>
        ))}
      </div>
    </div>
  );
};

export const ContentHeader = ({ content }: { content: ArticleContent }) => {
  if (!content?.image) return null;

  return (
    <div className="mb-4">
      <div className=" z-0">
        {content?.image ? (
          <img
            className={`z-0`}
            width={"100%"}
            src={(content as ArticleContent)?.image}
          />
        ) : null}
      </div>

      {content?.authors?.length ? (
        <div className="mt-[-1.8em]">
          <AuthorsDisplay belowImage={true} authors={content?.authors} />{" "}
        </div>
      ) : null}
    </div>
  );
};

const ActionButton = ({ content }: { content: ArticleContent }) => {
  const { t } = useTranslation("content");
  const { contentProgress, handleDoneClick, handleRollbackDoneClick } =
    useContentActions({ content });

  const getRightAction = () =>
    contentProgress?.progressValue == 100
      ? handleRollbackDoneClick
      : handleDoneClick;

  return (
    <>
      {contentProgress?.markedIrrelevant ? (
        <button
          disabled
          className={`bg-Grey-light ml-2 text-Black border-Black border px-4 py-1.5 rounded-lg`}
        >
          <span className="font-medium">Irrelevant</span>
        </button>
      ) : (
        <button
          onClick={getRightAction()}
          className={`${
            contentProgress?.progressValue === 100
              ? "bg-Yellow-default"
              : "bg-white"
          } ml-2 text-Black border-Black border px-4 py-1.5 rounded-lg`}
        >
          <span className="font-medium">
            {contentProgress?.progressValue === 100
              ? t("done")
              : t("mark-done")}
          </span>
        </button>
      )}
    </>
  );
};

const DoneButton = ({
  content,
  belongsToProgress,
}: {
  content: any;
  belongsToProgress: any;
}) => {
  const { contentProgress, handleDoneClick, handleRollbackDoneClick } =
    useContentActions({ content });

  return (
    <>
      <div className="hidden md:block">
        {belongsToProgress ? <ActionButton content={content} /> : null}
      </div>
      <div className="block md:hidden">
        {contentProgress?.progressValue == 100 ? (
          <DoneActive
            onClick={handleRollbackDoneClick}
            className="cursor-pointer mr-1"
          />
        ) : (
          <DoneInactive
            onClick={handleDoneClick}
            className="cursor-pointer mr-1"
          />
        )}
      </div>
    </>
  );
};

const BookmarkButton = ({ content }: { content: any }) => {
  const { t } = useTranslation("content");
  const { contentProgress, handleBookmark } = useContentActions({ content });

  return (
    <>
      <button
        onClick={handleBookmark}
        className={`hidden md:block ${
          contentProgress?.bookmarked ? "bg-Yellow-default" : "bg-white"
        } rounded-lg text-Black border-Black border px-4 py-1.5`}
      >
        <span className="font-medium">
          {contentProgress?.bookmarked ? t("saved") : t("save-for-later")}
        </span>
      </button>
      <div className="block md:hidden">
        {contentProgress?.bookmarked ? (
          <BookmarkActive onClick={handleBookmark} className="cursor-pointer" />
        ) : (
          <BookmarkInactive
            onClick={handleBookmark}
            className="cursor-pointer"
          />
        )}
      </div>
    </>
  );
};

export const InfoAndActions = ({
  content,
  belongsToProgress,
  hideEffort,
}: {
  content: ArticleContent;
  belongsToProgress: boolean;
  hideEffort?: boolean;
}) => {

  const { t } = useTranslation("content");
  const { configurations } = useContext(AuthContext);;
  const renderBookmarkActions = configurations?.includesBookmarks;

  const showActions = !content.hideFromMilestone;

  return (
    <div
      className={`flex ${
        hideEffort ? "justify-end" : "w-full justify-between"
      } items-center mt-4`}
    >
      {!hideEffort ? (
        <div className="text-sm text-Grey-dark ">
          {content?.effortEstimate || t("no-effort-estimation")}
        </div>
      ) : null}
      <div className={`flex gap-2 w-fit items-center`}>
        {renderBookmarkActions && <BookmarkButton content={content} />}
        {showActions && (
        <DoneButton content={content} belongsToProgress={belongsToProgress} />
        )}
      </div>
    </div>
  );
};

export const Feedback = ({ content }: { content: ArticleContent }) => {
  const { contentProgress, handleLike, handleDislike } = useContentActions({
    content,
  });

  return (
    <div className="bg-Yellow-light flex justify-end rounded-md p-5">
      <div>
        <div>War dies hilfreich?</div>
        <div className="w-full flex justify-end mt-2">
          <div className="cursor-pointer" onClick={handleLike}>
            {contentProgress?.feedback == "Liked" ? (
              <LikeEnabled />
            ) : (
              <LikeDisabled />
            )}
          </div>
          <div className="ml-3 cursor-pointer" onClick={handleDislike}>
            {contentProgress?.feedback == "Disliked" ? (
              <DislikeEnabled />
            ) : (
              <DislikeDisabled />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
