import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import { ButtonVariant } from "../../../components/button/types";
import { submitCV } from "../../../services/selfAssessment.service";
import { DragAndDropUploader } from "../../../components/DragAndDropUploader";


export function SubmitCv() {

  const { t } = useTranslation("myArea");
  const [cvFile, setCVFile] = useState<any[] | null>(null);
  const [submissionStatus, setSubmissionStatus] = useState<"" | "success">(""); // Add this line
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");


  const handleSubmit = async () => {
    try {
      const safeToken = token || "";
      console.log(cvFile);
      await submitCV({ cvFile: cvFile ? cvFile[0] : null, token: safeToken });
      setSubmissionStatus("success");
    } catch (error) {
      console.error("Error submitting CV:", error);
    }
  };

  return (
    <div className="flex flex-col w-full mt-5">
      <>
        {
          submissionStatus == "" ?
            <>
              <h2 className="ml-5">{t("assessments.cv-check.submit-title")}</h2>
              <DragAndDropUploader singleFile filesSetter={setCVFile} />
              <Button
                variant={ButtonVariant.PRIMARY}
                className="p-2 px-5 mx-5"
                onClick={handleSubmit}
                disabled={!cvFile}
              >
                {t("assessments.cv-check.submit-button")}
              </Button>

            </> :
            <div className="p-5 font-bold text-lg flex justify-center">{t("assessments.cv-check.submit-success-message")}</div>

        }
      </>


    </div>
  );
}
