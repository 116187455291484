export enum ButtonVariant {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
    GHOST_LIGHT = "GHOST_LIGHT",
    GHOST_DARK = "GHOST_DARK",
}

export enum ButtonSize {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM"
}

export interface ButtonProps {
    className?: string,
    disabled?: boolean,
    onClick?: (e?: any) => void,
    children?: any,
    variant?: ButtonVariant,
    size?: ButtonSize,
    type?: any
}
