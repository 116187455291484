import React from 'react';
import { useTranslation } from "react-i18next";
import { ButtonSize, ButtonVariant } from '../../../../../../../../../components/button/types';
import Button from '../../../../../../../../../components/button';

const SuccessPage = ({onClose} : { onClose : ()=> void}) => {
  const { t } = useTranslation("myArea");

  return (
    <div className="flex flex-col items-start justify-center h-full">     
      <h2 className='font-bold mt-2'>{t(`assessments.cv-check.success`)}</h2>
      <p className="mt-5 text-gray-700">{t(`assessments.cv-check.final-message`)}</p>
      <Button onClick={onClose} className='w-full mt-6' variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>OK</Button>
    </div>
  );
};

export default SuccessPage;
