import { CoachContent, CoachingContent } from "../../../../../../@types";
import Button from "../../../../../../components/button";
import { CircularImage } from "../../../../../../components/circularImage";
import { useContentActions } from "../../../../../../hooks/useContentActions";
import { ReactComponent as DoneInactive } from "../../../../../../assets/done_check_inactive.svg";
import { ReactComponent as DoneIrrelevant } from "../../../../../../assets/done_check_irrelevant.svg";

import { ReactComponent as BookmarkInactive } from "../../../../../../assets/bookmark_inactive.svg";
import { ReactComponent as DoneActive } from "../../../../../../assets/done_check_active.svg";
import { ReactComponent as BookmarkActive } from "../../../../../../assets/bookmark_active.svg";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../components/button/types";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";

export function CoachingTeaserDisplay({
  content,
  onClick,
  hideActions,
}: {
  content: CoachingContent;
  onClick: () => void;
  hideActions?: boolean;
}) {
  const {
    contentProgress,
    handleBookmark,
    handleDoneClick,
    handleRollbackDoneClick,
  } = useContentActions({ content });

  const { t } = useTranslation("coaches");

  const { configurations } = useContext(AuthContext);;
  const renderBookmarkActions = configurations?.includesBookmarks;

  const [loading, setLoading] = useState(true); // Add loading state
 

  const getDoneCheckmark = () => {
    if (contentProgress?.markedIrrelevant) return <DoneIrrelevant />;
    return (
      <>
        {contentProgress?.progressValue == 100 ? (
          <DoneActive
            onClick={handleRollbackDoneClick}
            className="cursor-pointer mr-1"
          />
        ) : (
          <DoneInactive
            onClick={handleDoneClick}
            className="cursor-pointer mr-1"
          />
        )}
      </>
    );
  };

  if (!content) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }
  
  return (
    <div onClick={onClick} className="flex flex-col items-start mb-2">
      <div className="w-full">
        <h2 className="font-bold text-xl mb-1">{content?.title}</h2>
        <h3 className="overflow-hidden">
          {content?.teaserText?.slice(0, 170)}...
        </h3>
      </div>
      <div className="flex mt-4 w-full">
        {content?.coaches?.map((c: CoachContent) => {
          return (
            <div
              key={c.platformContentId}
              className="w-[80px] h-[80px] overflow-hidden p-1"
            >
              <CircularImage src={`https:${c?.profilePicture}`} />
            </div>
          );
        })}
      </div>
      <div className="flex w-full justify-end mb-2">
        <div className="flex">
          <Button
            size={ButtonSize.SMALL}
            variant={ButtonVariant.SECONDARY}
            // className={`bg-white text-Black border-Black border px-4 py-1.5`}
          >
            <span className="font-medium">{t('see-all-coaches-text')}</span>
          </Button>
          {hideActions ? null : (
            <div className="flex p-2">
              {getDoneCheckmark()}
              {renderBookmarkActions && (
                <>
              {contentProgress?.bookmarked ? (
                <BookmarkActive
                  onClick={handleBookmark}
                  className="cursor-pointer"
                />
              ) : (
                <BookmarkInactive
                  onClick={handleBookmark}
                  className="cursor-pointer"
                />
              )}
              </>
            )}
            </div>
          )}
        </div>
      </div>
      <hr className="border-xs border-zinc-300 w-full mb-4 mt-2" />
    </div>
  );
}
