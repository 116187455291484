import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation } from "react-query";
import { checkVoucher } from "../../../services/vouchers.service";
import { NOT_FOUND_ERRORS, VOUCHER_ERRORS } from "../../../errors/errorCodes";

export function Activate() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const mutation = useMutation((code: string) => checkVoucher(code), {
    onSuccess: (data) => {
      localStorage.removeItem("pendingVoucherCode");
      if (data) {
        const { isValid, code, redirectUserToContent } = data;
        if (isValid && !redirectUserToContent) {
          navigate(`/profile/settings?code=${code}`);
        } else if (isValid && redirectUserToContent) {
          navigate("/");
        } else {
          navigate("/voucher-error?error");
        }
      }
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message;
      localStorage.removeItem("pendingVoucherCode");
      switch (errorMessage) {
        case NOT_FOUND_ERRORS.NOT_FOUND_VOUCHER:
          console.error("Error: Voucher not found.");
          navigate("/voucher-error?voucher-not-found");
          break;
        case VOUCHER_ERRORS.VOUCHER_ALREADY_USED:
          console.error("Error: Voucher already used by someone else.");
          navigate("/voucher-error?voucher-already-used");
          break;
        case VOUCHER_ERRORS.VOUCHER_EXPIRED:
          console.error("Error: Voucher expired.");
          navigate("/voucher-error?voucher-expired");
          break;
        case VOUCHER_ERRORS.VOUCHER_ALREADY_USED_IN_THE_PAST:
          console.error("Error: Voucher already used in the past.");
          navigate("/voucher-error?voucher-already-used-in-the-past");
          break;
        default:
          console.error("Error checking voucher code:", errorMessage || error);
          navigate("/voucher-error?error");
          break;
      }
    },
    retry: false,
  });

  useEffect(() => {
    const urlPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");

    if (urlPath.includes("/activate") && code && user) {
      mutation.mutate(code);
    } else if (!urlPath.includes("/activate") || !user) {
      // Do not navigate until all data is confirmed
      console.log("Waiting for all conditions to be met.");
    } else {
      navigate("/");
    }
  }, [user]);

  return null;
}
