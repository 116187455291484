import { useTranslation } from "react-i18next";
import InfoPage from "./InfoPage";
import { useMutation } from "react-query";
import { signup } from "../../services/auth.service";
import { useState } from "react";
import { ISignupData } from "../../@types";
import Button from "../../components/button";



export function EmailVerificationSent({ registeredUser }: { registeredUser: ISignupData | null }) {

    const { t } = useTranslation("authentication");

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const signupMutation = useMutation(signup, {
        onSuccess: () => {
            setButtonDisabled(true);
            setTimeout(() => {
                setButtonDisabled(false);
            }, 30000)
        },
        onError: () => {
            console.log("Error signing up");
        }
    })


    return (
        <InfoPage
            title={t("register-form.verification-email-sent-title")}
            message={t("register-form.verification-email-sent-message", { email: registeredUser?.email })}
            button={
                <Button
                    disabled={buttonDisabled}
                    className={`bg-Yellow-default w-full p-2 mt-3 ${buttonDisabled ? 'opacity-30' : ''}`}
                    onClick={() => {
                        if (registeredUser) signupMutation.mutate(registeredUser);
                    }}>
                    {t("register-form.request-link-again")}
                </Button>}
        />
    )

}