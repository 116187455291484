import { ArticleContent, AuthorContent, CoachContent } from "../../../../../@types";
import { ExternalContentTeaser } from "../../../../../components/externalContentTeaser";
import { MilestoneContentWrapper } from "../../../home/milestones/milestonesContentList";


export function CoachProductedContent({ coachDetails }: { coachDetails: CoachContent | AuthorContent | null }) {


    return (
        <div className="mt-4">
            {coachDetails?.content?.map((c: ArticleContent) => <ExternalContentTeaser key={c.id} content={c as ArticleContent}/>)}
        </div>
    );



}