import { FormControlLabel, FormGroup, Switch, styled } from "@mui/material";
import { LanguageDropDown } from "../../../../../components/navbar/languageDropdown";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { MyAreaTitle } from "../components";
import Button from "../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/button/types";
import { PopupWrapper } from "../../../../../components/popupWrapper";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Close } from "../../../../../assets/close.svg";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { deleteOwnAccount } from "../../../../../services/users.service";
import { UserDetails } from "../../../onboarding/types";
import { updateUserDetails } from "../../../../../services/onboarding.service";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { NOT_FOUND_ERRORS } from "../../../../../errors/errorCodes";
import { updateVoucher } from "../../../../../services/vouchers.service";
import { IContentWithProgress } from "../../../../../@types";
import { ContentType } from "../../../../../enums";

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginRight: 10,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#202117",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#202117",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#A7B6C5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export function Settings() {
  const { t } = useTranslation("myArea");

  const { logoutUser, user, setUser, configurations, refetchConfigs } =
    useContext(AuthContext);

  const [publicName, setPublicName] = useState(
    user?.usedVoucher?.licenseVariant?.publicName || ""
  );
  const lastValidName = useRef(publicName);

  useEffect(() => {
    if (!user || !user.usedVoucher?.licenseVariant?.publicName) {
      refetchConfigs?.();
    }
  }, []);

  useEffect(() => {
    // Update only if the new name is valid and different
    const newName = user?.usedVoucher?.licenseVariant?.publicName;
    if (newName && newName.trim() !== "") {
      setPublicName(newName);
      lastValidName.current = newName; // Store the last valid name
    } else if (lastValidName.current) {
      setPublicName(lastValidName.current); // Restore the last valid name if the new one is invalid
    }
  }, [user?.usedVoucher?.licenseVariant?.publicName]);

  useEffect(() => {
    if (
      user &&
      !user.usedVoucher?.licenseVariant?.publicName &&
      lastValidName.current
    ) {
      setPublicName(lastValidName.current);
    }
  }, [user]);

  const renderJobRecNotification = configurations?.includesNotificationsJobReco;
  const renderCommunityNotification =
    configurations?.includesNotificationsCommunity;
  const renderWeeklyDigestNotification =
    configurations?.includesNotificationsWeeklyDigest;

  const renderSettingsBlock =
    configurations?.includesNotificationsJobRecoc ||
    configurations?.includesNotificationsCommunity ||
    configurations?.includesNotificationsWeeklyDigest;

  const { isProduction, isAWSDev } = useEnvironment();

  const [pushNotifications, setPushNotifications] = useState(
    user?.userDetails?.pushNotifications
  );
  const [jobRecommendations, setJobRecommendations] = useState(
    user?.userDetails?.jobRecommendations
  );
  const [weeklyDigest, setWeeklyDigest] = useState(
    user?.userDetails?.weeklyDigest
  );
  const [discourseNotifications, setdiscourseNotifications] = useState(
    user?.userDetails?.discourseNotifications
  );

  const [popupOpen, setPopupOpen] = useState(false);

  const handleSubmit = async (payload: UserDetails) => {
    try {
      // handle success
      await submitNewUserDetails.mutate({ userDetails: payload });
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  //const userEmail = user?.email;

  // Check if the email ends with "@restartcareer.de"
  // const isRestartCareerUser = userEmail?.endsWith("@restartcareer.de");

  const submitNewUserDetails = useMutation(
    ["updateUserDetails"],
    updateUserDetails,
    {
      onSuccess: (data) => setUser(data),
    }
  );

  const deleteMutation = useMutation(["deleteAccount"], deleteOwnAccount, {
    onSuccess: () => logoutUser(),
  });

  const [info, setInfo] = useState<{
    message: string;
    type: "ERROR" | "SUCCESS";
  } | null>(null);

  const [voucherCode, setVoucherCode] = useState<string>("");
  const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
  const upgradeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const codeFromUrl = queryParams.get("code");

    if (codeFromUrl) {
      setVoucherCode(codeFromUrl);
      setIsUpgradePopupOpen(true);
    }
  }, []);

  const handleUpgrade = () => {
    updateVoucherMutation.mutate(voucherCode.trim());
  };

  const handlePostponeUpgrade = () => {
    setIsUpgradePopupOpen(false);
    setVoucherCode('')
  };

  const disabled = !voucherCode.length;
  const updateVoucherMutation = useMutation(updateVoucher, {
    onSuccess: (response) => {
      localStorage.removeItem(`lastState-${user?.id}`);
      refetchConfigs();
      setInfo({ message: t("settings.voucher-valid"), type: "SUCCESS" });
      setTimeout(() => {
        setPublicName(response.data?.usedVoucher?.licenseVariant?.publicName);

        setUser(response.data);
        setIsUpgradePopupOpen(false); // Close the popup after upgrading
        setTimeout(() => {
          window.location.href = "/profile/settings";
        }, 500);
      }, 2000);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
      if (error.response.data.message == NOT_FOUND_ERRORS.NOT_FOUND_VOUCHER) {
        setInfo({ message: t("settings.voucher-not-found"), type: "ERROR" });
      } else {
        setInfo({ message: t("settings.voucher-error"), type: "ERROR" });
      }
    },
  });

  return (
    <div>
      {isUpgradePopupOpen && (
        <PopupWrapper isOpen={isUpgradePopupOpen}>
          <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 flex justify-center items-center z-50">
            <div className="bg-white w-3/4 rounded-md p-6 lg:w-1/3">
              <h2 className="text-Grey-dark font-bold mb-2">
                {t("settings.voucherTitle")}
              </h2>
              <div className="flex items-center my-2 text-Grey-dark">
                {t("settings.voucherDescriptionPopUp", { placeholder: publicName })}
              </div>
              <input
                placeholder={t("settings.voucherPlaceHolder") || ""}
                className="border rounded-md mt-1 px-4 py-3 w-full"
                type="text"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
              />
              {info ? (
                <div
                  className="text-lg w-full"
                  style={{
                    color: info.type === "ERROR" ? "#C41429" : "#048273",
                  }}
                >
                  {info.message}
                </div>
              ) : null}
              <div className="flex">
                <button
                  ref={upgradeButtonRef}
                  onClick={() => {
                    handleUpgrade();
                  }}
                  disabled={!voucherCode}
                  className={`w-full mt-6 p-3 lg:w-1/2 btn btn-primary btn-small px-5 ${
                    disabled
                      ? "bg-Grey-tint cursor-not-allowed"
                      : "bg-Yellow-default"
                  }`}
                >
                  <div>{t("settings.voucherButton")}</div>
                </button>
                <div className="px-2"></div>
                <button
                  onClick={() => {
                    handlePostponeUpgrade();
                  }}
                  className={
                    "w-full mt-6 p-3 lg:w-1/2 btn btn-secondary btn-small"
                  }
                >
                  <div>{t("settings.upgradeLater")}</div>
                </button>
              </div>
            </div>
          </div>
        </PopupWrapper>
      )}
      <PopupWrapper isOpen={popupOpen}>
        <div className="bg-white bg-opacity-100 w-1/4 p-5 rounded-lg">
          <div className="flex mb-4 justify-between items-center text-lg font-bold">
            <div className="w-10/12">{t("settings.delete-confirmation")}</div>
            <div className="flex justify-center w-2/12 ">
              <Close
                className="cursor-pointer"
                onClick={() => setPopupOpen(false)}
              />
            </div>
          </div>
          <div className="text-sm text-Grey-dark mb-4">
            {t("settings.delete-warning")}
          </div>
          <div className="flex justify-between">
            <Button
              className="w-full mr-2"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={() => setPopupOpen(false)}
            >
              {t("settings.cancel")}
            </Button>
            <Button
              className="w-full ml-2"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={() => deleteMutation.mutate()}
            >
              {t("settings.confirm")}
            </Button>
          </div>
        </div>
      </PopupWrapper>
      <div className="hidden lg:flex">
        <MyAreaTitle title="Settings" />
      </div>
      {renderSettingsBlock && (
        <>
          <h2 className="text-Grey-dark font-bold">
            {t("settings.notifications")}
          </h2>
          <FormGroup className="w-fit ml-3">
            {/*
            <FormControlLabel className="my-3" onChange={(e: any) => {
                handleSubmit({
                    pushNotifications: !pushNotifications,
                    jobRecommendations,
                    weeklyDigest,
                    discourseNotifications
                })
                setPushNotifications(e.target?.checked)
            }} checked={pushNotifications} control={<CustomSwitch />} label={t("settings.push-notifications")} />
            <FormControlLabel className="my-3" onChange={(e: any) => {
                handleSubmit({
                    pushNotifications,
                    jobRecommendations,
                    weeklyDigest: !weeklyDigest,
                    discourseNotifications
                })
                setWeeklyDigest(e.target?.checked)
                
            }} checked={weeklyDigest} control={<CustomSwitch />} label={t("settings.weekly-email")} />
            
            */}
            {renderJobRecNotification && (
              <FormControlLabel
                className="my-3"
                onChange={(e: any) => {
                  handleSubmit({
                    pushNotifications,
                    jobRecommendations: !jobRecommendations,
                    weeklyDigest,
                    discourseNotifications,
                  });
                  setJobRecommendations(e.target?.checked);
                }}
                checked={jobRecommendations}
                control={<CustomSwitch />}
                label={t("settings.recomendation-email")}
              />
            )}
            {renderCommunityNotification && (
              <FormControlLabel
                className="my-3"
                onChange={(e: any) => {
                  handleSubmit({
                    pushNotifications,
                    jobRecommendations,
                    weeklyDigest,
                    discourseNotifications: !discourseNotifications,
                  });
                  setdiscourseNotifications(e.target?.checked);
                }}
                checked={discourseNotifications}
                control={<CustomSwitch />}
                label={t("settings.discourse")}
              />
            )}
            {renderWeeklyDigestNotification && (
              <FormControlLabel
                className="my-3"
                onChange={(e: any) => {
                  handleSubmit({
                    pushNotifications,
                    jobRecommendations,
                    weeklyDigest: !weeklyDigest,
                    discourseNotifications,
                  });
                  setWeeklyDigest(e.target?.checked);
                }}
                checked={weeklyDigest}
                control={<CustomSwitch />}
                label={t("settings.weekly-email")}
              />
            )}
          </FormGroup>
        </>
      )}
      <div className="mt-9">
        <h2 className="text-Grey-dark font-bold">
          {t("settings.account-management")}
        </h2>
        <Button
          variant={ButtonVariant.SECONDARY}
          className="px-5 py-1 mt-3"
          onClick={() => setPopupOpen(true)}
        >
          {t("settings.delete-account")}
        </Button>
      </div>

      <div className="mt-9">
        <h2 className="text-Grey-dark font-bold mb-2">
          {t("settings.language")}
        </h2>
        <LanguageDropDown />
      </div>

      <div className="mt-9">
        <h2 className="text-Grey-dark font-bold mb-2">
          {t("settings.voucherTitle")}
        </h2>
        <div className="flex items-center my-2 text-Grey-dark">
          {t("settings.voucherDescription", { placeholder: publicName })}
        </div>
        <input
          placeholder={t("settings.voucherPlaceHolder") || ""}
          className="border rounded-md mt-1 px-4 py-3 w-full lg:w-1/2"
          type="text"
          value={voucherCode}
          onChange={(e) => setVoucherCode(e.target.value)}
        />
        {info ? (
          <div
            className="text-lg w-full"
            style={{ color: info.type === "ERROR" ? "#C41429" : "#048273" }}
          >
            {info.message}
          </div>
        ) : null}
        <div className="">
          <button
            ref={upgradeButtonRef}
            onClick={() => {
              updateVoucherMutation.mutate(voucherCode.trim());
            }}
            disabled={!voucherCode}
            className={`w-full mt-6 p-3 lg:w-1/2 btn btn-primary btn-small  ${
              disabled ? "bg-Grey-tint cursor-not-allowed" : "bg-Yellow-default"
            }`}
          >
            <div>{t("settings.voucherButton")}</div>
          </button>
        </div>
      </div>
    </div>
  );
}
