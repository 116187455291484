import { ReactComponent as Logo } from "../../assets/restart_logo.svg";
import { useEnvironment } from "../../hooks/useEnvironment";
import { LanguageDropDown } from "../navbar/languageDropdown";





export function PublicNavbar() {

    const {
        isProduction,
        isAWSDev
    } = useEnvironment();
    
    return (
        <div className={`flex w-full inset-x-0 top-0 items-center p-4 justify-between  bg-Grey-navbar`}>
            <div />
            <a href="/" className="cursor-pointer">
                <Logo className="h-10" />
            </a>

            <LanguageDropDown />
            
        </div>
    )
}