// utils/journeyUtils.js

import { useQuery } from 'react-query';
import { getJourneys } from '../../../../services/onboarding.service';

export function useJourneyData() {
  const { data: journeys } = useQuery(['journey'], getJourneys, {
    onSuccess: (data) => {
      console.log('Journeys: ', data);
    },
  });

  const journeyData = {
    standardUserJourneyId: '',
    reorientationJourneyId: '',
  };

  const option1Journey = journeys?.find(
    (journey) => journey.contentTitleEn === 'Standard User Journey'
  );
  if (option1Journey) {
    journeyData.standardUserJourneyId = option1Journey.platformContentId;
  }

  const option2Journey = journeys?.find(
    (journey) => journey.contentTitleEn === 'Reorientation User Journey'
  );
  if (option2Journey) {
    journeyData.reorientationJourneyId = option2Journey.platformContentId;
  }

  return journeyData;
}
