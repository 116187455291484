import { ITopicPost } from "../../../../@types";
import { TextEditor } from "../TextEditor";
import { useMutation, useQuery } from "react-query";
import {
  createPost,
  fetchPostsForTopic,
  flagTopic,
  postFeedback,
  updatePost,
} from "../../../../services/discourse.service";
import { Post } from "./Post";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as MoreOptions } from "../../../../assets/more_options.svg";
import { ReactComponent as Comment } from "../../../../assets/comment.svg";
import { ReactComponent as LikeEnabled } from "../../../../assets/like_enabled.svg";
import { ReactComponent as LikeDisabled } from "../../../../assets/like_disabled.svg";
import { ReactComponent as BookmarkEnabled } from "../../../../assets/bookmark_active.svg";
import { ReactComponent as BookmarkDisabled } from "../../../../assets/bookmark_inactive.svg";
import { useOutsideClickDetector } from "../../../../hooks/useOutsideClickDetector";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function LikeButton({ isLiked }: { isLiked?: boolean }) {
  return <>{isLiked ? <LikeEnabled /> : <LikeDisabled />}</>;
}

function BookmarkButton({ isBookmarked }: { isBookmarked?: boolean }) {
  return <>{isBookmarked ? <BookmarkEnabled /> : <BookmarkDisabled />}</>;
}

export function CommunityTopic({
  topic,
  open,
  users,
  feedback,
  refetchFeedback,
  onClick
}: {
  topic: any;
  open: boolean;
  users?: any;
  feedback?: { isLiked?: boolean; isBookmarked?: boolean; topicId: number };
  refetchFeedback: () => void;
  onClick?: () => void
}) {
  const { t } = useTranslation("community");
  const location = useLocation();
  const [value, setValue] = useState("");
  const [postError, setPostError] = useState<string | null>(null);
  const [postToEdit, setPostToEdit] = useState<ITopicPost | null>(null);
  const [moreOptions, setMoreOptions] = useState<number | null>(null);
  const [isPostReported, setIsPostReported] = useState(false);
  const [reportError, setReportError] = useState<string | null>(null);


  const topicRef = useRef<any>(null);

  const topicId = location.pathname.split('/')[3];

  useEffect(() => {

    if (topicId == topic.id) {
      const element = topicRef?.current;
      // ref?.current?.scrollIntoView({ behavior: "smooth" });
      if (element) {

        element.scrollIntoView({behavior: "smooth"});

        setTimeout(() => {
          element.style.backgroundColor = "#FFDA6F";
          element.style.opacity = 0.9;
          element.style.borderRadius = '8px';
          setTimeout(() => {
            element.style.backgroundColor = "white";
            element.style.opacity = 1;
            element.style.borderRadius = 0;
          }, 1000);
        }, 500);
      }
    }
  }, [topic])


  const likeTopicMutation = useMutation(
    ["setFeedback", topic, feedback?.isLiked],
    () => postFeedback(topic.id, !feedback?.isLiked, feedback?.isBookmarked),
    {
      onSuccess: () => refetchFeedback(),
    }
  );
  const bookmarkTopicMutation = useMutation(
    ["setFeedback", topic, feedback?.isBookmarked],
    () => postFeedback(topic.id, feedback?.isLiked, !feedback?.isBookmarked),
    {
      onSuccess: () => refetchFeedback(),
    }
  );

  const moreOptionsRef = useRef<any>(null);

  useOutsideClickDetector(moreOptionsRef, () => setMoreOptions(null));

  const { data: topicPosts, refetch } = useQuery(
    ["topicPosts", topic, open],
    () => {
      if (open) return fetchPostsForTopic(topic.id);
    }
  );

  const submitPostMutation = useMutation(
    ["createPost"],
    (mutationArgs: { topicId: number; postContent: string }) =>
      createPost(mutationArgs.topicId, mutationArgs.postContent),
    {
      onSuccess: () => {
        setPostToEdit(null);
        setValue("");
        setPostError(null);
        refetch();
      },
      onError: (error: any) => {
        const status = error.response?.status;
        let errorMessage;

        if (status === 422 || status === 429) {
          errorMessage = t(`${status}`);
        } else {
          errorMessage = t("default");
        }

        setPostError(errorMessage);
      },
    }
  );

  const editPostMutation = useMutation(
    ["updatePost"],
    (mutationArgs: { postId: number; updatedContent: string }) =>
      updatePost(mutationArgs.postId, mutationArgs.updatedContent),
    {
      onSuccess: () => {
        refetch();
        setValue("");
        setPostToEdit(null);
      },
    }
  );

  const topicCreator = users?.find(
    (user: any) =>
      user?.id ===
      topic?.posters?.find((poster: any) =>
        poster?.description?.includes("Original Poster")
      )?.user_id
  );

  const getAvatarUrl = (avatarTemplate: string) => {
    if (!avatarTemplate) {
      return "";
    }

    if (avatarTemplate.startsWith("https")) {
      return avatarTemplate.replace("{size}", "30"); // Full URL, just replace {size}
    } else {
      // Relative path, prepend the domain and replace {size}
      return `https://restartcareer.discourse.group${avatarTemplate}`.replace(
        "{size}",
        "30"
      );
    }
  };

  const topicCreatorAvatarUrl = getAvatarUrl(topicCreator?.avatar_template);
  const createdByAvatarUrl = getAvatarUrl(
    topic?.details?.created_by?.avatar_template
  );

  const reportMutation = useMutation((postId: number) => flagTopic(postId));

  const flagPost = (postId: number) => {
    console.log("flagPost called");
    reportMutation.mutate(postId, {
      onSuccess: () => {
        setIsPostReported(true); // Set the reported state to true on successful report
        setReportError(null);
      },
      onError: (error: any) => {
        if (error.response?.status === 403) {
          setIsPostReported(false);
          setReportError(t("report-error"));
        }
      },
    });
  };

  return (
    <div ref={topicRef} className={`${onClick ? 'cursor-pointer hover:bg-Yellow-default hover:rounded-md hover:bg-opacity-25' : ''}`} onClick={(e) => {
      if (onClick) {
        e.stopPropagation();
        onClick();
      }
    }}>
      <div className="flex">
        <img
          src={topicCreatorAvatarUrl || createdByAvatarUrl}
          className="mr-2.5 rounded-full h-[40px]"
          alt=""
        />

        <div className="w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="font-bold">
                {topicCreator?.name || topic?.details?.created_by?.username}
              </div>
            </div>
            <div className="relative align-self-center">
              <div
                ref={moreOptionsRef}
                onClick={(e) => {
                  if (onClick) {
                    e.stopPropagation();
                    onClick()
                  }
                  else {
                    setMoreOptions(topic.id)
                  }
                }}
                className={`${moreOptions == topic.id
                  ? "border-opacity-100"
                  : "border-opacity-0"
                  } border-black cursor-pointer rounded-md p-2 border-2`}
              >
                <MoreOptions />
              </div>
              {moreOptions == topic.id ? (
                <div
                  ref={moreOptionsRef}
                  className="absolute left-[-28px] top-[50px] lg:left-[50px] lg:top-0 bg-Grey-light-3 rounded-md p-2 px-3"
                >
                  <div
                    className="cursor-pointer"
                    onClick={(e) => {
                      if (onClick) {
                        e.stopPropagation();
                        onClick()
                      }
                      else {
                        e.stopPropagation();
                        if (!isPostReported) {
                          flagPost(topicPosts?.[0]?.id ?? 0);
                        }
                      }

                    }}
                    style={{ color: isPostReported ? "#C41429" : "inherit" }}
                  >
                    {isPostReported ? t("reported") : t("report")}
                  </div>
                  {reportError && (
                    <p style={{ color: "#C41429" }}>{reportError}</p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <h1 className="mt-1 font-semibold ">{topic.title}</h1>
          <p
            className="mt-1 mb-2"
            dangerouslySetInnerHTML={{ __html: topicPosts?.[0]?.cooked || "" }}
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Comment />
          <p className="ml-1 text-Grey font-medium">{`${topic.posts_count - 1
            } ${t("comments")}`}</p>
        </div>
        <div className="flex items-center">
          <div
            onClick={(e: any) => {
              if (onClick) {
                e.stopPropagation();
                onClick()
              }
              else {
                e.stopPropagation();
                likeTopicMutation.mutate();
              }

            }}
          >
            <LikeButton isLiked={feedback?.isLiked} />
          </div>
          <div
            onClick={(e: any) => {
              if (onClick) {
                e.stopPropagation();
                onClick()
              }
              else {
                e.stopPropagation();
                bookmarkTopicMutation.mutate();
              }

            }}
          >
            <BookmarkButton isBookmarked={feedback?.isBookmarked} />
          </div>
        </div>
      </div>

      <hr className="border border-Grey-light-2 my-3" />

      {open ? (
        <>
          {topicPosts?.map((tp: ITopicPost, i: number) => {
            if (i === 0) return null;

            return (
              <>
                <div className="ml-[50px]">
                  <Post
                    key={tp.id}
                    editPost={() => setPostToEdit(tp)}
                    post={tp}
                  />
                </div>
                {i + 1 !== topicPosts?.length && (
                  <hr className="border border-Grey-light-2 my-3" />
                )}
              </>
            );
          })}

          <>
            <hr className="border border-Grey-light-2 my-3" />
            <div className="ml-[50px]">
              <TextEditor
                topicId={topic.id}
                postId={postToEdit?.id || null}
                editPost={editPostMutation}
                submitPost={submitPostMutation}
                defaultContent={postToEdit?.cooked || ""}
                placeholder={t("reply-placeholder") || ""}
                value={value}
                setValue={setValue}
                setError={postError || ""}
              />
            </div>
          </>
        </>
      ) : null}
    </div>
  );
}
