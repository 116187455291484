import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "./useEnvironment";



export function useLanguage() {

    const { i18n } = useTranslation();
    
    useEffect(() => {
        const lang: string = localStorage.getItem('language') || 'de';
        i18n.changeLanguage(lang);
      }, [])


}