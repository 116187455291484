import { ReactElement, useEffect, useRef } from "react";
import Button from "../../../../../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../../../../components/button/types";
import { useTranslation } from "react-i18next";
import { getAvailableTemplate } from "../../../../../../../../../services/selfAssessment.service";
import { ReactComponent as RightArrow } from '../../../../../../../../../assets/rigth-arrow-calendar.svg';
import { ReactComponent as LeftArrow } from '../../../../../../../../../assets/left-arrow-calendar.svg';


const TemplateNavigateButton = ({ onClick, icon }: { onClick: () => void, icon: ReactElement }) => {


  return (
    <div
      className="rounded bg-white mr-2 p-2 px-3 cursor-pointer border border-1 border-Black"
      onClick={onClick}
    >
      {icon}
    </div>
  )


}
function TemplateDisplay({
  isActive,
  templateRef,
  onClick,
  template,

}: {
  isActive: boolean;
  templateRef: any;
  onClick: () => void;
  template: any;

}) {


  const containerClass = `
  flex flex-col w-full
  justify-center items-center
`;


  return (
    <div className={containerClass}>
      <div className={isActive ? "w-[300px] p-4" : "w-60 p-4"}>
        
        <img
          width={400}
          ref={templateRef}
          onClick={onClick}
          className="cursor-pointer"
          src={template.templateUrl}
        />
      </div>

      {isActive && (
        <div className="flex w-full flex-col items-center mt-2">
          <div className="text-xl">{template.name}</div>
        </div>
      )}
    </div>
  );
}

export function TemplateSelector({
  selectedTemplate,
  setSelectedTemplate,
  onSelect,
  templates,
}: {
  selectedTemplate: number;
  setSelectedTemplate: (template: number) => void;
  onSelect: () => void;
  templates: any;
}) {
  const { t } = useTranslation("myArea");

  const templateMapping = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const scrollableContainer = useRef(null);

  const handleViewClick = async () => {
    try {
      const template = templates[selectedTemplate];
      const templateToPreview = await getAvailableTemplate(template.name); // Pass the template name here
      const templateUrl = templateToPreview;
      setTimeout(()=>window.open(templateUrl, "_blank"))
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  useEffect(() => {
    const targetRef = templateMapping[selectedTemplate] as any;
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center", // Change block value to center
        inline: "center",
      });
    }
  }, [selectedTemplate]);

  return (
    <>
      <div className="relative border border-Grey-metallic bg-gray-300 rounded-md mt-5">
        <div ref={scrollableContainer} className="max-w-full overflow-x-hidden">
          <div className="flex justify-center items-center h-full w-full my-5">
            <TemplateDisplay
              isActive={true}
              onClick={() => setSelectedTemplate(selectedTemplate)}
              templateRef={templateMapping[selectedTemplate]}
              template={templates[selectedTemplate]}
            />
          </div>
        </div>
        <div className="flex justify-between w-[101%] px-3 absolute top-52">
          <TemplateNavigateButton
            onClick={() =>
              setSelectedTemplate(
                selectedTemplate - 1 >= 0 ? selectedTemplate - 1 : templates.length - 1
              )}
            icon={<LeftArrow />}
          />
          <TemplateNavigateButton
            onClick={() =>
              setSelectedTemplate(
                selectedTemplate + 1 < templates.length ? selectedTemplate + 1 : 0
              )}
            icon={<RightArrow />}
          />
        </div>
      </div>
      <div className="flex w-full justify-around py-3">
        <Button
          className="w-1/2 mr-2"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={handleViewClick}
        >
          {t(`assessments.cv-check.download-button`)}
        </Button>
        <Button
          className="w-1/2 ml-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={onSelect}
        >
          {t(`assessments.cv-check.select-button`)}
        </Button>
      </div>
    </>
  );

}
