import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAssessmentsOverviewDownload,
  getAssessmentsOverviewForPhase,
  getFirstSelfAssessment,
  getSelfAssessments,
} from "../../../../../../services/selfAssessment.service";
import {
  AssessmentContent,
  IContentWithProgress,
} from "../../../../../../@types";
import { AssessmentPreview } from "./AssessmentPreview";
import Button from "../../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../components/button/types";
import { useContentAndProgress } from "../../../../../../contexts/UserProgressContext";

export function AssessmentOverview() {
  const { t } = useTranslation("myArea");
  const location = useLocation();
  const navigate = useNavigate();
  const currentPhaseId =
    location.pathname.split("/")[location.pathname.split("/").length - 2];
  const phaseId =
    location.pathname.split("/")[location.pathname.split("/").length - 2];

  const { data: assessments } = useQuery(
    [phaseId, "phaseAssessments"],
    () => getAssessmentsOverviewForPhase(phaseId),
    {
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          navigate("/notfound");
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
      retry: false, // Disable automatic retries
    }
  );

  const { phases, milestones, setOpenedContent } = useContentAndProgress();
  const { data: firstAssessmentId } = useQuery([], getFirstSelfAssessment);

  if (firstAssessmentId) {
    const handleAssessmentClick = () => {
      // Need to activate correspondent milestone and phase
      const milestone =
        milestones?.filter((ms: any) =>
          ms.content?.childContentIds.includes(firstAssessmentId)
        )[0] || null;
      const phase =
        phases?.filter((phase: any) =>
          phase.content?.childContentIds.includes(
            milestone?.content.platformContentId || ""
          )
        )[0] || null;
      navigate(
        `/phase/${phase?.content.platformContentId}/milestone/${milestone?.content.platformContentId}/content/${firstAssessmentId}`
      );
      setOpenedContent(firstAssessmentId.assessmentId);
    };

    if (assessments?.length == 0) {
      return (
        <div className="p-6">
          {t("assessments.no-assessments-answered")}
          <div className="mt-4">
            <Button
              onClick={handleAssessmentClick}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.SMALL}
            >
              {t("assessments.click-here-first-assessment")}
            </Button>
          </div>
        </div>
      );
    }
  }

  const handleDownloadClick = async () => {
    await getAssessmentsOverviewDownload(currentPhaseId);
  };

  return (
    <div>
      <div className="bg-Grey-light-3 flex justify-end items-center p-7 rounded-md">
        <Button
          variant={ButtonVariant.SECONDARY}
          className="px-3 ml-4"
          onClick={handleDownloadClick}
        >
          {t("assessments.download")}
        </Button>
      </div>
      {assessments?.map((a: AssessmentContent) => (
        <AssessmentPreview key={a.id} content={a} />
      ))}
    </div>
  );
}
