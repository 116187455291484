import { useQuery } from "react-query";
import { useContentAndProgress } from "../../../../contexts/UserProgressContext";
import { getContentById } from "../../../../services/content.service";
import { ContentType } from "../../../../enums";
import { Article } from "./article";
import {
  AuthorsDisplay,
  ContentHeader,
  Feedback,
  InfoAndActions,
} from "./components";
import {
  ArticleContent,
  AssessmentContent,
  CoachingContent,
  IContentWithProgress,
} from "../../../../@types";
import { Assessment } from "./assessment";
import { Coaching } from "./coaching";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

export function Content() {
  const { openedContent, activeMilestone } = useContentAndProgress();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const { data: content } = useQuery(
    ["content", openedContent],
    () => getContentById({ platformContentId: openedContent || undefined }),
    {
      onSuccess: (data) => {
        setIsLoading(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          navigate("/notfound"); // Navigate to the not found page
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
      retry: false, // Disable automatic retries
    }
  );

  const getContentBasedOnType = () => {
    if (content?.contentType == ContentType.ARTICLE)
      return <Article content={content} />;

    if (content?.contentType == ContentType.ASSESSMENT)
      return <Assessment content={content as AssessmentContent} />;

    if (content?.contentType == ContentType.COACHING)
      return <Coaching content={content as CoachingContent} />;
  };
  if (isLoading) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return (
    <div className="max-w-full">
      <ContentHeader content={content as ArticleContent} />
      <h1 className={`text-2xl font-bold mb-5`}>{content?.title}</h1>
      <div className="text-Grey text-xl ">
        {(content as ArticleContent)?.teaserText}
      </div>
      {!(content as ArticleContent)?.image ? (
        <AuthorsDisplay
          belowImage={false}
          authors={(content as ArticleContent)?.authors}
        />
      ) : null}
      {content ? (
        <InfoAndActions
          belongsToProgress={!!activeMilestone}
          content={content as ArticleContent}
        />
      ) : null}
      {getContentBasedOnType()}
      {content ? (
        <div className="my-10">
          <InfoAndActions
            hideEffort
            belongsToProgress={!!activeMilestone}
            content={content as ArticleContent}
          />
        </div>
      ) : null}
      {content ? <Feedback content={content as ArticleContent} /> : null}
    </div>
  );
}
