import { useTranslation } from "react-i18next";
import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { Author } from "./author";


export function Authors() {

    useOnPageMount();
    const location = useLocation();

    const selectedCoach = location.pathname.split('/').length > 1 ? location.pathname.split('/')[2] : null

    return (
        <div className="px-6 py-6 md:px-9 lg:py-9 lg:px-12  flex w-full">

            <div className="flex w-full flex-col lg:flex-row">
                <div className="w-full lg:w-1/4"></div>
                <div className="w-full lg:px-4 lg:w-3/4">
                    {selectedCoach ? <Author /> : null}
                </div>
            </div>
        </div>
    )

}