import { Field } from "formik"
import { ICustomFieldWithErrorsInput } from "../../@types"



export function CustomFieldWithError({error, touched, type, id, name, className, disabled, placeholder} : ICustomFieldWithErrorsInput) {


    return (
        <>
            <Field className={`border px-2 rounded ${className} ${error ? 'border-red-500' : ''}`} disabled={disabled} type={type} id={id} name={name} {...(placeholder && { placeholder })} />
            {error && touched ? <div className="text-sm text-red-500">{error}</div> : null}
        </>
    )


}