import { ArticleContent, CoachingContent, ExperienceContent, FormattedContent, IContentWithProgress, MilestoneContent } from "../../../../../@types";
import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";
import { useLocation, useNavigate } from "react-router-dom";
import { makeUrlFromTitle } from "../../../../../utils";
import { ContentType } from "../../../../../enums";
import { AssessmentContent } from "../../../../../@types";
import { GenericTeaserDisplay } from "./GenericTeaserDisplay";
import { CoachingTeaserDisplay } from "./CoachingTeaserDisplay";
import { CircularProgress } from "@mui/material";




export const MilestoneContentWrapper = ({ content, onClick }: {  content: ArticleContent | AssessmentContent | CoachingContent | ExperienceContent, onClick: () => void }) => {

    if (content.hideFromMilestone) {
        return null;  // Return null or any placeholder if needed when content should be hidden
    }
    if (!content) {
        // Render loading state while coachDetails are being fetched
        return (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress style={{ color: "#DCAA00" }} />
          </div>
        );
      }
    return (
        <>
            {content.contentType == ContentType.COACHING ?
                <CoachingTeaserDisplay content={content as CoachingContent} onClick={onClick} />
                :
                <GenericTeaserDisplay hideActions={content.contentType === ContentType.EXPERIENCE} content={content as ArticleContent | AssessmentContent} onClick={onClick} />
            }
        </>
    )
}


export function MilestonesContentList() {

    const navigate = useNavigate();
    const location = useLocation();

    const { activeMilestoneContent } = useContentAndProgress();


    const handleContentClick = (content: FormattedContent) => {
        navigate(`${location.pathname.split('/').slice(0, 5).join('/')}/content/${content.id}/${makeUrlFromTitle(content?.title)}`);
    }
  


    return (
        <div className="w-full">

            <div>
                {(activeMilestoneContent?.content as ArticleContent[])?.map((content: ArticleContent, idx: number) => {
                    return <MilestoneContentWrapper content={content} onClick={() => handleContentClick(content)} key={idx} />
                })}
            </div>
        </div>
    )

}