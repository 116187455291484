import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { getImprint } from "../../services/legal.service";

export default function Imprint() {
    const { t } = useTranslation("imprint");
    const { user } = useContext(AuthContext);;
    const [content, setContent] = useState("")

    const fetchData = async () => {
        try {
            const res = await getImprint()

            if(res?.renderedContent) {
                setContent(res?.renderedContent)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    

    return (
        <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
            <div className={`w-full ${user && "md:w-1/2" }`}>
                <h1 className="text-Yellow-default text-lg font-semibold mb-4">{t("page-title")}</h1>
                <div dangerouslySetInnerHTML={{ __html: content || ""}}></div>
            </div>
        </div>
    )
}