import { useTranslation } from "react-i18next";
import { CoachContent, CoachReview } from "../../../../../@types";

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const formatDate = (dateString: string, t: any) => {
    const date = new Date(dateString);
    const month = t(`months.${months[date.getMonth()]}`);
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;


}


export function Reviews({ coachDetails }: { coachDetails: CoachContent | null }) {

    const {t} = useTranslation("coaches");

    return (
        <div className="mt-4">
            {/* <h2 className="font-bold text-Grey-dark text-lg">{t("reviews")}</h2> */}
            {coachDetails?.reviews?.map((c: CoachReview) => {
                return (
                    <div key={c.id} className="mt-3">
                        <div className="text-Grey">{formatDate(c.date, t)}</div>
                        
                        <div className="text-Black" dangerouslySetInnerHTML={{ __html: c?.text || "" }}></div>

                        <hr/>
                    </div>
                )
            }) }
            {/*  */}
        </div>
    )

}