import { useNavigate } from "react-router-dom";
import { ArticleContent, CoachingContent, ExperienceContent, IContentWithProgress } from "../../@types";
import { useContentAndProgress } from "../../contexts/UserProgressContext";
import { MilestoneContentWrapper } from "../../pages/private/home/milestones/milestonesContentList";
import { makeUrlFromTitle } from "../../utils";
import { ContentType } from "../../enums";

export function ExternalContentTeaser({ content }: { content: ArticleContent | CoachingContent | ExperienceContent }) {

    const navigate = useNavigate();

    const { phases, milestones, setOpenedContent } = useContentAndProgress();

    const handleArticleClick = () => {
        if (content.contentType === ContentType.EXPERIENCE) {
            navigate(`/community/experience/${content?.id}`);
        } else {
            const milestone = milestones?.filter((ms: IContentWithProgress) => ms.content?.childContentIds.includes(content.id))[0] || null;
            const phase = phases?.filter((phase: IContentWithProgress) => phase.content?.childContentIds.includes(milestone?.content.platformContentId || ''))[0] || null;
            navigate(`/phase/${phase?.content.platformContentId}/milestone/${milestone?.content.platformContentId}/content/${content.id}/${makeUrlFromTitle(content?.title)}`);
        }
        setOpenedContent(content.id);
    }

    return (
        <div className="w-full">
            <MilestoneContentWrapper content={content as ArticleContent | CoachingContent} onClick={() => handleArticleClick()} />
        </div>
    )
}