import { CoachContent, CoachingContent, FormattedContent } from "../../../../../@types";
import { CoachListItem } from "../../../coaches";



export function Coaching({ content }: { content: FormattedContent | null }) {

    return <>
        <div className="py-6" dangerouslySetInnerHTML={{ __html: (content as CoachingContent)?.renderedContent || "" }} />
        <div className="text-xl font-bold mb-4">
            {(content as CoachingContent)?.coachingTitle}
        </div>
        <div className="grid grid-cols-3 gap-2">
            {(content as CoachingContent)?.coaches?.map((c: CoachContent) => <CoachListItem coach={c} />)}
        </div>
    </>
}