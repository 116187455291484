import { useEffect, useMemo, useRef, useState } from "react";
import {
  IContentWithProgress,
  IContentfulSearchResult,
  IDiscourseSearchResult,
  ISearchResult,
} from "../../../../@types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOutsideClickDetector } from "../../../../hooks/useOutsideClickDetector";
import { ContentType } from "../../../../enums";
import Button from "../../../button";
import { ButtonSize, ButtonVariant } from "../../../button/types";
import { useContentAndProgress } from "../../../../contexts/UserProgressContext";
import {
  ArticleResultDisplayer,
  AssessmentResultDisplayer,
  CoachResultDisplayer,
  CoachingResultDisplayer,
  PostResultDisplayer,
} from "../ResultsDisplayers";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useContext } from "react";
export type GroupedSearch = {
  coachResults: ISearchResult[];
  articlesResults: ISearchResult[];
  assessmentsResults: ISearchResult[];
  coachingResult: ISearchResult[];
  forumResult: ISearchResult[];
};

export function SearchDropdown({
  searchResults = [],
  searchPrompt,
}: {
  searchResults?: ISearchResult[];
  searchPrompt: string;
}) {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation("fullSearch");
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  useOutsideClickDetector(dropdownRef, () => setSearchDropdownOpen(false));
  const { configurations } = useContext(AuthContext);;
  const shouldShowForumResults = configurations?.includesCommunity !== false;

  const groupedSearchResults: GroupedSearch = useMemo(() => {
    const coachResults = searchResults.filter(
      (sr) => sr.contentType === ContentType.COACH
    );

    const articlesResults = searchResults.filter(
      (sr) => sr.contentType === ContentType.ARTICLE
    );
    const assessmentsResults = searchResults.filter(
      (sr) => sr.contentType === ContentType.ASSESSMENT
    );
    const coachingResult = searchResults.filter(
      (sr) => sr.contentType === ContentType.COACHING
    );
    let forumResult: ISearchResult[] = [];

    if (shouldShowForumResults) {
      forumResult = searchResults.filter(
        (sr) => sr.contentType === ContentType.POST
      );
    }

    return {
      coachResults,
      articlesResults,
      assessmentsResults,
      coachingResult,
      forumResult,
    };
  }, [searchResults, shouldShowForumResults]);

  const getTitle = (key: string) => {
    switch (key) {
      case "articlesResults":
        return t("articles");
      case "coachResults":
        return t("coaches");
      case "coachingResult":
        return t("coachings");
      case "assessmentsResults":
        return t("assessments");
      case "forumResult":
        return t("forum");
    }
  };

  useEffect(() => {
    if (searchResults.length > 0) {
      setSearchDropdownOpen(true);
    }
  }, [searchResults]);

  return (
    <div
      className="absolute w-full top-10 z-50"
      style={{ boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)" }}
      ref={dropdownRef}
    >
      {searchResults?.length && searchDropdownOpen ? (
        <>
          <div className="w-full bg-white border max-h-[500px] overflow-y-auto">
            {Object.entries(groupedSearchResults)
              ?.filter(([k, v]) => v.length > 0)
              ?.map(([k, v], i) => (
                <div className="py-2">
                  <h2 className="text-Grey-dark text-lg font-bold mb-1 px-4">
                    {getTitle(k)}
                  </h2>
                  {v.map((sr: ISearchResult) => (
                    <SearchResultDisplay
                      classNames="py-3 px-4"
                      searchResult={sr}
                      closeDropdown={() => setSearchDropdownOpen(false)}
                      onClose={() => {}}
                    />
                  ))}
                  <hr />
                </div>
              ))}
          </div>
          {Object.values(groupedSearchResults).some(
            (category) => category.length > 0
          ) && (
            <div className="p-2 bg-white border rounded-b">
              <Button
                onClick={() => navigate(`/search?${searchPrompt}`)}
                className="w-full"
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
              >
                {t("see-all")}
              </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export function SearchResultDisplay({
  searchResult,
  closeDropdown,
  onClose,
  classNames,
}: {
  classNames?: string;
  searchResult: ISearchResult;
  closeDropdown: () => void;
  onClose: () => void;
}) {
  const navigate = useNavigate();

  const { phases, milestones } = useContentAndProgress();

  const handleArticleClick = () => {
    navigate("/");
    // Need to activate correspondent milestone and phase
    const milestone =
      milestones?.filter((ms: IContentWithProgress) =>
        ms.content?.childContentIds.includes(searchResult.platformContentId)
      )[0] || null;
    const phase =
      phases?.filter((phase: IContentWithProgress) =>
        phase.content?.childContentIds.includes(
          milestone?.content.platformContentId || ""
        )
      )[0] || null;
    navigate(
      `/phase/${phase?.content.platformContentId}/milestone/${milestone?.content.platformContentId}/content/${searchResult.platformContentId}`
    );
    closeDropdown();
    onClose();
  };

  const handleCoachClick = () => {
    navigate(`coaches/${searchResult.platformContentId}/overview`);
    closeDropdown();
  };

  const handlePostClick = () => {
    navigate(
      `/thread/${(searchResult as IDiscourseSearchResult).topic.id}/reply/${
        (searchResult as IDiscourseSearchResult).platformContentId
      }`
    );
    closeDropdown();
  };

  const info = () => {
    switch (searchResult.contentType) {
      case ContentType.ARTICLE:
        return (
          <ArticleResultDisplayer
            searchResult={searchResult as IContentfulSearchResult}
            onClick={handleArticleClick}
          />
        );

      case ContentType.ASSESSMENT:
        return (
          <AssessmentResultDisplayer
            searchResult={searchResult as IContentfulSearchResult}
            onClick={handleArticleClick}
          />
        );

      case ContentType.COACH:
        return (
          <CoachResultDisplayer
            searchResult={searchResult as IContentfulSearchResult}
            onClick={handleCoachClick}
          />
        );

      case ContentType.COACHING:
        return (
          <CoachingResultDisplayer
            searchResult={searchResult as IContentfulSearchResult}
            onClick={handleArticleClick}
          />
        );

      case ContentType.POST:
        return (
          <PostResultDisplayer
            searchResult={searchResult as IDiscourseSearchResult}
            onClick={handlePostClick}
          />
        );

      default:
        return (
          <div>
            <h1>{(searchResult as IContentfulSearchResult).title}</h1>
          </div>
        );
    }
  };

  return (
    <>
      <div className={`${classNames} cursor-pointer hover:bg-Grey-light-3`}>
        {info()}
      </div>
    </>
  );
}
