import { useTranslation } from "react-i18next";




export function NavigationBar({ goToLogin, goToSignup }: { goToLogin?: () => void, goToSignup?: () => void }) {

    const {t} = useTranslation("authentication");

    return (
        <div className="flex flex-col w-full">
            <div className="flex w-full justify-start">
                <div>
                    <div onClick={goToLogin} className={`p-3 mx-4 cursor-pointer ${goToSignup ? 'font-bold' : ''}`}>
                        {t("login")}
                    </div>
                    <div className={goToSignup ? `flex h-[2px] w-full bg-Yellow-default` : `flex h-[2px] w-full bg-Grey-tint`  }   />
                </div>
                <div>
                    <div onClick={goToSignup} className={`p-3 mx-4 cursor-pointer ${goToLogin ? 'font-bold' : ''}`}>
                        {t("register")}
                    </div>
                    <div className={goToLogin ? `flex h-[2px] w-full bg-Yellow-default` : `flex h-[2px] w-full bg-Grey-tint`  }   />
                </div>
                <div className="flex flex-col w-full items-center justify-end">
                    
                    <div className="flex h-[2px] w-full bg-Grey-tint" />
                </div>
            </div>

        </div>
    )

}