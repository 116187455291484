/* React imports */
import { useEffect, useState } from "react";

/* Third party imports */
import { Field, Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

/* Local imports */
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
import { getEnum } from "../../../services/onboarding.service";
import { KeyValue, StepCareerProps } from "./types";
import { AuthContext } from "../../../contexts/AuthContext";

export default function StepCareer({ onNext, onSkip }: StepCareerProps) {
  const { t } = useTranslation("onboarding");

  const [careerLevels, setCareerLevels] = useState<KeyValue[]>([]);
  const [industries, setIndustries] = useState<KeyValue[]>([]);
  const [disciplines, setDisciplines] = useState<KeyValue[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchEnums() {
      const careerLevels = await getEnum("career-level");
      setCareerLevels(careerLevels);
      const industries = await getEnum("industries");
      setIndustries(industries);
      const disciplines = await getEnum("disciplines");
      setDisciplines(disciplines);
      setIsLoading(false);
    }
    fetchEnums();
  }, []);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Formik
          initialValues={{
            lastPosition: "",
            careerLevel: "",
            industry: "",
            discipline: "",
          }}
          onSubmit={onNext}
        >
          {({ values, handleChange }) => (
            <div className="w-full">
              <div className="flex flex-col">
                <h1 className="text-xl font-bold leading-7 mb-1">
                  {t("stepCareer.title")}
                </h1>
                <h2 className="text-base mt-2">{t("stepCareer.subtitle")}</h2>
                <Form className="w-full  mt-4">
                  <div className="flex flex-col w-full">
                    <label className="text-base mb-1">
                      {t("stepCareer.lastPosition")}
                    </label>
                    <Field
                      type="text"
                      placeholder={t("stepCareer.lastPositionPlaceHolder")}
                      className="border rounded-lg  px-4 py-3.5  text-base mb-4"
                      id="lastPosition"
                      name="lastPosition"
                      value={values?.lastPosition}
                      onChange={handleChange}
                    />

                    <label className="text-base mb-1">
                      {t("stepCareer.careerLevel")}
                    </label>
                    <Field
                      as="select"
                      id="career-select"
                      placeholder={t("stepCareer.please-select")}
                      name="careerLevel"
                      className="border rounded-lg  px-4 py-3.5  text-base mb-4"
                      value={values.careerLevel}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {t("stepCareer.please-select")}
                      </option>
                      {careerLevels?.map((careerLevel) => (
                        <option
                          key={careerLevel?.key}
                          value={careerLevel?.value}
                        >
                          {t(`careerLevel.${careerLevel?.value}`)}
                        </option>
                      ))}
                    </Field>
                    <label className="text-base mb-1">
                      {t("stepCareer.industry")}
                    </label>

                    <Field
                      as="select"
                      id="industry-select"
                      placeholder={t("stepName.please-select")}
                      name="industry"
                      className="border rounded-lg px-4 py-3.5  text-base  mb-4"
                      value={values.industry}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {t("stepCareer.please-select")}
                      </option>
                      {industries?.map((industry) => (
                        <option key={industry?.key} value={industry?.value}>
                          {t(`industries.${industry?.value}`)}
                        </option>
                      ))}
                    </Field>
                    <label className="text-base mb-1">
                      {t("stepCareer.discipline")}
                    </label>
                    <Field
                      as="select"
                      id="discipline-select"
                      placeholder={t("stepName.please-select")}
                      name="discipline"
                      className="border rounded-lg  px-4 py-3.5  text-base"
                      value={values.discipline}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {t("stepCareer.please-select")}
                      </option>
                      {disciplines?.map((discipline) => (
                        <option key={discipline?.key} value={discipline?.value}>
                          {t(`disciplines.${discipline?.value}`)}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="w-full flex justify-between	mt-6">
                    <Button
                      className="w-full"
                      type="submit"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.MEDIUM}
                    >
                      {t("stepCareer.next")}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      )}
    </>
  );
}
