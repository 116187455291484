import { axiosInstance } from ".";
import { ICommunityTopic, IProxyRequestPayload, ITopicPost } from "../@types";

export enum HttpMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  PATCH = "patch",
}

export enum SupportedSource {
  CONTENTFUL = "Contentful",
  DISCOURSE = "Discourse",
}

export async function fetchLatestTopics(): Promise<ICommunityTopic[]> {
  const requestBody: IProxyRequestPayload = {
    endpoint: "/latest.json",
    method: HttpMethod.GET,
    source: SupportedSource.DISCOURSE,
  };
  return (await axiosInstance.post("/content/proxy", requestBody)).data;
}

export async function fetchTopicById(topicId : number): Promise<ICommunityTopic> {

  const requestBody: IProxyRequestPayload = {
    endpoint: `/t/${topicId}.json`,
    method: HttpMethod.GET,
    source: SupportedSource.DISCOURSE,
  };
  return (await axiosInstance.post("/content/proxy", requestBody)).data;
}

export async function fetchPostsForTopic(
  topicId: number
): Promise<ITopicPost[]> {
  const requestBody: IProxyRequestPayload = {
    endpoint: `/t/${topicId}/posts.json`,
    method: HttpMethod.GET,
    source: SupportedSource.DISCOURSE,
  };

  const posts = (await axiosInstance.post("/content/proxy", requestBody)).data
    .post_stream.posts;
  posts.map((p: ITopicPost) => {
    const processedAvatarLink = p["avatar_template"].replace("{size}", "30");
    p["avatar_template"] = `${processedAvatarLink}`;
    return p;
  });

  return posts;
}

export async function createTopic({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  const requestBody: IProxyRequestPayload = {
    endpoint: `/posts.json`,
    method: HttpMethod.POST,
    source: SupportedSource.DISCOURSE,
    payload: {
      title: title,
      raw: content,
      draft_key: "new_topic",
      category: 4, // TODO check this number. Probably needs to go to env file
    },
  };
  const response = await axiosInstance.post("/content/proxy", requestBody);

  if (response.status >= 200 && response.status < 350) {
    return response.data;
  } else {
    throw new Error(response.data.message);
  }
}

export async function createPost(
  topicId: number,
  postContent: string
): Promise<any> {
  const requestBody: IProxyRequestPayload = {
    endpoint: `/posts.json`,
    method: HttpMethod.POST,
    source: SupportedSource.DISCOURSE,
    payload: {
      topic_id: topicId,
      raw: postContent,
    },
  };
  const response = await axiosInstance.post("/content/proxy", requestBody);

  if (response.status >= 200 && response.status < 350) {
    return response.data;
  } else {
    throw new Error(response.data.message);
  }
}

export async function updatePost(
  postId: number,
  updatedContent: string
): Promise<any> {
  const requestBody: IProxyRequestPayload = {
    endpoint: `/posts/${postId}.json`,
    method: HttpMethod.PUT,
    source: SupportedSource.DISCOURSE,
    payload: {
      raw: updatedContent,
    },
  };
  return await axiosInstance.post("/content/proxy", requestBody);
}

export async function flagTopic(postId: number): Promise<any> {
  const requestBody: IProxyRequestPayload = {
    endpoint: `/post_actions.json`,
    method: HttpMethod.POST,
    source: SupportedSource.DISCOURSE,
    payload: {
      id: postId,
      post_action_type_id: 4,
      flag_topic: false,
      queue_for_review:	true
    }
  };
  
  // Perform flagging logic and return a resolved promise when successful
  // For example, you might use axios to send the POST request to the Discourse API
  return axiosInstance.post("/content/proxy/", requestBody);
}




export async function postFeedback(
  topicId: number,
  isLiked?: boolean,
  isBookmarked?: boolean
): Promise<void> {
  try {
    const response = await axiosInstance.post("/community", {
      topicId,
      isLiked,
      isBookmarked,
    });
    return response.data;
  } catch (error) {
    console.error("An error occurred while posting feedback:", error);
    throw error;
  }
}

export async function getFeedback(): Promise<
  { topicId: number; isLiked: boolean; isBookmarked: boolean }[]
> {
  try {
    const response = await axiosInstance.get("/community");
    return response.data;
  } catch (error) {
    console.error("An error occurred while posting feedback:", error);
    throw error;
  }
}

export async function getOwnTopics(userName?: string): Promise<any> {
  const requestBody: IProxyRequestPayload = {
    endpoint: `/topics/created-by/${userName}.json`,
    method: HttpMethod.GET,
    source: SupportedSource.DISCOURSE,
  };
  return (await axiosInstance.post("/content/proxy", requestBody)).data;
}

export async function getBookmarkedTopics(): Promise<any> {
  try {
    const response = await axiosInstance.get("/community/bookmarked");
    return response.data;
  } catch (error) {
    console.error("An error occurred while posting feedback:", error);
    throw error;
  }
}

export async function getTopicsContentByIds(topicIds?: number[]): Promise<any> {
  if (!topicIds?.length) return null;

  return (
    await Promise.all(
      topicIds.map(async (t) => {
        const requestBody: IProxyRequestPayload = {
          endpoint: `/t/${t}.json`,
          method: HttpMethod.GET,
          source: SupportedSource.DISCOURSE,
        };
        try {
          return (await axiosInstance.post("/content/proxy", requestBody)).data;
        } catch (e) {
          console.log("Unable to fetch topic with id: ", t);
        }
      })
    )
  ).filter((r) => r);
}
