import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getContentById } from "../../../../services/content.service";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CoachContent } from "../../../../@types";
import { CircularImage } from "../../../../components/circularImage";
import Button from "../../../../components/button";
import { Overview } from "./overview";
import { Reviews } from "./reviews";
import { CoachProductedContent } from "./content";
import { ButtonVariant } from "../../../../components/button/types";
import { BookingPopup } from "./booking/index";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ReactComponent as LeftArrow } from "../../../../assets/left-arrow-calendar.svg";
import CoachAvailabilityIndicator from "../../../../components/coachTraffic/CoachAvailabilityIndicator";
import { CircularProgress } from "@mui/material";

function CoachTabs({
  coachDetails,
  activeTab,
  contentId,
}: {
  coachDetails: CoachContent | null;
  activeTab: string;
  contentId: string;
}) {
  const { t } = useTranslation("coaches");
  const navigate = useNavigate();

  const tabs = useCallback(() => {
    const baseTabs = ["overview"];
    if (coachDetails?.reviews?.length) baseTabs.push("reviews");
    if (coachDetails?.content?.length) baseTabs.push("content");
    return baseTabs;
  }, [coachDetails]);

  return (
    <>
      <div className="flex w-full mt-6">
        {tabs().map((tab) => {
          return (
            <div key={tab}>
              <div
                className="px-4 py-3 cursor-pointer"
                onClick={() => navigate(`/coaches/${contentId}/${tab}`)}
              >
                <span className="p-2 pb-0 flex">
                  {t(tab)}{" "}
                  {tab == "reviews"
                    ? `(${coachDetails?.reviews?.length})`
                    : null}
                </span>
              </div>
              {tab == activeTab ? (
                <div className="h-[2px] bg-Yellow-default w-full" />
              ) : null}
            </div>
          );
        })}
      </div>
      <hr />
    </>
  );
}

function CoachHeader({
  coachData,
  refetch,
}: {
  coachData: CoachContent | null;
  refetch: () => void;
}) {
  const { t } = useTranslation("coaches");

  const { user, configurations } = useContext(AuthContext);


  const [bookingOpen, setBookingOpen] = useState(false);

  const getCoachAction = () => {
    if (!hasEnoughCredits()) {
      return <div className="mr-5">{t("not-enough-credits")}</div>;
    } else {
      return (
        <Button
          variant={ButtonVariant.SECONDARY}
          className="mr-5 px-3"
          onClick={() => setBookingOpen(true)}
        >
          <span className="font-medium">{t("coach-session")}</span>
        </Button>
      );
    }
  };

  const getWorkModeText = () => {
    if (coachData?.workModePresence && coachData?.workModeRemote)
      return t("remote-and-in-person");
    if (coachData?.workModePresence) return t("in-person");
    return t("remote");
  };

  const hasEnoughCredits = useCallback(() => {
    if (user && coachData) {
      if (user?.availableCredits >= (coachData as any)?.creditsPerCoaching)
        return true;
    }
    return false;
  }, [user, coachData]);



  const bookingPopUpFormType = configurations?.coachBookingForm
  
  return (
    <>
      <BookingPopup
        close={() => setBookingOpen(false)}
        isOpen={bookingOpen}
        coachData={coachData}
        refetch={refetch}
        formType={bookingPopUpFormType}
      />
      <div className="flex w-full bg-Yellow-light rounded-md flex-col md:flex-row items-center md:items-start">
        <div className="w-[160px] h-[160px] overflow-hidden p-2">
          <CircularImage src={`https:${coachData?.profilePicture}`} />
        </div>
        <div className="p-3">
          <h1 className="text-lg font-semibold">
            {`${coachData?.title ? coachData?.title : ""} ${
              coachData?.givenName
            } ${coachData?.surname}`.toUpperCase()}
          </h1>
          <div className="mt-3">
            <h2 className="text-lg font-semibold">{coachData?.location}</h2>
            <span>{getWorkModeText()}</span>
          </div>
          <div className="flex items-center">
            <div>{t("availability")}:</div>
            <div className="px-1">
              {coachData?.coachAvailability !== undefined &&
                coachData?.coachAvailability !== null &&
                coachData?.maxCoachings !== undefined &&
                coachData?.maxCoachings !== null && (
                  <CoachAvailabilityIndicator
                    availability={coachData.coachAvailability}
                    maxCoachings={coachData.maxCoachings}
                  />
                )}
            </div>
          </div>
          <div className="flex items-center mt-2">
            {getCoachAction()}
            {coachData?.calendlyUrl ? (
              <a href={coachData?.calendlyUrl} target="_blank">
                <Button variant={ButtonVariant.SECONDARY} className="px-3">
                  <span className="font-medium">{t("intro-session")}</span>
                </Button>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export function Coach() {
  const { t } = useTranslation("coaches");

  const navigate = useNavigate();

  const location = useLocation();

  const contentId = location.pathname.split("/")[2];

  const activeTab = location.pathname.split("/")[3];

  const [coachDetails, setCoachDetails] = useState<CoachContent | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { data, refetch } = useQuery(
    ["coach", contentId],
    () => getContentById({ platformContentId: contentId }),
    {
      onSuccess: (data: CoachContent) => {
        setCoachDetails(data as CoachContent);
        setIsLoading(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          navigate("/notfound");
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
      retry: false, // Disable automatic retries
    }
  );

  const renderCoachTab = () => {
    if (activeTab === "overview")
      return <Overview coachDetails={coachDetails} />;
    if (activeTab === "reviews") return <Reviews coachDetails={coachDetails} />;
    if (activeTab === "content")
      return <CoachProductedContent coachDetails={coachDetails} />;
  };

  if (isLoading) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return (
    <>
      <div className="w-full lg:w-4/6 px-4">
        <div className="lg:hidden flex w-full items-center mb-4">
          <LeftArrow className="" onClick={() => navigate("/coaches")} />
          <p className="text-lg text-Yellow-default w-full text-center">
            COACHES
          </p>
        </div>
        <CoachHeader coachData={coachDetails} refetch={refetch} />
        <CoachTabs
          coachDetails={coachDetails}
          activeTab={activeTab}
          contentId={contentId}
        />
        {renderCoachTab()}
      </div>
    </>
  );
}
