import { useMutation } from "react-query";
import { addWeeklyGoal, createWeeklyPlan, deleteWeeklyGoal, getWeeklyPlan, updateWeeklyGoal, updateWeeklyPlan } from "../../../../services/journal.service";
import { Dispatch, SetStateAction } from "react";


export function useWeeklyGoals({
    weeklyPlanId,
    refetchGoals,
    setNewWeeklyGoal
}: {
    weeklyPlanId: number | null,
    refetchGoals: ()=> void,
    setNewWeeklyGoal: Dispatch<SetStateAction<string>>
    }) {



    const addNewWeeklyGoal = (data: { title: string }) => {
        return addWeeklyGoal(data, weeklyPlanId || undefined);
    }

    const deleteGoal = async (goalId?: number) => {
        if (!weeklyPlanId || !goalId) return;
        return deleteWeeklyGoal(weeklyPlanId, goalId)
    }

    const addWeeklyGoalMutation = useMutation(['addWeeklyGoal'],
        (mutationArgs: { title: string }) => addNewWeeklyGoal({ ...mutationArgs }), {
        onSuccess: () => {
            refetchGoals();
            setNewWeeklyGoal("");
        }
    })

    const updateWeeklyGoalMutation = useMutation(['updateWeeklyGoal'],
        (mutationArgs: { goalId?: number, title?: string, check?: boolean, rescheduleWeek?: number, rescheduleYear?: number }) => updateWeeklyGoal(mutationArgs, weeklyPlanId || undefined, mutationArgs.goalId), {
        onSuccess: () => {
            refetchGoals();
        }
    })

    const deleteWeeklyMutation: any = useMutation(['deleteWeeklyMutation'],
        (mutationArgs: { goalId?: number }) => deleteGoal(mutationArgs.goalId), {
        onSuccess: () => {
            refetchGoals();
        }
    })


    return {
        addWeeklyGoalMutation,
        updateWeeklyGoalMutation,
        deleteWeeklyMutation
    }


}