/* Third party imports */
import { useTranslation } from "react-i18next";

/* Local imports */
import ProgressBar from "../../../../../components/progressBar";
import { getTitleFromLanguage } from "../../../../../utils";
import { IContentWithProgress } from "../../../../../@types";
import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";
import { ReactComponent as RightArrowActive } from "../../../../../assets/rigth-arrow_active.svg";
import { ReactComponent as RightArrow } from "../../../../../assets/rigth-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const MilestoneButtonWrapper = ({
  isActive,
  onClick,
  text,
  progress,
  markedIrrelevant,
}: {
  isActive: boolean;
  onClick: () => void;
  text: string;
  progress: number;
  markedIrrelevant?: boolean;
}) => {
  const activeStyles = isActive
    ? "bg-Blue-metalic font-semibold"
    : "bg-Blue-ligth text-Grey-dark";

  return (
    <div
      onClick={onClick}
      className={`mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${activeStyles}`}
    >
      <div className="flex justify-between items-center mb-1">
        <p className="	">{text}</p>
        <div className="ml-5">
          {isActive ? <RightArrowActive /> : <RightArrow />}
        </div>
      </div>
      {!markedIrrelevant ? (
        <div className="flex justify-between items-center">
          <ProgressBar
            percentage={Math.round(progress)}
            progressColor={isActive ? "bg-black" : "bg-Grey-dark"}
          />
          <div className="ml-2 font-bold text-sm">{Math.round(progress)}%</div>
        </div>
      ) : null}
    </div>
  );
};

export function MilestonesList({
  onSelectMilestone = () => null,
}: {
  onSelectMilestone?: () => void;
}) {
  const { t } = useTranslation("milestones");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentPhaseMilestones, activeMilestone, setOpenedContent } =
    useContentAndProgress();
  const { i18n } = useTranslation();

  const currentLang = i18n.language;

  if (!currentPhaseMilestones) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return (
    <>
      {activeMilestone ? (
        <div className="flex flex-col">
          <h1 className="font-bold font-lg text-Grey-dark mb-4 hidden lg:flex">
            {t("milestonesList.title")}
          </h1>
          {currentPhaseMilestones?.map((milestone: IContentWithProgress) => {
            const isActive =
              activeMilestone?.content.platformContentId ===
              milestone.content.platformContentId;
            return (
              <MilestoneButtonWrapper
                key={milestone.content.platformContentId}
                text={getTitleFromLanguage(
                  milestone?.content || null,
                  currentLang
                )}
                progress={milestone.progressValue}
                isActive={isActive}
                markedIrrelevant={milestone.markedIrrelevant}
                onClick={() => {
                  setOpenedContent(null);
                  navigate(
                    `${location.pathname
                      .split("/")
                      .slice(0, 3)
                      .join("/")}/milestone/${
                      milestone.content?.platformContentId
                    }`
                  );
                  onSelectMilestone();
                }}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
