import { useContext, useEffect, useState } from "react";
import Button from "../../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../components/button/types";
import { CVCheckPopup } from "./CVCheckPopup";
import { useTranslation } from "react-i18next";
import {
  fetchCVCheckData,
  fetchSignedUrl,
} from "../../../../../../services/selfAssessment.service";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { useQuery } from "react-query";

function CVCheckFlavor({
  title,
  description,
  buttonText,
  cvReadyButtonText,
  onClick,
  cost,
  creditRequested,
  hasCVReadyToDownload,
}: {
  title: string;
  description: string;
  buttonText: string;
  cvReadyButtonText: string;
  onClick: () => void;
  cost: number;
  creditRequested: boolean;
  hasCVReadyToDownload: boolean;
}) {
  const { t } = useTranslation("myArea");

  const { user } = useContext(AuthContext);;

  const buttonDisplayProps = () => {
    if (hasCVReadyToDownload) {
      return { text: cvReadyButtonText, variant: ButtonVariant.PRIMARY };
    } else if (creditRequested && !hasCVReadyToDownload) {
      return {
        text: t("assessments.cv-check.in-progress"),
        variant: ButtonVariant.PRIMARY,
      };
    } else {
      return { text: buttonText, variant: ButtonVariant.SECONDARY };
    }
  };

  const isButtonDisabled = () => {
    if (creditRequested) {
      return !hasCVReadyToDownload;
    }

    if (cost > 0) {
      console.log(user?.availableCredits, cost);
      return (user?.availableCredits ?? 0) < cost;
    }

    return false;
  };

  return (
    <div>
      <h2 className="font-bold text-lg text-Grey-dark mb-3">{title}</h2>
      <hr className="mb-4" />
      <div>{description}</div>
      <div className="flex justify-between mt-6">
        {cost === 0 ? (
          <span className="font-bold text-Grey-dark ">
            Credits: {cost} {t("assessments.cv-check.inclusive")}
          </span>
        ) : (
          <span className="font-bold text-Grey-dark ">
            {user?.availableCredits ?? 0 >= cost
              ? `Credits: ${cost}`
              : `Credits: ${cost} (${t(
                  "assessments.cv-check.insufficient-credits"
                )})`}
          </span>
        )}
        <Button
          variant={buttonDisplayProps().variant}
          size={ButtonSize.SMALL}
          onClick={onClick}
          disabled={isButtonDisabled()}
        >
          {buttonDisplayProps().text}
        </Button>
      </div>
      <hr className="mt-4 mb-6" />
    </div>
  );
}

export function CVCheck() {
  const [basicCVCheckOpen, setBasicCVCheckOpen] = useState(false);
  const [plusCVCheckOpen, setPlusCVCheckOpen] = useState(false);
  const { t } = useTranslation("myArea");
  const plusCost = 4;
  const { configurations } = useContext(AuthContext);;
  const shouldRenderCVCheckBasic = configurations?.includesCVCheck;
  const shouldRenderCVCheckPlus = configurations?.includesCVCheckPlus;

  const { data: cvCheckData, refetch } = useQuery(
    ["fetchUserData", basicCVCheckOpen],
    fetchCVCheckData
  );

  const hasBasicCredit = cvCheckData?.some(
    (credit: any) => credit.cvCheckType === "Basic"
  );

  const hasBasicCVReadyToDownload = cvCheckData?.some(
    (credit: any) =>
      credit.cvCheckType === "Basic" && credit.s3PartnerFileLink !== null
  );

  const hasPlusCredit = cvCheckData?.some(
    (credit: any) => credit.cvCheckType === "Plus"
  );

  const hasPlusCVReadyToDownload = cvCheckData?.some(
    (credit: any) =>
      credit.cvCheckType === "Plus" && credit.s3PartnerFileLink !== null
  );

  const handleBasicDownload = async () => {
    const basicIndex = cvCheckData.findIndex(
      (credit: any) => credit.cvCheckType === "Basic"
    );

    if (basicIndex !== -1) {
      try {
        const url = await fetchSignedUrl(
          cvCheckData[basicIndex]?.s3PartnerFileLink
        );

        setTimeout(() => {
          window.open(url, "_blank");
        });
      } catch (error) {
        console.error("Error fetching signed URL:", error);
      }
    } else {
      console.error("Basic CV check not found in cvCheckData.");
    }
  };

  const handlePlusDownload = async () => {
    const plusIndex = cvCheckData.findIndex(
      (credit: any) => credit.cvCheckType === "Plus"
    );

    if (plusIndex !== -1) {
      try {
        const url = await fetchSignedUrl(
          cvCheckData[plusIndex]?.s3PartnerFileLink
        );

        setTimeout(() => window.open(url, "_blank"));
      } catch (error) {
        console.error("Error fetching signed URL:", error);
      }
    } else {
      console.error("Plus CV check not found in cvCheckData.");
    }
  };

  return (
    <>
      <CVCheckPopup
        isOpen={basicCVCheckOpen}
        close={() => {
          setBasicCVCheckOpen(false);
          refetch();
        }}
        checkType="Basic"
      />
      <CVCheckPopup
        isOpen={plusCVCheckOpen}
        close={() => {
          setPlusCVCheckOpen(false);
          refetch();
        }}
        checkType="Plus"
      />
      <div>
        <div className="mb-9 p-6 bg-Grey-light-3 rounded">
          <span>{t(`assessments.cv-check.first-text`)}</span>
        </div>
        {shouldRenderCVCheckBasic && (
          <CVCheckFlavor
            title={"Basic"}
            description={t(`assessments.cv-check.basic-text`)}
            buttonText={t(`assessments.cv-check.start-basic`)}
            cvReadyButtonText={t("assessments.cv-check.basic-button-ready")}
            onClick={() => {
              if (hasBasicCVReadyToDownload) {
                handleBasicDownload();
              } else {
                setBasicCVCheckOpen(true);
              }
            }}
            cost={0}
            creditRequested={hasBasicCredit}
            hasCVReadyToDownload={hasBasicCVReadyToDownload}
          />
        )}

        {shouldRenderCVCheckPlus && (
          <CVCheckFlavor
            title={"PLUS"}
            description={t(`assessments.cv-check.plus-text`)}
            buttonText={t(`assessments.cv-check.start-plus`)}
            cvReadyButtonText={t("assessments.cv-check.plus-button-ready")}
            onClick={() => {
              if (hasPlusCVReadyToDownload) {
                handlePlusDownload();
              } else {
                setPlusCVCheckOpen(true);
              }
            }}
            cost={plusCost}
            creditRequested={hasPlusCredit}
            hasCVReadyToDownload={hasPlusCVReadyToDownload}
          />
        )}
      </div>
    </>
  );
}
