import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../contexts/AuthContext";
import {
  cancelCoaching,
  getUsedCreditsAmount,
  getUserCredits,
} from "../../../../../services/coaching.service";
import { MyAreaTitle } from "../components";
import { useTranslation } from "react-i18next";
import { useContext, useRef, useState } from "react";
import { CircularImage } from "../../../../../components/circularImage";
import Button from "../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/button/types";
import { PopupWrapper } from "../../../../../components/popupWrapper";
import { ReactComponent as Close } from "../../../../../assets/close.svg";
import { ReactComponent as MoreOptions } from "../../../../../assets/more_options.svg";
import {
  useArrayOutsideClickDetector,
  useOutsideClickDetector,
} from "../../../../../hooks/useOutsideClickDetector";

function CreditsCounter({
  creditsAmount,
  setCreditsAmount,
}: {
  creditsAmount: number;
  setCreditsAmount: (amount: number) => void;
}) {
  const { user } = useContext(AuthContext);;

  const { t } = useTranslation("myArea");

  useQuery(["usedCreditsAmount"], getUsedCreditsAmount, {
    onSuccess: (data) => setCreditsAmount(data),
  });

  return (
    <div className="flex flex-col w-full rounded-md bg-Grey-light-3 p-6">
      <div className="flex justify-between items-center w-full">
        <div className="h-3 rounded-md bg-Grey-dark w-full relative">
          <div
            className="h-3 rounded-md bg-Yellow-default absolute left-0 top-0"
            style={{
              width: `${
                user?.usedVoucher?.maxCredits
                  ? (
                      (creditsAmount / user?.usedVoucher?.maxCredits) *
                      100
                    ).toFixed(0)
                  : 0
              }%`,
            }}
          ></div>
        </div>
      </div>
      <div className="flex justify-between mt-2 font-bold text-Grey-dark text-sm">
        <div>{t("credits.used-credits")}</div>
        <div>
          {creditsAmount}/{user?.usedVoucher?.maxCredits}
        </div>
      </div>
    </div>
  );
}

export function CreditsTable({
  credits,
  refetchCredits,
  setCreditsAmount,
}: {
  credits: any;
  refetchCredits: () => void;
  setCreditsAmount: (v: number) => void;
}) {
  const { t } = useTranslation("myArea");
  const [cancelCreditSelected, setCancelCreditSelected] = useState<any | null>(
    null
  );
  const [moreOptions, setMoreOptions] = useState<number | null>(null);
  const [successfullyCanceled, setSuccessfullyCanceled] = useState(false);
  const [message, setMessage] = useState("");

  const moreOptionsRef = useRef<any>(null);
  const cancelButtonRef = useRef<any>(null);

  useArrayOutsideClickDetector([moreOptionsRef, cancelButtonRef], () =>
    setMoreOptions(null)
  );

  useQuery(["usedCreditsAmount"], getUsedCreditsAmount, {
    enabled: successfullyCanceled,
    onSuccess: (data) => setCreditsAmount(data),
  });

  const cancelCoachingMutation = useMutation(
    ["cancelCoaching"],
    () => cancelCoaching({ creditId: cancelCreditSelected?.id || 0, message }),
    {
      onSuccess: () => {
        setSuccessfullyCanceled(true);
        setTimeout(() => {
          setSuccessfullyCanceled(false);
          setCancelCreditSelected(null);
          setMessage("");
          refetchCredits();
        }, 2000);
      },
    }
  );

  return (
    <>
      <PopupWrapper isOpen={!!cancelCreditSelected}>
        <div className="bg-white bg-opacity-100 w-1/3 p-5 rounded-lg">
          {successfullyCanceled ? (
            <div>{t("credits.success-cancelation-message")}</div>
          ) : (
            <>
              <div className="flex mb-4 justify-between items-center text-lg font-bold">
                <div className="w-10/12">
                  {t("credits.cancel-confirmation")} {t("credits.with")}{" "}
                  <span className="font-bold">
                    {cancelCreditSelected?.coachName}{" "}
                    {cancelCreditSelected?.coachSurname}
                  </span>
                </div>
                <div className="flex justify-center w-2/12 ">
                  <Close
                    className="cursor-pointer"
                    onClick={() => setCancelCreditSelected(null)}
                  />
                </div>
              </div>
              <div className="text-sm text-Grey-dark mb-4">
                {t("credits.cancel-warning")}
              </div>
              <textarea
                value={message}
                placeholder={t("credits.inform-placeholder") as string}
                onChange={(e: any) => setMessage(e.target.value)}
                className="w-full rounded-md h-32 mb-5"
              />

              <div className="flex justify-between">
                <Button
                  className="w-full mr-2"
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.MEDIUM}
                  onClick={() => setCancelCreditSelected(null)}
                >
                  {t("credits.cancel-button")}
                </Button>
                <Button
                  className="w-full ml-2"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  onClick={() => cancelCoachingMutation.mutate()}
                >
                  {t("credits.confirm-button")}
                </Button>
              </div>
            </>
          )}
        </div>
      </PopupWrapper>
      {credits?.length > 0 ? (
        <>
          <table className="table-auto w-full hidden md:table">
            <thead className="w-full border-b">
              <tr>
                <th className="text-left pb-2">
                  {t("credits.table-headers.coach")}
                </th>
                <th className="text-left pb-2">
                  {t("credits.table-headers.request-date")}
                </th>
                <th className="text-left pb-2">
                  {t("credits.table-headers.status")}
                </th>
                <th className="text-left pb-2">
                  {t("credits.table-headers.credits")}
                </th>
                <th className="text-right pb-2">
                  {t("credits.table-headers.actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {credits?.map((c: any, idx: number) => (
                <tr className="w-full border-b">
                  <td>
                    <div className="flex items-center py-3">
                      {c.code !== "03" && c.code !== "05" && (
                        <div className="w-9 h-9 mr-2">
                          <CircularImage src={c.coachProfilePicture} />
                        </div>
                      )}
                      <span>
                        {c.code === "03"
                          ? t("credits.cv-check")
                          : c.code === "05"
                          ? c.externalServiceCredit.service
                          : `${c.coachName} ${c.coachSurname}`}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span>{c.createdAt.split("T")[0]}</span>
                  </td>
                  <td>
                    <span>{t(`credits.status.${c.status}`)}</span>
                  </td>
                  <td>
                    <span>{c.creditsPerCoaching}</span>
                  </td>
                  <td className="flex justify-end items-end">
                    <div className="relative align-self-center mt-2 mr-4">
                      {c.code !== "03" && c.code !== "05" && (
                        <div
                          ref={moreOptionsRef}
                          onClick={() => setMoreOptions(idx)}
                          className={`${
                            moreOptions == idx
                              ? "border-opacity-100"
                              : "border-opacity-0"
                          } border-black cursor-pointer rounded-md p-2 border-2`}
                        >
                          <MoreOptions />
                        </div>
                      )}
                      {moreOptions == idx ? (
                        <div
                          ref={moreOptionsRef}
                          className="absolute left-[-28px] top-[50px] lg:left-[50px] lg:top-0 bg-Grey-light-3 rounded-md p-2 px-3"
                        >
                          {c.status !== "Blocked" ? (
                            <div className="text-Grey">
                              {t("credits.no-available-actions")}
                            </div>
                          ) : (
                            <div
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setCancelCreditSelected(c);
                                setMoreOptions(null);
                              }}
                            >
                              {t("credits.cancel-button")}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>

                    {/* <MoreOptions onClick={} className="align-self-center mt-5 mr-4"/> */}
                    {/* <Button variant={ButtonVariant.SECONDARY} className="p-1 align-self-center mt-3" onClick={() => setCancelCreditSelected(c)}>Cancel</Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="md:hidden">
            {credits?.map((c: any, idx: number) => (
              <>
                <div className="mb-4 flex justify-between">
                  <div>
                    <div className="flex items-center mb-1">
                      {c.code !== "03" && c.code !== "05" && (
                        <div className="w-9 h-9 mr-2">
                          <CircularImage src={c.coachProfilePicture} />
                        </div>
                      )}
                      <span>
                        {c.code === "03"
                          ? t("credits.cv-check")
                          : c.code === "05"
                          ? c.externalServiceCredit.service
                          : `${c.coachName} ${c.coachSurname}`}
                      </span>
                    </div>
                    <div className="text-grey">
                      <span className="mr-2">
                        {t("credits.table-headers.status")}:
                      </span>
                      <span>{t(`credits.status.${c.status}`)}</span>
                    </div>
                    <div className="text-Grey">
                      <span className="mr-2">
                        {t("credits.table-headers.request-date")}:
                      </span>
                      <span>{c.createdAt.split("T")[0]}</span>
                    </div>
                    <div className="text-Grey">
                      <span className="mr-2">
                        {t("credits.table-headers.credits")}:
                      </span>
                      <span>{c.creditsPerCoaching}</span>
                    </div>
                  </div>
                  <div className="relative">
                    {c.code !== "03" && c.code !== "05" && (
                      <div
                        ref={moreOptionsRef}
                        onClick={() => setMoreOptions(idx)}
                        className={`${
                          moreOptions == idx
                            ? "border-opacity-100"
                            : "border-opacity-0"
                        } border-black cursor-pointer rounded-md p-2 border-2 rotate-90`}
                      >
                        <MoreOptions />
                      </div>
                    )}

                    {moreOptions == idx ? (
                      <div
                        ref={moreOptionsRef}
                        className="absolute left-[-28px] top-[50px] bg-Grey-light-3 rounded-md p-2 px-3"
                      >
                        {c.status !== "Blocked" ? (
                          <div className="text-Grey">
                            {t("credits.no-available-actions")}
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCancelCreditSelected(c);
                              setMoreOptions(null);
                            }}
                          >
                            {t("credits.cancel-button")}
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                {idx + 1 < credits?.length && (
                  <hr className="border-1 border-Grey-light-2 mb-4" />
                )}
              </>
            ))}
          </div>
        </>
      ) : (
        <div className="text-Grey">{t("credits.no-credits-used")}</div>
      )}
    </>
  );
}

export function Credits() {
  const { t } = useTranslation("myArea");

  const PAGE_INCREMENT = 3;

  const [credits, setCredits] = useState<any[]>([]);
  const [allCreditsLoaded, setAllCreditsLoaded] = useState(false);
  const [creditsAmount, setCreditsAmount] = useState(0);
  const [creditsPageSize, setCreditsPageSize] = useState(PAGE_INCREMENT);

  const fetchSpecifiedAmountOfUserCredits = () => {
    return getUserCredits(creditsPageSize);
  };

  const { refetch: refetchCredits } = useQuery(
    ["credits", creditsPageSize],
    fetchSpecifiedAmountOfUserCredits,
    {
      onSuccess: (data) => {
        setCredits(data.credits);
        setAllCreditsLoaded(data.all);
      },
    }
  );

  return (
    <div>
      <div className="hidden lg:flex">
        <MyAreaTitle title={t("credits.title")} />
      </div>
      <CreditsCounter
        creditsAmount={creditsAmount}
        setCreditsAmount={setCreditsAmount}
      />
      <div className="mt-10">
        <div className="font-bold text-Grey-dark mb-7">
          {t("credits.transaction-history")}
        </div>
        <CreditsTable
          setCreditsAmount={setCreditsAmount}
          refetchCredits={refetchCredits}
          credits={credits}
        />
        {!allCreditsLoaded ? (
          <Button
            variant={ButtonVariant.SECONDARY}
            className="p-1 mt-5 text-Black border-Black"
            onClick={() =>
              setCreditsPageSize(
                (creditsAmount) => creditsAmount + PAGE_INCREMENT
              )
            }
          >
            {t("credits.show-more")}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
