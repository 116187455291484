import { axiosInstance } from ".";
import {
  FormattedContent,
  IContent,
  IContentWithProgress,
  ISearchResult,
} from "../@types";
import i18n from "i18next";
import { LANGUAGES } from "../i18n";
import { ContentType } from "../enums";

export function getLocaleFromLanguage() {
  const language = i18n.language;
  if (language === LANGUAGES.en) {
    return "en-US";
  } else if (language === LANGUAGES.de) {
    return "de";
  } else {
    return "en-US";
  }
}

export async function getUserContentAndProgressTree({
  platformContentId,
}: {
  platformContentId?: string;
}): Promise<IContentWithProgress[] | IContentWithProgress> {

  let queryString = platformContentId
    ? `platformContentId=${platformContentId}`
    : "";

  return (await axiosInstance.get(`/content/progress?${queryString}`)).data;
}

export async function getContentById({
  id,
  platformContentId,
  projection,
}: {
  id?: number;
  platformContentId?: string;
  projection?: "min" | "normal";
}): Promise<FormattedContent | null> {
  console.log("being called by Id", platformContentId)
  if (!id && !platformContentId) return null;
  let baseEndpoint = id
    ? `/content/from-source?id=${id}`
    : `/content/from-source?platformContentId=${platformContentId}`;
  if (projection) baseEndpoint += `&projection=${projection}`;

  return (
    await axiosInstance.get(`${baseEndpoint}&locale=${getLocaleFromLanguage()}`)
  ).data;
}

export async function getMultipleContentByIds({
  ids,
}: {
  ids: number[];
}): Promise<FormattedContent | null> {
  console.log("Will fetch ids: ", ids);
  return null;
  // return (await axiosInstance.get(`/content/from-source?id=${ids}&locale=${getLocaleFromLanguage()}`)).data;
}

export async function getContentByProperty({
  contentType,
}: {
  contentType?: ContentType;
}): Promise<IContent[]> {

  let queryString = "";
  if (contentType) queryString += `&contentType=${contentType}`;

  return (await axiosInstance.get(`/content/by-property?${queryString}`)).data;
}

export async function getBookmarkedContent(): Promise<{
  [key: string]: FormattedContent[];
}> {
  return (
    await axiosInstance.get(
      `/content/bookmarked?locale=${getLocaleFromLanguage()}`
    )
  ).data;
}

function getLocaleCodeFromLanguage(language: string): string {
  return language == "de" ? language : "en-US";
}

export async function searchContent(
  query: string,
  language: string
): Promise<ISearchResult[]> {
  return (
    await axiosInstance.get(
      `/content/search?query=${query}&locale=${getLocaleCodeFromLanguage(
        language
      )}`
    )
  ).data;
}

export async function searchFullContent(
  query: string,
  language: string
): Promise<FormattedContent[]> {
  return (
    await axiosInstance.get(
      `/content/search/full-content?query=${query}&locale=${getLocaleCodeFromLanguage(
        language
      )}`
    )
  ).data;
}

export async function takeActionAgainstContent({
  contentId,
  action,
}: {
  contentId: number;
  action: string;
}) {
  return (await axiosInstance.post(`/users/${contentId}/actions/${action}`))
    .data;
}

export async function getHeyForm(formTitle: string): Promise<any> {
  const result = await axiosInstance.get("/content/contentful/form", {
    params: {
      locale: getLocaleFromLanguage(),
      contentTitle: formTitle,
    },
  });
  return result.data;
}
