import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useContentAndProgress } from "../../contexts/UserProgressContext";
import { IContentWithProgress } from "../../@types";
import { useQuery } from "react-query";
import { getContentById } from "../../services/content.service";
import { makeUrlFromTitle } from "../../utils";
import { ReactComponent as FAQIcon } from "../../assets/navbar-FAQ.svg";

const FAQHandler = ({ isMobile }: { isMobile?: boolean }) => {
  const navigate = useNavigate();
  const { configurations } = useContext(AuthContext);;
  const { t } = useTranslation("navbar");

  const { phases, milestones, setOpenedContent } = useContentAndProgress();

  const faqId = configurations?.includesFAQ[0];
  const { data: content } = useQuery(["content", faqId], () =>
    getContentById({ platformContentId: faqId || undefined })
  );

  const handleFAQClick = () => {
    if (faqId) {
      // Need to activate correspondent milestone and phase
      if (content?.contentType === "article") {
        const milestone =
          milestones?.filter((ms: IContentWithProgress) =>
            ms.content?.childContentIds.includes(faqId)
          )[0] || null;
        const phase =
          phases?.filter((phase: IContentWithProgress) =>
            phase.content?.childContentIds.includes(
              milestone?.content.platformContentId || ""
            )
          )[0] || null;
        navigate(
          `/phase/${phase?.content.platformContentId}/milestone/${
            milestone?.content.platformContentId
          }/content/${faqId}/${makeUrlFromTitle(content?.title)}`
        );
        setOpenedContent(faqId);
      } else if (content?.contentType === "milestone") {
        const phase =
          phases?.filter((phase: IContentWithProgress) =>
            phase.content?.childContentIds.includes(faqId || "")
          )[0] || null;
        navigate(
          `/phase/${phase?.content.platformContentId}/milestone/${faqId}`
        );
      }
    } else {
      console.log("No FAQ ID configured.");
    }
  };

  return (
    <>
      {isMobile ? (
        <button
          onClick={handleFAQClick}
          className="flex items-center justify-center bg-transparent border-none"
        >
          <FAQIcon />
        </button>
      ) : (
        <button onClick={handleFAQClick} className="text-Grey-dark">
          {t("FAQ")}
        </button>
      )}
    </>
  );
};

export default FAQHandler;
