import { useEffect } from "react";


export function useOutsideClickDetector(ref: any, callback: Function) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useArrayOutsideClickDetector(refs: any[], callback: Function) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      let count = 0;
      for(const ref of refs) {
        if (ref.current && !ref.current.contains(event.target)) { 
          count +=1;
        }
      }
      if(count == refs.length) callback();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);
}