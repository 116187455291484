import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MenuSection, MenusObject } from "..";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowInactive } from "../../../../assets/rigth-arrow.svg";
import { ReactComponent as ArrowActive } from "../../../../assets/rigth-arrow_active.svg";
import { ReactComponent as RatingIcon } from "../../../../assets/myArea/rating.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useContentAndProgress } from "../../../../contexts/UserProgressContext";
import { IContentWithProgress } from "../../../../@types";
import { getTitleFromLanguage } from "../../../../utils";
import { AuthContext } from "../../../../contexts/AuthContext";

export function AssessmentsSubMenus() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const location = useLocation();
  const activeSubRoute = location.pathname.split("/")[3];
  const { configurations } = useContext(AuthContext);
  const shouldRenderCVCheck =
    configurations?.includesCVCheck || configurations?.includesCVCheckPlus;
  const shouldRenderLINK = configurations?.includesLINCAccessement;
  const isMobileView = window.innerWidth < 1024;

  const { t } = useTranslation("myArea");

  const { phases } = useContentAndProgress();

  const phasesToDisplay = useMemo(
    () =>
      phases?.filter((p: IContentWithProgress) => p.hasAssessments === true),
    [phases]
  );

  const [activeSubMenu, setActiveSubMenu] = useState<{
    idx: number;
    type: string;
  } | null>(null);

  useEffect(() => {
    const firstIndex = phasesToDisplay?.length ? phasesToDisplay.length - 1 : 0;
    if (activeSubRoute === "strength-and-personality-profile") {
      setActiveSubMenu({ idx: 0, type: "strength-and-personality-profile" });
    } else if (activeSubRoute === "cv-check") {
      setActiveSubMenu({ idx: 0, type: "cv-check" });
    } else {
      if (!isMobileView) {
        setActiveSubMenu({ idx: 0, type: "overview" });
        navigate(
          `assessments/${
            phasesToDisplay && phasesToDisplay[0]?.content.platformContentId
          }/overview`
        );
      }
    }
  }, []);

  return (
    <>
      <div className="p-3 pr-0 pt-0 flex">
        <div className="flex flex-col bg-Grey-light rounded-md w-[0.1em]"></div>
        <div className="flex flex-col w-full">
          {!!phasesToDisplay?.length ? (
            <>
              {phasesToDisplay?.map((phase, idx) => {
                const overviewActive =
                  activeSubMenu?.idx == idx && activeSubMenu.type == "overview";
                const reportActive =
                  activeSubMenu?.idx == idx && activeSubMenu.type == "report";
                const translatedTitle = getTitleFromLanguage(
                  phase.content,
                  i18n.language
                );

                return (
                  <div
                    key={phase.content.title}
                    className={`ml-2 w-full h-full flex-col pr-2 ${
                      idx !== phasesToDisplay.length - 1 ? "mb-3" : ""
                    }`}
                  >
                    <div
                      onClick={() => {
                        navigate(
                          `assessments/${phase.content.platformContentId}/overview`
                        );
                        setActiveSubMenu({ idx, type: "overview" });
                      }}
                      className={`flex items-center justify-between mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${
                        overviewActive
                          ? "bg-Blue-metalic font-semibold"
                          : "bg-Blue-ligth text-Grey-dark"
                      } `}
                    >
                      <div className="w-11/12">
                        {translatedTitle}: {t("tabs.overview")}
                      </div>
                      <div className="flex w-1/12 justify-end">
                        {overviewActive ? <ArrowActive /> : <ArrowInactive />}
                      </div>
                    </div>
                    {/* <div
                        onClick={() => {
                          navigate(
                            `assessments/${phase.content.platformContentId}/report`
                          );
                          setActiveSubMenu({ idx, type: "report" });
                        }}
                        className={`flex items-center justify-between mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${
                          reportActive
                            ? "bg-Blue-metalic font-semibold"
                            : "bg-Blue-ligth text-Grey-dark"
                        } `}
                      >
                        <div className="w-11/12">
                          {translatedTitle}: {t("tabs.report")}
                        </div>
                        <div className="flex w-1/12 justify-end">
                          {reportActive ? <ArrowActive /> : <ArrowInactive />}
                        </div>
                      </div> */}
                  </div>
                );
              })}
            </>
          ) : (
            <div className="p-3 pr-0 pt-0 text-Grey">
              {t("tabs.no-available-assessments")}
            </div>
          )}

          <div className={`ml-2 w-full h-full flex-col pr-2 mb-3`}>
            {shouldRenderLINK && (
              <div
                onClick={() => {
                  navigate(`assessments/strength-and-personality-profile`);
                  setActiveSubMenu({
                    idx: 0,
                    type: "strength-and-personality-profile",
                  });
                }}
                className={`flex items-center justify-between mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${
                  activeSubMenu?.type == "strength-and-personality-profile"
                    ? "bg-Blue-metalic font-semibold"
                    : "bg-Blue-ligth text-Grey-dark"
                } `}
              >
                <div className="w-11/12">{t("tabs.strength")}</div>
                <div className="flex w-1/12 justify-end">
                  {activeSubMenu?.type == "strength-and-personality-profile" ? (
                    <ArrowActive />
                  ) : (
                    <ArrowInactive />
                  )}
                </div>
              </div>
            )}
            {shouldRenderCVCheck && (
              <div
                onClick={() => {
                  navigate(`assessments/cv-check`);
                  setActiveSubMenu({ idx: 0, type: "cv-check" });
                }}
                className={`flex items-center justify-between pt-2 pb-3 px-3 rounded-lg cursor-pointer ${
                  activeSubMenu?.type == "cv-check"
                    ? "bg-Blue-metalic font-semibold"
                    : "bg-Blue-ligth text-Grey-dark"
                } `}
              >
                <div className="w-11/12">{t("tabs.cv-check")}</div>
                <div className="flex w-1/12 justify-end">
                  {activeSubMenu?.type == "cv-check" ? (
                    <ArrowActive />
                  ) : (
                    <ArrowInactive />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const TabContent = ({
  menu,
  isActive,
  onClick,
  setMobileActiveMenu,
}: {
  menu: MenuSection;
  isActive: boolean;
  onClick: () => void;
  setMobileActiveMenu?: any;
}) => {
  const { t } = useTranslation("myArea");

  const activeStyles = isActive
    ? "bg-Blue-metalic font-semibold"
    : "bg-Blue-ligth text-Grey-dark";

  return (
    <>
      <div
        onClick={onClick}
        className={`flex items-center justify-between mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${activeStyles}`}
      >
        <div className="flex items-center">
          <div className="mr-4">
            {isActive ? menu.iconActive : menu.iconInactive}
          </div>
          {t(`tabs.${menu.name}`)}
        </div>
        <div>{isActive ? <ArrowActive /> : <ArrowInactive />}</div>
      </div>

      <div onClick={setMobileActiveMenu}>
        {menu.subMenuComponent && isActive ? menu.subMenuComponent : null}
      </div>
    </>
  );
};

export function Tabs({
  menus,
  activeMenu,
  setActiveMenu,
  setMobileActiveMenu,
}: {
  menus: MenusObject;
  activeMenu: MenuSection | null;
  setActiveMenu: Dispatch<SetStateAction<MenuSection | null>>;
  setMobileActiveMenu?: (active: boolean) => void;
}) {
  const { t } = useTranslation("myArea");
  const navigate = useNavigate();
  const isMobileView = window.innerWidth < 1024;
  return (
    <div className="md:p-4">
      {Object.entries(menus).map(([k, v]: [k: string, v: any]) => {
        return (
          <div className="mt-4">
            {v.length > 0 && (
              <div className="font-bold mb-3 text-Grey-dark">
                {t(`tabs.${k}`)}
              </div>
            )}
            {v.map((vv: any) => {
              const isActive = activeMenu?.name == vv.name;
              return (
                <TabContent
                  menu={vv}
                  isActive={isActive}
                  onClick={() => {
                    if (isActive) return;
                    if (!isMobileView) {
                      navigate(vv.route);
                      setActiveMenu(vv);
                    } else {
                      setActiveMenu(vv);
                      if (vv.subMenuComponent) {
                        // If there are submenus, keep the menu open
                        setMobileActiveMenu && setMobileActiveMenu(false);
                      } else {
                        // If no submenus, close the mobile menu
                        setMobileActiveMenu && setMobileActiveMenu(true);
                        navigate(vv.route);
                      }
                    }
                  }}
                  setMobileActiveMenu={setMobileActiveMenu}
                />
              );
            })}
            {v.length > 0 && <hr className="mt-6" />}
          </div>
        );
      })}
      <div className="flex items-center p-2 pt-3 text-Grey-dark">
        <RatingIcon className="mr-2" />
        <a target="_blank" href={process.env.REACT_APP_FEEDBACK_FORM_URL}>
          {t("feedback")}
        </a>
      </div>
    </div>
  );
}
