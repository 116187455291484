import { useTranslation } from "react-i18next";
import about from '../../assets/about-page.png';
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";

export default function About() {
    const { t } = useTranslation("about");
    const { user } = useContext(AuthContext);;

    return (
        <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
            <div className={`w-full ${user && "md:w-1/2" }`}>

                <h1 className="text-Yellow-default text-lg font-semibold mb-4">{t("page-title")}</h1>

                <img src={about} alt="about-page" className="w-full mb-6" />

                <p className="font-bold mb-4">{t('page-sub-title')}</p>
                
                <p className="mb-4">{t('page-text-1')}</p>
                
                <p className="mb-4">{t('page-text-2')}</p>
                
                <p>{t('page-text-3')}</p>
            </div>
        </div>
    )
}