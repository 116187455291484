/* React imports */
import { useContext, useEffect, useState } from "react";

/* Third party imports */
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

/* Local imports */
import { CustomFieldWithError } from "../../components/forms/CustomFieldWithError";
import { linkedinLogin, login } from "../../services/auth.service";
import { LinkedInOAuthButton } from "./utils/components/OAuthButtons/LinkedinOAuthButton";
import { SeparationBar } from "./utils/components/SeparationBar";
import {
  NOT_FOUND_ERRORS,
  SIGNIN_ERRORS,
  VOUCHER_ERRORS,
} from "../../errors/errorCodes";
import { AuthContext } from "../../contexts/AuthContext";
import { NavigationBar } from "./utils/components/NavigationBar";
import Button from "../../components/button";
import { ButtonSize, ButtonVariant } from "../../components/button/types";
import { Password } from "../../components/forms/Password";
import { checkVoucher } from "../../services/vouchers.service";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address")
    .min(1, "Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(1, "Password required"),
});

export default function Login({
  goToSignup,
  goToResetPassword,
}: {
  goToSignup: () => void;
  goToResetPassword: () => void;
}) {
  const [invalidPasswordError, setInvalidPasswordError] = useState<
    string | null
  >(null);
  const [invalidEmailError, setInvalidEmailError] = useState<string | null>(
    null
  );
  const [voucherCode, setVoucherCode] = useState<string | null>(null);
  const { setUser } = useContext(AuthContext);
  const { t } = useTranslation("authentication");

  const voucherMutation = useMutation((code: string) => checkVoucher(code), {
    onSuccess: (data) => {
      if (data) {
        localStorage.removeItem("pendingVoucherCode");
        const { isValid, code, redirectUserToContent } = data;
        if (isValid && !redirectUserToContent) {
          window.location.href = `/profile/settings?code=${code}`;
        } else if (isValid && redirectUserToContent) {
          window.location.href = "/";
        } else {
          window.location.href = "/voucher-error?error";
        }
      }
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message;
      localStorage.removeItem("pendingVoucherCode");

      switch (errorMessage) {
        case NOT_FOUND_ERRORS.NOT_FOUND_VOUCHER:
          console.error("Error: Voucher not found.");
          window.location.href = "/voucher-error?voucher-not-found";
          break;
        case VOUCHER_ERRORS.VOUCHER_ALREADY_USED:
          console.error("Error: Voucher already used by someone else.");
          window.location.href = "/voucher-error?voucher-already-used";
          break;
        case VOUCHER_ERRORS.VOUCHER_EXPIRED:
          console.error("Error: Voucher expired.");
          window.location.href = "/voucher-error?voucher-expired";
          break;
        case VOUCHER_ERRORS.VOUCHER_ALREADY_USED_IN_THE_PAST:
          console.error("Error: Voucher already used in the past.");
          window.location.href =
            "/voucher-error?voucher-already-used-in-the-past";
          break;
        default:
          console.error("Error checking voucher code:", errorMessage || error);
          window.location.href = "/voucher-error?error";
          break;
      }
    },
    retry: false,
  });

  const loginMutation = useMutation(login, {
    onSuccess: async (data) => {
      try {
        const { user, token } = data.data;
        localStorage.setItem("accessToken", token);
        setUser(user);

        const code = localStorage.getItem("pendingVoucherCode");
        console.log("code", code);
        if (code) {
          setVoucherCode(code);
        }
      } catch (error) {
        console.error("Error storing token in local storage:", error);
      }
    },
    onError: ({ response }) => {
      if (response.data.message == SIGNIN_ERRORS.INVALID_PASSWORD) {
        setInvalidPasswordError(t("errors.invalid-password"));
      } else if (response.data.message == SIGNIN_ERRORS.UNREGISTERED_EMAIL) {
        setInvalidEmailError(t("errors.email-not-registered"));
      } else {
        // Handle other errors
        console.error("Login error:", response);
      }
    },
  });

  useEffect(() => {
    if (voucherCode) voucherMutation.mutate(voucherCode);
  }, [voucherCode]);

  return (
    <div className="w-full p-9">
      <NavigationBar goToSignup={goToSignup} />
      <div className="mt-4">
        <LinkedInOAuthButton onClick={linkedinLogin} />
      </div>

      <SeparationBar />

      <Formik
        initialValues={{ email: "", password: "", keepMeLogged: false }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setInvalidEmailError(null);
          setInvalidPasswordError(null);
          loginMutation.mutate(values);
        }}
      >
        {({ errors, isValid, touched, setFieldValue, values }) => {
          const noFieldsEmpty =
            values["email"].length > 0 && values["password"].length > 0;
          const disabled = !isValid || !noFieldsEmpty;

          return (
            <Form className="mt-4">
              <div className="w-full">
                <label htmlFor="email">{t("login-form.email")}*</label>
                <div>
                  <CustomFieldWithError
                    className="w-full h-10 mt-1"
                    type="email"
                    id="email"
                    name="email"
                    error={errors.email || invalidEmailError}
                    touched={!!touched.email}
                  />
                </div>
              </div>
              <div className="w-full mt-4">
                <label htmlFor="password">{t("login-form.password")}*</label>
                <div className="mt-1">
                  <Password
                    error={errors.password || invalidPasswordError}
                    touched={!!touched.password}
                  />
                </div>
              </div>

              <div className="w-full mt-4 flex items-center">
                <input
                  className="mr-2 h-5 w-5 border-2 border-Yellow-default rounded focus:ring-0 text-Yellow-default"
                  type="checkbox"
                  onChange={(e) =>
                    setFieldValue("keepMeLogged", e.target?.checked)
                  }
                  id="keepMeLogged"
                  name="keepMeLogged"
                />
                <label className="text-sm" htmlFor="keepMeLogged">
                  {t("login-form.keep-me-logged")}
                </label>
              </div>

              <Button
                type="submit"
                className="mt-6 w-full"
                disabled={disabled}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
              >
                {t("buttons.login")}
              </Button>

              <div className="mt-4 text-sm">
                <button className="underline" onClick={goToResetPassword}>
                  {t("forgot-password")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <div className="text-sm mt-5">
        {t("new-register")}:{" "}
        <button onClick={goToSignup} className="underline cursor-pointer">
          {t("become-a-member")}
        </button>
      </div>
    </div>
  );
}
