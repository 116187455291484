import { axiosInstance } from ".";
import { IDailyPlan } from "../@types";



export async function getDailyPlan(date: string): Promise<IDailyPlan> {
    return (await axiosInstance.get(`/journal/daily/${date}`)).data;
}

export async function createDailyPlan(date: string, data: any): Promise<IDailyPlan> {
    return (await axiosInstance.post(`journal/daily`, { date, ...data })).data;
}


export async function updateDailyPlan(id: number, date: string, data: any): Promise<IDailyPlan> {
    return (await axiosInstance.patch(`journal/daily/${id}`, { date, ...data })).data;
}

export async function getDailyGoals(journalId: number) : Promise<any[]> {
    return (await axiosInstance.get(`/journal/daily/${journalId}/goal`)).data;
}


export async function addDailyGoal(data: { title: string, date: string }, journalId?: number): Promise<any> {
    return (await axiosInstance.post(`journal/daily/${journalId || 0}/goal`, { ...data })).data;
}

export async function updateDailyGoal( data: { title?: string, check?: boolean, rescheduleDate?: string }, journalId?: number, dailyGoalId?: number) {
    if (!journalId || !dailyGoalId) return;
    return (await axiosInstance.patch(`journal/daily/${journalId}/goal/${dailyGoalId}`, { ...data })).data;
}


export async function deleteDailyGoal( journalId?: number, dailyGoalId?: number) {
    if(!journalId || !dailyGoalId) return;
    return (await axiosInstance.delete(`journal/daily/${journalId}/goal/${dailyGoalId}`));
}

export async function getWeeklyPlan(week: number, year: number): Promise<any> {
    return (await axiosInstance.get(`/journal/weekly/${week}/${year}`)).data;
}

export async function addWeeklyGoal(data: { title: string }, journalId?: number): Promise<any> {
    return (await axiosInstance.post(`journal/weekly/${journalId}/goal`, { ...data })).data;
}

export async function deleteWeeklyGoal( journalId?: number, dailyGoalId?: number) {
    if(!journalId || !dailyGoalId) return;
    return (await axiosInstance.delete(`journal/weekly/${journalId}/goal/${dailyGoalId}`));
}

export async function updateWeeklyGoal( data: { title?: string, check?: boolean, rescheduleYear?: number, rescheduleWeek?: number }, journalId?: number, weeklyGoalId?: number) {
    if (!journalId || !weeklyGoalId) return;
    return (await axiosInstance.patch(`journal/weekly/${journalId}/goal/${weeklyGoalId}`, { ...data })).data;
}

export async function updateWeeklyPlan(id: number | null, data: any): Promise<any> {
    if(!id){
        console.log("No id provided to update weekly plan");
        return;
    }
    return (await axiosInstance.patch(`journal/weekly/${id}`, {...data} )).data;
}

export async function createWeeklyPlan(week: number, year: number, data: any): Promise<any> {
    try {
        return (await axiosInstance.post(`journal/weekly`, { week, year, ...data })).data;
    } catch (e) {
        console.log("Error storing weekly plan");
    }
    
}
