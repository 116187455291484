import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import unauthorizedImage from "../../assets/400.png";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { ButtonVariant } from "../../components/button/types";

export default function VoucherErrorPage() {
  const { t } = useTranslation("common");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const queryString = window.location.search;
  const errorType = queryString.split("?")[1];

  const handleGoHome = () => {
    const lastStateString = localStorage.getItem(`lastState-${user?.id}`);
    const lastState = lastStateString ? JSON.parse(lastStateString) : null;

    if (lastState) {
      let url = `/phase/${lastState.phase}`;
      if (lastState.milestone) url += `/milestone/${lastState.milestone}`;
      if (lastState.content) url += `/content/${lastState.content}`;
      navigate(url);
      return;
    } else {
      navigate("/");
    }
  };

  let errorMessage = "";
  switch (errorType) {
    case "voucher-not-found":
      errorMessage = t("voucher-error.voucher-not-found");
      break;
    case "voucher-already-used":
      errorMessage = t("voucher-error.voucher-already-used");
      break;
    case "voucher-expired":
      errorMessage = t("voucher-error.voucher-expired");
      break;
    case "voucher-already-used-in-the-past":
      errorMessage = t("voucher-error.voucher-already-used-in-the-past");
      break;
    default:
      errorMessage = t("voucher-error.unknown-error");
      break;
  }

  return (
    <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
      <div className={`w-full ${user && "md:w-1/2"}`}>
        <h1 className="text-lg font-semibold mb-4">
          {t("voucher-error.page-title")}
        </h1>

        <p className="font-bold mb-4">{errorMessage}</p>

        <Button
          variant={ButtonVariant.PRIMARY}
          className="bg-Grey-light-2 px-4 py-1.5 text-Black"
          onClick={() => handleGoHome()}
        >
          <span className="font-medium">
            {" "}
            {t("voucher-error.go-home-button")}
          </span>
        </Button>
      </div>
    </div>
  );
}
