import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const LANGUAGES = {
  en: "en",
  de: "de",
};

i18n.use(initReactI18next).init({
  lng: LANGUAGES.de,
  fallbackLng: [LANGUAGES.de],
  resources: {
    en: {
      navbar: require("./static/texts/en/navbar.json"),
      // Pages
      authentication: require("./static/texts/en/pages/authentication.json"),
      onboarding: require("./static/texts/en/pages/onboarding.json"),
      content: require("./static/texts/en/pages/content.json"),
      coaches: require("./static/texts/en/pages/coaches.json"),
      journal: require("./static/texts/en/pages/journal.json"),
      community: require("./static/texts/en/pages/community.json"),
      milestones: require("./static/texts/en/pages/milestones.json"),
      home: require("./static/texts/en/pages/home.json"),
      myArea: require("./static/texts/en/pages/myArea.json"),
      termsOfService: require("./static/texts/en/pages/termsOfService.json"),
      privacyPolicy: require("./static/texts/en/pages/privacyPolicy.json"),
      cookiePolicy: require("./static/texts/en/pages/cookiePolicy.json"),
      about: require("./static/texts/en/pages/about.json"),
      jobs: require("./static/texts/en/pages/jobs.json"),
      support: require("./static/texts/en/pages/support.json"),
      imprint: require("./static/texts/en/pages/imprint.json"),
      fullSearch: require("./static/texts/en/pages/fullSearch.json"),
      // Root folder
      common: require("./static/texts/en/common.json"),

    },
    de: {
      navbar: require("./static/texts/de/navbar.json"),
      // Pages
      authentication: require("./static/texts/de/pages/authentication.json"),
      onboarding: require("./static/texts/de/pages/onboarding.json"),
      content: require("./static/texts/de/pages/content.json"),
      coaches: require("./static/texts/de/pages/coaches.json"),
      journal: require("./static/texts/de/pages/journal.json"),
      community: require("./static/texts/de/pages/community.json"),
      milestones: require("./static/texts/de/pages/milestones.json"),
      home: require("./static/texts/de/pages/home.json"),
      myArea: require("./static/texts/de/pages/myArea.json"),
      termsOfService: require("./static/texts/de/pages/termsOfService.json"),
      privacyPolicy: require("./static/texts/de/pages/privacyPolicy.json"),
      cookiePolicy: require("./static/texts/de/pages/cookiePolicy.json"),
      about: require("./static/texts/de/pages/about.json"),
      jobs: require("./static/texts/de/pages/jobs.json"),
      support: require("./static/texts/de/pages/support.json"),
      imprint: require("./static/texts/de/pages/imprint.json"),
      fullSearch: require("./static/texts/de/pages/fullSearch.json"),
      // Root folder
      common: require("./static/texts/de/common.json"),

    },
  },
  ns: ["common"],
  defaultNS: "common",
  fallbackNS: "common",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
