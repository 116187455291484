import { useMutation, useQuery } from "react-query";
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
import { useState } from "react";
import {
  fetchCreditDetailsByToken,
  invoiceCoaching,
} from "../../../services/coaching.service";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

export interface CreditDetails {
  id: string;
  minuteCost: number;
  creditsPerCoaching: number;
}

export function CoachingExpertInvoicePage({
  verificationToken,
}: {
  verificationToken: string;
}) {
  const { t } = useTranslation("coaches");

  const { data: creditDetails, isLoading: isCreditLoading } =
    useQuery<CreditDetails>(
      ["fetchCreditDetails", verificationToken], // The key for caching
      () => fetchCreditDetailsByToken({ verificationToken }), // The function call
      {
        enabled: !!verificationToken, // Ensure token exists before making the request
      }
    );

  const generateDropdownOptions = () => {
    if (!creditDetails) return [];

    const minuteCost = creditDetails.minuteCost;
    const creditsPerCoaching = creditDetails.creditsPerCoaching;
    const steps = [];

    for (let i = 1; i <= creditsPerCoaching; i++) {
      steps.push(i * minuteCost);
    }
    return steps;
  };

  const [date, setDate] = useState<Date>(new Date());
  const [usedCredits, setUsedCredits] = useState(1);
  const [successfullRequest, setSuccessfullRequest] = useState(false);
  const [error, setError] = useState<string | null>(null); // Add error state

  const invoiceMutation = useMutation(
    ["invoiceMutation"],
    () =>
      invoiceCoaching({
        verificationToken,
        date: date.toISOString(),
        usedCredits,
      }),
    {
      onSuccess: () => setSuccessfullRequest(true),
      onError: (error: Error) => setError(error.message),
    }
  );

  return (
    <>
      <h1 className="text-xl mb-5">{t("invoice-page.title")}</h1>
      {successfullRequest ? (
        <div>{t("invoice-page.success")}</div>
      ) : (
        <>
          <div className="mb-3">
            <span>{t("invoice-page.expert-message")}</span>
          </div>
          {creditDetails ? ( // Render only when creditDetails is available
          <select
            id="career-select"
            name="careerLevel"
            className="border rounded-lg px-4 py-3.5 text-base mb-4 w-1/3"
            onChange={(e) => {
              const minutes = parseInt(e?.target?.value);
              setUsedCredits(minutes / creditDetails.minuteCost);
            }}
            value={usedCredits * creditDetails.minuteCost}
          >
            {generateDropdownOptions().map((time) => (
              <option key={time} value={time}>
                {`${time} ${t("invoice-page.minutes")}`}
              </option>
            ))}
          </select>
        ) : (
          <div>{t("invoice-page.loading")}</div>
        )}
          <div>
            {/* <div className="ml-1 mb-1">
                            Coaching date:
                        </div> */}
            <ReactDatePicker
              id="date-picker"
              maxDate={new Date()}
              selected={date}
              onChange={(d) => setDate(d || new Date())}
              dateFormat="dd/MM/yyyy"
              placeholderText={t("select-a-date") || "Select a date"}
              className="border rounded-lg px-4 py-3.5 text-base"
            />
          </div>
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            className="mt-5"
            onClick={() => invoiceMutation.mutate()}
          >
            {t("invoice-page.invoice")}
          </Button>
          {error && (
            <div className="text-red-500 mt-2" style={{ color: "#C41429" }}>
              {t("warning-invoice")}
            </div>
          )}{" "}
          {/* Display error message */}
        </>
      )}
    </>
  );
}
