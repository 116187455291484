import { endOfWeek, getWeek, getYear, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { createWeeklyPlan, getWeeklyPlan, updateWeeklyPlan } from "../../../../services/journal.service";
import { useWeeklyJournalState } from "./useWeeklyJournalState";
import { useWeeklyGoals } from "./useWeeklyGoals";



export function useWeeklyJournal() {

    const {
        weeklyPlanId,
        startDate,
        endDate,
        achievements,
        setAchievements,
        insights,
        setInsights,
        improvements,
        setImprovements,
        productivityRate,
        setProductivityRate,
        gratitudeRate,
        setGratitudeRate,
        happinessRate,
        setHappinessRate,
        setJournal,
        handleDateChange,
        weeklyGoals,
        setWeeklyGoals,
        newWeeklyGoal,
        setNewWeeklyGoal
    } = useWeeklyJournalState();

    useEffect(() => {
        return () => {
            updatePlanMutation.mutate()
        }
    }, [startDate])


    const createPlanMutation = useMutation(['weeklyPlan'], ()=> createWeeklyPlan(getWeek(startDate || new Date()), getYear(startDate || new Date()), {  }),
     {
        onSuccess: (data) => {
            setJournal(data)
        }
    });

    const updatePlanMutation = useMutation(['weeklyPlanUpdate'], () => updateWeeklyPlan(weeklyPlanId, { productivityRate, gratitudeRate, happinessRate, achievements, insights, improvements }))

    const { refetch: refetchGoals } = useQuery(['weeklyJournal', startDate], () => getWeeklyPlan(getWeek(startDate), getYear(startDate)), {
        onSuccess: (data) => {
            if(!data) {
                setJournal(data);
                createPlanMutation.mutate();
            } else {
                setJournal(data);
            }
        }
    });

    useEffect(()=>{
        updatePlanMutation.mutate()
    },[gratitudeRate, productivityRate, happinessRate])


    const { addWeeklyGoalMutation, updateWeeklyGoalMutation, deleteWeeklyMutation} = useWeeklyGoals({weeklyPlanId, refetchGoals, setNewWeeklyGoal});

    return {
        startDate,
        endDate,
        handleDateChange,
        achievements,
        setAchievements,
        insights,
        setInsights,
        improvements,
        setImprovements,
        weeklyGoals,
        newWeeklyGoal,
        setNewWeeklyGoal,
        updateWeeklyGoalMutation,
        deleteWeeklyMutation,
        addWeeklyGoalMutation,
        weeklyPlanId,
        productivityRate,
        setProductivityRate,
        gratitudeRate,
        setGratitudeRate,
        happinessRate,
        setHappinessRate,
        updatePlanMutation
    }



}