import { useQuery } from "react-query";
import { AssessmentContent, Question } from "../../../../../../@types";
import { getCurrentAssessmentAnswers } from "../../../../../../services/selfAssessment.service";

function AssessmentQuestionReport({ question, selfAssessmentState }: { question: Question,  selfAssessmentState: any }) {



    const answer = selfAssessmentState?.answers?.filter((a: any) => a.questionId === question.id)[0]?.answer;

    const getReport = () => {
        switch(answer) {
            case 'Yes':
                return question.templateResponseYes;
            case 'Maybe':
                return question.templateResponseMaybe;
            case 'No':
                return question.templateResponseNo;
        }
    }

    return ( <div dangerouslySetInnerHTML={{ __html: getReport() || "" }}></div>)
}


export function AssessmentReport({content}: {content : AssessmentContent}) {

    const { data: selfAssessmentState, refetch } = useQuery(['self-assessment', content], () => getCurrentAssessmentAnswers(content.id));


    return (
        <div>
            {content?.questions?.map((q: Question) => <AssessmentQuestionReport question={q} selfAssessmentState={selfAssessmentState}/>)}
        </div>
    )


}