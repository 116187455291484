import { useState } from "react";
import { Modal } from "../../components/modal";
import { useLatestLegal } from "../../hooks/useLatestLegal";
import { useTranslation } from "react-i18next";
import { BusinessInfoContent } from "../../@types";



export function LegalTerm({ accepted, setAccepted, textPrefix, clickableText, textSuffix, latestVersion }: {  accepted: boolean, setAccepted: (value: boolean) => void, textPrefix: string, clickableText: string, textSuffix: string, latestVersion?: BusinessInfoContent | null }) {

    const [showLegalModal, setShowLegalModal] = useState(false);

    return (
        <div className="mt-4">
            <Modal isOpen={showLegalModal} onClose={() => setShowLegalModal(false)} title={latestVersion?.title || ""}>
                <div className="max-h-96 overflow-auto p-5" dangerouslySetInnerHTML={{ __html: latestVersion?.renderedContent || ""}}></div>
            </Modal>
            <div className="flex items-center">
                <input className="h-5 w-5 border-2 border-Yellow-default rounded focus:ring-0 text-Yellow-default" type="checkbox" id="terms-conditions" checked={accepted} onChange={(e: any) => setAccepted(e.target.checked)} />
                <span className="ml-2">{textPrefix} <a className="cursor-pointer underline" onClick={() => setShowLegalModal(true)}>{clickableText}</a> {textSuffix}</span>
            </div>
        </div>
    )

}



export function LegalRequirements({ termsAndConditionsAccepted,
    privacyStatementAccepted,
    setTermsAndConditionsAccepted,
    setPrivacyStatementAccepted
}: {
    termsAndConditionsAccepted: boolean,
    privacyStatementAccepted: boolean,
    setTermsAndConditionsAccepted: (value: boolean) => void,
    setPrivacyStatementAccepted: (value: boolean) => void
}) {

    const {t} = useTranslation("authentication");

    // TODO: Add getters for the legal terms
    const { latestTermsAndConditions, latestPrivacyStatement } = useLatestLegal();

    return (<>

        <div className="mt-2 text-sm">
            <LegalTerm
                accepted={termsAndConditionsAccepted}
                setAccepted={setTermsAndConditionsAccepted}
                textPrefix={t("legal.terms-and-conditions.prefix")}
                clickableText={t("legal.terms-and-conditions.name")}
                textSuffix={t("legal.terms-and-conditions.suffix")}
                latestVersion={latestTermsAndConditions}
            />

            <LegalTerm
                accepted={privacyStatementAccepted}
                setAccepted={setPrivacyStatementAccepted}
                textPrefix={t("legal.privacy-statement.prefix")}
                clickableText={t("legal.privacy-statement.name")}
                textSuffix={t("legal.privacy-statement.suffix")}
                latestVersion={latestPrivacyStatement}
            />

        </div>
    </>

    )

}