import { useParams } from "react-router-dom";
import HeyflowForm from "../../../components/forms/HeyflowForm";
import { useQuery } from "react-query";
import { getHeyForm } from "../../../services/content.service";
import { useTranslation } from "react-i18next";

export function Forms() {
  const { form = "" } = useParams();
  const { t } = useTranslation("common");
  const { data, isLoading, isError } = useQuery(["heyflowForm", form], () =>
    getHeyForm(form)
  );

  return (
  <div className="flex items-center justify-center min-h-screen">
    {isLoading ? (
      <p>{t("forms-load")}</p>
    ) : isError ? (
      <p className="text-red-500">{t("forms-error")}</p>
    ) : data ? (
      <HeyflowForm
        formTitle={data?.fields?.formTitle}
        heyflowWrapper={data?.fields?.heyflowWrapper}
      />
    ) : (
      <p className="text-red-500">{t("forms-error")}</p>
    )}
  </div>
);

}
