/* Third party imports */
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

/* Local imports */
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
import { AuthContext } from "../../../contexts/AuthContext";
import { StepNameProps } from "./types";
import { useContext } from "react";


export default function StepName({ onNext, onSkip }: StepNameProps) {
  const { t } = useTranslation("onboarding");

  const { user, configurations } = useContext(AuthContext);;

  const renderBirthdayField = configurations?.onboardingBirthday

  const onSubmit = ({ firstName,
    lastName,
    day,
    month,
    year }: any) => {

    if (day.length < 2) { day = `0${day}` };
    if (month.length < 2) { month = `0${month}` };

    const birthday = day && month && year ? new Date(`${year}-${month}-${day}`).toISOString() : null;

    onNext({ firstName, lastName, birthday })
  }

  if (user?.displayName) onSkip();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        day: '',
        month: '',
        year: ''
      }}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, setFieldValue }) => (
        <div className="w-full">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold leading-7 mb-1">{t("stepName.title")}</h1>
            <h2 className="text-base mt-2">{t("stepName.subtitle")}</h2>
            <Form className="w-full  mt-4">
              <div className="flex flex-col w-full">
                <label className="text-base mb-1">{t("stepName.firstName")}*</label>
                <Field
                  type="text"
                  placeholder={t("stepName.writeHere")}
                  className="border rounded-lg px-4 py-3.5 text-base w-full border-black"
                  id="firstName"
                  name="firstName"
                  value={values?.firstName}
                  onChange={handleChange}
                  required
                />
                <label className="text-base mb-1 mt-4">{t("stepName.lastName")}*</label>
                <Field
                  type="text"
                  placeholder={t("stepName.writeHere")}
                  className="border rounded-lg  px-4 py-3.5  text-base  w-full border-black"
                  id="lastName"
                  name="lastName"
                  value={values?.lastName}
                  onChange={handleChange}
                  required
                />
                {renderBirthdayField && (
                  <>
                <label htmlFor="date-picker" className="text-base mb-1 mt-4">{t("stepName.birthday")}</label>
                <div className="w-full mb-4 flex flex-row grid grid-cols-3 gap-2">
                  <input
                    placeholder={t("stepName.day") || ""}
                    type="number"
                    min="1"
                    max="31"
                    className="border border-black rounded-lg px-4 py-3.5 text-base"
                    onChange={(data) => setFieldValue("day", data?.target?.value)} 
                    />
                    
                  <input
                    placeholder={t("stepName.month") || ""}
                    type="number"
                    min="1"
                    max="12"
                    className="border border-black rounded-lg px-4 py-3.5 text-base"
                    onChange={(data) => setFieldValue("month", data?.target?.value)} 
                    />
                  <input
                    placeholder={t("stepName.year") || ""}
                    type="number"
                    min="1900"
                    max={new Date(Date.now()).getFullYear().toString()}
                    className="border border-black rounded-lg px-4 py-3.5 text-base"
                    onChange={(data) => setFieldValue("year", data?.target?.value)} 
                    />
                </div>
                </>
                )}
              </div>
              <div className="w-full flex justify-between	mt-6">
                <Button className="w-full" type="submit" variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                  {t("stepName.next")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
