
export enum JWT_AUTH_ERRORS {
    INVALID_PAYLOAD = 'INVALID_PAYLOAD',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    INVALID_TOKEN = 'INVALID_TOKEN',
}

export enum SIGNUP_ERRORS {
    EMAIL_ALREADY_REGISTERED = 'EMAIL_ALREADY_REGISTERED',
    NOT_MATCHING_PASSWORDS = 'NOT_MATCHING_PASSWORDS',
    PASSWORD_NOT_STRONG_ENOUGH = 'PASSWORD_NOT_STRONG_ENOUGH',
    USERNAME_ALREADY_EXISTS = 'USERNAME_ALREADY_EXISTS',
    INVALID_EMAIL_VERIFICATION_TOKEN = 'INVALID_EMAIL_VERIFICATION_TOKEN',
    INVALID_SIGNUP_VERIFICATION_TOKEN = 'INVALID_SIGNUP_VERIFICATION_TOKEN',
    INVALID_VOUCHER = 'INVALID VOUCHER CODE PROVIDED'
}

export enum PASSWORD_RESET_ERRORS {
    INVALID_RESET_PASSWORD_VERIFICATION_TOKEN = 'INVALID_RESET_PASSWORD_VERIFICATION_TOKEN',
    INVALID_EMAIL = 'INVALID_EMAIL',
}

export enum SIGNIN_ERRORS {
    NO_AUTHORIZATION_HEADER = 'NO_AUTHORIZATION_HEADER',
    INVALID_AUTH_SCHEME = 'INVALID_AUTH_SCHEME',
    UNREGISTERED_EMAIL = 'UNREGISTERED_EMAIL',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
}

export enum VOUCHER_ERRORS {
    VOUCHER_EXPIRED = 'VOUCHER_EXPIRED',
    VOUCHER_ALREADY_USED = 'VOUCHER_ALREADY_USED',
    VOUCHER_ALREADY_USED_IN_THE_PAST = 'VOUCHER_ALREADY_USED_IN_THE_PAST'
}

export enum INTERNAL_SERVER_ERROR {
    ERROR_UPDATING_USER = 'ERROR_UPDATING_USER',
    ERROR_SENDING_EMAIL = 'ERROR_SENDING_EMAIL',
    ERROR_FETCHING_USER = 'ERROR_FETCHING_USER',
    ERROR_CREATING_XING_USER = 'ERROR_CREATING_XING_USER',

}


export enum NOT_FOUND_ERRORS {
    NOT_FOUND_ONBOARDING_ENUM = 'NOT_FOUND_ONBOARDING_ENUM',
    NOT_FOUND_USER = 'NOT_FOUND_USER',
    NOT_FOUND_VOUCHER = 'NOT_FOUND_VOUCHER',
    NOT_FOUND_EMAIL = 'NOT_FOUND_EMAIL',
}

export type ERROR_CODE = JWT_AUTH_ERRORS | SIGNUP_ERRORS | PASSWORD_RESET_ERRORS | SIGNIN_ERRORS | VOUCHER_ERRORS | INTERNAL_SERVER_ERROR | NOT_FOUND_ERRORS;
