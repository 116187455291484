import { useNavigate } from "react-router-dom";
import { IContentWithProgress } from "../../../../../@types";
import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";

const PhaseButtonWrapper = ({
  isActive,
  onClick,
  text,
  index,
  dynamicWidth,
}: {
  isActive: boolean;
  onClick: () => void;
  text: string;
  index: number;
  dynamicWidth: number;
}) => {
  const width = dynamicWidth;
  const height = 50;

  const arrowLength = 17;
  const heightOffset = 2;

  const polygonCoords = (idx: number) => {
    const outerArrow = `${width - arrowLength} ${
      height - heightOffset
    }, ${width} ${height / 2}, ${width - arrowLength} ${heightOffset}`;
    const innerArrow = `0 ${heightOffset}, ${
      idx == 0 ? `0 ${height / 2}` : `${arrowLength} ${height / 2}`
    }, 0 ${height - heightOffset}`;
    return `${innerArrow}, ${outerArrow}`;
  };

  return (
    <div onClick={onClick} className="cursor-pointer">
      <svg height={height} width={width}>
        <polygon
          points={polygonCoords(index)}
          stroke={isActive ? "#FDC300" : "black"}
          fill={isActive ? "#FDC300" : "white"}
          strokeWidth="1"
        />
        <text
          x={index == 0 ? `${width / 2 - 30}` : `${width / 2 - 22}`}
          y={`${height / 2 + 5}`}
          fontSize="14"
          className={`fill-Black ${isActive ? "font-bold" : "font-medium"}`}
        >
          {text}
        </text>
      </svg>
    </div>
  );
};

export function PhasesList({ className }: { className?: string }) {
  const { phases, activePhase, milestones } = useContentAndProgress();
  const { user } = useContext(AuthContext);;
  const journeyId = user?.userDetails.journeyId;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const phaseListRef = useRef<any>(null);



  const navigate = useNavigate();

  const handlePhaseCLick = (phase: IContentWithProgress) => {
    const firstMilestone = milestones
      ?.filter((ms: IContentWithProgress) =>
        phase.content.childContentIds.includes(ms.content.platformContentId)
      )
      .sort(
        (ms1: IContentWithProgress, ms2: IContentWithProgress) =>
          ms1.content.orderForProgress[journeyId ?? ""] -
          ms2.content.orderForProgress[journeyId ?? ""]
      )[0];

    navigate(
      `/phase/${phase.content.platformContentId}/milestone/${firstMilestone?.content.platformContentId}`
    );
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  if (!phases || phases.length <= 1) {
    return null;
  }

 

  return (
    <>
      {activePhase ? (
        <div
          ref={phaseListRef}
          className={`w-full flex flex-row justify-between ${className}`}
        >
          {phases?.map((phase: IContentWithProgress, idx: number) => {
            const isActive =
              activePhase?.content.platformContentId ===
              phase.content.platformContentId;
            return (
              <PhaseButtonWrapper
                key={idx}
                isActive={isActive}
                onClick={() => handlePhaseCLick(phase)}
                text={`Phase ${
                  (phase?.content?.orderForProgress[journeyId ?? ""] || 0) + 1
                }`}
                index={idx}
                dynamicWidth={
                  phaseListRef?.current?.offsetWidth / phases.length
                }
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
