import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/restart_logo.svg";
import { NavigationTabs } from "./navigationTabs";
import { SearchBar } from "./searchBar";
import { ReactComponent as SearcIcon } from "../../assets/search.svg";
import { createPortal } from "react-dom";
import { useState } from "react";
import { SearchBarMobile } from "./searchBar/MobileSearchBar";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
export default function Navbar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();

  const { configurations } = useContext(AuthContext);;
  const shouldRenderSearch = configurations?.includesSearch;

  return (
    <div className="flex w-full items-center p-4 justify-between bg-Grey-navbar">
      <div className="w-1/3">
        <div className="ml-12 cursor-pointer" onClick={() => navigate("/")}>
          <Logo className="h-10" />
        </div>
      </div>
      <div id="test" />
      <NavigationTabs className="lg:w-[45%] xl:w-1/3 hidden lg:flex" />

      <div className="w-1/3 flex justify-end pl-32 pr-8 hidden lg:flex">
        {shouldRenderSearch && <SearchBar className="w-full" />}
      </div>

      <div
        className="pr-3 lg:hidden"
        onClick={() => setIsSearchOpen(!isSearchOpen)}
      >
        {shouldRenderSearch && <SearcIcon />}
      </div>
      {isSearchOpen &&
        createPortal(
          <div className="top-0 pt-4 absolute overflow-hidden w-full bg-white z-50">
            {shouldRenderSearch && (
              <SearchBarMobile onClose={() => setIsSearchOpen(false)} />
            )}
          </div>,
          document.getElementById("search-view") || document?.body
        )}
    </div>
  );
}
