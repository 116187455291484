import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/restart_logo.svg";

export function PrivateFooter() {
  const { t } = useTranslation("common");

  return (
    <div className="flex w-full bg-Grey-navbar flex-col-reverse lg:flex-row lg:items-center lg:justify-between pb-[84px] lg:mb-0 py-6 px-9 lg:px-12 lg:py-6 mt-12">
      <div className="flex flex-col lg:flex-row">
        <a href="/terms-of-service" className="mr-6 mb-2 lg:mb-0">
          {t("footer.terms")}
        </a>
        <a href="/privacy-policy" className="mr-6 mb-2 lg:mb-0">
          {t("footer.privacy")}
        </a>
        <a href="/cookie-policy" className="mr-6 mb-2 lg:mb-0">
          {t("footer.cookie")}
        </a>
        <a href="/support" className="mr-6 mb-2 lg:mb-0">
          {t("footer.support")}
        </a>
        <a href="/imprint" className="mr-6 mb-2 lg:mb-0">
          {t("footer.imprint")}
        </a>
        {process.env.REACT_APP_FEEDBACK_FORM_URL ? (
          <a
            href={process.env.REACT_APP_FEEDBACK_FORM_URL}
            target="_blank"
            className="mr-6 mb-2 lg:mb-0"
          >
            {t("footer.feedback")}
          </a>
        ) : null}
        <a
          href="https://www.restartcareer.de"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 mb-2"
        >
          {t("footer.about")}
        </a>
      </div>
      <div>
        <Logo className="h-10 mb-6 lg:mb-0" />
      </div>
    </div>
  );
}
