/* Third party imports */
import DatePicker from "react-datepicker";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

/* Local imports */
import { StepNeedsProps } from "./types";

/* Styles imports */
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import { getLocaleFromLanguage } from "../../../services/content.service";
export default function StepNeeds({
  onNext,
  onSkip,
  selectedOption,
  availableJourneyIds,
}: StepNeedsProps) {
  const { t } = useTranslation("onboarding");

  const monthsLocal = getLocaleFromLanguage();
  const localeMapping = {
    de: de,
    "en-US": enUS,
  };

  const convertedLocale = localeMapping[monthsLocal];

  return (
    <Formik
      initialValues={{
        timingLastDayAtWork: null,
        timingAchieveRestart: null,
        need: "",
      }}
      onSubmit={onNext}
    >
      {({ values, setFieldValue }) => (
        <div className="w-full pb-10">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold leading-7 mb-1">
              {selectedOption === availableJourneyIds?.standardUserJourneyId
                ? t("stepNeeds.title")
                : t("stepNeeds-reorientation.title")}
            </h1>
            <h2 className="text-base">
              {selectedOption === availableJourneyIds?.standardUserJourneyId
                ? t("stepNeeds.subtitle")
                : t("stepNeeds-reorientation.subtitle")}
            </h2>
            <h2 className="text-base">
              {selectedOption === availableJourneyIds?.standardUserJourneyId
                ? t("stepNeeds.subtitle2")
                : t("stepNeeds-reorientation.subtitle2")}
            </h2>
            <Form className="w-full  mt-4">
              <div className="flex flex-col w-full">
                {selectedOption !==
                  availableJourneyIds?.reorientationJourneyId && (
                  <div>
                    <label htmlFor="date-picker" className="text-base mb-1">
                      {t("stepNeeds.lastDay")}
                    </label>
                    <DatePicker
                      id="date-picker"
                      selected={values.timingLastDayAtWork}
                      onChange={(date) =>
                        setFieldValue("timingLastDayAtWork", date)
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText={t("stepNeeds.select-date") as string}
                      className="border rounded-lg px-4 py-3.5 text-base w-full mb-4"
                      locale={convertedLocale}
                    />
                  </div>
                )}
                <label htmlFor="date-picker" className="text-base mb-1">
                  {t("stepNeeds.timeToRestart")}
                </label>
                <DatePicker
                  id="date-picker"
                  selected={values.timingAchieveRestart}
                  onChange={(date) =>
                    setFieldValue("timingAchieveRestart", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText={t("stepNeeds.select-date") as string}
                  className="border rounded-lg px-4 py-3.5 text-base w-full mb-4"
                  locale={convertedLocale}
                />
                <label htmlFor="date-picker" className="text-base mb-1">
                  {t("stepNeeds.notes")}
                </label>
                <Field
                  type="text"
                  name="need"
                  placeholder={t("stepNeeds.needs")}
                  className="border rounded-lg px-4 py-3.5 text-base w-full h-36"
                  component="textarea"
                />
              </div>
              <div className="w-full flex justify-between	mt-6">
                <Button
                  className="w-full"
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                >
                  {t("stepNeeds.next")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
