import { axiosInstance } from ".";
import i18n from "i18next";
import { LANGUAGES } from "../i18n";
import { useTranslation } from "react-i18next";
import { Templates } from "../@types";

function getLocaleFromLanguage() {
  const language = i18n.language;
  if (language === LANGUAGES.en) {
    return "en-US";
  } else if (language === LANGUAGES.de) {
    return "de";
  } else {
    return "en-US";
  }
}

export async function submitAnswer({
  selfAssessmentId,
  answer,
}: {
  selfAssessmentId: string;
  answer: { questionId: string; answer: "Yes" | "Maybe" | "No" };
}) {
  return await axiosInstance.post(`/self-assessments/submit`, {
    selfAssessmentId,
    answers: [answer],
  });
}

export async function getSelfAssessments(): Promise<
  { assessmentId: string; answers: any[] }[]
> {
  return (await axiosInstance.get(`/self-assessments`)).data;
}

export async function getFirstSelfAssessment(): Promise<
  { assessmentId: string }
> {
  return (await axiosInstance.get(`/self-assessments/first`)).data;
}

export async function getCurrentAssessmentAnswers(selfAssessmentId: string) {
  return (await axiosInstance.get(`/self-assessments/${selfAssessmentId}`))
    .data;
}

export async function getAssessmentsOverviewForPhase(phaseId: string) {
  return (
    await axiosInstance.get(
      `/self-assessments/overview/${phaseId}?locale=${getLocaleFromLanguage()}`
    )
  ).data;
}

export async function getAssessmentsOverviewDownload(phaseId: string) {
  try {
    const response = await axiosInstance.get(
      `/self-assessments/overview/download/${phaseId}?locale=${getLocaleFromLanguage()}`,
      {
        responseType: "arraybuffer", // Specify the response type as 'arraybuffer'
      }
    );
    let filename;
    if (getLocaleFromLanguage() === "en-US") {
      filename = "Goalsetting.pdf";
    } else {
      filename = "Zielsetzung.pdf";
    }

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and initiate the download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    // Clean up the temporary URL after download
    URL.revokeObjectURL(url);

    return true; // Return true to indicate successful download
  } catch (error) {
    console.error("Error downloading PDF:", error);
    return false; // Return false to indicate download failure
  }
}

export async function getAssessmentsReportDownload(phaseId: string) {
  try {
    const response = await axiosInstance.get(
      `/self-assessments/report/download/${phaseId}?locale=${getLocaleFromLanguage()}`,
      {
        responseType: "arraybuffer", // Specify the response type as 'arraybuffer'
      }
    );
    let filename;
    if (getLocaleFromLanguage() === "en-US") {
      filename = "Assessmentreport.pdf";
    } else {
      filename = "Beurteilung.pdf";
    }

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and initiate the download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    // Clean up the temporary URL after download
    URL.revokeObjectURL(url);

    return true; // Return true to indicate successful download
  } catch (error) {
    console.error("Error downloading PDF:", error);
    return false; // Return false to indicate download failure
  }
}
export async function getAvailableTemplates(): Promise<Templates[]> {
  try {
    const templates = (await axiosInstance.get(`/credits/templates`)).data;
    return templates;
  } catch (error) {
    console.error("Error fetching available templates:", error);
    throw error;
  }
}
export async function getAvailableTemplate(templateName: string) {
  try {
    const template = (
      await axiosInstance.get(`/credits/template`, {
        params: { name: templateName },
      })
    ).data;
    console.log(
      "🚀 ~ file: selfAssessment.service.ts:136 ~ getAvailableTemplate ~ template:",
      template
    );
    return template;
  } catch (error) {
    console.error("Error fetching available templates:", error);
    throw error;
  }
}

export async function uploadCVAndCreateCredit(
  cvFile: any,
  selectedTemplate: string,
  cvCheckType: string
) {
  try {
    const formData = new FormData();
    formData.append("cvCheckType", cvCheckType);
    formData.append("selectedTemplate", selectedTemplate);
    formData.append("cvFile", cvFile);

    const response = await axiosInstance.post("/credits/cv-check", formData);

    return response.data;
  } catch (error) {
    console.error("Error uploading CV and creating credit:", error);
    throw error;
  }
}

export async function fetchCVCheckData() {
  try {
    const response = await axiosInstance.get("/credits/cv-check");
    return response.data;
  } catch (error) {
    console.error("Error fetching CV check data:", error);
    throw error;
  }
}

export async function submitCV({cvFile, token} : {cvFile: any, token: string}) {
  try {
    const formData = new FormData();
    formData.append("cvFile", cvFile);
    formData.append("token", token);
    
    const response = await axiosInstance.post("/credits/submit-cv", formData);

    return response.data;
  } catch (error) {
    console.error("Error submitting CV:", error);
    throw error;
  }
}


export async function fetchSignedUrl(key: string) {
  try {
    const response = await axiosInstance.get("/credits/signed-url", {
      params: { awsKey: key },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching CV check data:", error);
    throw error;
  }
}
