import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { ISearchResult } from "../../../../@types";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { searchContent } from "../../../../services/content.service";
import { debounce, throttle } from "lodash";
import { GroupedSearch, SearchResultDisplay } from "../components";
import { ContentType } from "../../../../enums";
import { useNavigate } from "react-router-dom";
import { useOutsideClickDetector } from "../../../../hooks/useOutsideClickDetector";
import Button from "../../../button";
import { ButtonSize, ButtonVariant } from "../../../button/types";



function SearchListMobile({ searchResults = [], searchPrompt  }: { searchResults?: ISearchResult[], searchPrompt: string }) {

  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('fullSearch')
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  useOutsideClickDetector(dropdownRef, () => setSearchDropdownOpen(false));

  const groupedSearchResults: GroupedSearch = useMemo(() => {
    const coachResults = searchResults.filter((sr) => sr.contentType === ContentType.COACH);
    const articlesResults = searchResults.filter((sr) => sr.contentType === ContentType.ARTICLE);
    const assessmentsResults = searchResults.filter((sr) => sr.contentType === ContentType.ASSESSMENT);
    const coachingResult = searchResults.filter((sr) => sr.contentType === ContentType.COACHING);
    const forumResult = searchResults.filter((sr) => sr.contentType === ContentType.POST);

    return {
      coachResults,
      articlesResults,
      assessmentsResults,
      coachingResult,
      forumResult
    }

  }, [searchResults])

  const getTitle = (key: string) => {
    switch (key) {
      case 'articlesResults':
        return t('articles');
      case 'coachResults':
        return t('coaches');
      case 'coachingResult':
        return t('coachings');
      case 'assessmentsResults':
        return t('assessments');
      case 'forumResult':
        return t('forum');
    }
  }


  useEffect(() => {
    if (searchResults.length > 0) {
      setSearchDropdownOpen(true);
    }
  }, [searchResults])

  return (<div className='w-full z-50 border-b px-4 shadow-md'>
    {(searchResults?.length && searchDropdownOpen) ?
      <>
      <div className='text-lg text-Yellow-default font-semibold	mt-8 mb-6' >{t('results')}</div>
        <div className='w-full overflow-hidden z-50'>
          {Object.entries(groupedSearchResults)?.filter(([k, v]) => v.length > 0)?.map(([k, v], i) =>
            <div className='py-2'>
              <h2 className="text-Grey-dark text-lg font-bold mb-1 px-4">{getTitle(k)}</h2>
              {v.map((sr: ISearchResult) => <SearchResultDisplay
                classNames="py-3 px-4"
                searchResult={sr}
                closeDropdown={() => setSearchDropdownOpen(false)}
                onClose={() => { }} />)}
              <hr />
            </div>

          )}

        </div>
        <div className=' bg-white rounded-b pb-3 pt-1'>
          <Button onClick={() => {
            navigate(`/search?${searchPrompt}`)
            setSearchDropdownOpen(false);
            }} className='w-full' variant={ButtonVariant.SECONDARY} size={ButtonSize.SMALL}>
            {t('see-all')}
          </Button>
        </div>
      </>
      : null}
  </div>
  )

}

export function SearchBarMobile({ className, onClose }: { className?: string, onClose: () => void }) {

  const { i18n, t } = useTranslation("content");

  const [searchPrompt, setSearchPrompt] = useState("");

  const { data: searchResults } = useQuery([searchPrompt], () => {
    if (searchPrompt.length > 0) {
      return searchContent(searchPrompt, i18n.language);
    }
  });

  const debouncedOnChange = debounce((value: string) => {
    // Make request to backend with value
    setSearchPrompt(value);
  }, 500);

  // Throttled onChange handler that fires every 500ms
  const throttledOnChange = throttle((value: string) => {
    // Make request to backend with value
    setSearchPrompt(value);
  }, 500);

  // Effect hook to cleanup debounced onChange handler
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleSearchPromptChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedOnChange(e.target.value);
    throttledOnChange(e.target.value);
  }

  return (
    <div className={` ${className}`}>
      <div className='flex justify-between w-full items-center px-8 pb-4'>
        <input value={searchPrompt} onChange={handleSearchPromptChange} className="w-full pl-[20px] border placeholder-Grey rounded-full pr-4 py-2" placeholder={t("search") || "Search"} />
        <span className="ml-6 cursor-pointer" onClick={onClose}>{t('cancel')}</span>
      </div>
      <SearchListMobile searchResults={searchResults} searchPrompt={searchPrompt} />

    </div>
  )
}