import { useQuery } from "react-query";
import { ArticleContent, FormattedContent } from "../../../../../@types";
import { getMultipleContentByIds } from "../../../../../services/content.service";
import { GenericTeaserDisplay } from "../../../home/milestones/milestonesContentList/GenericTeaserDisplay";
import { ExternalContentTeaser } from "../../../../../components/externalContentTeaser";



export function AssessmentsPreview({assessments} : {assessments : FormattedContent[]}) {

    return (<>
            {assessments.map((a: FormattedContent) => <ExternalContentTeaser content={a as ArticleContent} />)}
        </>)

}