import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { AuthContext } from "../../../contexts/AuthContext";
import { NOT_FOUND_ERRORS, VOUCHER_ERRORS } from "../../../errors/errorCodes";
import { redeemVoucher } from "../../../services/vouchers.service";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import { SeparationBar } from "../../public/utils/components/SeparationBar";

export function VoucherRequired() {
  const { setUser, refetchConfigs } = useContext(AuthContext);

  const { t } = useTranslation("authentication");

  const [info, setInfo] = useState<{
    message: string;
    type: "ERROR" | "SUCCESS";
  } | null>(null);

  const [voucherCode, setVoucherCode] = useState<string>(
    localStorage.getItem("pendingVoucherCode") || ""
  );

  const disabled = !voucherCode.length;

  const redeemVoucherMutation = useMutation(redeemVoucher, {
    onSuccess: (response) => {
      refetchConfigs();
      setInfo({ message: t("voucher-valid"), type: "SUCCESS" });
      setTimeout(() => {
        setUser(response.data);
      }, 2000);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
      if (error.response.data.message == NOT_FOUND_ERRORS.NOT_FOUND_VOUCHER) {
        setInfo({ message: t("voucher-not-found"), type: "ERROR" });
      } else if (
        error.response.data.message == VOUCHER_ERRORS.VOUCHER_ALREADY_USED
      ) {
        setInfo({ message: t("voucher-already-used"), type: "ERROR" });
      } else if (
        error.response.data.message == VOUCHER_ERRORS.VOUCHER_EXPIRED
      ) {
        setInfo({ message: t("voucher-expired"), type: "ERROR" });
      } else {
        setInfo({ message: t("voucher-error"), type: "ERROR" });
      }
    },
  });

  return (
    <div className="flex w-full p-6 justify-center">
      <div className="md:w-1/2 lg:w-1/3 p-6">
        <h1 className="text-xl font-bold">{t("license-activation.title")}</h1>
        <p className="text-md mt-2">{t("license-activation.message-pt1")}</p>
        <p className="text-md mt-6">{t("license-activation.message-pt2")}</p>

        <div className="mt-3">
          <p className="font-bold">{t("license-activation.input-label")}*</p>
          <input
            placeholder={t("license-activation.placeholder") || ""}
            className="border rounded-md mt-1 px-4 py-3 w-full"
            type="text"
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
          />
          {info ? (
            <div
              className="text-lg"
              style={{ color: info.type === "ERROR" ? "#C41429" : "#048273" }}
            >
              {info.message}
            </div>
          ) : null}
        </div>
        <Button
          onClick={() => redeemVoucherMutation.mutate(voucherCode.trim())}
          className={`w-full mt-6 p-3 ${
            disabled ? "bg-Grey-tint" : "bg-Yellow-default"
          }`}
        >
          <div>{t("license-activation.button")}</div>
        </Button>

        <SeparationBar />
        <div className="mt-4">{t("license-activation.message-pt3")}</div>
      </div>
    </div>
  );
}
