import { axiosInstance } from ".";
import { IUser, UserAvatar, UserWithConfigurations } from "../@types";

export async function getLoggedUser(): Promise<UserWithConfigurations> {
  const token = localStorage.getItem("accessToken");
  const response = await axiosInstance.get(`/users/logged-in`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return {
    user: response.data?.user,
    configurations: response.data?.configurations,
  };
}

export async function updateUser(): Promise<IUser> {
  return (await axiosInstance.get("/users/logged-in")).data?.user;
}

export function createPhotoUrl(user: IUser | null) {
  const onlyExternalPhoto = user?.externalProfilePicture && !user.avatar;
  return onlyExternalPhoto
    ? user.externalProfilePicture
    : `${process.env.REACT_APP_API_URL}/users/${user?.id}/avatar`;
}

export async function getAvailableAvatars(): Promise<UserAvatar[]> {
  return (await axiosInstance.get(`/avatars`)).data;
}

export async function updateUserAvatar({
  userAvatar,
  imageId,
}: {
  userAvatar: UserAvatar | undefined;
  imageId: number;
}) {
  if (userAvatar)
    return (await axiosInstance.patch(`/avatars/${imageId}`)).data;
  return (await axiosInstance.post(`/avatars/${imageId}`)).data;
}

export async function contactSupport(data: {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}) {
  return (await axiosInstance.post(`/users/contact`, data)).data;
}

export async function contactSupportGuest(data: {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}) {
  return (await axiosInstance.post(`/users/nouser-contact`, data)).data;
}

export async function updateLocale(data: { locale: "de" | "en-US" }) {
  return (await axiosInstance.patch(`/users/locale`, data)).data;
}

export async function deleteOwnAccount() {
  return await axiosInstance.delete("/users/account");
}

export async function requestExternalAssessment() {
  return await (
    await axiosInstance.post(`/users/send-assessment-link`, {})
  ).data;
}
