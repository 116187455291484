import { axiosInstance } from ".";
import { ILegalTerm } from "../@types";
import { getLocaleFromLanguage } from "./content.service";

export async function getTermsAndConditions(): Promise<ILegalTerm> {
  return (await axiosInstance.get("/legal/terms-and-conditions/latest")).data;
}

export async function getPrivacyStatement(): Promise<ILegalTerm> {
  return (await axiosInstance.get("/legal/privacy-statement/latest")).data;
}

export async function getCookiePolicy(): Promise<ILegalTerm> {
  return (await axiosInstance.get("/legal/cookie-policy/latest")).data;
}

export async function getImprint(): Promise<any> {
  const businessInfo = await getBusinessData();

  const imprintDataId = await businessInfo?.find(
    (item: any) => item?.internalName === "imprint"
  )?.platformContentId;

  if (imprintDataId) {
    return (
      await axiosInstance.get(
        `/content/from-source?platformContentId=${imprintDataId}`
      )
    ).data;
  }
}

export async function getLinc(): Promise<any> {
  const businessInfo = await getBusinessData();

  const lincId = await businessInfo?.find(
    (item: any) => item?.internalName === "linc"
  )?.platformContentId;
  const response = await axiosInstance.get("/content/from-source", {
    params: {
      platformContentId: lincId,
      locale: getLocaleFromLanguage(),
    },
  });
  return response.data;
}

export async function getBusinessData(): Promise<any> {
  return (
    await axiosInstance.get("/content/by-property?contentType=businessInfo")
  ).data;
}

export async function acceptTermsAndConditions(): Promise<any> {
  return await axiosInstance.post("/legal/terms-and-conditions/accept");
}

export async function acceptPrivacyStatement(): Promise<any> {
  return await axiosInstance.post("/legal/privacy-statement/accept");
}

export async function acceptCookiePolicy(cookieSettings: {
  essentialCookiesAccepted?: boolean;
  preferencesCookiesAccepted?: boolean;
  statisticsCookiesAccepted?: boolean;
  marketingCookiesAccepted?: boolean;
}): Promise<any> {
  return await axiosInstance.post("/legal/cookie-policy/accept", {
    ...cookieSettings,
  });
}
