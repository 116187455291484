import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useMutation } from "react-query";
import { CustomFieldWithError } from "../../components/forms/CustomFieldWithError";
import { PASSWORD_RESET_ERRORS } from "../../errors/errorCodes";
import { resetPasswordRequest } from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import Button from "../../components/button";
import { ButtonSize, ButtonVariant } from "../../components/button/types";


const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});



export default function ResetPasswordRequest({onPasswordResetSuccessfulRequest, goBack} : {onPasswordResetSuccessfulRequest: () => void, goBack: () => void}) {

    const [resetPasswordEmailError, setResetPasswordEmailError] = useState<string | null>(null);

    const { t } = useTranslation("authentication");


    const resetPasswordRequestMutation = useMutation(resetPasswordRequest, {
        onSuccess: () => onPasswordResetSuccessfulRequest(),
        onError: ({response}) => {
            if(response.data.message == PASSWORD_RESET_ERRORS.INVALID_EMAIL) {
                setResetPasswordEmailError("Email not registered");
            }
        }
    });

    return (
        <div
        className="p-9 w-full"
    >
            <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setResetPasswordEmailError(null);
                    resetPasswordRequestMutation.mutate(values);
                }}
            >
                {({ errors, touched, isValid, values }) => {
                    const disabled = !isValid || values['email'].length == 0;
                    return (
                    <Form>
                         <div className="w-full">
                            <label htmlFor="email">{t("login-form.email")}*</label>
                            <div>
                                <CustomFieldWithError className="w-full h-10 mt-1" type="email" id="email" name="email" error={errors.email || resetPasswordEmailError} touched={!!touched.email} />
                            </div>
                        </div>

                        <Button type="submit" className="mt-6 w-full" disabled={disabled} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                            {t("buttons.request-password-reset")}
                        </Button>
                    </Form>
                )}}
                
            </Formik>
            <button onClick={goBack} className="underline mt-6 text-sm">{t('go-back')}</button>
        </div>
    )



}