import { IRoute } from "../@types";
import Forbidden from "../pages/private/Forbidden";
import NotFound from "../pages/private/Notfound";
import Unauthorized from "../pages/private/Notfound";

export function GetAuthorizedRoutes(configurations: any, routes: IRoute[]): IRoute[] {

  if (!configurations || typeof configurations !== "object") {
    console.error("Configurations object is null or not an object");
    return [];
  }

  const authorizationConditions = [
    {
      path: "/coaches",
      configKey: "includesCoaches",
    },
    {
      path: "/jobs",
      configKey: "includesJobs",
    },
    {
      path: "/search",
      configKey: "includesSearch",
    },
    {
      path: "/journal",
      configKey: "includesJournal",
    },
    {
      path: "/community",
      configKey: "includesCommunity",
    },
    {
      path: "/profile/guide",
      configKey: "includesGuide",
    },
    {
      path: "/profile/assessments",
      configKey: "includesAssessmentsAndTools",
    },
    {
      path: "/search",
      configKey: "includesSearch",
    },
    // Add more conditions as needed
  ];

  const authorizedRoutes: IRoute[] = routes
    .map((route) => {
      let isAuthorized = true; // All routes are authorized by default

      authorizationConditions.forEach(({ path, configKey }) => {
        if (
          (route?.path === path || route?.path?.startsWith(`${path}/`)) &&
          !configurations?.[configKey]
        ) {
          isAuthorized = false;
        }
      });

      return {
        ...route,
        authorized: isAuthorized,
      };
    })
    .filter((route) => route?.authorized); // Filter out unauthorized routes

    // Check if there are any unauthorized routes
  const unauthorizedRoutes = routes.filter(route => !authorizedRoutes.includes(route));

  // Push unauthorized routes to Forbidden element
  unauthorizedRoutes.forEach(route => {
    authorizedRoutes.push({
      ...route,
      nameCode: "forbidden",
      element: () => <Forbidden />,
      hidden: true,
    });
  });

  // If no authorized routes were found, push NotFound element

    authorizedRoutes.push({
      path: "*", // Catch-all route for any undefined paths
      nameCode: "notfound",
      element: () => <NotFound />,
      hidden: true,
    });
  

  return authorizedRoutes;
}


