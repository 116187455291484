import { ReactElement, useContext, useEffect, useState } from "react";
import { PersonalInformation } from "./menus/myProfile";
import { AssessmentsSubMenus, Tabs } from "./tabs";
import { Settings } from "./menus/settings";
import { Bookmarks } from "./menus/bookmarks";
import { CareerInformation } from "./menus/careerInformation";
import { ReactComponent as ProfileInactiveIcon } from "../../../assets/myArea/profile-inactive.svg";
import { ReactComponent as ProfileActiveIcon } from "../../../assets/myArea/profile-active.svg";
import { ReactComponent as CareerInfoActiveIcon } from "../../../assets/myArea/career-info-active.svg";
import { ReactComponent as CareerInfoInactiveIcon } from "../../../assets/myArea/career-info-inactive.svg";
import { ReactComponent as AssessmentsActive } from "../../../assets/myArea/assessments-active.svg";
import { ReactComponent as AssessmentsInactive } from "../../../assets/myArea/assessments-inactive.svg";
import { ReactComponent as BookmarksInactive } from "../../../assets/myArea/bookmarks-inactive.svg";
import { ReactComponent as BookmarksActive } from "../../../assets/myArea/bookmarks-active.svg";
import { ReactComponent as CreditsActive } from "../../../assets/myArea/credits-active.svg";
import { ReactComponent as CreditsInactive } from "../../../assets/myArea/credits-inactive.svg";
import { ReactComponent as SettingsActive } from "../../../assets/myArea/settings-active.svg";
import { ReactComponent as SettingsInactive } from "../../../assets/myArea/settings-inactive.svg";
import { ReactComponent as SignoutIcon } from "../../../assets/myArea/sign-out.svg";
import { ReactComponent as LeftArrow } from "../../../assets/left-arrow-calendar.svg";
import { ReactComponent as GuideInactive } from "../../../assets/guide_inactive.svg";

import { AuthContext } from "../../../contexts/AuthContext";
import { CircularImage } from "../../../components/circularImage";
import default_avatar from "../../../assets/default_avatar.svg";
import { getUserFullName } from "./menus/utils";
import { useContentAndProgress } from "../../../contexts/UserProgressContext";
import { useLocation } from "react-router-dom";
import { AssessmentsReportsAndOverviews } from "./menus/assessments";
import { Logout } from "./menus/logout";
import { Credits } from "./menus/credits";
import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { MyAreaTitle } from "./menus/components";
import { useTranslation } from "react-i18next";
import { Guide } from "./menus/guide";

export type MenuSection = {
  name: string;
  iconActive?: ReactElement;
  iconInactive?: ReactElement;
  subMenuComponent?: ReactElement;
  route: string;
  title?: string;
};

export type MenusObject = {
  [key: string]: MenuSection[];
};

const menus: MenusObject = {
  "my-profile": [
    {
      name: "personal-information",
      iconInactive: <ProfileInactiveIcon />,
      iconActive: <ProfileActiveIcon />,
      route: "",
      title: "profile.title",
    },
    {
      name: "career-information",
      iconActive: <CareerInfoActiveIcon />,
      iconInactive: <CareerInfoInactiveIcon />,
      route: "career-information",
      title: "career-information.title",
    },
  ],
  "learning-and-successes": [
    {
      name: "assessments",
      iconActive: <AssessmentsActive />,
      iconInactive: <AssessmentsInactive />,
      subMenuComponent: <AssessmentsSubMenus />,
      route: "assessments",
      title: "tabs.overview",
    },
    {
      name: "bookmarks",
      iconActive: <BookmarksActive />,
      iconInactive: <BookmarksInactive />,
      route: "bookmarks",
      title: "tabs.bookmarks",
    },
    {
      name: "credits",
      iconActive: <CreditsActive />,
      iconInactive: <CreditsInactive />,
      route: "credits",
      title: "tabs.credits",
    },
    {
      name: "guide",
      iconActive: <GuideInactive />, // TODO get guide icon svg and replace here (add svg to assets)
      iconInactive: <GuideInactive />,
      route: "guide",
      title: "tabs.guide",
    },
  ],
  preferences: [
    {
      name: "settings",
      iconActive: <SettingsActive />,
      iconInactive: <SettingsInactive />,
      route: "settings",
      title: "tabs.settings",
    },
  ],
  login: [
    {
      name: "sign-out",
      iconInactive: <SignoutIcon />,
      iconActive: <SignoutIcon />,
      route: "tabs.sign-out",
    },
  ],
};

const UserLayout = () => {
  const { user, configurations } = useContext(AuthContext);

  const { journey } = useContentAndProgress();

  const { firstName, lastName } = getUserFullName(user);

  const shouldRenderProgressBar = configurations?.includesProgressBar;
  const shouldRenderAvatar = configurations?.includesCommunity;

  const userHasPhoto = () => {
    return user?.userDetails?.selectedAvatar?.signedUrl;
  };

  return (
    <div className="md:px-4 pb-0 md:pt-6">
      <div className="flex items-center bg-Yellow-light rounded-md h-20">
        {shouldRenderAvatar ? (
          <div className="mx-3 h-full w-[4.5rem] py-1 flex items-center">
            {userHasPhoto() ? (
              <CircularImage
                src={user?.userDetails?.selectedAvatar?.signedUrl || ""}
              />
            ) : (
              <CircularImage src={default_avatar} />
            )}
          </div>
        ) : (
          <div className="mx-3 h-full w-[1.5rem]">
            {" "}
            {/* This empty div will push the next elements to the right */}
          </div>
        )}

        <div className="flex flex-col w-4/5 px-4 pl-0">
          <div className="font-bold mb-1">
            {firstName} {lastName}
          </div>
          {shouldRenderProgressBar && (
            <div className="flex justify-between items-center w-full">
              <div className="h-2 rounded-md bg-white w-full relative">
                <div
                  className="h-2 rounded-md bg-black absolute left-0 top-0"
                  style={{
                    width: `${journey?.progressValue.toFixed(0).toString()}%`,
                  }}
                ></div>
              </div>
              <div className="pl-2 font-bold text-sm">
                {journey?.progressValue.toFixed(0)}%
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MobileView = ({
  activeMenu,
  setActiveMenu,
  mobileActiveMenu,
  setMobileActiveMenu,
  currentComp,
  getProfilePage,
}: {
  activeMenu: any;
  setActiveMenu: any;
  mobileActiveMenu: boolean;
  setMobileActiveMenu: any;
  currentComp: string;
  getProfilePage: any;
}) => {
  return <></>;
};

export function Profile() {
  const { t } = useTranslation("myArea");

  useOnPageMount();
  const location = useLocation();
  const activeLocation = location.pathname.split("/")[2] || "";

  const isMobileView = window.innerWidth < 1024;

  const menuToActivate = Object.values(menus)
    .flatMap((m) => m)
    .filter((m) => m.route === activeLocation)[0];

  const [activeMenu, setActiveMenu] = useState<MenuSection | null>(
    menuToActivate
  );

  const [mobileActiveMenu, setMobileActiveMenu] = useState<boolean>(false);

  const { configurations } = useContext(AuthContext);
  const shouldRenderMyContent = configurations?.includesMyContent;

  const shouldRenderGuideTab = configurations?.includesGuide;
  const shouldRenderCreditsTab = configurations?.includesCreditSection;

  const shouldRenderBookmarkTab = configurations?.includesBookmarks;

  const shouldRenderAssessmentsTab =
    configurations?.includesAssessmentsAndTools;

  const shouldRenderCareerInformationTab =
    configurations?.includesCareerInformation ||
    configurations?.includesCareerExpectations ||
    configurations?.includesCareerPreferences;

  const updatedMenus = {
    ...menus,
    "my-profile": menus["my-profile"].filter((menu) =>
      shouldRenderCareerInformationTab
        ? true
        : menu.name !== "career-information"
    ),
    "learning-and-successes": shouldRenderMyContent
      ? (shouldRenderGuideTab
          ? menus["learning-and-successes"]
          : menus["learning-and-successes"].filter(
              (menu) => menu.name !== "guide"
            )
        )
          .filter((menu) =>
            shouldRenderAssessmentsTab ? true : menu.name !== "assessments"
          )
          .filter((menu) =>
            shouldRenderBookmarkTab ? true : menu.name !== "bookmarks"
          )
          .filter((menu) =>
            shouldRenderCreditsTab ? true : menu.name !== "credits"
          )
      : [], // Empty array if shouldRenderMyContent is false
  };

  useEffect(() => {
    if (!mobileActiveMenu && isMobileView) setActiveMenu(null);
  }, [mobileActiveMenu]);

  useEffect(() => {
    if (location.pathname === "/profile/settings") {
      setActiveMenu(menus["preferences"][0]);
      setMobileActiveMenu(true)
    }
  }, [location.pathname]);

  const currentComp =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const getProfilePage = () => {

    switch (activeMenu) {
      case menus["my-profile"][0]:
        return <PersonalInformation />;
      case menus["my-profile"][1]:
        return shouldRenderCareerInformationTab ? (
          <CareerInformation />
        ) : (
          <PersonalInformation />
        );
      case menus["learning-and-successes"][0]:
        return shouldRenderMyContent && shouldRenderAssessmentsTab ? (
          <AssessmentsReportsAndOverviews />
        ) : (
          <PersonalInformation />
        );
      case menus["learning-and-successes"][1]:
        return shouldRenderMyContent && shouldRenderBookmarkTab ? (
          <Bookmarks />
        ) : (
          <PersonalInformation />
        );
      case menus["learning-and-successes"][2]:
        return shouldRenderMyContent && shouldRenderCreditsTab ? (
          <Credits />
        ) : (
          <PersonalInformation />
        );
      case menus["learning-and-successes"][3]:
        return shouldRenderMyContent && shouldRenderGuideTab ? (
          <Guide />
        ) : (
          <PersonalInformation />
        );
      case menus["preferences"][0]:
        return <Settings />;
      case menus["login"][0]:
        return <Logout />;
      default:
        return <PersonalInformation />;
    }
  };

  return (
    <div className="w-full">
      {/* MOBILE BREAKPOINT */}
      <div className="lg:hidden px-6 py-4 md:px-9 ">
        {!mobileActiveMenu ? (
          <div className="w-full">
            <UserLayout />
            <Tabs
              menus={updatedMenus}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              setMobileActiveMenu={setMobileActiveMenu}
            />
          </div>
        ) : (
          <div className="w-full">
            <div className="flex items-center mb-9">
              <LeftArrow
                className="mr-2"
                onClick={() => setMobileActiveMenu(false)}
              />
              <MyAreaTitle
                className="text-center -ml-1 w-full"
                title={t(
                  `${
                    activeMenu?.subMenuComponent
                      ? currentComp.split("-").join(" ")
                      : activeMenu?.title
                  }`
                )}
              />
            </div>
            {getProfilePage()}
          </div>
        )}
      </div>
      {/* AFTER MD BREAKPOINT */}
      <div className="hidden lg:flex py-6 px-9">
        <div className="w-1/4">
          <UserLayout />
          <Tabs
            menus={updatedMenus}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            setMobileActiveMenu={() => null}
          />
        </div>
        <div className="w-1/2 p-5">{getProfilePage()}</div>
      </div>
    </div>
  );
}
