import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { getAssessmentsOverviewForPhase, getAssessmentsReportDownload } from "../../../../../../services/selfAssessment.service";
import { AssessmentContent } from "../../../../../../@types";
import { AssessmentReport } from "./AssessmentReport";
import Button from "../../../../../../components/button";
import { ButtonVariant } from "../../../../../../components/button/types";


export function Report() {


    const { t } = useTranslation("myArea")
    const location = useLocation();
    const currentPhaseId =
    location.pathname.split("/")[location.pathname.split("/").length - 2];
    const phaseId = location.pathname.split('/')[location.pathname.split('/').length - 2];

    const { data: assessments } = useQuery([phaseId, 'phaseAssessments'], () => getAssessmentsOverviewForPhase(phaseId));

    if(assessments?.length == 0) return <div className="p-6">{t("assessments.no-assessments-answered")}</div>

    const handleDownloadClick = async () => {
        await getAssessmentsReportDownload(currentPhaseId);
    };

    return (
        <div>
             <div className="bg-Grey-light-3 flex justify-end items-center p-7 rounded-md">
                <Button
                    variant={ButtonVariant.SECONDARY}
                    className="px-3 ml-4"
                    onClick={handleDownloadClick}
                >
                    {t("assessments.download")}
                </Button>
            </div>
            {assessments?.map((a: AssessmentContent) => <AssessmentReport key={a.id} content={a} />)}
        </div>
    )

}