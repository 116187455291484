import { axiosInstance } from ".";

export const redeemVoucher = async (vouchedCode: string) => {
  return await axiosInstance.post(`/vouchers/${vouchedCode}/redeem`, {});
};
export const updateVoucher = async (vouchedCode: string) => {
  return await axiosInstance.post(`/vouchers/${vouchedCode}/upgrade`, {});
};

export const checkVoucher = async (voucherCode: string) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("Access token is missing");
    }
    const response = await axiosInstance.post(
      `/vouchers/${voucherCode}/check`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { isValid, code, redirectUserToContent } = response.data;
    return { isValid, code, redirectUserToContent };
  } catch (error) {
    throw error;
  }
};
