import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import {
  searchContent,
  searchFullContent,
} from "../../../services/content.service";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  ArticleContent,
  CoachContent,
  FormattedContent,
  ISearchResult,
} from "../../../@types";
import { ContentType } from "../../../enums";
import { ArticlesPreview } from "./pages/ArticlesPreview";
import { CoachesPreview } from "./pages/CoachesPreview";
import { CoachingsPreview } from "./pages/CoachingsPreview";
import { AssessmentsPreview } from "./pages/AssessmentsPreview";

import { PostsPreview } from "./pages/PostsPreview";
import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { AuthContext } from "../../../contexts/AuthContext";
import { useContext } from "react";
export const Tab = ({
  title,
  resultAmount,
  isActive,
  onClick,
}: {
  isActive: boolean;
  title?: string;
  resultAmount?: number;
  onClick: () => void;
}) => {
  const hasResults =
    !resultAmount || (typeof resultAmount === "number" && resultAmount > 0);

  return (
    <div
      className={`${hasResults ? "cursor-pointer" : ""}`}
      onClick={() => (hasResults ? onClick() : null)}
    >
      <div className={`p-3 px-4 ${title ? "" : "text-white"}`}>
        {title || "a"}{" "}
        <span className="text-sm text-Grey-dark">
          {resultAmount !== undefined ? `(${resultAmount})` : null}
        </span>
      </div>
      {isActive ? (
        <div className="flex w-full h-[2px] bg-Yellow-default z-20" />
      ) : null}
    </div>
  );
};

enum Tabs {
  ARTICLES = "ARTICLES",
  COACHINGS = "COACHINGS",
  COACHES = "COACHES",
  ASSESSMENTS = "ASSESSMENTS",
  FORUM = "FORUM",
}

export function FullSearch() {
  useOnPageMount();
  const location = useLocation();
  const search = location.search.split("?")[1];
  const { i18n } = useTranslation();
  const { t } = useTranslation("fullSearch");
  const [articles, setArticles] = useState<FormattedContent[]>([]);
  const [coachings, setCoachings] = useState<FormattedContent[]>([]);
  const [coaches, setCoaches] = useState<FormattedContent[]>([]);
  const [assessments, setAssessments] = useState<FormattedContent[]>([]);
  const [forumContent, setForumContent] = useState<ISearchResult[]>([]);
  const [activeTab, setActiveTab] = useState<Tabs | null>(null);
  const [fetching, setFetching] = useState(true);
  const { configurations } = useContext(AuthContext);;

  const { data: searchResults } = useQuery(
    ["search", search],
    () => {
      return searchFullContent(search, i18n.language);
    },
    {
      onSuccess: (data) => {
        setArticles(
          data?.filter(
            (d: FormattedContent) => d.contentType == ContentType.ARTICLE
          )
        );
        setCoachings(
          data?.filter(
            (d: FormattedContent) => d.contentType == ContentType.COACHING
          )
        );
        setCoaches(
          data?.filter(
            (d: FormattedContent) => d.contentType == ContentType.COACH
          )
        );
        setAssessments(
          data?.filter(
            (d: FormattedContent) => d.contentType == ContentType.ASSESSMENT
          )
        );
        setFetching(false);
      },
    }
  );

  useQuery(
    ["forumSearch", search],
    () => {
      return searchContent(search, i18n.language);
    },
    {
      onSuccess: (data) => {
        setForumContent(
          data?.filter((d: ISearchResult) => d.contentType == ContentType.POST)
        );
      },
    }
  );

  useEffect(() => {
    if (!fetching) {
      if (articles?.length) setActiveTab(Tabs.ARTICLES);
      else if (coachings?.length) setActiveTab(Tabs.COACHINGS);
      else if (coaches?.length) setActiveTab(Tabs.COACHES);
      else if (assessments?.length) setActiveTab(Tabs.ASSESSMENTS);
      else if (forumContent?.length) setActiveTab(Tabs.FORUM);
    }
  }, [fetching, forumContent]);

  const pages = {
    [Tabs.ARTICLES]: (
      <ArticlesPreview articles={articles as ArticleContent[]} />
    ),
    [Tabs.COACHES]: <CoachesPreview coaches={coaches as CoachContent[]} />,
    [Tabs.COACHINGS]: <CoachingsPreview coachings={coachings} />,
    [Tabs.ASSESSMENTS]: <AssessmentsPreview assessments={assessments} />,
    [Tabs.FORUM]: <PostsPreview posts={forumContent} />,
  };

  return (
    <div className="flex flex-col w-full items-center">
      <div className="p-6 bg-Grey-light-3 mt-5 w-9/12 lg:w-1/2 rounded-md">
        <h1 className="font-bold text-Grey-dark mb-2">
          {t("your-search-for")}
        </h1>
        <span>{decodeURIComponent(search)}</span>
      </div>
      <div className="flex flex-col w-9/12 max-w-9/12 overflow-x-scroll lg:w-1/2 items-center mt-3 mb-5">
        <div className="flex w-full">
          <Tab
            title={t("articles") as string}
            resultAmount={articles.length}
            isActive={activeTab === Tabs.ARTICLES}
            onClick={() => setActiveTab(Tabs.ARTICLES)}
          />
          <Tab
            title={t("coachings") as string}
            resultAmount={coachings.length}
            isActive={activeTab === Tabs.COACHINGS}
            onClick={() => setActiveTab(Tabs.COACHINGS)}
          />
          <Tab
            title={t("coaches") as string}
            resultAmount={coaches.length}
            isActive={activeTab === Tabs.COACHES}
            onClick={() => setActiveTab(Tabs.COACHES)}
          />
          <Tab
            title={t("assessments") as string}
            resultAmount={assessments.length}
            isActive={activeTab === Tabs.ASSESSMENTS}
            onClick={() => setActiveTab(Tabs.ASSESSMENTS)}
          />
          {configurations?.includesCommunity !== false && (
            <Tab
              title={t("forum") as string}
              resultAmount={forumContent.length}
              isActive={activeTab === Tabs.FORUM}
              onClick={() => setActiveTab(Tabs.FORUM)}
            />
          )}
        </div>
      </div>
      <div className="w-9/12 lg:w-6/12 flex flex-col">
        {activeTab ? pages[activeTab] : null}
      </div>
    </div>
  );
}
