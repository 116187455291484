import { ArticleContent, FormattedContent, IContentWithProgress } from "../../../../../@types";
import { ExternalContentTeaser } from "../../../../../components/externalContentTeaser";



export function ArticlesPreview({articles} : {articles : FormattedContent[]}) {

    return (<>
            {articles.map((a: FormattedContent) => <ExternalContentTeaser content={a as ArticleContent}/>)}
        </>)

}