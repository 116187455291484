// Unauthorized.js
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import unauthorizedImage from "../../assets/403.png";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { ButtonVariant } from "../../components/button/types";

export default function Forbidden() {
  const { t } = useTranslation("common");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  localStorage.removeItem(`lastState-${user?.id}`);

  const handleGoHome = () => {
    localStorage.removeItem(`lastState-${user?.id}`);
    navigate("/");
  };

  return (
    <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
      <div className={`w-full ${user && "md:w-1/2"}`}>
        <h1 className="text-lg font-semibold mb-4">
          {t("forbidden.page-title")}
        </h1>

        <img
          src={unauthorizedImage}
          alt="forbidden access"
          className="w-full mb-6"
        />

        <p className="font-bold mb-4">{t("forbidden.page-sub-title")}</p>

        <p className="mb-4">{t("forbidden.page-text-1")}</p>

        <Button
          variant={ButtonVariant.PRIMARY}
          className="bg-Grey-light-2 px-4 py-1.5 text-Black"
          onClick={() => handleGoHome()}
        >
          <span className="font-medium"> {t("forbidden.go-home-button")}</span>
        </Button>
      </div>
    </div>
  );
}
