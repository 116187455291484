import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";



export function Logout() {

    const {logoutUser} = useContext(AuthContext);;

    useEffect(()=>{
        logoutUser();
    },[logoutUser])

    return null;


}