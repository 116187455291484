



export function Modal({ isOpen, onClose, children, title }: { isOpen: boolean, onClose: () => void, children: React.ReactNode, title: string }) {


    return (
        isOpen ?
            <div className="absolute top-0 left-0 flex w-full opacity-100 h-screen items-center justify-center" >
                <div className="w-2/5 rounded bg-white border border-zinc-400 rounder">
                    <div className="flex w-full justify-between p-3 bg-white opacity-100 mb-3" onClick={onClose}>
                        <div className="font-bold">{title}</div>
                        <div className="text-zinc-400 cursor-pointer">Close</div>
                    </div>

                    {children}

                </div>
            </div >
            : null
    )


}