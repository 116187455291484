import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { useLatestLegal } from "../../hooks/useLatestLegal";
import { CookieSettings } from "../private/legal/CookieConsent";
import { useContext, useState } from "react";
import { ButtonSize, ButtonVariant } from "../../components/button/types";
import Button from "../../components/button";

export default function CookiePolicy() {

    const { t } = useTranslation("cookiePolicy");
    const { latestCookiePolicy } = useLatestLegal();
    const { user } = useContext(AuthContext);;
    const [displayCookieSettings, setDisplayCookieSettings] = useState(false);

    return (
        <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
            <CookieSettings settingsOpen={displayCookieSettings} close={() => setDisplayCookieSettings(false)} />
            <div className={`w-full ${user && "md:w-1/2"}`}>
                <h1 className="text-Yellow-default text-lg font-semibold mb-4">{t("page-title")}</h1>
                <div dangerouslySetInnerHTML={{ __html: latestCookiePolicy?.renderedContent || "" }}></div>
                <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} onClick={()=> setDisplayCookieSettings(true)}>{t("settings-button")}</Button>
            </div>
        </div>
    )
}