import { useQuery } from "react-query"
import { getContentById } from "../../../../services/content.service"
import { ExperienceContent } from "../../../../@types"
import { useLocation } from "react-router-dom";
import { AuthorsDisplay} from "../../home/content/components";
import { Article } from "../../home/content/article";
import { useTranslation } from "react-i18next";


export function Experience() {

    const location = useLocation();
    const { t } = useTranslation('community');

    const id = location.pathname.split('/')[location.pathname.split('/').length - 1];
    const { data: experience } = useQuery(['experience', location], () => getContentById({ platformContentId: id }));

    return (
        <div className="px-6 sm:px-9 md:px-0 flex w-full h-full justify-center">
            <div className="w-full md:w-1/2">
                <h1 className="text-Yellow-default text-lg font-semibold my-4 md:my-9 ">{t("page-title")}</h1>
                <div className="max-w-full">
                    <div className="mb-4">
                        <div className=" z-0">
                            {(experience as ExperienceContent)?.image ?
                                <img className={`z-0`} width={'100%'} src={(experience as ExperienceContent)?.image} />
                                : null}
                        </div>

                        {(experience as ExperienceContent)?.authors?.length ? <div className="mt-[-1.8em]" ><AuthorsDisplay belowImage={true} authors={(experience as ExperienceContent)?.authors} /> </div> : null}

                    </div>
                    <h1 className={`text-2xl font-bold mb-5`}>{experience?.title}</h1>
                    <div className="text-Grey text-xl ">{(experience as ExperienceContent)?.teaserText}</div>
                    {!(experience as ExperienceContent)?.image ? <AuthorsDisplay belowImage={false} authors={[(experience as ExperienceContent)?.authors]} /> : null}
                    <Article content={experience as ExperienceContent} />
                </div>
            </div>
        </div>
    )

}