import { useTranslation } from "react-i18next";
import Button from "../../../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../../../components/button/types";
import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";
import { useContentActions } from "../../../../../hooks/useContentActions";
import ProgressBar from "../../../../../components/progressBar";
import { useSkipMilestone } from "../../../../../hooks/useSkipMilestone";
import { CircularProgress } from "@mui/material";



export function MilestoneHeader() {

    const { t } = useTranslation("home");

    const { activeMilestoneContent, activeMilestone, currentPhaseMilestones, setActiveMilestone, setOpenedContent } = useContentAndProgress();
    const { handleDoneClick, handleRollbackDoneClick } = useContentActions({ content: activeMilestoneContent })

    const activeMilestoneVals = currentPhaseMilestones?.find((cpm) => cpm.id === activeMilestone?.id);

    const skipMilestone = useSkipMilestone();

    if (!currentPhaseMilestones) {
        // Render loading state while coachDetails are being fetched
        return (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress style={{ color: "#DCAA00" }} />
          </div>
        );
      }

    return (
        <>
            {activeMilestone ?
                <>
                    <div className="bg-Blue-metalic p-6 rounded-lg mb-6 min-w-full">
                        <h1 className="text-xl font-semibold mb-4">{activeMilestoneContent?.title}</h1>
                        {!activeMilestoneVals?.markedIrrelevant ?
                            <div className="lg:hidden flex justify-between items-center mb-2">
                                <ProgressBar percentage={Math.round(activeMilestoneVals?.progressValue || 0)} progressColor={ "bg-black"} />
                                <div className="ml-2 font-bold text-sm">{Math.round(activeMilestoneVals?.progressValue || 0)}%</div>
                            </div>
                            : null}

                        <div className="hidden lg:block w-full h-[1px] bg-white mb-4"></div>
                        <div className="flex">
                            {activeMilestone.markedIrrelevant ?
                                null :
                                <>{activeMilestone?.progressValue !== 100 ?
                                    <div className="md:flex w-full justify-end">
                                        <div className="flex w-full justify-center md:w-fit">
                                            <Button
                                            
                                            className="mr-3 bg-transparent border-0 w-full" 
                                            onClick={()=> skipMilestone()} variant={ButtonVariant.GHOST_DARK} size={ButtonSize.SMALL}>
                                                <span className="font-medium">{t("skip")}</span>
                                            </Button>
                                        </div>
                                        <div className="flex w-full justify-center md:w-fit">
                                            <Button className="w-full" variant={ButtonVariant.SECONDARY} size={ButtonSize.SMALL} onClick={handleDoneClick}>
                                                <span className="font-medium">{t("next")}</span>
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div className="flex w-full justify-end">
                                        <Button onClick={handleRollbackDoneClick} className="bg-Grey-light-2 px-4 py-1.5 text-Black">
                                            <span className="font-medium">{t("concluded")}</span>
                                        </Button>
                                    </div>
                                }</>
                            }

                        </div>
                    </div>
                    <h2 className="font-semibold text-xl text-Yellow-default mb-4">{t("subtitle")}</h2>
                </>
                : null}

        </>
    )
}