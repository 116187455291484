import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { GetAuthorizedRoutes } from "./useAuthorizedRoutes";
import { routes } from "../routes";

export function useAuthorizedRoutesWithVoucher() {
  const [authorizedRoutes, setAuthorizedRoutes] = useState(routes);
  const { configurations, user } = useContext(AuthContext);;

  useEffect(() => {
    if (user?.usedVoucher) {
      const FetchAuthorizedRoutes = async () => {
        const updatedRoutes = GetAuthorizedRoutes(configurations,routes);
        setAuthorizedRoutes(updatedRoutes);
      };
      FetchAuthorizedRoutes();
    }
  }, [ user?.usedVoucher, configurations]);

  return authorizedRoutes;
}
