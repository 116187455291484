import { useMutation, useQuery } from "react-query";
import { AssessmentContent, FormattedContent, Question } from "../../../../../@types";
import { ReactComponent as CollapseIcon } from '../../../../../assets/collapse.svg';
import { ReactComponent as ExpandIcon } from '../../../../../assets/expand.svg';
import { getCurrentAssessmentAnswers, submitAnswer } from "../../../../../services/selfAssessment.service";
import { useContentActions } from "../../../../../hooks/useContentActions";
import { getUserContentAndProgressTree } from "../../../../../services/content.service";
import { useTranslation } from "react-i18next";


const AnswerWrapper = ({ text, onClick, isActive }: { text: string, onClick: Function, isActive: boolean }) => {


    return (<div onClick={() => onClick()} className={`m-1 flex justify-center w-1/3 border p-3 rounded-md cursor-pointer ${isActive ? 'bg-Yellow-default' : ''}`}>
        {text}
    </div>)
}

function AssessmentQuestion({ question, selfAssessmentId, selfAssessmentState, refreshAnswers }: { question: Question, selfAssessmentId: string, selfAssessmentState: any, refreshAnswers: Function, refreshUserProgressState: any }) {


    const { t } = useTranslation("content");

    const answer = selfAssessmentState?.answers?.filter((a: any) => a.questionId === question.id)[0]?.answer;

    const {answerQuestion} = useContentActions({content: {id: selfAssessmentId} as FormattedContent, questionId: question.id, callback: refreshAnswers});

    const handleResponse = async (answer: 'Yes' | 'Maybe' | 'No') => {
        answerQuestion(answer);
    }

    const isActive = (defaultAnswer: string) => {
        return answer == defaultAnswer;
    }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-4 mt-4">
                <div className="mb-6 md:mb-3">
                    {question.text}
                </div>
                <div className="flex justify-between">
                    <AnswerWrapper isActive={isActive('No')} onClick={() => handleResponse('No')} text={t("answers.No")} />
                    <AnswerWrapper isActive={isActive('Maybe')} onClick={() => handleResponse('Maybe')} text={t("answers.Maybe")} />
                    <AnswerWrapper isActive={isActive('Yes')} onClick={() => handleResponse('Yes')} text={t("answers.Yes")} />
                </div>
            </div>
            <hr className="border border-Grey-light-2"/>
        </>
    )
}



export function Assessment({ content }: { content: AssessmentContent }) {

    const { t } = useTranslation("home");
    const { data: selfAssessmentState, refetch } = useQuery(['self-assessment'], () => getCurrentAssessmentAnswers(content.id));
    const { refetch : refreshUserProgressState } = useQuery(['userContentProgress'], () => getUserContentAndProgressTree({platformContentId: content.id}))

    return (
        <>
            <div className="mt-10">
                <hr />
                <div className="flex justify-between mt-2">
                    <h1 className="text-lg text-Grey-dark font-bold">{t("self-assessment")}</h1>
                    <CollapseIcon className="cursor-pointer" />
                </div>
            </div>

            {(content as AssessmentContent).questions?.map((q: Question) => <AssessmentQuestion refreshUserProgressState={refreshUserProgressState} selfAssessmentState={selfAssessmentState} refreshAnswers={refetch} question={q} selfAssessmentId={content.id} />)}
            <div className="py-6" dangerouslySetInnerHTML={{ __html: (content as AssessmentContent)?.renderedContent || "" }}></div>

        </>
    )


}