import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigationBar } from "./common";
import { DailyJournal } from "./daily";
import { WeeklyJournal } from "./weekly";
import { useOnPageMount } from "../../../hooks/useOnPageMount";

export function Journal() {
   
    const [isDaily, setIsDaily] = useState<boolean>(true)
    const { t } = useTranslation('journal');
    useOnPageMount();

    return (
        <div className="px-6 pt-4 lg:pt-9 sm:px-9 lg:px-0 flex w-full h-full justify-center">
            <div className="w-full lg:w-1/2">
                <h1 className="text-Yellow-default text-lg font-semibold mb-4 md:mb-9 ">{t("page-title")}</h1>

                <NavigationBar isDaily={isDaily} setIsDaily={setIsDaily} />

                {isDaily? <DailyJournal/> : <WeeklyJournal/>}
            
            </div>
        </div>
    )

}