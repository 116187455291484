/* Third party imports */
import { useTranslation } from "react-i18next";
import { useState } from "react";

/* Local imports */

import { MilestonesContentList } from "./milestones/milestonesContentList";
import { MilestonesList } from "./milestones/milestonesList";
import { PhaseDetails } from "./phases/phaseDetails";
import { PhasesList } from "./phases/phasesList";
import { useContentAndProgress } from "../../../contexts/UserProgressContext";
import { Content } from "./content";
import { MilestoneHeader } from "./milestones/milestoneHeader";
import { ReactComponent as UpArrow } from "../../../assets/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../../assets/down-arrow.svg";
import { useOnPageMount } from "../../../hooks/useOnPageMount";


export default function Home() {
    useOnPageMount();
    const { t } = useTranslation("home");
    const [areMilestonesCollapsed, setAreMilestonesCollapsed] = useState(false)

    const { openedContent, activePhase } = useContentAndProgress();


  

    return (
        <div className="p-6 md:px-9 w-full h-full">
            {/* PhasesList being shown on small screens */}
          
                    <PhasesList className="mb-5 lg:hidden" />
                    <PhaseDetails className="lg:hidden" />
              
            <div className="w-full h-full grid grid-cols-1 lg:grid-cols-4 lg:gap-2">
                <div className="hidden w-full lg:p-5 lg:block">
                     <MilestonesList />
                </div>
                <div className="w-full flex flex-col items-center lg:p-5 lg:col-span-2">
                    {/* PhasesList being shown on large screens */}
                    
                    <PhasesList className="mb-5 hidden lg:flex" />

                    <div className="w-full lg:hidden">
                        <div className="flex justify-between items-center my-4" onClick={() => setAreMilestonesCollapsed(!areMilestonesCollapsed)}>
                            <h1 className="font-bold font-lg text-Grey-dark" >{t("milestonesList-title")}</h1>
                            {areMilestonesCollapsed ? <UpArrow /> : <DownArrow />}
                        </div>
                        {areMilestonesCollapsed && <MilestonesList onSelectMilestone={() => setAreMilestonesCollapsed(false)} />}
                    </div>
                    <div className="w-full">
                        {openedContent ? null : <MilestoneHeader /> }
                        

                        {openedContent ?
                            <Content />
                            : <MilestonesContentList />}
                    </div>
                </div>
                {/* PhaseDetails being shown on large screens */}
                <div className="p-5 hidden lg:block">
                    {activePhase ? <PhaseDetails /> : null}
                </div>


            </div>
        </div>
    )

}