import { Link, useLocation } from "react-router-dom";
import { IRoute } from "../../../@types";
import { routes } from "../../../routes";
import { ReactComponent as HomeIcon } from "../../../assets//navbar-home.svg";
import { ReactComponent as HomeIconActive } from "../../../assets/navbar-home-active.svg";
import { ReactComponent as LearningsIcon } from "../../../assets/navbar-learnings.svg";
import { ReactComponent as LearningsIconActive } from "../../../assets/navbar-learnings-active.svg";
import { ReactComponent as BookmarksIcon } from "../../../assets/navbar-bookmarks.svg";
import { ReactComponent as BookmarksIconActive } from "../../../assets/navbar-bookmarks-active.svg";
import { ReactComponent as CommunityIcon } from "../../../assets/navbar-community.svg";
import { ReactComponent as CommunityIconActive } from "../../../assets/navbar-community-active.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/navbar-profile.svg";
import { ReactComponent as ProfileIconActive } from "../../../assets/navbar-profile-active.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import FAQHandler from "../../FAQ";
import { GetAuthorizedRoutes } from "../../../hooks/useAuthorizedRoutes";
import { useContext } from "react";
export function BottomNavigation({ className }: { className?: string }) {
  const location = useLocation();
  const pathName = location.pathname.split("/")[1] || "home";
  const { configurations } = useContext(AuthContext);;

  const authorizedRoutes = GetAuthorizedRoutes(configurations, routes)?.filter(route => !route?.hidden && route?.nameCode !== 'jobs');
 

  const renderIcon = (pathname: string) => {
    switch (pathname) {
      case "home":
        return { icon: <HomeIcon />, activeIcon: <HomeIconActive /> };
      case "coaches":
        return { icon: <LearningsIcon />, activeIcon: <LearningsIconActive /> };
      case "community":
        return { icon: <CommunityIcon />, activeIcon: <CommunityIconActive /> };
      case "journal":
        return { icon: <BookmarksIcon />, activeIcon: <BookmarksIconActive /> };
      case "profile":
        return { icon: <ProfileIcon />, activeIcon: <ProfileIconActive /> };
      default:
        break;
    }
  };

  const faqIndex = Math.max(
    authorizedRoutes?.findIndex((route) => route?.nameCode?.toLowerCase() === "coaches") + 1,
    authorizedRoutes?.findIndex((route) => route?.nameCode?.toLowerCase() === "community"),
    1 // Default to second position if other conditions fail
  );

  const modifiedRoutes = [...authorizedRoutes];
  if (faqIndex >= 0 && configurations?.includesFAQ) {
    modifiedRoutes?.splice(faqIndex, 0, {
      nameCode: "FAQ",
      path: "#" // FAQ doesn't have a traditional route, it triggers an action
    });
  }

  return (
    <div
      className={`${className} w-full flex items-center justify-between fixed bottom-0 border-t-[1px] px-10 py-3.5 bg-white`}
      style={{ zIndex: "2" }}
    >
      {modifiedRoutes
        .filter((r: IRoute) => !r?.hidden && r?.nameCode !== "jobs")
        .map((r: IRoute) => (
          <div key={r?.nameCode}>
            {r?.nameCode === "FAQ" ? (
              <FAQHandler isMobile= {true}/>
            ) : (
            <Link to={r?.path}>
              {pathName === r?.nameCode
                ? renderIcon(r?.nameCode)?.activeIcon
                : renderIcon(r?.nameCode)?.icon}
            </Link>
               )}
          </div>
        ))}
    </div>
  );
}
