/* React imports */
import {
  Children,
  ReactElement,
  createContext,
  useContext,
  useState,
} from "react";

/* Local imports */
import { IUser, UserWithConfigurations } from "../@types";
import { logout } from "../services/auth.service";
import { useQuery } from "react-query";
import { getLoggedUser } from "../services/users.service";

export const AuthContext = createContext<{ user: IUser | null; setUser: any, configurations: any, logoutUser: any, refetchConfigs: any }>({
  user: null,
  setUser: null,
  configurations: null,
  logoutUser: () => null,
  refetchConfigs: () => null,
});


export function AuthProvider({ children }: { children: ReactElement }) {
  const [user, setUser] = useState<IUser | null>(null);
  const token: string | null = localStorage.getItem("accessToken");

  const logoutUser = () => {
    logout();
    setUser(null);
    localStorage.removeItem("accessToken");
    window.location.href = "/";
  };

  const { data, refetch } = useQuery<UserWithConfigurations | undefined>(
    ["configurations", token],
    async () => {
      if (!token) return;
      return await getLoggedUser();
    },
    {
      onSuccess: (data) => {
        setUser(data?.user || null);
      },
      onError: (err) => {
        logoutUser();
      },
    }
  );

 
  return (
    <AuthContext.Provider value={{ user, setUser, configurations: data?.configurations, logoutUser, refetchConfigs: refetch }}>
      {children}
    </AuthContext.Provider>
  );
}
