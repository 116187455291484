import { ReactNode, useMemo } from "react";
import { AuthorContent, CoachContent } from "../../../../../@types";
import { useTranslation } from "react-i18next";
import { ContentType } from "../../../../../enums";
import { CircularProgress } from "@mui/material";

function CoachInfoWrapper({ title, children }: { title: string, children: ReactNode }) {

    return (
        <div className="text-Grey-dark mt-6">
            <h2 className="font-bold text-Grey-dark text-lg">{title}</h2>
            {children}
        </div>
    )
    
}

export function Overview({ coachDetails }: { coachDetails: CoachContent | AuthorContent | null }) {

    const { t } = useTranslation('coaches');

    const overviewTitle = useMemo(() => {
        if (coachDetails?.contentType === ContentType.COACH) {
            return coachDetails?.creditMode === "Minutes" ? t("about-us") : t("about-me");
        }
        return t("about-me");
    }, [coachDetails?.contentType])

    const remainingInfo = useMemo(() => {
        if (coachDetails?.contentType === ContentType.COACH) {
            if (coachDetails?.creditMode === "Minutes") {
                return (
                    <CoachInfoWrapper title={t("lawyers")}>
                        <div dangerouslySetInnerHTML={{ __html: coachDetails?.cv || "" }}></div>
                    </CoachInfoWrapper>
                )
            }
            return (<>
                <CoachInfoWrapper title={t("experience")}>
                    <div dangerouslySetInnerHTML={{ __html: coachDetails?.cv || "" }}></div>
                </CoachInfoWrapper>
                <CoachInfoWrapper title={t("certificate-awards")}>
                    <div dangerouslySetInnerHTML={{ __html: coachDetails?.certificateAwards || "" }}></div>
                </CoachInfoWrapper>
            </>)

        }
        return null

    }, [coachDetails?.contentType])

 
    return (
        <div className="mt-4">
            <div className="bg-Grey-light-3 rounded-md p-6">
                <h1 className="font-bold text-Grey-dark text-lg">{overviewTitle}</h1>
                <div dangerouslySetInnerHTML={{ __html: coachDetails?.aboutMe || "" }}></div>
            </div>
            {remainingInfo}
        </div>
    )

}