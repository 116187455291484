import React from "react";
import { useTranslation } from "react-i18next";

interface CoachAvailabilityIndicatorProps {
  availability: number; // Current number of blocked credits
  maxCoachings: number
}

const CoachAvailabilityIndicator: React.FC<CoachAvailabilityIndicatorProps> = ({
  availability,maxCoachings
}) => {
  const { t } = useTranslation("coaches");

  const usagePercentage = maxCoachings > 0 ? (availability / maxCoachings) * 100 : 100;

  // Determine the availability text and color based on the percentage
  const getAvailabilityInfo = () => {
    if (usagePercentage >= 100) {
      return { text: t("coach-status-not-available"), color: "#C41429" }; // Red
    } else if (usagePercentage > 50) {
      return { text: t("coach-status-medium-availability"), color: "#DCAA00" }; // Yellow
    } else {
      return { text: t("coach-status-high-availability"), color: "#048273" }; // Green
    }
  };

  const availabilityInfo = getAvailabilityInfo();

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <div
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          backgroundColor: availabilityInfo.color,
          border: "0px solid #000000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "12px",
          color: "white",
          fontWeight: "bold",
        }}
        title={`${availabilityInfo.text}`}
      ></div>
    </div>
  );
};

export default CoachAvailabilityIndicator;
