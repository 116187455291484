const bootsrapToken = (): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get("token");
};

export const softLogin = () => {
  const token = bootsrapToken();
  localStorage.setItem("accessToken", token || "");

  window.location.href = "/";
};
