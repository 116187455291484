import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useContext, useState } from "react";
import { LANGUAGES } from "../../../i18n";
import { useMutation } from "react-query";
import { updateLocale } from "../../../services/users.service";
import { AuthContext } from "../../../contexts/AuthContext";


function LanguageDropdownContent() {

    const { t, i18n } = useTranslation();
    const { user } = useContext(AuthContext);;

    const updateLocaleMutation = useMutation(['localeUpdate'],
        (mutationArgs: { locale: 'de' | 'en-US' }) => updateLocale({ locale: mutationArgs.locale }),);

    const getFormattedLang = (lang: string) => {
        if (lang === LANGUAGES.en) {
            return "en-US";
        } else if (lang === LANGUAGES.de) {
            return "de";
        } else {
            return "en-US";
        }
    }


    const changeLanguageHandler = (lang: string) => {
        console.log('Change Language Handler called with lang:', lang);
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
        console.log('Calling updateLocaleMutation.mutate');
        if (user?.cookieSettings?.preferencesCookiesAccepted) {
            updateLocaleMutation.mutate({ locale: getFormattedLang(lang) as ('de' | 'en-US') });
        }
    }

    return (
        <div className="absolute top-5 left-0 bg-white border border-zinc-500 rounded">{
            Object.entries(LANGUAGES).map(([k, v]) => {
                return <div className="px-2 hover:bg-zinc-200" onClick={() => changeLanguageHandler(k)}>{v}</div>
            })
        }</div>
    )

}



export function LanguageDropDown() {

    const { t, i18n } = useTranslation();

    const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

    return (
        <div className="relative">
            <button className="flex h-full items-center" onClick={() => setDropdownOpened(!dropdownOpened)}>
                <div>{i18n.language}</div>
                {dropdownOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                {dropdownOpened ? <LanguageDropdownContent /> : null}
            </button>
        </div>
    )

}