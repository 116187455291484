import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../../components/navbar";
import { BottomNavigation } from "../../components/navbar/bottomNavigation";
import { PrivateFooter } from "../../components/privateFooter";
import { AuthContext } from "../../contexts/AuthContext";
import { UserProgressContextProvider } from "../../contexts/UserProgressContext";
import { WrapperGuard } from "../../guards";
import { routes } from "../../routes";
import { LegalsRequired } from "./legal";
import { Onboarding } from "./onboarding";
import { VoucherRequired } from "./voucherRequired";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { useCookieUpdateConsent } from "../../hooks/useUpdateCookieConsent";
import { CookieConsentBanner } from "./legal/CookieConsent";
import { useContext, useState } from "react";
import { useCookieConsentBanner } from "../../hooks/useCookieConsentBanner";
import { useAuthorizedRoutesWithVoucher } from "../../hooks/useAuthorizedRoutesWithVoucher";

export function PrivateRoutes() {
  const { user } = useContext(AuthContext);
  const [displayCookieConsent, setDisplayCookieConsent] = useState(false);
  useCookieConsentBanner({
    displayCookieConsent: () => setDisplayCookieConsent(true),
    hideCookieConsent: () => setDisplayCookieConsent(false),
  });
  useCookieUpdateConsent();
  useGoogleAnalytics();

  const authorizedRoutes = useAuthorizedRoutesWithVoucher();


  if (!user) {
    return null;
  }


  return (
    <WrapperGuard
      guardView={<LegalsRequired />}
      accessCondition={!!user?.termsUpToDate && !!user?.privacyUpToDate}
      dependencies={[!!user?.termsUpToDate, !!user?.privacyUpToDate]}
    >
      <WrapperGuard
        guardView={<VoucherRequired />}
        accessCondition={!!user?.usedVoucher}
        dependencies={[!!user?.usedVoucher]}
      >
        <WrapperGuard
          guardView={<Onboarding />}
          accessCondition={!!user?.onboarded}
          dependencies={[!!user?.onboarded]}
        >
          <>
            <CookieConsentBanner
              showBanner={displayCookieConsent}
              closeBanner={() => setDisplayCookieConsent(false)}
            />
            <Router>
              <UserProgressContextProvider>
                <div className="w-full flex flex-col min-h-screen">
                  <Navbar />
                  <div className="lg:hidden" id="search-view"></div>
                  <div className="flex flex-col grow w-full">
                    <Routes>
                      {authorizedRoutes.map((r: any) => (
                        <Route
                          key={r.path}
                          path={r.path}
                          element={<r.element />}
                        />
                      ))}
                    </Routes>
                  </div>
                  <PrivateFooter />
                  <BottomNavigation className="lg:hidden" />
                </div>
              </UserProgressContextProvider>
            </Router>
          </>
        </WrapperGuard>
      </WrapperGuard>
    </WrapperGuard>
  );
}
