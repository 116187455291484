import { useNavigate } from "react-router-dom";
import { IContentfulSearchResult, IDiscourseSearchResult } from "../../../../@types";
import { CircularImage } from "../../../circularImage";



export function ArticleResultDisplayer({ searchResult, onClick }: { searchResult: IContentfulSearchResult, onClick: () => void }) {

    return (
        <>
            
            <div onClick={onClick}>
                <h1>{searchResult.title}</h1>
            </div>

        </>
    )
}

export function AssessmentResultDisplayer({ searchResult, onClick }: { searchResult: IContentfulSearchResult, onClick: () => void }) {

    return (
        <>
            <div onClick={onClick}>
                <h1>{searchResult.title}</h1>
            </div>
        </>

    )
}

export function CoachResultDisplayer({ searchResult, onClick }: { searchResult: IContentfulSearchResult, onClick: () => void }) {
    return (
        <>
            <div onClick={onClick} className="flex items-center">
                <div className="w-[40px] h-[40px] overflow-hidden mr-2">
                    <CircularImage src={`https:${(searchResult)?.profilePicture}`} />
                </div>
                <h1>{`${(searchResult as IContentfulSearchResult).title ? (searchResult as IContentfulSearchResult).title : ''} ${(searchResult as IContentfulSearchResult).givenName} ${(searchResult as IContentfulSearchResult).surname}`}</h1>
            </div>
        </>
    )
}

export function CoachingResultDisplayer({ searchResult, onClick }: { searchResult: IContentfulSearchResult, onClick: () => void }) {
    return (
        <>
            <div onClick={onClick}>
                <h1>{searchResult.title}</h1>
            </div>
        </>
    )
}


export function PostResultDisplayer({ searchResult, onClick }: { searchResult: IDiscourseSearchResult, onClick: () => void }) {
    return (
        <>
            <div onClick={onClick}>
                <p className='text-sm'>{(searchResult).text.slice(0, 100)}...</p>
                {/* <p className='text-sm font-bold'>From topic: {searchResult.topic.title}</p> */}
            </div>
        </>
    )
}