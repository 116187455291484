import { useQuery } from "react-query";
import {
  getContentById,
  getContentByProperty,
} from "../../../services/content.service";
import { ContentType } from "../../../enums";
import { CoachContent, FormattedContent, IContent } from "../../../@types";
import { CircularImage } from "../../../components/circularImage";
import { ReactElement, useCallback, useEffect, useState } from "react";
import Button from "../../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Coach } from "./coach";
import { getTitleFromLanguage } from "../../../utils";
import {
  getCoachesFromCategory,
  getCoachingCategory,
} from "../../../services/coaches.service";
import { ReactComponent as UpArrow } from "../../../assets/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../../assets/down-arrow.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { BookingPopup } from "./coach/booking/index";
import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { updateUser } from "../../../services/users.service";
import { useContext } from "react";
import CoachAvailabilityIndicator from "../../../components/coachTraffic/CoachAvailabilityIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export const PresenceMode = ({
  workModePresence,
  workModeRemote,
}: {
  workModePresence: boolean;
  workModeRemote: boolean;
}): ReactElement | null => {
  const { t } = useTranslation("coaches");
  const RemoteComponent = (
    <div className="text-sm font-medium	rounded-full bg-Grey-light-2 py-1 mr-1 w-1/2 flex justify-center">
      {t("remote")}
    </div>
  );
  const InPerson = (
    <div className="text-sm font-medium rounded-full bg-Grey-light-2 py-1 ml-1 w-1/2 flex justify-center">
      {t("in-person")}
    </div>
  );

  if (workModePresence && workModeRemote) {
    return (
      <div className="flex w-full justify-between">
        {RemoteComponent}
        {InPerson}
      </div>
    );
  } else if (workModePresence) {
    return <>{InPerson}</>;
  } else if (workModeRemote) {
    return <>{RemoteComponent}</>;
  }
  return null;
};

export function CoachListItem({
  coach,
}: {
  coach: { platformContentId: string };
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("coaches");
  const [bookingOpen, setBookingOpen] = useState(false);
  const [coachDetails, setCoachDetails] = useState<CoachContent | null>(null);
  const { user, configurations } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const { data: coachData, refetch } = useQuery(
    ["coachDataQuery", coach],
    () => getContentById({ platformContentId: coach.platformContentId }),
    {
      onSuccess: (data: CoachContent) => {
        setCoachDetails(data as CoachContent);
        setIsLoading(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          navigate("/notfound");
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
    }
  );

  const hasEnoughCredits = useCallback(() => {
    if (user && coachData) {
      if (user.availableCredits >= (coachData as any).creditsPerCoaching)
        return true;
    }
    return false;
  }, [user, coachData]);

  if (!coachData) return null;

  const getCoachAction = () => {
    if (!hasEnoughCredits()) {
      return (
        <div className="text-Grey text-center p-2">
          {t("not-enough-credits")}
        </div>
      );
    } else {
      return (
        <Button
          className={
            "bg-Yellow-default rounded-md w-full mt-3 font-medium py-1.5"
          }
          onClick={() => setBookingOpen(true)}
        >
          <span className="font-medium">{t("book-session")}</span>
        </Button>
      );
    }
  };

  const bookingPopUpFormType = configurations?.coachBookingForm;

  return (
    <>
      <BookingPopup
        close={() => setBookingOpen(false)}
        isOpen={bookingOpen}
        coachData={coachData as CoachContent}
        refetch={refetch}
        formType={bookingPopUpFormType}
      />
      <div className="p-2 border rounded-md flex flex-col">
        <div className="w-full flex justify-center mb-4">
          <div className="w-[200px] h-[200px] overflow-hidden ">
            <CircularImage
              src={`https:${(coachData as CoachContent)?.profilePicture}`}
            />
          </div>
        </div>
        <div className="w-full h-full flex flex-col text-Black">
          <div className="font-semibold">
            {(coachData as CoachContent)?.givenName}{" "}
            {(coachData as CoachContent)?.surname}
          </div>
          <div className="mb-4 text-Grey">
            {(coachData as CoachContent)?.location}
          </div>
          {/* <PresenceMode
                    workModePresence={(coachData as CoachContent).workModePresence || false}
                    workModeRemote={(coachData as CoachContent).workModeRemote || false} /> */}
          <div className="font-medium">
            {(coachData as CoachContent)?.coachingCategories
              ?.filter((cc: any) => cc.hideInTeaser === false)
              ?.map((cc: any) => cc.title)
              ?.join(", ")}
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-Grey">{t("availability")}:</div>
          <div className="px-1">
            {coachData?.coachAvailability !== undefined &&
              coachData?.coachAvailability !== null &&
              coachData?.maxCoachings !== undefined &&
              coachData?.maxCoachings !== null && (
                <CoachAvailabilityIndicator
                  availability={coachData.coachAvailability}
                  maxCoachings={coachData.maxCoachings}
                />
              )}
          </div>
        </div>
        <div>
          <Button
            onClick={() =>
              navigate(`/coaches/${coach.platformContentId}/overview`)
            }
            className={
              "bg-white border border-black rounded-md w-full mt-4 py-1.5 font-medium"
            }
          >
            <span className="font-medium">{t("see-details")}</span>
          </Button>
          {getCoachAction()}
        </div>
      </div>
    </>
  );
}

type CoachingCategory = { title: string; platformContentId?: string };

export function Coaches() {
  useOnPageMount();
  const { t, i18n } = useTranslation("coaches");
  const location = useLocation();
  const navigate = useNavigate();
  const [coachingCategories, setCoachingCategories] = useState<
    CoachingCategory[] | null
  >(null);
  const [activeMenu, setActiveMenu] = useState<CoachingCategory | null>(null);
  const [isCategoriesCollapsed, setIsCategoriesCollapsed] =
    useState<boolean>(false);

  const { setUser, configurations } = useContext(AuthContext);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingCoaches, setIsLoadingCoaches] = useState(true);

  const fetchAndUpdateUserData = async () => {
    try {
      const updatedUserData = await updateUser();
      setUser(updatedUserData);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  useEffect(() => {
    fetchAndUpdateUserData();
  }, []);

  const { data: coaches } = useQuery(
    ["coachesQuery", activeMenu],
    async () => await getCoachesFromCategory(activeMenu?.platformContentId),
    {
      onSuccess: (data) => {
        setIsLoadingCoaches(false);
      },
    }
  );

  const { data: coachingCategoryData } = useQuery(
    ["coachesCategoryQuery", activeMenu],
    () => getCoachingCategory(activeMenu?.platformContentId),
    {
      onSuccess: (data) => {},
    }
  );

  useQuery(
    ["coachingCategories", coachingCategories],
    async () => {
      // Fetch all coaching categories
      return await getContentByProperty({
        contentType: ContentType.COACHING_CATEGORY,
      });
    },
    {
      onSuccess: (allCategories) => {
        // Create updateCoachingCategories with filtered coaching categories
        const updateCoachingCategories = [
          { title: t("all-title"), platformContentId: undefined },
          ...allCategories?.map((d: IContent) => ({
            title: getTitleFromLanguage(d, i18n.language),
            platformContentId: d?.platformContentId,
          })),
        ];
        
        const sortedCategories = updateCoachingCategories.sort((a, b) => {
          const indexA = configurations?.availableCoachingCategories.indexOf(a.platformContentId);
          const indexB = configurations?.availableCoachingCategories.indexOf(b.platformContentId);
          if (a.title === t("all-title")) return -1;
          if (b.title === t("all-title")) return 1;
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        });
        // Set the coaching categories state
        setCoachingCategories(sortedCategories);

        // Set the active menu to the first category
        setActiveMenu(updateCoachingCategories[0]);
        setIsLoadingCategories(false);
      },
    }
  );

  const selectedCoach =
    location?.pathname.split("/").length > 1
      ? location?.pathname.split("/")[2]
      : null;

  if (isLoadingCategories || isLoadingCoaches) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return (
    <>
      <div className="px-6 py-6 md:px-9 lg:py-9 lg:px-12  flex w-full">
        <div className="flex w-full flex-col lg:flex-row">
          <div className="w-full lg:w-1/4">
            {/* Categories title with Arrow only showing in mobile */}
            <div
              className={`${
                selectedCoach && "hidden lg:flex"
              } flex justify-between items-center mb-4`}
              onClick={() => setIsCategoriesCollapsed(!isCategoriesCollapsed)}
            >
              <h1 className="text-Grey-dark text-lg font-bold ">
                {t("categories")}
              </h1>
              <span className="lg:hidden">
                {isCategoriesCollapsed ? <UpArrow /> : <DownArrow />}
              </span>
            </div>
            {/* Active category section for mobile */}
            <div
              className={`${
                selectedCoach && "hidden lg:flex"
              } mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer bg-Blue-metalic font-semibold lg:hidden`}
              onClick={() => {
                navigate("/coaches");
              }}
            >
              {activeMenu?.title}
            </div>
            {/* Category list */}
            <div className={`${isCategoriesCollapsed || "hidden"} lg:block`}>
              {coachingCategories?.map((m: CoachingCategory) => {
                const isActive = activeMenu?.title == m?.title;
                const activeStyles = isActive
                  ? "bg-Blue-metalic font-semibold"
                  : "bg-Blue-ligth text-Grey-dark";
                return (
                  <div
                    key={m?.platformContentId}
                    className={`mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${activeStyles}`}
                    onClick={() => {
                      navigate("/coaches");
                      setActiveMenu(m);
                      setIsCategoriesCollapsed(false);
                    }}
                  >
                    {t(m?.title)}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full lg:px-4 lg:w-3/4">
            {selectedCoach ? (
              <Coach />
            ) : (
              <>
                <div className="text-lg font-semibold text-Yellow-default mb-1">
                  {t("coaches")}
                </div>
                <h2 className="text-Black font-bold text-lg mb-1">{`${
                  activeMenu?.title !== t("all-title") ? t("coaches-for") : ""
                }  ${activeMenu?.title}`}</h2>
                <h3 className="text-Black mb-3">
                  {coachingCategoryData?.description}
                </h3>
                <div className="w-full grid grid-cols-2 gap-3 md:grid-cols-3">
                  {coaches?.map((c: IContent) => (
                    <CoachListItem key={c?.platformContentId} coach={c} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
