import React, { useContext, useEffect, useRef, useState } from "react";
import { getHeyForm } from "../../services/content.service";
import { useQuery } from "react-query";
import { AuthContext } from "../../contexts/AuthContext";

interface HeyflowFormProps {
  formTitle: string;
  heyflowWrapper: string;
}

function HeyflowForm({ formTitle, heyflowWrapper }: HeyflowFormProps) {
  const { user } = useContext(AuthContext);;

  return (
    <div>
      {/* Display the Heyflow form */}
      <div dangerouslySetInnerHTML={{ __html: heyflowWrapper }} />
    </div>
  );
}

export default HeyflowForm;
