import { Goal, MoodSection, TextAreaSection } from "../common";
import { ReactComponent as LeftArrow } from "../../../../assets/left-arrow-calendar.svg";
import { ReactComponent as RightArrow } from "../../../../assets/rigth-arrow-calendar.svg";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useWeeklyJournal } from "./useWeeklyJournal";
import { useEffect } from "react";

export function WeeklyJournal() {
  const { t } = useTranslation("journal");

  const {
    startDate,
    endDate,
    handleDateChange,
    achievements,
    setAchievements,
    insights,
    setInsights,
    improvements,
    setImprovements,
    weeklyGoals,
    newWeeklyGoal,
    setNewWeeklyGoal,
    updateWeeklyGoalMutation,
    deleteWeeklyMutation,
    addWeeklyGoalMutation,
    productivityRate,
    setProductivityRate,
    gratitudeRate,
    setGratitudeRate,
    happinessRate,
    setHappinessRate,
    updatePlanMutation,
  } = useWeeklyJournal();
  const maxLength = 256;

  return (
    <>
      {/* Date picker section */}
      <div className="w-full sm:w-1/2 mb-3">
        <div className="flex items-center justify-between">
          <LeftArrow
            className="mr-5 cursor-pointer"
            onClick={() => {
              handleDateChange([startDate.setDate(startDate.getDate() - 6)]);
            }}
          />
          <ReactDatePicker
            selected={startDate}
            onChange={handleDateChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            className="border rounded-lg px-4 py-3.5 text-base justify-stretch w-full text-center "
          />
          <RightArrow
            className="ml-5 cursor-pointer"
            onClick={() => {
              handleDateChange([startDate.setDate(startDate.getDate() + 7)]);
            }}
          />
        </div>
      </div>

      {/* Mood section */}
      <div className="p-6 bg-Grey-light-3 mb-9">
        <p className="text-lg text-Grey-dark font-bold mb-4 ">
          {t("mood-title-weekly")}
        </p>
        <MoodSection
          classNames="mb-4"
          moodSelected={productivityRate || 0}
          onSelect={setProductivityRate}
          title={t("mood-weekly-1") || ""}
        />
        <MoodSection
          classNames="mb-4"
          moodSelected={gratitudeRate || 0}
          onSelect={setGratitudeRate}
          title={t("mood-weekly-2") || ""}
        />
        <MoodSection
          moodSelected={happinessRate || 0}
          onSelect={setHappinessRate}
          title={t("mood-weekly-3") || ""}
        />
      </div>

      <p className="text-lg text-Grey-dark font-bold mb-4 ">
        {t("weekly-goals")}
      </p>

      <p className="mb-3">{t("add-goal-weekly-title")}</p>

      <div>
        {weeklyGoals.map((goal: any) => (
          <Goal
            key={goal.id}
            currentDate={startDate}
            goal={goal}
            updateMutation={updateWeeklyGoalMutation}
            deleteMutation={deleteWeeklyMutation}
            isWeekly={true}
          />
        ))}
        <div className="flex items-center w-full mt-4">
          <input
            onChange={(e) => {
                const newGoal = e.target.value;
                if (newGoal.length <= maxLength) {
                  setNewWeeklyGoal(newGoal);
                }
              }}
            value={newWeeklyGoal}
            className="border rounded-lg px-4 py-2 w-full "
            placeholder={t("add-goal-input-placeholder-weekly") || ""}
          />
          <button
            disabled={newWeeklyGoal.length == 0}
            onClick={() =>
              addWeeklyGoalMutation.mutate({ title: newWeeklyGoal })
            }
            className={`border rounded-lg ${
              newWeeklyGoal.length == 0
                ? "text-Grey-dark bg-Grey-light-2"
                : "bg-Yellow-default text-black font-bold"
            } px-4 py-2 ml-3 whitespace-nowrap`}
          >
            {t("add-weekly-goal-button")}
          </button>
        </div>
        {newWeeklyGoal.length === maxLength && (
          <p className="text-red-500 mt-2" style={{ color: "#C41429" }}>
            {t("warning-goal-limit-characters")}
          </p>
        )}
        <div className="mb-9"></div>
      </div>

      <div>
        <TextAreaSection
          onMouseLeaveAction={()=> updatePlanMutation.mutate()}
          placeholder={t("achievements-placeholder-weekly")}
          title={t("achievements")}
          text={achievements}
          onChangeText={setAchievements}
          maxLength={1024}
        />
        <TextAreaSection
          onMouseLeaveAction={()=> updatePlanMutation.mutate()}
          placeholder={t("insights-placeholder-weekly")}
          title={t("insights")}
          text={insights}
          onChangeText={setInsights}
          maxLength={1024}
        />
        <TextAreaSection
          onMouseLeaveAction={()=> updatePlanMutation.mutate()}
          placeholder={t("improvements-placeholder-weekly")}
          title={t("improvements")}
          text={improvements}
          onChangeText={setImprovements}
          maxLength={1024}
        />
      </div>
    </>
  );
}
