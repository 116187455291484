import { useQuery } from "react-query";
import { getOwnTopics } from "../../../../../services/discourse.service";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { CommunityTopic } from "..";
import { ICommunityTopic } from "../../../../../@types";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";

export function UsersPosts({ feedback }: { feedback?: { isLiked?: boolean, isBookmarked?: boolean, topicId: number }[] }) {

    const { user } = useContext(AuthContext);;
    const navigate = useNavigate();
    const location = useLocation();

    const { data: usersPosts } = useQuery(['userPosts'], () => getOwnTopics(user?.username));

    const openTopic = parseInt(location.pathname.split("/")[3]);


    return (
        <div>
            {usersPosts?.topic_list?.topics?.map((lt: ICommunityTopic, idx: number) => {
                const fb = feedback?.find((f) => f.topicId === lt.id);
                return <div onClick={() => navigate(`/community/topic/${lt.id}`)}>
                    <CommunityTopic open={openTopic === lt.id} topic={lt} users={usersPosts?.users} feedback={fb} refetchFeedback={() => null} />
                </div>
            })}
        </div>
    )



}