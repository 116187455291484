import { Form, Formik } from "formik";
import { useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { linkedinLogin, signup } from "../../services/auth.service";
import { LegalRequirements } from "./LegalRequirements";
import { LinkedInOAuthButton } from "./utils/components/OAuthButtons/LinkedinOAuthButton";
import { SeparationBar } from "./utils/components/SeparationBar";
import * as Yup from 'yup';
import { CustomFieldWithError } from "../../components/forms/CustomFieldWithError";
import { SIGNUP_ERRORS } from "../../errors/errorCodes";
import { NavigationBar } from "./utils/components/NavigationBar";
import Button from "../../components/button";
import { ButtonSize, ButtonVariant } from "../../components/button/types";
import { Password } from "../../components/forms/Password";
import { useLocation } from "react-router-dom";


const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
});



export default function Signup({ goToLogin, goToSignUp, setRegisteredUser, goToVerificationEmailSent }: { goToLogin: () => void, goToSignUp: () => void ,goToVerificationEmailSent: () => void, setRegisteredUser: (user: any) => void }) {

    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [privacyStatementAccepted, setPrivacyStatementAccepted] = useState(false);
    const legalsAccepted = termsAndConditionsAccepted && privacyStatementAccepted;
    
    const [emailRegisteredError, setEmailRegisteredError] = useState<boolean>(false);
    const [passwordNotStrongEnoughError, setPasswordNotStrongEnoughError] = useState<boolean>(false);
    const [voucherError, setVoucherError] = useState<boolean>(false);
    let voucherCode ;

    const { t } = useTranslation("authentication");

    const emailErrorMessage = emailRegisteredError ? t("register-form.email-already-registered") : null;
    const passwordNotStrongEnoughMessage = passwordNotStrongEnoughError ? t("register-form.password-not-strong.message") : null;

    const voucherMessage = voucherError ? t("register-form.voucher-error") : null;

    const signupMutation = useMutation(signup, {
        onSuccess: () => {

            localStorage.removeItem("pendingVoucherCode");
            setVoucherError(false);
            goToVerificationEmailSent();
        }, onError: ({ response }) => {
            console.log(response.data.message);
            if (response.data.message == SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {
                setEmailRegisteredError(true);
            }
            if (response.data.message == SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH) {
                setPasswordNotStrongEnoughError(true);
            }
            if (response.data.message == SIGNUP_ERRORS.INVALID_VOUCHER) {
                setVoucherError(true);
            }
        }
    });

   
     // Handle voucher code from URL
     voucherCode = new URLSearchParams(window.location.search).get("code");
     const sessionCode = localStorage.getItem("pendingVoucherCode");

     if (sessionCode && !voucherCode) {
        voucherCode = sessionCode
    }

    return (
        <div className="w-full p-9">
            <NavigationBar goToLogin={goToLogin} />
            <div className="mt-4">
                <LinkedInOAuthButton onClick={linkedinLogin} />
            </div>
            <SeparationBar />
            
            <Formik
                initialValues={{ email: '', password: '', voucherCode: voucherCode || '' }}
                validationSchema={validationSchema}
                onSubmit={(values, {setFieldValue}) => {
                    setRegisteredUser(values);
                    if (voucherError) {
                       values.voucherCode= ''
                    }
                    signupMutation.mutate(values);
                }}
            >
                {({ errors, touched, isValid, values }) => {

                    const noFieldsEmpty = values['email'].length > 0 && values['password'].length > 0;
                    const disabled = !isValid || (!noFieldsEmpty) || !(termsAndConditionsAccepted && privacyStatementAccepted)

                    return (
                        <Form className="mt-4">
                            <div className="w-full">
                                <label htmlFor="email">{t("register-form.email")}*</label>
                                <div>
                                    <CustomFieldWithError className="w-full h-10 mt-1" type="email" id="email" name="email" error={errors.email || emailErrorMessage} touched={!!touched.email} />
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <label htmlFor="password">{t("register-form.password")}*</label>
                                <div>
                                    <Password error={errors.password || passwordNotStrongEnoughMessage} touched={!!touched.password} />
                                    {passwordNotStrongEnoughError ? <ul className="text-red-500 text-xs ml-5">
                                        <li>{t("register-form.password-not-strong.rule-1")}</li>
                                        <li>{t("register-form.password-not-strong.rule-2")}</li>
                                        <li>{t("register-form.password-not-strong.rule-3")}</li>
                                    </ul> : null}
                                </div>
                            </div>


                            <LegalRequirements
                                termsAndConditionsAccepted={termsAndConditionsAccepted}
                                privacyStatementAccepted={privacyStatementAccepted}
                                setTermsAndConditionsAccepted={setTermsAndConditionsAccepted}
                                setPrivacyStatementAccepted={setPrivacyStatementAccepted}
                            />

                            <Button type="submit" className="mt-6 w-full" disabled={disabled} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                                {t("buttons.sign-up")}
                            </Button>

                            {voucherMessage && (
                               <div className="text-sm text-red-500">{voucherMessage}</div> 
                            )}
                        </Form>
                    )
                }}
            </Formik>
            <div className="mt-6 text-sm">
                {t("already-member")}: <button className="underline" onClick={goToLogin}>{t("login")}</button>
            </div>
        </div>
    )
}
