import { IContent } from "../@types";
import { LANGUAGES } from "../i18n";



export function getTitleFromLanguage(content: IContent | null, currentLang: string): string {

    if (content == null) return '';


    if (currentLang === LANGUAGES.de) {
        return content?.contentTitleDe;
    }

    return content?.contentTitleEn;

}

export function makeUrlFromTitle(title?: string) {
    return title?.replace(/[^a-zA-Z\s]/g, '').split(' ').map((word: string) => word.toLowerCase()).join('-');
}


export const goToWindow = (route: string) => {
    window.history.pushState(null, '', route);
}



export function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}