import { IRoute } from './@types';
import { Community } from './pages/private/community';
import Home from './pages/private/home';
import { Profile } from './pages/private/myArea';
import { Coaches } from './pages/private/coaches/index';
import { Journal } from './pages/private/journal';
import TermsOfService from './pages/public/TermsOfService';
import PrivacyPolicy from './pages/public/PrivacyPolicy';
import About from './pages/public/About';
import Support from './pages/public/Support';
import Imprint from './pages/public/Imprint';
import CookiePolicy from './pages/public/CookiePolicy';
import { FullSearch } from './pages/private/fullSearch';
import { Experience } from './pages/private/community/Experience';
import { BookedCoachingPrivateWrap } from './pages/public/coachings/BookedCoachingPrivateWrap';
import { SubmitCvPrivateWrap } from './pages/public/submitCv/SubmitCvPrivateWrapper';
import { CommunityThread } from './pages/private/community/Thread/index';
import { Authors } from './pages/private/authors';
import { Forms } from './pages/private/forms';
import { RegeneratePartnerCVCheckPrivateWrap } from './pages/public/RegeneratePartnerCvCheckPrivateWrap';
import { Jobs } from './pages/private/jobs';
import { Activate } from './pages/private/activate';
import Unauthorized from './pages/private/Notfound';
import Forbidden from './pages/private/Forbidden';
import NotFound from './pages/private/Notfound';
import VoucherErrorPage from './pages/private/VoucherErrorPage';


export const routes : IRoute[] = [
    {
        path: '/',
        element: Home,
        nameCode: 'home'
    },
    {
        path: '/phase/*',
        element: Home,
        nameCode: 'content',
        hidden: true
    },
    {
        path: '/coaches',
        element: Coaches,
        nameCode: 'coaches'
    },
    {
        path: '/coaches/*',
        element: Coaches,
        nameCode: 'coaches',
        hidden: true
    },
    {
        path: 'authors/*',
        element: Authors,
        nameCode: 'authors',
        hidden: true
    },
    {
        path: 'forms/:form',
        element: Forms,
        nameCode: 'forms',
        hidden: true
    },
    {
        path: '/community',
        element: Community,
        nameCode: 'community'
    },  
      
    {
        path: '/community',
        element: Community,
        nameCode: 'community',
        hidden: true
    },
    {
        path: '/community/experience/*',
        element: Experience,
        nameCode: 'community',
        hidden: true
    }, 
    {
        path: '/community/topic/*', 
        element: Community,
        nameCode: 'community',
        hidden: true
    },
    {
        path: '/thread/*',
        element: CommunityThread,
        nameCode: 'thread',
        hidden: true
    },
    {
        path: '/journal',
        element: Journal,
        nameCode: 'journal'
    },
    {
        path: '/jobs',
        element: Jobs,
        nameCode: 'jobs'
    },
    {
        path: '/profile',
        element: Profile,
        nameCode: 'profile'
    },
    {
        path: '/profile/*',
        element: Profile,
        nameCode: 'profile',
        hidden: true
    },
    {
        path: '/search',
        element: FullSearch,
        nameCode: 'search',
        hidden: true
    },
    {
        path: '/terms-of-service',
        element: TermsOfService,
        nameCode: 'terms-of-service',
        hidden: true
    },
    {
        path: '/privacy-policy',
        element: PrivacyPolicy,
        nameCode: 'privacy-policy',
        hidden: true
    },
    {
        path: '/cookie-policy',
        element: CookiePolicy,
        nameCode: 'cookie-policy',
        hidden: true
    },
    {
        path: '/about',
        element: About,
        nameCode: 'about',
        hidden: true
    },
    {
        path: '/support',
        element: Support,
        nameCode: 'support',
        hidden: true
    },
    {
        path: '/imprint',
        element: Imprint,
        nameCode: 'imprint',
        hidden: true
    },
    {
        path: '/coaching/rejectpage',
        element: BookedCoachingPrivateWrap,
        nameCode: 'reject',
        hidden: true
    },
    {
        path: 'coaching/invoicepage',
        element: BookedCoachingPrivateWrap,
        nameCode: 'invoice',
        hidden: true
    },
    {
        path: '/coaching/expert-invoicepage',
        element: BookedCoachingPrivateWrap,
        nameCode: 'expert-invoice',
        hidden: true
    },
    {
        path: '/submit-cv',
        element: SubmitCvPrivateWrap,
        nameCode: 'submit-cv',
        hidden: true 
    },
    {
        path: '/regenerate-partner-cv-check-email',
        element: RegeneratePartnerCVCheckPrivateWrap,
        nameCode: 'regenerate-partner-cv-check-email',
        hidden: true 
    },
    {
        path: '/activate',
        element: Activate,
        nameCode: 'activate',
        hidden: true 
    },
    {
        path: '/voucher-error',
        element: VoucherErrorPage,
        nameCode: 'voucherError',
        hidden: true
    },
    {
        path: '/forbidden',
        element: Forbidden,
        nameCode: 'forbidden',
        hidden: true
    },
    {
        path: '/notfound',
        element: NotFound,
        nameCode: 'notfound',
        hidden: true
    },
]  
