export function PopupWrapper({ children, isOpen, className }: { children: JSX.Element, isOpen: boolean, className?: string }) {

    return (
        <>
            {isOpen ?
                <div className={`fixed inset-0 overflow-hidden top-0 right-0 flex w-full h-screen bg-opacity-30 bg-Grey-dark justify-center items-center z-40 ${className}`}>
                    {children}
                </div>
                : false}

        </>
    )

}