import { Link, useLocation } from "react-router-dom";
import { IRoute } from "../../../@types";
import { routes } from "../../../routes";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import FAQHandler from "../../FAQ";
import { GetAuthorizedRoutes } from "../../../hooks/useAuthorizedRoutes";
import { useContext } from "react";

export function NavigationTabs({ className }: { className?: string }) {
  const { t } = useTranslation("navbar");
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];

  const { configurations } = useContext(AuthContext);;

  const authorizedRoutes = GetAuthorizedRoutes(configurations,routes)?.filter(
    (route) => !route?.hidden
  );
 
  const faqIndex = Math.max(
    authorizedRoutes?.findIndex(
      (route) => route?.nameCode?.toLowerCase() === "coaches"
    ) + 1,
    authorizedRoutes?.findIndex(
      (route) => route?.nameCode?.toLowerCase() === "community"
    ),
    1 // Default to second position if other conditions fail
  );

  const modifiedRoutes = [...authorizedRoutes];

  if (faqIndex >= 0 && configurations?.includesFAQ) {
    modifiedRoutes?.splice(faqIndex, 0, {
      path: "#",
      nameCode: "FAQ",
    });
  }

  return (
    <div className={`${className} flex justify-between lg:space-x-2`}>
      {modifiedRoutes
        .filter((r: IRoute) => !r.hidden)
        .map((r: IRoute) => {
          const isActive =
            pathName === r?.nameCode ||
            ((!pathName || pathName == "phase") && r?.nameCode === "home");

          if (r?.nameCode === "FAQ") {
            // Special handling for FAQ
            return (
              <div key="faq" style={{ whiteSpace: "nowrap" }}>
                <FAQHandler />
              </div>
            );
          } else {
            return (
              <div key={r?.nameCode} style={{ whiteSpace: "nowrap" }}>
                <Link
                  to={r?.path}
                  className={
                    isActive ? "font-bold text-Black" : "text-Grey-dark"
                  }
                >
                  {t(`${r?.nameCode}`)}
                </Link>
                {isActive ? (
                  <div className="w-full bg-amber-400 h-[3px]"></div>
                ) : null}
              </div>
            );
          }
        })}
    </div>
  );
}
