import { ReactComponent as Reaction1 } from "../../../../assets/reaction-1.svg";
import { ReactComponent as Reaction1Active } from "../../../../assets/reaction-1-active.svg";
import { ReactComponent as Reaction2 } from "../../../../assets/reaction-2.svg";
import { ReactComponent as Reaction2Active } from "../../../../assets/reaction-2-active.svg";
import { ReactComponent as Reaction3 } from "../../../../assets/reaction-3.svg";
import { ReactComponent as Reaction3Active } from "../../../../assets/reaction-3-active.svg";
import { ReactComponent as Reaction4 } from "../../../../assets/reaction-4.svg";
import { ReactComponent as Reaction4Active } from "../../../../assets/reaction-4-active.svg";
import { ReactComponent as Reaction5 } from "../../../../assets/reaction-5.svg";
import { ReactComponent as Reaction5Active } from "../../../../assets/reaction-5-active.svg";
import { ReactComponent as DoneCheckInactive } from "../../../../assets/done_check_inactive.svg";
import { ReactComponent as DoneCheckActive } from "../../../../assets/done_check_active.svg";
import { ReactComponent as MoreOptions } from "../../../../assets/more_options.svg";
import { ReactComponent as Close } from "../../../../assets/close.svg";

import { useTranslation } from "react-i18next";
import { useOutsideClickDetector } from "../../../../hooks/useOutsideClickDetector";
import { useRef, useState } from "react";
import { UseMutationResult } from "react-query";
import { PopupWrapper } from "../../../../components/popupWrapper";
import Button from "../../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../../components/button/types";
import ReactDatePicker from "react-datepicker";
import { formatDate } from "../../../../utils";
import { startOfWeek, endOfWeek, getWeek, getYear } from "date-fns";

export function MoodSection({
  moodSelected = 0,
  onSelect,
  title,
  classNames = "",
}: {
  moodSelected: number | undefined;
  onSelect: (sel: number) => void;
  title?: string;
  classNames?: string;
}) {


  return (
    <div className={`w-full ${classNames}`}>
      {title && <p className="mb-4">{title}</p>}
      <div className="flex justify-between w-full sm:w-1/2 ">
        {moodSelected === 1 ? (
          <div >
            <Reaction5Active />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => onSelect(1)}>
            <Reaction5 />
          </div>
        )}
        {moodSelected === 2 ? (
          <div >
            <Reaction4Active />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => onSelect(2)}>
            <Reaction4 />
          </div>
        )}
        {moodSelected === 3 ? (
          <div>
            <Reaction3Active />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => onSelect(3)}>
            <Reaction3 />
          </div>
        )}
        {moodSelected === 4 ? (
          <div >
            <Reaction2Active />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => onSelect(4)}>
            <Reaction2 />
          </div>
        )}
        {moodSelected === 5 ? (
          <div >
            <Reaction1Active />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => onSelect(5)}>
            <Reaction1 />
          </div>
        )}
      </div>
    </div>
  );
}

export function TextAreaSection({
  title,
  text = "",
  onChangeText,
  placeholder = "",
  maxLength,
  onMouseLeaveAction
}: {
  title: string;
  text: string;
  onChangeText: (sel: string) => void;
  placeholder: string;
  onMouseLeaveAction: () => void
  maxLength: number;
}) {
  const { t } = useTranslation('journal')
  const characterCount = text.length;
  const [hasChanged, setHasChanged] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHasChanged(true);
    const newText = e.target.value;
    if (newText.length <= maxLength) {
      onChangeText(newText);
    }
  };
  return (
    <div className="w-full mb-9">
      <p className="text-lg text-Grey-dark font-bold mb-4 ">{title}</p>
      <textarea
        className="w-full px-4 py-3 border border-Grey rounded-lg h-44 overflow-auto"
        value={text}
        onMouseLeave={()=> {
          if(hasChanged) onMouseLeaveAction();
        }}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {characterCount === maxLength && (
        <p className="text-red-500 mt-2" style={{ color: "#C41429" }}>
          {t("warning-achivements-limit-characters")}
        </p>
      )}
    </div>
  );
}

export function NavigationBar({
  isDaily,
  setIsDaily,
}: {
  isDaily: boolean;
  setIsDaily: (is: boolean) => void;
}) {
  const { t } = useTranslation("journal");

  return (
    <div className="flex flex-col w-full mb-3">
      <div className="flex w-full justify-start">
        <div>
          <div
            onClick={() => setIsDaily(true)}
            className={`py-3 px-4 cursor-pointer ${isDaily ? "font-semibold" : ""
              }`}
          >
            {t("daily")}
          </div>
          <div
            className={
              isDaily
                ? `flex h-[2px] w-full bg-Yellow-default`
                : `flex h-[2px] w-full bg-Grey-light-2`
            }
          />
        </div>
        <div>
          <div
            onClick={() => setIsDaily(false)}
            className={`py-3 px-4 cursor-pointer ${!isDaily ? "font-semibold" : ""
              }`}
          >
            {t("weekly")}
          </div>
          <div
            className={
              !isDaily
                ? `flex h-[2px] w-full bg-Yellow-default`
                : `flex h-[2px] w-full bg-Grey-light-2`
            }
          />
        </div>
        <div className="flex flex-col w-full items-center justify-end">
          <div className="flex h-[2px] w-full bg-Grey-light-2" />
        </div>
      </div>
    </div>
  );
}

export function DeleteModal({
  isOpen,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation("journal");
  return (
    <PopupWrapper isOpen={isOpen}>
      <div className="bg-white bg-opacity-100 w-1/4 p-5 rounded-lg">
        <div className="flex mb-4 justify-between items-center text-lg font-bold">
          <div className="w-10/12">{t("delete-confirmation")}</div>
          <div className="flex justify-center w-2/12 ">
            <Close className="cursor-pointer" onClick={onCancel} />
          </div>
        </div>
        <div className="text-sm text-Grey-dark mb-4">{t("action-warning")}</div>
        <div className="flex justify-between">
          <Button
            className="w-full mr-2"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            className="w-full ml-2"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onConfirm}
          >
            {t("confirm")}
          </Button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export function RescheduleModal({
  isOpen,
  onCancel,
  onConfirm,
  currentDate,
  isWeekly,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (newDate: any) => void;
  currentDate: Date;
  isWeekly?: boolean;
}) {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);

  const handleDateChange = ([newStartDate]: any[]) => {
    if (newStartDate !== selectedDate) {
      setSelectedDate(startOfWeek(newStartDate));
      setEndDate(endOfWeek(newStartDate));
    }
  };

  const { t } = useTranslation("journal");

  return (
    <PopupWrapper isOpen={isOpen}>
      <div className="bg-white bg-opacity-100 w-1/4 p-5 rounded-lg">
        {isWeekly ? (
          <ReactDatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            selectsRange
            startDate={selectedDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            className="border rounded-lg px-4 py-3.5 text-base justify-stretch w-full text-center "
          />
        ) : (
          <ReactDatePicker
            id="date-picker"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date || currentDate)}
            dateFormat="dd/MM/yyyy"
            placeholderText={t("select-a-date") || "Select a date"}
            className="border rounded-lg px-4 py-3.5 text-base justify-stretch w-full "
          /*inline
                       renderCustomHeader={() => <div className="bg-white">test</div>} */
          />
        )}
        <div className="flex justify-between mt-4">
          <Button
            className="w-full mr-2"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            className="w-full ml-2"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() =>
              onConfirm(isWeekly ? selectedDate : formatDate(selectedDate))
            }
          >
            {t("apply")}
          </Button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export function Goal({
  goal,
  currentDate,
  updateMutation,
  deleteMutation,
  isWeekly,
}: {
  goal: { title?: string; id?: number; check?: boolean };
  currentDate: Date;
  updateMutation: UseMutationResult<
    any,
    unknown,
    {
      goalId?: number | undefined;
      title?: string | undefined;
      check?: boolean | undefined;
      rescheduleDate?: string | undefined;
      rescheduleWeek?: number | undefined;
      rescheduleYear?: number | undefined;
    },
    unknown
  >;
  deleteMutation: UseMutationResult<
    any,
    unknown,
    {
      goalId?: number | undefined;
      title?: string | undefined;
      check?: boolean | undefined;
      rescheduleDate?: string | undefined;
    },
    unknown
  >;
  isWeekly?: boolean;
}) {
  const { t } = useTranslation("journal");

  console.log("Got start date: ", currentDate);

  const [moreOptions, setMoreOptions] = useState<number | null>(null);
  const [goalEditable, setGoalEditable] = useState<{
    title?: string;
    id?: number;
  } | null>(null);
  const [goalToDelete, setGoalToDelete] = useState<number | null>(null);
  const [goalToReschedule, setGoalToReschedule] = useState<number | null>(null);

  const moreOptionsRef = useRef<any>(null);

  useOutsideClickDetector(moreOptionsRef, () => setMoreOptions(null));

  return (
    <>
      <DeleteModal
        isOpen={!!goalToDelete}
        onCancel={() => setGoalToDelete(null)}
        onConfirm={() => {
          deleteMutation.mutate({ goalId: goalToDelete || undefined });
          setGoalToDelete(null);
        }}
      />
      <RescheduleModal
        isOpen={!!goalToReschedule}
        onCancel={() => setGoalToReschedule(null)}
        onConfirm={(date: string) => {
          if (!goalToReschedule) return;
          if (isWeekly) {
            updateMutation.mutate({
              goalId: goalToReschedule,
              ...goal,
              rescheduleWeek: getWeek(new Date(date)),
              rescheduleYear: getYear(new Date(date)),
            });
          } else {
            updateMutation.mutate({
              goalId: goalToReschedule,
              ...goal,
              rescheduleDate: date,
            });
          }
        }}
        currentDate={currentDate}
        isWeekly={isWeekly}
      />
      <div key={goal.id}>
        {goalEditable?.id === goal.id ? (
          <div className="flex items-center w-full">
            <input
              onChange={(e) =>
                setGoalEditable((goal) => ({ ...goal, title: e.target?.value }))
              }
              value={goalEditable?.title}
              className="border rounded-lg px-4 py-3.5 w-full "
              placeholder={t("add-goal-input-placeholder") || ""}
            />
            <button
              disabled={goalEditable?.title?.length == 0}
              onClick={() => {
                updateMutation.mutate({
                  goalId: goalEditable?.id,
                  ...goalEditable,
                });
                setGoalEditable(null);
                setMoreOptions(null);
              }}
              className={`border rounded-lg ${goalEditable?.title?.length == 0
                  ? "text-Grey-dark bg-Grey-light-2"
                  : "bg-Yellow-default text-black font-bold"
                } px-4 py-2 ml-3 whitespace-nowrap`}
            >
              {isWeekly
                ? t("save-weekly-goal-button")
                : t("save-daily-goal-button")}
            </button>
          </div>
        ) : (
          <div className=" flex w-full justify-between items-center">
            <div>{goal.title}</div>
            <div className="flex items-center">
              <div className="mr-4 cursor-pointer">
                {goal.check ? (
                  <DoneCheckActive
                    onClick={() =>
                      updateMutation.mutate({ goalId: goal.id, check: false })
                    }
                  />
                ) : (
                  <DoneCheckInactive
                    onClick={() =>
                      updateMutation.mutate({ goalId: goal.id, check: true })
                    }
                  />
                )}
              </div>
              <div className="relative">
                <div
                  ref={moreOptionsRef}
                  onClick={() => setMoreOptions(goal?.id || null)}
                  className={`${moreOptions == goal.id
                      ? "border-opacity-100"
                      : "border-opacity-0"
                    } border-black cursor-pointer rounded-md p-2 border-2`}
                >
                  <MoreOptions />
                </div>
                {moreOptions == goal.id ? (
                  <div
                    ref={moreOptionsRef}
                    className="absolute left-[50px] bottom-[-20px] bg-Grey-light-3 rounded-md p-3"
                  >
                    <div
                      className="mb-2 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setGoalEditable(goal);
                      }}
                    >
                      {t("edit")}
                    </div>
                    <div
                      className="mb-2 cursor-pointer"
                      onClick={(e) => {
                        setGoalToDelete(goal?.id || null);
                      }}
                    >
                      {t("delete")}
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        setGoalToReschedule(goal?.id || null);
                        setMoreOptions(null);
                      }}
                    >
                      {t("reschedule")}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
