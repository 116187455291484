import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { MyAreaTitle } from "../components";
import { Tab } from "../../../fullSearch";
import { ContentBookmarks } from "./ContentBookmarks";
import { CommunityBookmarks } from "./CommunityBookmarks";
import { AuthContext } from "../../../../../contexts/AuthContext";

enum Tabs {
  CONTENT = "Content",
  COMMUNITY = "Community",
}

export function Bookmarks() {
  const { t } = useTranslation("myArea");
  const [tab, setTab] = useState(Tabs.CONTENT);

  const fullTabsList = {
    [Tabs.CONTENT]: <ContentBookmarks />,
    [Tabs.COMMUNITY]: <CommunityBookmarks />,
  };
  const { configurations } = useContext(AuthContext);
  const shouldRenderCommunityBookmarks = configurations?.includesCommunity;

  return (
    <div className="p-3">
      <div className="hidden lg:flex">
        <MyAreaTitle title={t("bookmarks.title")} />
      </div>
      <div className="flex w-full">
        <Tab
          title={"Content"}
          isActive={tab === Tabs.CONTENT}
          onClick={() => setTab(Tabs.CONTENT)}
        />
        {shouldRenderCommunityBookmarks && (
          <Tab
            title={"Community"}
            isActive={tab === Tabs.COMMUNITY}
            onClick={() => setTab(Tabs.COMMUNITY)}
          />
        )}
      </div>
      {fullTabsList[tab]}
    </div>
  );
}
