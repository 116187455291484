import { CoachContent } from "../../../../../@types";
import { CoachListItem } from "../../../coaches";



export function CoachesPreview({coaches} : {coaches: CoachContent[]}) {

    console.log("Got coaches: ", coaches);

    return (
        <div className="grid grid-cols-2">
            {coaches.map((c:CoachContent) => <CoachListItem key={c.platformContentId} coach={c}/>)}
        </div>
    )



}