import { BookedCoaching } from ".";



export function BookedCoachingPrivateWrap() {

    return (
        <div className="flex w-full justify-center">
            <div className="w-1/3">
                <BookedCoaching/>
            </div>
        </div>
    )


}