import { Dispatch, SetStateAction, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { UseMutationResult } from 'react-query';


export function TextEditor({ submitPost, editPost, topicId, postId, value, setValue, defaultContent, placeholder = "", setError }:
    {
        submitPost: UseMutationResult<any, unknown, { topicId: number; postContent: string; }, unknown>,
        editPost: UseMutationResult<any, unknown, { postId: number; updatedContent: string; }, unknown>,
        topicId: number,
        postId: number | null,
        defaultContent: string | null,
        placeholder?: string,
        value: string,
        setValue: Dispatch<SetStateAction<string>>,
        setError: string
    }) {


    const handleSubmit = () => {
        if(postId) {
            editPost.mutate({postId, updatedContent: value});
        } else {
            submitPost.mutate({topicId, postContent: value});
        }
    }

    return (
        <div className='w-full'>
            <textarea rows={3} value={value.length > 0 ? value: defaultContent || ""} onChange={(e) => setValue(e?.target?.value)} className="px-4 py-3 border border-Grey rounded-lg w-full" placeholder={placeholder} />
            <div className='md:flex md:justify-end'>
            {setError && <p className="text-red-500 mt-2 mr-5">{setError}</p>}
                <button className='w-full md:w-fit border border-black rounded-lg py-1.5 px-4' onClick={handleSubmit}>Reply</button>
            </div>
        </div>
    );

}