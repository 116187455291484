import { createContext, ReactNode, useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  FormattedContent,
  IContentWithProgress,
  IuseContentAndProgress,
  IUser,
} from "../@types";
import { ContentType } from "../enums";
import {
  getContentById,
  getUserContentAndProgressTree,
} from "../services/content.service";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "./AuthContext";

const bootstrapStateFromUrl = (location: Location) => {
  if (location.pathname.split("/")[1] !== "phase") return [];
  const phaseId = location.pathname.split("/")[2];
  const milestoneId = location.pathname.split("/")[4];
  const milestoneKeyWord = location.pathname.split("/")[3];
  const contentKeyWord = location.pathname.split("/")[5];
  const contentId = location.pathname.split("/")[6];
  return [phaseId, milestoneId, contentId, milestoneKeyWord, contentKeyWord];
};

const manageApplicationReload = ({
  user,
  location,
  navigate,
  phases,
  milestones,
}: {
  user: IUser | null;
  location: Location;
  navigate: NavigateFunction;
  phases: IContentWithProgress[] | null;
  milestones: IContentWithProgress[] | null;
}) => {
  if (location.pathname == "" || location.pathname == "/") {
    const journeyId = user?.userDetails.journeyId;
    const lastStateString = localStorage.getItem(`lastState-${user?.id}`);
    const lastState = lastStateString ? JSON.parse(lastStateString) : null;
    if (lastState) {
      let url = `/phase/${lastState.phase}`;
      if (lastState.milestone) url += `/milestone/${lastState.milestone}`;
      if (lastState.content) url += `/content/${lastState.content}`;
      navigate(url);
      return;
    }
    if (!phases) return;
    const phaseId = phases[0]?.content?.platformContentId;
    console.log("🚀 ~ phaseId:", phaseId)
    const phaseMilestones = milestones?.filter((ms: IContentWithProgress) =>
      phases[0].content.childContentIds.includes(ms.content.platformContentId)
    );
    const milestone = phaseMilestones?.sort(
      (ms1: IContentWithProgress, ms2: IContentWithProgress) =>
        ms1.content.orderForProgress[journeyId ?? ""] -
        ms2.content.orderForProgress[journeyId ?? ""]
    )[0];
    console.log("🚀 ~ milestone:", milestone)


    navigate(
      `/phase/${phaseId}/milestone/${milestone?.content.platformContentId}`
    );
  }
};

export const ContentAndProgressContext = createContext<{
  journey: IContentWithProgress | null;
  setJourney: React.Dispatch<React.SetStateAction<IContentWithProgress | null>>;
  phases: IContentWithProgress[] | null;
  setPhases: React.Dispatch<
    React.SetStateAction<IContentWithProgress[] | null>
  >;
  milestones: IContentWithProgress[] | null;
  setMilestones: React.Dispatch<
    React.SetStateAction<IContentWithProgress[] | null>
  >;
  activePhase: IContentWithProgress | null;
  setActivePhase: React.Dispatch<
    React.SetStateAction<IContentWithProgress | null>
  >;
  currentPhaseMilestones: IContentWithProgress[] | null;
  setCurrentPhaseMilestones: React.Dispatch<
    React.SetStateAction<IContentWithProgress[] | null>
  >;
  activeMilestone: IContentWithProgress | null;
  setActiveMilestone: React.Dispatch<
    React.SetStateAction<IContentWithProgress | null>
  >;
  activeMilestoneContent: FormattedContent | null;
  setActiveMilestoneContent: React.Dispatch<
    React.SetStateAction<FormattedContent | null>
  >;
  openedContent: string | null;
  setOpenedContent: React.Dispatch<React.SetStateAction<string | null>>;
}>({
  journey: null,
  setJourney: () => null,
  phases: null,
  setPhases: () => null,
  milestones: null,
  setMilestones: () => null,
  activePhase: null,
  setActivePhase: () => null,
  currentPhaseMilestones: null,
  setCurrentPhaseMilestones: () => null,
  activeMilestone: null,
  setActiveMilestone: () => null,
  activeMilestoneContent: null,
  setActiveMilestoneContent: () => null,
  openedContent: null,
  setOpenedContent: () => null,
});

export function useContentAndProgress(): IuseContentAndProgress {
  const {
    journey,
    setJourney,
    phases,
    setPhases,
    milestones,
    setMilestones,
    activePhase,
    setActivePhase,
    currentPhaseMilestones,
    setCurrentPhaseMilestones,
    activeMilestone,
    setActiveMilestone,
    activeMilestoneContent,
    setActiveMilestoneContent,
    openedContent,
    setOpenedContent,
  } = useContext(ContentAndProgressContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const journeyId = user?.userDetails.journeyId;

  useEffect(() => {
    manageApplicationReload({
      user: user,
      navigate,
      location,
      phases,
      milestones,
    });
  }, [location.pathname, phases, milestones, currentPhaseMilestones]);

  useEffect(() => {
    updateStateFromUrl();
  }, [location.pathname, phases, activePhase, currentPhaseMilestones]);

  const updateStateFromUrl = () => {
    const [
      phaseId,
      milestoneId,
      contentId,
      milestoneKeyWord,
      contentKeyWord,
    ] = bootstrapStateFromUrl(location);

    if (!location.pathname.startsWith("/phase")) {
      return;
    }

    if (phases?.length === 0 || milestones?.length === 0) {
      return;
    }

    let isValidMilestoneKey = true;
    let isValidContentKey = true;

    // Check if milestoneKeyword is defined and matches "milestone"
    if (milestoneKeyWord && milestoneKeyWord !== "milestone") {
      isValidMilestoneKey = false;
    }

    // Check if contentKeyword is defined and matches "content"
    if (contentKeyWord && contentKeyWord !== "content") {
      isValidContentKey = false;
    }
    
    if (
      (!phaseId && phaseId !== undefined) ||
      (!milestoneId && milestoneId !== undefined) ||
      (!contentId && contentId !== undefined) ||
      !isValidMilestoneKey ||
      !isValidContentKey
    ) {

      // Redirect to 404 page if any ID is missing
      navigate("/notfound");
      return;
    }

    const validPhase = phases?.some(
      (p) => p.content.platformContentId === phaseId
    );

    const validMilestone = milestones?.some(
      (p) => p.content.platformContentId === milestoneId
    );

    if (!validMilestone && validMilestone !== undefined) {
      // Redirect to 403 page if phase ID is invalid
      navigate("/notfound");
      return;
    }
    
    if (!validPhase && validPhase !== undefined) {

      // Redirect to 403 page if phase ID is invalid
      navigate("/notfound");
      return;
    }

    const newPhase = phases?.find(
      (p) => p.content.platformContentId === phaseId
    );
    setActivePhase(newPhase || null);
    const newMilestone =
      milestones?.find((m) => m.content.platformContentId === milestoneId) ||
      (currentPhaseMilestones ? currentPhaseMilestones[0] : null);

    setActiveMilestone(newMilestone);
    setOpenedContent(contentId);
    if (phaseId && phaseId !== "undefined") {

      localStorage.setItem(
        `lastState-${user?.id}`,
        JSON.stringify({
          phase: phaseId,
          milestone: milestoneId,
          content: contentId,
        })
      );
    }
  };

  const updateProgress = useCallback((data: IContentWithProgress[]) => {
    setJourney(
      data.filter(
        (d: IContentWithProgress) =>
          d.content.contentType === ContentType.JOURNEY
      )[0]
    );
    const phases = data
      .filter(
        (d: IContentWithProgress) => d.content.contentType === ContentType.PHASE
      )
      .sort();
    setPhases(
      phases.sort(
        (a, b) =>
          a.content?.orderForProgress[journeyId ?? ""] -
          b.content?.orderForProgress[journeyId ?? ""]
      )
    );
    setMilestones(
      data.filter(
        (d: IContentWithProgress) =>
          d.content.contentType === ContentType.MILESTONE
      )
    );
  }, []);

  const { data: userProgress, refetch: updateUserProgressTree } = useQuery(
    ["userProgress"],
    () => getUserContentAndProgressTree({}),
    {
      onSuccess: (data: IContentWithProgress[]) => {
        updateProgress(data);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          navigate("/notfound");
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
      retry: false, // Disable automatic retries
    }
  );

  useEffect(() => {
    const phaseMilestones =
      userProgress?.filter(
        (d: IContentWithProgress) =>
          d.content.contentType === "milestone" &&
          activePhase?.content.childContentIds.includes(
            d.content.platformContentId
          )
      ) || null;
    const sortedMilestones =
      phaseMilestones?.sort(
        (a, b) =>
          a.content?.orderForProgress[journeyId ?? ""] -
          b.content?.orderForProgress[journeyId ?? ""]
      ) || null;
    setCurrentPhaseMilestones(sortedMilestones);
    if (!activeMilestone)
      setActiveMilestone(sortedMilestones ? sortedMilestones[0] : null);
  }, [activePhase]);

  useQuery(
    ["milestone", activeMilestone],
    () =>
      getContentById({
        platformContentId: activeMilestone?.content.platformContentId,
      }),
    {
      onSuccess: (data: FormattedContent) => {
        setActiveMilestoneContent(data);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          navigate("/forbidden"); // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          navigate("/notfound");
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
      retry: false, // Disable automatic retries
    }
  );

  return {
    journey,
    setJourney,
    phases,
    setPhases,
    milestones,
    setMilestones,
    activePhase,
    setActivePhase,
    currentPhaseMilestones,
    setCurrentPhaseMilestones,
    activeMilestone,
    setActiveMilestone,
    activeMilestoneContent,
    setActiveMilestoneContent,
    openedContent,
    setOpenedContent,
    updateUserProgressTree,
  };
}

export function UserProgressContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [journey, setJourney] = useState<IContentWithProgress | null>(null);
  const [phases, setPhases] = useState<IContentWithProgress[] | null>(null);
  const [milestones, setMilestones] = useState<IContentWithProgress[] | null>(
    null
  );
  const [activePhase, setActivePhase] = useState<IContentWithProgress | null>(
    null
  );
  const [currentPhaseMilestones, setCurrentPhaseMilestones] = useState<
    IContentWithProgress[] | null
  >(null);
  const [activeMilestone, setActiveMilestone] =
    useState<IContentWithProgress | null>(null);
  const [activeMilestoneContent, setActiveMilestoneContent] =
    useState<FormattedContent | null>(null);
  const [openedContent, setOpenedContent] = useState<string | null>(null);

  return (
    <ContentAndProgressContext.Provider
      value={{
        journey,
        setJourney,
        phases,
        setPhases,
        milestones,
        setMilestones,
        activePhase,
        setActivePhase,
        currentPhaseMilestones,
        setCurrentPhaseMilestones,
        activeMilestone,
        setActiveMilestone,
        activeMilestoneContent,
        setActiveMilestoneContent,
        openedContent,
        setOpenedContent,
      }}
    >
      {children}
    </ContentAndProgressContext.Provider>
  );
}
