import { useQuery } from "react-query";
import { PopupWrapper } from "../../../../../../components/popupWrapper";
import { getAvailableAvatars } from "../../../../../../services/users.service";
import { UserAvatar } from "../../../../../../@types";
import { SyntheticEvent, useState } from "react";



export function AvatarPicker({ isOpen, onCancel, onConfirm }: { isOpen: boolean, onCancel: any, onConfirm: any }) {


    const { data: avatars } = useQuery(['avatars'], getAvailableAvatars);

    const [hovered, setHovered] = useState<number | null>(null);

    return (
        <PopupWrapper isOpen={isOpen} >
            <div className="bg-white h-[90vh] bg-opacity-100 w-1/2 py-2 rounded-lg">
                <div className="text-Yellow-default p-3 px-5 text-xl shadow-sm flex justify-between">
                    <h1>Pick your avatar</h1>
                    <div className="cursor-pointer" onClick={onCancel}>Close</div>
                </div>
                <div className="max-h-[80vh] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-3">
                    {avatars?.map((a: UserAvatar, idx) =>
                        <div className="relative flex justify-center my-2 w-fit justify-self-center"
                            onMouseEnter={() => setHovered(idx)}
                            onMouseLeave={(e: SyntheticEvent) => {
                                e.stopPropagation();
                                setHovered(null)
                            }}

                        >
                            <img className={`${hovered == idx ? 'opacity-80' : ''} relative h-[190px] w-[190px]`} src={a.signedUrl} />
                            {hovered == idx ? <div
                                className="bg-white cursor-pointer border rounded hover:border-2 hover:border-Yellow-default absolute m-auto bottom-0 z-50 p-2"
                                onMouseEnter={(e) => e.preventDefault()}
                                onClick={() => onConfirm(a.id)}>Pick avatar</div> : null}

                        </div>)}
                </div>
            </div>
        </PopupWrapper>
    )

} 