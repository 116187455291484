import { useMutation, useQuery } from "react-query";
import {
  createTopic,
  fetchLatestTopics,
  getFeedback,
  getOwnTopics,
} from "../../../services/discourse.service";
import { ICommunityTopic } from "../../../@types";
import { CommunityTopic } from "./Topic";
import { useLocation, useNavigate } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Experiences } from "./Experiences";
import { UsersPosts } from "./Topic/UsersPosts";
import { AuthContext } from "../../../contexts/AuthContext";
import { useOnPageMount } from "../../../hooks/useOnPageMount";

export function Community() {

  useOnPageMount();
  const { t } = useTranslation("community");
  
  const [newPostTitle, setNewPostTitle] = useState("");
  const [newPostQuestion, setNewPostQuestion] = useState("");
  const [isNewPosts, setIsNewPosts] = useState(true);
  const [postError, setPostError] = useState<string | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   navigate("/community");
  // }, [isNewPosts]);

  const { data: feedback, refetch: refetchFeedback } = useQuery(
    ["feedback"],
    () => getFeedback()
  );

  const openTopic = parseInt(location.pathname.split("/")[3]);

  const { data: latestTopics, refetch } = useQuery(
    "latestTopics",
    fetchLatestTopics,
    {
      onSuccess: (data: any) => null,
    }
  );

  const submitTopicMutation = useMutation(
    () => createTopic({ title: newPostTitle, content: newPostQuestion }),
    {
      onSuccess: () => {
        setNewPostTitle("");
        setNewPostQuestion("");
        setPostError(null)
        refetch();
      },

      onError: (error: any) => {
        const status = error.response?.status;
        let errorMessage;
  
        if (status === 422 || status === 429) {
          errorMessage = t(`${status}`);
        } else {
          errorMessage = t('default');
        }
  
        setPostError(errorMessage);
      },
    }
  );

  return (
    <div className="pt-6 px-6 md:px-9 md:pt-9 lg:px-72 xl:px-88 xl:px-96 flex flex-col w-full h-full">
      <h1 className="text-Yellow-default text-lg font-semibold mb-4 md:mb-5 ">
        {t("page-title")}
      </h1>
      <div>
        <Experiences />
      </div>

      <div className="bg-Grey-light-3 rounded-lg p-6 my-6">
        <p className="text-lg text-Grey-dark font-bold mb-4 ">{t("forum")}</p>
        <p className="font-semibold mb-1 ">{t("title")}</p>
        <input
          className="mb-4 w-full border border-black py-3 px-4 rounded-lg"
          placeholder={t("title-placeholder") || ""}
          value={newPostTitle}
          onChange={(e) => setNewPostTitle(e?.target?.value)}
        />
        <p className="font-semibold mb-1 ">{t("question")}</p>
        <textarea
          className="mb-4 w-full border border-black py-3 px-4 rounded-lg"
          placeholder={t("question-placeholder") || ""}
          value={newPostQuestion}
          onChange={(e) => setNewPostQuestion(e?.target?.value)}
          rows={4}
        />
        <div className="flex justify-end">
        {postError && <p className="text-red-500 mt-2 mr-5">{postError}</p>}

          <button
            onClick={() => submitTopicMutation.mutate()}
            className="border border-black bg-white px-4 py-1.5 font-semibold rounded-lg w-full md:w-fit"
          >
            {t("submit-post")}
          </button>
        </div>
      </div>

      <div className="mb-3">
        <NavigationBar isNewPosts={isNewPosts} setIsNewPosts={setIsNewPosts} />
      </div>
      <>
        {isNewPosts ? (
          <>
            {latestTopics?.topic_list?.topics?.map(
              (lt: ICommunityTopic, idx: number) => {
                const fb = feedback?.find((f) => f.topicId === lt.id);
                return (
                  <div onClick={() => navigate(`/community/topic/${lt.id}`)}>
                    <CommunityTopic
                      key={lt.id}
                      users={latestTopics?.users}
                      topic={lt}
                      open={openTopic === lt.id}
                      feedback={fb}
                      refetchFeedback={refetchFeedback}
                    />
                  </div>
                );
              }
            )}
          </>
        ) : (
          <UsersPosts feedback={feedback} />
        )}
      </>
    </div>
  );
}

export function NavigationBar({
  isNewPosts,
  setIsNewPosts,
}: {
  isNewPosts: boolean;
  setIsNewPosts: (is: boolean) => void;
}) {
  const { t } = useTranslation("community");

  return (
    <div className="flex flex-col w-full mb-3">
      <div className="flex w-full justify-start">
        <div className="min-w-fit">
          <div
            onClick={() => setIsNewPosts(true)}
            className={`py-3 px-4 cursor-pointer ${
              isNewPosts ? "font-semibold" : ""
            }`}
          >
            {t("new-posts")}
          </div>
          <div
            className={
              isNewPosts
                ? `flex h-[2px] w-full bg-Yellow-default`
                : `flex h-[2px] w-full bg-Grey-light-2`
            }
          />
        </div>
        <div className="min-w-fit">
          <div
            onClick={() => setIsNewPosts(false)}
            className={`py-3 px-4 cursor-pointer ${
              !isNewPosts ? "font-semibold" : ""
            }`}
          >
            {t("your-posts")}
          </div>
          <div
            className={
              !isNewPosts
                ? `flex h-[2px] w-full bg-Yellow-default`
                : `flex h-[2px] w-full bg-Grey-light-2`
            }
          />
        </div>
        <div className="flex flex-col w-full items-center justify-end">
          <div className="flex h-[2px] w-full bg-Grey-light-2" />
        </div>
      </div>
    </div>
  );
}
