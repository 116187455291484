import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as LeftArrow } from "../../../../assets/left-arrow-calendar.svg";
import { ReactComponent as RigthArrow } from "../../../../assets/rigth-arrow-calendar.svg";
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getContentById, getContentByProperty } from '../../../../services/content.service';
import { ContentType } from '../../../../enums';
import { ExperienceContent, IContent } from '../../../../@types';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useEffect, useState } from 'react';


const Story = ({content}: {content : IContent}) => {
    const navigate = useNavigate();

    const { data : experience} = useQuery(['experience', content], () => getContentById({platformContentId: content.platformContentId, projection: 'min'}));

    return (
       <div className="mr-4" onClick={()=> navigate(`/community/experience/${content?.platformContentId}`)}>
            <img className="object-cover rounded-t-lg" src={`https:${(experience as ExperienceContent)?.image}`} alt="about" />
            <div className="h-[230px] p-2 border border-Grey-light-2 border-t-0 rounded-b-lg overflow-hidden">
                <p className="text-sm text-Grey-warm font-medium">{(experience as ExperienceContent)?.authors[0]?.givenName} {(experience as ExperienceContent)?.authors[0]?.surname}</p>
                <p className="text-sm text-Grey-warm font-medium mb-2">{(experience as ExperienceContent)?.authorExternal}</p>
                <p className=" font-semibold mb-1">{(experience as ExperienceContent)?.title}</p>
                <p className="text-sm font-medium text-ellipsis  ...">{(experience as ExperienceContent)?.teaserText.slice(0, 100)}</p>
            </div>
        </div>
    )
}


export function Experiences() {

    const { t } = useTranslation('community');

    const [slidesNumber, setSlidesNumber] = useState(3)

    const {data: experiences} = useQuery(['experiences'], ()=> getContentByProperty({contentType: ContentType.EXPERIENCE}));

    const windowSize = useWindowSize();

    useEffect(() => {
        const width = windowSize || 1440;

        if (width >= 768 && slidesNumber !== 3) {
            setSlidesNumber(3)
        } else if (width < 768 && width >= 640 && slidesNumber !== 2) {
            setSlidesNumber(2)
        } else if (width < 640 && slidesNumber !== 1) {
            setSlidesNumber(1)
        }
    }, [windowSize])

    return (
        <>
            <p className="text-lg text-Grey-dark font-bold mb-6 ">{t("restart-experiences")}</p>
            <div className="w-full relative">
                <CarouselProvider
                    naturalSlideWidth={5}
                    naturalSlideHeight={10}
                    totalSlides={experiences?.length || 0}
                    visibleSlides={slidesNumber}
                    isIntrinsicHeight
                >
                    <ButtonBack><button className="absolute top-[100px] left-0 lg:left-[-15px] z-10 bg-white border border-black rounded-lg flex justify-center items-center"><LeftArrow className="h-6 w-6 p-2" /></button></ButtonBack>
                    <Slider>
                        {experiences?.map((p : IContent, idx: number) => <Slide key={idx} index={idx}><Story content={p}/></Slide>)}
                    </Slider>
                    <ButtonNext><button className="absolute top-[100px] right-[16px] lg:right-0 z-10 bg-white border border-black rounded-lg flex justify-center items-center"><RigthArrow className="h-6 w-6 p-2" /></button></ButtonNext>
                </CarouselProvider>
            </div>
        </>
    )
}