import { CookiesProvider } from "react-cookie";
import { CookieConsentBanner } from "./pages/private/legal/CookieConsent";
import { WrapperGuard } from "./guards";
import Public, { PUBLIC_PAGES, getLocation } from "./pages/public";
import { PrivateRoutes } from "./pages/private";
import { useCookieConsentBanner } from "./hooks/useCookieConsentBanner";
import { useContext, useEffect, useState } from "react";
import { useLanguage } from "./hooks/useLanguage";
import {
  useInterceptRequest,
  useInterceptorResponse,
} from "./hooks/useInterceptRequest";
import { AuthContext } from "./contexts/AuthContext";
import { CircularProgress } from "@mui/material";

export function AppContainer() {
  const { user, setUser } = useContext(AuthContext);

  const [displayCookieConsent, setDisplayCookieConsent] = useState(false);
  useCookieConsentBanner({
    displayCookieConsent: () => setDisplayCookieConsent(true),
    hideCookieConsent: () => setDisplayCookieConsent(false),
  });

  useLanguage();

  useEffect(() => {
    const urlPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    const voucherCode = searchParams.get("code");

    if (
      urlPath.includes("/activate") &&
      voucherCode &&
      !localStorage.getItem("accessToken")
    ) {
      localStorage.setItem("pendingVoucherCode", voucherCode);

      window.location.href = "/signup";

    }
  }, []);

  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("pendingVoucherCode");
    window.location.href = "/";
  };

  useInterceptRequest(user);

  useInterceptorResponse(user, logoutUser);

  useEffect(() => {
    if (user) {
      setDisplayCookieConsent(false);
    }
  }, [user]);

  const [showLoginSpinner, setShowLoginSpinner] = useState(true);
  
  useEffect(() => {
    const loginDelayTimeout = setTimeout(() => {
      setShowLoginSpinner(false);
    }, 500); // Adjust the delay time as needed

    return () => {
      clearTimeout(loginDelayTimeout);
    };
  }, []);

  if (showLoginSpinner) {
    // Show login spinner with delay
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return (
    <CookiesProvider>
      <CookieConsentBanner
        showBanner={displayCookieConsent}
        closeBanner={() => setDisplayCookieConsent(false)}
      />
      <WrapperGuard
        accessCondition={!!user}
        guardView={<Public />}
        dependencies={[!!user]}
      >
        <PrivateRoutes />
      </WrapperGuard>
    </CookiesProvider>
  );
}
