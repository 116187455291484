import { useEffect } from "react";



export function useOnPageMount() {

    useEffect(()=> {
        window.scrollTo(0,0);
    },[])


}