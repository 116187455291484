import { ProgressBarProps } from "./types"

export default function ProgressBar({ percentage, progressColor }: ProgressBarProps) {
    return (
        <div className="h-1 w-full bg-white rounded-2xl	">
            <div style={{ width: percentage ? `${percentage}%` : 0 }} className={`h-1 ${progressColor}  rounded-2xl`}></div>
        </div>
    )
}

