import { useQuery } from "react-query";
import { getContentById } from "../services/content.service";
import { getCookiePolicy, getPrivacyStatement, getTermsAndConditions } from "../services/legal.service";
import {useTranslation} from 'react-i18next';
import { BusinessInfoContent } from "../@types";



export function useLatestLegal() {
    const {i18n} = useTranslation();

    const {data: latestTermsAndConditionsVersion} = useQuery("latestTermsAndConditionsVersion", getTermsAndConditions);
    const {data: latestPrivacyStatementVersion} = useQuery("latestPrivacyStatementVersion", getPrivacyStatement);
    const { data: latestCookiePolicyVersion} = useQuery("latestCookiePolicyVersion", getCookiePolicy);
    
    const {data: latestTermsAndConditions} = useQuery(['latestTermsAndConditions',latestTermsAndConditionsVersion, i18n.language], () => getContentById({id: latestTermsAndConditionsVersion?.contentEntity.id}));
    const {data: latestPrivacyStatement} = useQuery(['latestPrivacyStatement',latestPrivacyStatementVersion, i18n.language], () => getContentById({id: latestPrivacyStatementVersion?.contentEntity.id}));
    const {data: latestCookiePolicy} = useQuery(['latestPrivacyStatement',latestCookiePolicyVersion, i18n.language], () => getContentById({id: latestCookiePolicyVersion?.contentEntity.id}));
    

    return {
        latestTermsAndConditions: latestTermsAndConditions as BusinessInfoContent,
        latestPrivacyStatement: latestPrivacyStatement as BusinessInfoContent,
        latestCookiePolicy: latestCookiePolicy as BusinessInfoContent
    }

}