import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { IUser } from "../../@types";
import { linkedinSignup } from "../../services/auth.service";
import { LegalRequirements } from "./LegalRequirements";
import { AuthContext } from "../../contexts/AuthContext";

const bootsrapUserData = (): {
  displayName: string | null;
  email: string | null;
  token: string | null;
} => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return {
    displayName: urlSearchParams.get("user"),
    email: urlSearchParams.get("email"),
    token: urlSearchParams.get("token"),
  };
};

export function LinkedinSignup() {
  const userData = bootsrapUserData();

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [privacyStatementAccepted, setPrivacyStatementAccepted] =
    useState(false);
  const { setUser } = useContext(AuthContext);

  const legalsAccepted = termsAndConditionsAccepted && privacyStatementAccepted;

  const locale = localStorage.getItem("language")
  
  const [signupInfo, setSignupInfo] = useState<Partial<IUser> | null>(null);

  const registerAccountMutation = useMutation(linkedinSignup, {
    onSuccess: (response) => {
      setUser(response.data);
      try {
        localStorage.setItem("accessToken", response.data.token);
       
      } catch (error) {
        console.error("Error storing token in local storage:", error);
      }
      // setSignupInfo(response.data);
      setUser(response.data.user);
      localStorage.removeItem("pendingVoucherCode");
      window.location.href = '/'
      // setTimeout(() => {
      //     window.location.href = '/';
      // }, 2000)
    },
  });
  
  const voucherCode = localStorage.getItem("pendingVoucherCode");
  console.log("🚀 ~ LinkedinSignup ~ voucherCode:", voucherCode)


  return (
    <div className="p-5 border rounded-md shadow-md">
      {signupInfo ? (
        <>
          <div className="text-green-600 text-2xl">
            Das hat geklappt
            <span className="text-zinc-500">{signupInfo.displayName}</span>!
          </div>
          <div className="mt-2">Deine Registrierung ist abgeschlossen</div>
          <div className="mt-2 text-sm text-zinc-500">
            Wir werden Dich gleich zum Login weiterleiten...
          </div>
        </>
      ) : (
        <>
          <h1 className="text-xl ">
            Wir werden einen Account mit den folgenden Daten anlegen{" "}
          </h1>
          <div className="mt-2">Name: {userData.displayName}</div>
          <div className="mt-2">Email: {userData.email}</div>
          <div className="mt-2">Authentifizierung: Linkedin</div>
          <div className="mt-4 text-sm">
            Um mit der Registrierung fortzufahren prüfe und bestätige bitte
            Folgendes
          </div>

          <LegalRequirements
            termsAndConditionsAccepted={termsAndConditionsAccepted}
            privacyStatementAccepted={privacyStatementAccepted}
            setTermsAndConditionsAccepted={setTermsAndConditionsAccepted}
            setPrivacyStatementAccepted={setPrivacyStatementAccepted}
          />

          <button
            onClick={() => registerAccountMutation.mutate({token: userData.token, voucherCode: voucherCode, locale: locale})}
            disabled={!legalsAccepted}
            className={`mt-6 p-2 rounded border-cyan-600 bg-cyan-600 border w-full text-white ${
              !legalsAccepted
                ? "border-cyan-200 bg-cyan-200 cursor-not-allowed"
                : "  hover:bg-white hover:text-cyan-600"
            }  `}
          >
            Registrieren
          </button>
        </>
      )}
    </div>
  );
}
