import { useQuery } from "react-query";
import { regeneratePartnerCVCheck } from "../../services/coaching.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const RegeneratePartnerCVCheck = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false); // Flag to track if email has been sent

  const {t} = useTranslation("coaches")

  const urlParams = new URLSearchParams(window.location.search);
  const s3key = urlParams.get("s3key") || ""; // Fallback to empty string if s3key is null

  const { isLoading, isError, refetch } = useQuery(
    ["regenerateCV", s3key],
    () => {
      if (!s3key || emailSent) { // Check if s3key is empty or email has already been sent
        throw new Error("Invalid s3key or email already sent");
      }
      return regeneratePartnerCVCheck(s3key);
    },
    {
      enabled: !!s3key && !emailSent, // Enable the query only if s3key is present and email hasn't been sent
      onSuccess: () => {
        setStatusMessage("Email sent successfully!");
        setEmailSent(true); // Set the flag to true after successfully sending the email
      },
      onError: () => setStatusMessage("Error sending email. Please try again."),
    }
  );

  // Handle the data response if needed
  useEffect(() => {
    // You can set any logic here based on the response data if necessary
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="text-center">
        {isLoading && <p className="text-blue-500 text-2xl">{t("regenerate-partner-cvcheck-email.sending")}</p>}
        {!isLoading && emailSent && <p className="text-green-700 text-2xl">{t("regenerate-partner-cvcheck-email.success")}</p>}
        {!isLoading && !emailSent && isError && <p className="text-red-500 text-2xl">{t("regenerate-partner-cvcheck-email.error")}</p>}
      </div>
      {/* Add additional components or elements if necessary */}
    </div>
  );
  
};

export default RegeneratePartnerCVCheck;
