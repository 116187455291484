import { useState } from "react";
import { ArticleContent, FormattedContent } from "../../../../../../@types";
import { ReactComponent as DownArrow } from "../../../../../../assets/down-arrow.svg";
import { ReactComponent as UpArrow } from "../../../../../../assets/up-arrow.svg";
import { ExternalContentTeaser } from "../../../../../../components/externalContentTeaser";
import { useQuery } from "react-query";
import { getBookmarkedContent } from "../../../../../../services/content.service";
import { useTranslation } from "react-i18next";

export function ContentBookmarks() {
  const { t } = useTranslation("myArea");

  const [bookmarkedContent, setBookmarkContent] = useState<{
    [key: string]: FormattedContent[];
  } | null>(null);
  const [loading, setLoading] = useState(true);

  useQuery(["bookmarkedContent"], () => getBookmarkedContent(), {
    onSuccess: async (data) => {
      setBookmarkContent(data);
      setCollapsedPhases([...Object.keys(data).map((k) => k)]);
      setLoading(false);
    },
  });

  const [collapsedPhases, setCollapsedPhases] = useState<string[]>([]);
  const handleCollapse = (k: string) => {
    setCollapsedPhases((current) => [...current, k]);
  };

  const handleDropDown = (k: string) => {
    setCollapsedPhases((current) => {
      const newArr = current.filter((v) => v !== k);
      return newArr;
    });
  };

  const handleCollapseOrExpand = (k: string) => {
    if (collapsedPhases.includes(k)) {
      handleDropDown(k);
      // Collapse the content
    } else {
      handleCollapse(k); // Expand the content
    }
  };

  return (
    <div>
      {loading ? <div>{t("bookmarks.loading")}</div> : null}
      {bookmarkedContent ? (
        <>
          {Object.entries(bookmarkedContent)?.map(([k, v]) => (
            <div key={k}>
              <div className="mb-2">
                <hr className="border-Grey-light" />
                <div className="flex items-center font-bold w-full justify-between text-Grey-dark py-3">
                  <h2
                    className="cursor-pointer"
                    onClick={() => handleCollapseOrExpand(k)}
                  >
                    {k}
                  </h2>
                  {collapsedPhases.includes(k) ? (
                    <DownArrow
                      className="cursor-pointer"
                      onClick={() => handleCollapseOrExpand(k)}
                    />
                  ) : (
                    <UpArrow
                      className="cursor-pointer"
                      onClick={() => handleCollapseOrExpand(k)}
                    />
                  )}
                </div>
              </div>
              {!collapsedPhases.includes(k)
                ? (v as ArticleContent[])
                    ?.filter((bc) => bc)
                    ?.map((bc: ArticleContent) => (
                      <ExternalContentTeaser key={bc.id} content={bc} />
                    ))
                : null}
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}
