import { useEffect, useRef, useState } from "react";
import { ReactComponent as UploadIcon } from '../../assets/upload.svg';
import { useFiles } from "../../hooks/useFiles";
import { ReactComponent as DeleteIcon } from '../../assets/close.svg';
import { useTranslation } from "react-i18next";


// drag drop file component
export function DragAndDropUploader({ singleFile, filesSetter, currentFile }: { singleFile?: boolean, filesSetter?: (files: any) => void, currentFile?: any }) {

  const filesHook = useFiles({ single: !!singleFile });
const {t} = useTranslation('common')
  // drag state
  const [dragActive, setDragActive] = useState(false);

  // ref
  const inputRef = useRef<any>(null);

  useEffect(()=> {
    if(currentFile?.length > 0) filesHook.addFiles([...currentFile]);
  },[])

  useEffect(() => {
    if (filesSetter) filesSetter(filesHook.files);
  }, [filesHook.files])

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      filesHook.addFiles(e.dataTransfer.files);
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files) {
      filesHook.addFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (<div className="flex flex-col w-full items-center justify-center">
    <form id="form-file-upload" className="w-full max-w-full text-center relative" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} className={'hidden'} type="file" id="input-file-upload" multiple={!singleFile} onChange={handleChange} />
      {
        filesHook.files.length == 0 ?
          <div className="p-5 ">
            <label className={`h-full flex flex-col w-full justify-center items-center content-center border py-10  rounded-lg border-Grey-metallic  ${dragActive ? "bg-zinc-100" : ""} `} id="label-file-upload" htmlFor="input-file-upload" >

              <UploadIcon className={`${dragActive ? 'animate-bounce' : ''} `} />
              <p className="font-bold text-lg">{t('drag-and-drop')}</p>
              <p className="text-xs">{t('or')}</p>
              <button className="cursor-pointer text-sm bg-opacity-0 hover:underline" onClick={onButtonClick}>{t('click-to-upload')}</button>

            </label>
            {dragActive && <div id="drag-file-element" className="absolute w-full h-full rounded top-0 right-0 bottom-0 left-0" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
          </div>
          :
          <div className="flex flex-col w-full items-start py-5">
            <div className="font-bold mb-2 flex w-full justify-between items-center">
              <div>
              {filesHook?.files[0].name} {(filesHook?.files[0].size / 1024 ** 2).toFixed(2)} mb
              </div>
              
              <DeleteIcon className="cursor-pointer w-6 text-Grey-dark" onClick={()=> filesHook.removeFile(0)}/>
              
            </div>
            <div className="flex w-full rounded h-1 bg-Grey-dark"></div>
            <div className="mt-2">{t('completed')}</div>
          </div>
      }

    </form>

  </div>
  );
};