import { useState } from "react";

export type IuseFiles = {
    files: File[],
    addFiles: (newFiles: File[]) => void,
    removeFile: (idx: number) => void
}


export const useFiles = ({single}: {single?: boolean}): IuseFiles => {

    const [files, setFiles] = useState<File[]>([]);

    const addFiles = (newFiles: File[]) => {
        if(single) {
            setFiles([newFiles[0]])
            return;
        }
        setFiles([...files, ...newFiles]);
    }

    const removeFile = (idx: number) => {
        const updatedFiles = files.filter((f: File, fIdx: number) => idx != fIdx);
        setFiles([...updatedFiles]);
    }


    return { files, addFiles, removeFile };

}