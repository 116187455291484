import { CoachContent } from "../../../../../@types";
import { PopupWrapper } from "../../../../../components/popupWrapper";
import { ReactComponent as CloseIcon } from "../../../../../assets/close.svg";
import Button from "../../../../../components/button";
import { ButtonVariant } from "../../../../../components/button/types";
import { useMutation } from "react-query";
import { bookCoaching } from "../../../../../services/coaches.service";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { updateUser } from "../../../../../services/users.service";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import DatePicker from "react-datepicker";
import { getLocaleFromLanguage } from "../../../../../services/content.service";
import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";

export enum FormType {
  CLASSIC = "CLASSIC",
  EXPLORE = "EXPLORE",
  PRE = "PRE",
}

export function BookingPopup({
  close,
  isOpen,
  coachData,
  refetch,
  formType,
}: {
  close: () => void;
  isOpen: boolean;
  coachData: CoachContent | null;
  refetch: () => void;
  formType: FormType;
}) {
  const { user, setUser } = useContext(AuthContext); // Get the setUser function from your AuthContext
  const { t } = useTranslation("coaches");
  const [success, setSuccess] = useState(false);
  const monthsLocal = getLocaleFromLanguage();
  const localeMapping = {
    de: de,
    "en-US": enUS,
  };
  const { phases } = useContentAndProgress();
  console.log(phases);
  const convertedLocale = localeMapping[monthsLocal];

  const bookingMutation = useMutation(
    ["bookSession"],
    (mutationArgs: { values: any }) =>
      bookCoaching({
        coachId: (coachData as any)?.platformContentId,
        fields: mutationArgs?.values,
        formType: formType,
      }),
    {
      onSuccess: async () => {
        const updatedUserData = await updateUser();
        refetch();
        setUser(updatedUserData);
        setSuccess(true);
      },
    }
  );

  const handleSubmit = (values: any) => {
    bookingMutation.mutate({ values });
  };

  /* FORM EXPLORE */

  const initialValuesExplore = {
    currentPosition: user?.userDetails?.lastPosition || "",
    thoughts: "",
    reason: "",
    goals: "",
    inputCV: false,
    inputJobOpportunities: false,
    inputJobHappiness: false,
    input360: false,
    inputOther: false,
    otherDetails: "",
    formType: "EXPLORE",
  };

  const validationSchemaExplore = Yup.object().shape({
    goals: Yup.string()
      .required(t("exploreForm.fieldRequired") || "Field is required")
      .max(
        500,
        ({ max }) =>
          t("exploreForm.maxCharacters") ||
          "Max characters for this field reached"
      ),
    thoughts: Yup.string().max(
      500,
      ({ max }) =>
        t("exploreForm.maxCharacters") ||
        "Max characters for this field reached"
    ),
    otherDetails: Yup.string().max(
      500,
      ({ max }) =>
        t("exploreForm.maxCharacters") ||
        "Max characters for this field reached"
    ),
  });

  /* FORM CLASSIC */

  const initialValuesClassic = {
    lastPosition: user?.userDetails?.lastPosition || "",
    lastWorkingDay: user?.userDetails?.timingLastDayAtWork
      ? new Date(user?.userDetails?.timingLastDayAtWork)
      : null,
    restartDate: user?.userDetails?.timingAchieveRestart
      ? new Date(user?.userDetails?.timingAchieveRestart)
      : null,
    progressValue1: phases?.[0]?.progressValue || 0,
    progressValue2: phases?.[1]?.progressValue || 0,
    progressValue3: phases?.[2]?.progressValue || 0,
    comment: "",
    thoughts: "",
    goals: "",
    inputCV: false,
    inputLINC: false,
    input360: false,
    inputOther: false,
    otherDetails: "",
    formType: "CLASSIC",
  };

  const validationSchemaClassic = Yup.object().shape({
    goals: Yup.string()
      .required(t("classicForm.fieldRequired") || "Field is required")
      .max(
        500,
        ({ max }) =>
          t("classicForm.maxCharacters") ||
          "Max characters for this field reached"
      ),
    comment: Yup.string().max(
      500,
      ({ max }) =>
        t("classicForm.maxCharacters") ||
        "Max characters for this field reached"
    ),
    thoughts: Yup.string().max(
      500,
      ({ max }) =>
        t("classicForm.maxCharacters") ||
        "Max characters for this field reached"
    ),
    otherDetails: Yup.string().max(
      500,
      ({ max }) =>
        t("classicForm.maxCharacters") ||
        "Max characters for this field reached"
    ),
  });

  /* FORM PRE */

  const initialValuesPre = {
    currentPosition: user?.userDetails?.lastPosition || "",
    decisionUntil: null,
    thoughts: "",
    goals: "",
    inputCV: false,
    inputJobOpportunities: false,
    inputJobHappiness: false,
    inputOther: false,
    otherDetails: "",
    formType: "PRE",
  };

  const validationSchemaPre = Yup.object().shape({
    goals: Yup.string()
      .required(t("preForm.fieldRequired") || "Field is required")
      .max(
        500,
        ({ max }) =>
          t("preForm.maxCharacters") || "Max characters for this field reached"
      ),
    thoughts: Yup.string().max(
      500,
      ({ max }) =>
        t("preForm.maxCharacters") || "Max characters for this field reached"
    ),
    otherDetails: Yup.string().max(
      500,
      ({ max }) =>
        t("preForm.maxCharacters") || "Max characters for this field reached"
    ),
  });

  const maxPopupHeight = Math.round(window.innerHeight * 0.8);

  return (
    <PopupWrapper isOpen={isOpen}>
      <div
        className="bg-white rounded-md w-3/4 lg:w-1/2 p-5 overflow-y-auto max-h-screen-3/4"
        style={{ maxHeight: `${maxPopupHeight}px` }}
      >
        <div className="flex justify-between items-center text-lg mb-2">
          <div>
            {t("booking.book-session")}{" "}
            <span className="font-bold">
              {coachData?.givenName} {coachData?.surname}
            </span>
          </div>
          <CloseIcon
            onClick={() => {
              close();
              setSuccess(false);
            }}
            className="cursor-pointer"
          />
        </div>
        {success ? (
          <div className="mt-4">{t("booking.success")}</div>
        ) : (
          <>
            {formType === FormType.CLASSIC && (
              <Formik
                initialValues={initialValuesClassic}
                validationSchema={validationSchemaClassic}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <div className="">
                      <div className="flex items-center">
                        <label htmlFor="lastPosition" className="w-2/3">
                          {t("classicForm.lastPosition")}
                        </label>
                        <div className="w-1/3 flex justify-end">
                          <Field
                            type="text"
                            id="lastPosition"
                            name="lastPosition"
                            value={values.lastPosition}
                            onChange={(e: any) =>
                              setFieldValue("lastPosition", e.target.value)
                            }
                            className="rounded-md mt-1 w-full"
                            placeholder={t(
                              "classicForm.lastPositionPlaceHolder"
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <label htmlFor="lastWorkingDay" className="w-2/3">
                          {t("classicForm.lastWorkingDay")}
                        </label>
                        <div className="w-1/3 flex justify-end">
                          <DatePicker
                            id="lastWorkingDay"
                            name="lastWorkingDay"
                            selected={values?.lastWorkingDay}
                            onChange={(date: Date) =>
                              setFieldValue("lastWorkingDay", date)
                            }
                            className="rounded-md mt-1 w-full"
                            locale={convertedLocale}
                            placeholderText={
                              t("classicForm.lastWorkingDayPlaceHolder") ||
                              "Select a date"
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <label htmlFor="restartDate" className="w-2/3">
                          {t("classicForm.restartDate")}
                        </label>
                        <div className="w-1/3 flex justify-end">
                          <DatePicker
                            id="restartDate"
                            name="restartDate"
                            selected={values?.restartDate}
                            onChange={(date: Date) =>
                              setFieldValue("restartDate", date)
                            }
                            className="rounded-md mt-1 w-full"
                            locale={convertedLocale}
                            placeholderText={
                              t("classicForm.restartDatePlaceHolder") ||
                              "Select a date"
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-6">
                        <div className="">{t("classicForm.textTitle1")}</div>
                        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                          <div className="flex flex-col md:flex-row items-start md:items-center mb-2 md:mb-0">
                            <div className="flex items-center mr-2">
                              <Field
                                type="text"
                                id="progressValue1"
                                name="progressValue1"
                                value={
                                  values.progressValue1 != null
                                    ? `${parseFloat(
                                        String(values.progressValue1)
                                      ).toFixed(1)}%`
                                    : "0.0%"
                                }
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  const inputValue = parseFloat(e.target.value);
                                  let formattedValue = "";

                                  if (!isNaN(inputValue)) {
                                    const clampedValue = Math.min(
                                      Math.max(inputValue, 0),
                                      100
                                    );
                                    formattedValue = `${clampedValue.toFixed(
                                      1
                                    )}%`;
                                  } else {
                                    formattedValue = "0.0%";
                                  }

                                  setFieldValue(
                                    "progressValue1",
                                    formattedValue
                                  );
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue(
                                    "progressValue1",
                                    e.target.value
                                  );
                                }}
                                className="rounded-md w-20 mr-2 text-gray-700"
                              />

                              <h2 className="text-sm ">
                                {t("classicForm.phase1")}
                              </h2>
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row items-start md:items-center mb-2 md:mb-0">
                            <div className="flex items-center mr-2">
                              <Field
                                type="text"
                                id="progressValue2"
                                name="progressValue2"
                                value={
                                  values.progressValue2 != null
                                    ? `${parseFloat(
                                        String(values.progressValue2)
                                      ).toFixed(1)}%`
                                    : "0.0%"
                                }
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  const inputValue = parseFloat(e.target.value);
                                  let formattedValue = "";

                                  if (!isNaN(inputValue)) {
                                    const clampedValue = Math.min(
                                      Math.max(inputValue, 0),
                                      100
                                    );
                                    formattedValue = `${clampedValue.toFixed(
                                      1
                                    )}%`;
                                  } else {
                                    formattedValue = "0.0%";
                                  }

                                  setFieldValue(
                                    "progressValue2",
                                    formattedValue
                                  );
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue(
                                    "progressValue2",
                                    e.target.value
                                  );
                                }}
                                className="rounded-md w-20 mr-2 text-gray-700"
                              />

                              <h2 className="text-sm">
                                {t("classicForm.phase2")}
                              </h2>
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row items-start md:items-center mb-2 md:mb-0">
                            <div className="flex items-center mr-2">
                              <Field
                                type="text"
                                id="progressValue3"
                                name="progressValue3"
                                value={
                                  values.progressValue3 != null
                                    ? `${parseFloat(
                                        String(values.progressValue3)
                                      ).toFixed(1)}%`
                                    : "0.0%"
                                }
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  const inputValue = parseFloat(e.target.value);
                                  let formattedValue = "";

                                  if (!isNaN(inputValue)) {
                                    const clampedValue = Math.min(
                                      Math.max(inputValue, 0),
                                      100
                                    );
                                    formattedValue = `${clampedValue.toFixed(
                                      1
                                    )}%`;
                                  } else {
                                    formattedValue = "0.0%";
                                  }

                                  setFieldValue(
                                    "progressValue3",
                                    formattedValue
                                  );
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue(
                                    "progressValue3",
                                    e.target.value
                                  );
                                }}
                                className="rounded-md w-20 mr-2 text-gray-700"
                              />

                              <h2 className="text-sm">
                                {t("classicForm.phase3")}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <Field
                          as="textarea"
                          name="comment"
                          value={values.comment}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            const newValue = e.target.value.slice(0, 501); // Limit to 500 characters
                            setFieldValue("comment", newValue);
                          }}
                          maxLength={501}
                          className="w-full rounded-md h-15 mb-2 mt-2"
                          placeholder={t(
                            "classicForm.progressCommentPlaceHolder"
                          )}
                        />
                        <ErrorMessage
                          name="comment"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div> {t("classicForm.thoughtsGoals")}</div>
                      <Field
                        as="textarea"
                        name="thoughts"
                        value={values.thoughts}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const newValue = e.target.value.slice(0, 501); // Limit to 500 characters
                          setFieldValue("thoughts", newValue);
                        }}
                        className="w-full rounded-md h-15 mb-2"
                        maxLength={501}
                        placeholder={t("classicForm.thoughtsGoalsPlaceHolder")}
                      />
                      <ErrorMessage
                        name="thoughts"
                        component="div"
                        className="text-red-500"
                      />
                      <div> {t("classicForm.goalForCoachingSession")}</div>
                      <Field
                        as="textarea"
                        name="goals"
                        value={values.goals}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const newValue = e.target.value.slice(0, 501); // Limit to 500 characters
                          setFieldValue("goals", newValue);
                        }}
                        className="w-full rounded-md h-15"
                        maxLength={501}
                        placeholder={t(
                          "classicForm.goalForCoachingSessionPlaceHolder"
                        )}
                      />
                      <ErrorMessage
                        name="goals"
                        component="div"
                        className="text-red-500"
                      />
                      <div className=" mt-2">
                        {" "}
                        {t("classicForm.textTittle2")}
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div>
                          <Field
                            type="checkbox"
                            id="inputCV"
                            name="inputCV"
                            checked={values.inputCV}
                            nChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("inputCV", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputCV">
                            {t("classicForm.inputCV")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="input360"
                            name="input360"
                            checked={values.input360}
                            onChange={(e: any) =>
                              setFieldValue("input360", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="input360">
                            {t("classicForm.input360")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputLINC"
                            name="inputLINC"
                            checked={values.inputLINC}
                            onChange={(e: any) =>
                              setFieldValue("inputLINC", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputLINC">
                            {t("classicForm.inputLINC")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputOther"
                            name="inputOther"
                            checked={values.inputOther}
                            onChange={(e: any) =>
                              setFieldValue("inputOther", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputOther">
                            {t("classicForm.inputOther")}
                          </label>
                        </div>
                      </div>

                      {values.inputOther && (
                        <Field
                          as="textarea"
                          name="otherDetails"
                          value={values.otherDetails}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            const newValue = e.target.value.slice(0, 501); // Limit to 500 characters
                            setFieldValue("otherDetails", newValue);
                          }}
                          maxLength={501}
                          className="w-full rounded-md mt-4 h-15"
                          placeholder={t("classicForm.inputOtherPlaceHolder")}
                        />
                      )}
                      <ErrorMessage
                        name="otherDetails"
                        component="div"
                        className="text-red-500"
                      />

                      <div className="mb-6"></div>
                    </div>
                    <div className="text-sm mt-1 text-Grey-dark">
                      {coachData?.creditMode === "Minutes"
                        ? `${t("booking.booking-cost-prefix-minutes")} ${
                            coachData?.creditsPerCoaching
                          } ${t("booking.booking-cost-suffix-minutes")}`
                        : `${t("booking.booking-cost-prefix-full")} ${
                            coachData?.creditsPerCoaching
                          } ${t("booking.booking-cost-suffix-full")}`}
                    </div>
                    <div className="flex justify-start mt-3">
                      <Button
                        type="submit"
                        variant={ButtonVariant.PRIMARY}
                        className="p-2 px-5"
                      >
                        {t("booking.send")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {formType === FormType.EXPLORE && (
              <Formik
                initialValues={initialValuesExplore}
                validationSchema={validationSchemaExplore}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <div className="">
                      <div className="flex items-center">
                        <label htmlFor="currentPosition" className="w-2/3">
                          {t("exploreForm.currentPosition")}
                        </label>
                        <div className="w-1/3 flex justify-end">
                          <Field
                            type="text"
                            id="currentPosition"
                            name="currentPosition"
                            value={values.currentPosition}
                            onChange={(e: any) =>
                              setFieldValue("currentPosition", e.target.value)
                            }
                            className="rounded-md mt-1 w-full"
                            placeholder={t(
                              "exploreForm.currentPositionPlaceHolder"
                            )}
                          />
                        </div>
                      </div>

                      <div> {t("exploreForm.reason")}</div>
                      <Field
                        as="textarea"
                        name="reason"
                        value={values.reason}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const newValue = e.target.value.slice(0, 501);
                          setFieldValue("reason", newValue);
                        }}
                        maxLength={501}
                        className="w-full rounded-md h-15 mb-2"
                        placeholder={t("exploreForm.reasonPlaceHolder")}
                      />
                      <ErrorMessage
                        name="reason"
                        component="div"
                        className="text-red-500"
                      />
                      <div> {t("exploreForm.goal")}</div>
                      <Field
                        as="textarea"
                        name="goals"
                        value={values.goals}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const newValue = e.target.value.slice(0, 501);
                          setFieldValue("goals", newValue);
                        }}
                        maxLength={501}
                        className="w-full rounded-md h-15"
                        placeholder={t("exploreForm.goalPlaceHolder")}
                      />
                      <ErrorMessage
                        name="goals"
                        component="div"
                        className="text-red-500"
                      />
                      <div className=" mt-2">
                        {" "}
                        {t("exploreForm.textTittle2")}
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div>
                          <Field
                            type="checkbox"
                            id="inputCV"
                            name="inputCV"
                            checked={values.inputCV}
                            nChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("inputCV", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputCV">
                            {t("exploreForm.inputCV")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputJobOpportunities"
                            name="inputJobOpportunities"
                            checked={values.inputJobOpportunities}
                            onChange={(e: any) =>
                              setFieldValue(
                                "inputJobOpportunities",
                                e.target.checked
                              )
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputJobOpportunities">
                            {t("exploreForm.inputJobOpportunities")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputJobHappiness"
                            name="inputJobHappiness"
                            checked={values.inputJobHappiness}
                            onChange={(e: any) =>
                              setFieldValue(
                                "inputJobHappiness",
                                e.target.checked
                              )
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputJobHappiness">
                            {t("exploreForm.inputJobHappiness")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="input360"
                            name="input360"
                            checked={values.input360}
                            onChange={(e: any) =>
                              setFieldValue("input360", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="input360">
                            {t("exploreForm.input360")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputOther"
                            name="inputOther"
                            checked={values.inputOther}
                            onChange={(e: any) =>
                              setFieldValue("inputOther", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputOther">
                            {t("exploreForm.inputOther")}
                          </label>
                        </div>
                      </div>

                      {values.inputOther && (
                        <Field
                          as="textarea"
                          name="otherDetails"
                          value={values.otherDetails}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            const newValue = e.target.value.slice(0, 501);
                            setFieldValue("otherDetails", newValue);
                          }}
                          maxLength={501}
                          className="w-full rounded-md mt-4 h-15"
                          placeholder={t("exploreForm.inputOtherPlaceHolder")}
                        />
                      )}
                      <ErrorMessage
                        name="otherDetails"
                        component="div"
                        className="text-red-500"
                      />

                      <div className="mb-2"></div>
                    </div>
                    <div className="text-sm mt-1 text-Grey-dark">
                      {coachData?.creditMode === "Minutes"
                        ? `${t("booking.booking-cost-prefix-minutes")} ${
                            coachData?.creditsPerCoaching
                          } ${t("booking.booking-cost-suffix-minutes")}`
                        : `${t("booking.booking-cost-prefix-full")} ${
                            coachData?.creditsPerCoaching
                          } ${t("booking.booking-cost-suffix-full")}`}
                    </div>
                    <div className="flex justify-start mt-3">
                      <Button
                        type="submit"
                        variant={ButtonVariant.PRIMARY}
                        className="p-2 px-5"
                      >
                        {t("booking.send")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {formType === FormType.PRE && (
              <Formik
                initialValues={initialValuesPre}
                validationSchema={validationSchemaPre}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <div className="">
                      <div className="flex items-center">
                        <label htmlFor="currentPosition" className="w-2/3">
                          {t("preForm.currentPosition")}
                        </label>
                        <div className="w-1/3 flex justify-end">
                          <Field
                            type="text"
                            id="currentPosition"
                            name="currentPosition"
                            value={values.currentPosition}
                            onChange={(e: any) =>
                              setFieldValue("currentPosition", e.target.value)
                            }
                            className="rounded-md mt-1 w-full"
                            placeholder={t(
                              "preForm.currentPositionPlaceHolder"
                            )}
                          />
                        </div>
                      </div>

                      <div className="flex items-center">
                        <label htmlFor="decisionUntil" className="w-2/3">
                          {t("preForm.decisionUntil")}
                        </label>
                        <div className="w-1/3 flex justify-end">
                          <DatePicker
                            id="decisionUntil"
                            name="decisionUntil"
                            selected={values?.decisionUntil}
                            onChange={(date: Date) =>
                              setFieldValue("decisionUntil", date)
                            }
                            className="rounded-md mt-1 w-full"
                            locale={convertedLocale}
                            placeholderText={
                              t("preForm.decisionUntilPlaceHolder") ||
                              "Select a date"
                            }
                          />
                        </div>
                      </div>

                      <div> {t("preForm.thoughtsGoals")}</div>
                      <Field
                        as="textarea"
                        name="thoughts"
                        value={values.thoughts}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const newValue = e.target.value.slice(0, 501);
                          setFieldValue("thoughts", newValue);
                        }}
                        maxLength={501}
                        className="w-full rounded-md h-15 mb-2"
                        placeholder={t("preForm.thoughtsGoalsPlaceHolder")}
                      />
                      <ErrorMessage
                        name="thoughts"
                        component="div"
                        className="text-red-500"
                      />
                      <div> {t("preForm.goalForCoachingSession")}</div>
                      <Field
                        as="textarea"
                        name="goals"
                        value={values.goals}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const newValue = e.target.value.slice(0, 501);
                          setFieldValue("goals", newValue);
                        }}
                        maxLength={501}
                        className="w-full rounded-md h-15"
                        placeholder={t(
                          "preForm.goalForCoachingSessionPlaceHolder"
                        )}
                      />
                      <ErrorMessage
                        name="goals"
                        component="div"
                        className="text-red-500"
                      />
                      <div className=" mt-2"> {t("preForm.textTittle2")}</div>

                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div>
                          <Field
                            type="checkbox"
                            id="inputCV"
                            name="inputCV"
                            checked={values.inputCV}
                            nChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("inputCV", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputCV">
                            {t("preForm.inputCV")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputJobOpportunities"
                            name="inputJobOpportunities"
                            checked={values.inputJobOpportunities}
                            onChange={(e: any) =>
                              setFieldValue(
                                "inputJobOpportunities",
                                e.target.checked
                              )
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputJobOpportunities">
                            {t("preForm.inputJobOpportunities")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputJobHappiness"
                            name="inputJobHappiness"
                            checked={values.inputJobHappiness}
                            onChange={(e: any) =>
                              setFieldValue(
                                "inputJobHappiness",
                                e.target.checked
                              )
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputJobHappiness">
                            {t("preForm.inputJobHappiness")}
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            id="inputOther"
                            name="inputOther"
                            checked={values.inputOther}
                            onChange={(e: any) =>
                              setFieldValue("inputOther", e.target.checked)
                            }
                            className="mr-2"
                          />
                          <label htmlFor="inputOther">
                            {t("preForm.inputOther")}
                          </label>
                        </div>
                      </div>

                      {values.inputOther && (
                        <Field
                          as="textarea"
                          name="otherDetails"
                          value={values.otherDetails}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            const newValue = e.target.value.slice(0, 501);
                            setFieldValue("otherDetails", newValue);
                          }}
                          maxLength={501}
                          className="w-full rounded-md mt-4 h-15"
                          placeholder={t("preForm.inputOtherPlaceHolder")}
                        />
                      )}
                      <ErrorMessage
                        name="otherDetails"
                        component="div"
                        className="text-red-500"
                      />

                      <div className="mb-2"></div>
                    </div>
                    <div className="text-sm mt-1 text-Grey-dark">
                      {coachData?.creditMode === "Minutes"
                        ? `${t("booking.booking-cost-prefix-minutes")} ${
                            coachData?.creditsPerCoaching
                          } ${t("booking.booking-cost-suffix-minutes")}`
                        : `${t("booking.booking-cost-prefix-full")} ${
                            coachData?.creditsPerCoaching
                          } ${t("booking.booking-cost-suffix-full")}`}
                    </div>
                    <div className="flex justify-start mt-3">
                      <Button
                        type="submit"
                        variant={ButtonVariant.PRIMARY}
                        className="p-2 px-5"
                      >
                        {t("booking.send")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </>
        )}
      </div>
    </PopupWrapper>
  );
}
