import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import { useContext } from "react";

export function Jobs() {
  const { user } = useContext(AuthContext);;
  const { t } = useTranslation("jobs");

  const jobRecommendationsKey = user?.userDetails?.discipline;
  const jobRecommendations =
    jobRecommendationsKey
      ? t(`disciplines.${jobRecommendationsKey}`)
      : "-" 

  const jobTitleParam =
    typeof jobRecommendations === "string"
      ? (jobRecommendations as string).replace(/\s/g, "-")
      : "-";

  const iframeSrc = `https://restartcareer.board.jobads.de/jobs/${jobTitleParam}/deutschland`;

  return (
    <div className="px-6 pt-4 lg:pt-9 sm:px-9 lg:px-0 flex flex-col w-full h-full items-center">
      <div className="w-full lg:w-5/6 max-w-screen-lg">
        <h1 className="text-Yellow-default text-lg font-semibold mb-4 md:mb-9 ">{t("page-title")}</h1>
  
        <iframe title="Job Board" src={iframeSrc} width="100%" height="890px" />
      </div>
    </div>
  );
  
}
