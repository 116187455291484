import Button from "../../../../../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../../../../components/button/types";
import { useTranslation } from "react-i18next";
import { uploadCVAndCreateCredit } from "../../../../../../../../../services/selfAssessment.service";
import { ReactComponent as CloseIcon } from "../../../../../../../../../assets/close.svg";
import { AuthContext } from "../../../../../../../../../contexts/AuthContext";

function FileDownloader({ file }: { file: any }) {
  return (
    <a
      href={file ? URL.createObjectURL(file) : ""}
      download={file ? file.name : ""}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="font-bold">
        {file ? (
          <>
            <span>{file.name}</span>{" "}
            <span className="text-Grey-dark ml-3">
              {(file.size / 1024 / 1024).toFixed(2)} mb
            </span>
          </>
        ) : (
          " .pdf file"
        )}
      </button>
    </a>
  );
}

export function TemplatePreview({
  onNext,
  onBack,
  selectedTemplate,
  cvToUpload,
  templates,
  checkType,
  deleteTemplate,
  deleteFile,
}: {
  onNext: () => void;
  onBack: () => void;
  selectedTemplate: number;
  cvToUpload: any;
  templates: any;
  checkType: string;
  deleteTemplate: () => void;
  deleteFile: () => void;
}) {
  const { t } = useTranslation("myArea");
  const handleNextClick = async () => {
    try {
      const selectedTemplateName = templates[selectedTemplate].name;
      const cvCheckType = checkType;

      await uploadCVAndCreateCredit(
        cvToUpload[0],
        selectedTemplateName,
        cvCheckType
      );
      onNext();
    } catch (error) {
      console.error("Error submitting CV check:", error);
    }
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col w-full ">
        <div className="flex flex-col py-4">
          <div className="flex flex-col w-full p-4 bg-gray-300 ">
            <h2 className="font-bold mb-4">
              {t(`assessments.cv-check.template`)}
            </h2>
            <div className="flex w-full justify-between">
              <div className="w-1/4 flex items-center">
                <img
                  width={300}
                  className="cursor-pointer"
                  src={templates[selectedTemplate].templateUrl}
                  alt={`Selected Template`}
                />
                <div
                  className="ml-5 font-bold"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {templates[selectedTemplate].name}
                </div>
              </div>
              <CloseIcon
                onClick={deleteTemplate}
                className="w-6 cursor-pointer"
              />
            </div>
          </div>
          <div className="w-full justify-center items-center p-4">
            <h2 className="font-bold mb-4">
              {t(`assessments.cv-check.your-cv`)}
            </h2>
            <div className="flex w-full justify-between items-center">
              <FileDownloader file={cvToUpload[0]} />
              <CloseIcon onClick={deleteFile} className="w-6 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center p-2">
        <Button
          className="w-full mr-2"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onBack}
        >
          {t(`assessments.cv-check.back-button`)}
        </Button>
        <Button
          className="w-full ml-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={handleNextClick}
        >
          {t(`assessments.cv-check.submit-button`)}
        </Button>
      </div>
    </div>
  );
}
