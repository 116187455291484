import { useTranslation } from "react-i18next";
import { MyAreaTitle } from "../components";
import { useLocation, useNavigate } from "react-router-dom";
import { AssessmentOverview } from "./overview";
import { Report } from "./reports";
import { useContentAndProgress } from "../../../../../contexts/UserProgressContext";
import { IContentWithProgress } from "../../../../../@types";
import { getTitleFromLanguage } from "../../../../../utils";
import Button from "../../../../../components/button";
import { ButtonVariant } from "../../../../../components/button/types";
import { useContext, useRef } from "react";
import { getAssessmentsOverviewDownload, getAssessmentsReportDownload } from "../../../../../services/selfAssessment.service";
import { SterngthAndPersonalityProfile } from "./StrengthAndPersonalityProfile";
import { CVCheck } from "./CVCheck";
import { AuthContext } from "../../../../../contexts/AuthContext";

export function AssessmentsReportsAndOverviews() {
  const contentAreaRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation("myArea");

  
  const { phases } = useContentAndProgress();
  const location = useLocation();
  const currentComp =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const currentPhaseId =
    location.pathname.split("/")[location.pathname.split("/").length - 2];
  const phase = phases?.find(
    (p: IContentWithProgress) => p.content.platformContentId == currentPhaseId
  );
  const { configurations } = useContext(AuthContext);;
 const shouldRenderCVCheck = configurations?.includesCVCheck || configurations?.includesCVCheckPlus;
 const shouldRenderLINC = configurations?.includesLINCAccessement;

  const buildTitle = () => {
    if (phase)
      return `${getTitleFromLanguage(
        phase?.content || null,
        i18n.language
      )}: ${currentComp}`.toUpperCase();
    return currentComp.split("-").join(" ");
  };

  const navigate = useNavigate();
  if (currentComp === "cv-check" && !shouldRenderCVCheck) {
    navigate("/profile");
    return null; 
  }

  if (currentComp === "strength-and-personality-profile" && !shouldRenderLINC) {
    navigate("/profile");
    return null; 
  }

  const getComponent = () => {
    if (currentComp == "overview") return <AssessmentOverview />;
    if (currentComp == "report") return <Report />;
    if (currentComp == "cv-check" && shouldRenderCVCheck) return <CVCheck/>;
    if (currentComp == "strength-and-personality-profile" && shouldRenderLINC)
      return <SterngthAndPersonalityProfile/>;
  };



  return (
    <div className="mb-5">
      <div className="hidden lg:flex">
        <MyAreaTitle title={buildTitle()} />
      </div>
      <div ref={contentAreaRef}>{getComponent()}</div>
    </div>
  );
}
