import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useLatestLegal } from "../../../hooks/useLatestLegal";
import {
  acceptPrivacyStatement,
  acceptTermsAndConditions,
} from "../../../services/legal.service";
import { updateUser } from "../../../services/users.service";
import { LegalTerm } from "../../public/LegalRequirements";
import { useTranslation } from "react-i18next";

export function LegalsRequired() {
  const { t } = useTranslation("authentication");

  const { user, setUser } = useContext(AuthContext);;

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    user?.termsUpToDate || false
  );
  const [privacyStatementAccepted, setPrivacyStatementAccepted] = useState(
    user?.privacyUpToDate || false
  );

  const { latestTermsAndConditions, latestPrivacyStatement } = useLatestLegal();

  const buttonDisabled =
    !termsAndConditionsAccepted || !privacyStatementAccepted;

  const handleSubmit = async () => {
    try {
      if (!user?.termsUpToDate) await acceptTermsAndConditions();
      if (!user?.privacyUpToDate) await acceptPrivacyStatement();
      const newUser = await updateUser();
      setUser(newUser);
    } catch (e) {
      console.log("failed to submit terms and privacy");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4">
        <h2 className="text-xl">{t("terms-and-services")}</h2>
        {!user?.termsUpToDate ? (
          <LegalTerm
            accepted={termsAndConditionsAccepted}
            setAccepted={setTermsAndConditionsAccepted}
            textPrefix={t("legal.terms-and-conditions.prefix")}
            clickableText={t("legal.terms-and-conditions.name")}
            textSuffix={t("legal.terms-and-conditions.suffix")}
            latestVersion={latestTermsAndConditions}
          />
        ) : null}

        {!user?.privacyUpToDate ? (
          <LegalTerm
            accepted={privacyStatementAccepted}
            setAccepted={setPrivacyStatementAccepted}
            textPrefix={t("legal.privacy-statement.prefix")}
            clickableText={t("legal.privacy-statement.name")}
            textSuffix={t("legal.privacy-statement.suffix")}
            latestVersion={latestPrivacyStatement}
          />
        ) : null}

        <div>
          <button
            onClick={handleSubmit}
            className={` text-black px-4 py-2 rounded-md mt-4 ${
              buttonDisabled
                ? "cursor-not-allowed"
                : "cursor-pointer hover:bg-white"
            }`}
            style={{ backgroundColor: "#FDC300" }}
            disabled={buttonDisabled}
          >
            {t("accept-button")}
          </button>
        </div>
      </div>
    </div>
  );
}
