import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/restart_logo.svg";

export function PublicFooter() {
  const { t } = useTranslation("common");

  return (
    <footer className="flex w-full bg-Grey-navbar md:flex-row md:items-center md:justify-between py-6 px-9 md:px-12 md:py-6 mt-10">
      <div className="flex flex-col md:flex-row">
        <a href="/terms-of-service" className="mr-6 mb-2 md:mb-0">
          {t("footer.terms")}
        </a>
        <a href="/privacy-policy" className="mr-6 mb-2 md:mb-0">
          {t("footer.privacy")}
        </a>
        <a href="/cookie-policy" className="mr-6 mb-2 lg:mb-0">
          {t("footer.cookie")}
        </a>
        <a href="/support" className="mr-6 mb-2">
          {t("footer.support")}
        </a>
        <a href="/imprint" className="mr-6 mb-2">
          {t("footer.imprint")}
        </a>
        <a
          href="https://www.restartcareer.de"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 mb-2"
        >
          {t("footer.about")}
        </a>
      </div>
      <a href="/" className="cursor-pointer">
        <Logo className="h-10 mb-6 md:mb-0" />
      </a>
    </footer>
  );
}
