import Button from "../../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../../components/button/types";
import { ReactComponent as CloseIcon } from "../../../../assets/close.svg";
import { PopupWrapper } from "../../../../components/popupWrapper";
import { SyntheticEvent, useContext, useState } from "react";
import { FormControlLabel, FormGroup, Switch, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../contexts/AuthContext";
import { acceptCookiePolicy } from "../../../../services/legal.service";
import { useMutation } from "react-query";
import { getLoggedUser } from "../../../../services/users.service";
import { useCookies } from "react-cookie";

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginTop: 5,
  marginRight: 20,
  alignSelf: "start",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#202117",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#202117",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#A7B6C5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Cookie({
  title,
  description,
  checked,
  onChange,
  isEssential
}: {
  title: string;
  description: string;
  checked: boolean;
  onChange: (e: any) => void;
  isEssential: boolean
}) {
  return (
    <>
      <h3 className="text-Grey-dark font-bold">{title}</h3>
      <div className="ml-3">
        {isEssential ? (
          <FormControlLabel
          className="my-5"
          checked={checked}
          control={<CustomSwitch />}
          label={description}
        />
        ) : (
          <FormControlLabel
            className="my-5"
            onChange={(e: any) => {
              onChange(e.target.checked);
            }}
            checked={checked}
            control={<CustomSwitch />}
            label={description}
          />
        )}
      </div>
    </>
  );
}

export function CookieSettings({
  settingsOpen,
  close,
  closeBanner,
}: {
  settingsOpen: boolean;
  close: () => void;
  closeBanner?: () => void;
}) {
  const { user, setUser } = useContext(AuthContext);;
  const { t } = useTranslation("cookiePolicy");
  const [cookies, setCookie] = useCookies();

  const defaultCookies = (
    prop:
      | "essentialCookiesAccepted"
      | "preferencesCookiesAccepted"
      | "statisticsCookiesAccepted"
      | "marketingCookiesAccepted"
  ) => {
    if (user)
      return !!user?.cookieSettings
        ? user?.cookieSettings[prop]
        : true;
    if (cookies["restart_consent"]) {
      return cookies["restart_consent"][prop];
    }
    return true;
  };

  const [essentialCookiesAccepted, setEssentialCookiesAccepted] = useState(
    defaultCookies("essentialCookiesAccepted")
  );
  const [preferencesCookiesAccepted, setPreferenceCookiesAccepted] = useState(
    defaultCookies("preferencesCookiesAccepted")
  );
  const [statisticsCookiesAccepted, setStatisticsCookiesAccepted] = useState(
    defaultCookies("statisticsCookiesAccepted")
  );
  const [marketingCookiesAccepted, setMarketingCookiesAccepted] = useState(
    defaultCookies("marketingCookiesAccepted")
  );

  const acceptCookiesMutation = useMutation(
    ["acceptCookies"],
    () =>
      acceptCookiePolicy({
        essentialCookiesAccepted,
        preferencesCookiesAccepted,
        statisticsCookiesAccepted,
        marketingCookiesAccepted,
      }),
    {
      onSuccess: () => {
        getLoggedUser().then((response) => {
          console.log("Updating user on success", response.user);
          setUser(response.user);
        });
      },
    }
  );

  const handleCookiesAcceptance = () => {
    console.log("Accepting cookies", {
      essentialCookiesAccepted,
      preferencesCookiesAccepted,
      statisticsCookiesAccepted,
      marketingCookiesAccepted,
    });
    setCookie(
      "restart_consent",
      {
        essentialCookiesAccepted,
        preferencesCookiesAccepted,
        statisticsCookiesAccepted,
        marketingCookiesAccepted,
      },
      {
        path: "/",
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Currently 30 days for expirations
      }
    );

    if (user) acceptCookiesMutation.mutate();
    console.log("Cookies set, closing modal and banner");
    close();
    if (closeBanner) closeBanner();
  };

  return (
    <PopupWrapper
      isOpen={settingsOpen}
      className="pt-60 md:pt-0 lg:pt-0 max-h-full overflow-y-auto z-50"
    >
      <div className="w-full md:w-1/2 lg:w-1/3 bg-white rounded-md p-4 h-fit md:h-fit lg:h-fit flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <span className="text-Black font-bold">
            {t("advanced-cookies-title")}
          </span>
          <CloseIcon className="cursor-pointer" onClick={close} />
        </div>
        <FormGroup>
          <Cookie
            checked={essentialCookiesAccepted}
            onChange={setEssentialCookiesAccepted}
            title={t("essential-cookies.title")}
            description={t("essential-cookies.description")}
            isEssential={true}
          />
          <Cookie
            checked={marketingCookiesAccepted}
            onChange={setMarketingCookiesAccepted}
            title={t("marketing-cookies.title")}
            description={t("marketing-cookies.description")}
            isEssential={false}
          />
          <Cookie
            checked={preferencesCookiesAccepted}
            onChange={setPreferenceCookiesAccepted}
            title={t("preferences-cookies.title")}
            description={t("preferences-cookies.description")}
            isEssential={false}
          />
          <Cookie
            checked={statisticsCookiesAccepted}
            onChange={setStatisticsCookiesAccepted}
            title={t("statistics-cookies.title")}
            description={t("statistics-cookies.description")}
            isEssential={false}
          />
        </FormGroup>
        <Button
          className="mt-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={handleCookiesAcceptance}
        >
          {t("save-button")}
        </Button>
      </div>
    </PopupWrapper>
  );
}

export function CookieConsentBanner({
  showBanner,
  closeBanner,
}: {
  showBanner: boolean;
  closeBanner: () => void;
}) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [cookies, setCookie] = useCookies();
  const { user, setUser } = useContext(AuthContext);;
  const { t } = useTranslation("cookiePolicy");

  const acceptCookieMutation = useMutation(
    ["acceptCookies"],
    () =>
      acceptCookiePolicy({
        essentialCookiesAccepted: true,
        preferencesCookiesAccepted: true,
        statisticsCookiesAccepted: true,
        marketingCookiesAccepted: true,
      }),
    {
      onSuccess: () => {
        closeBanner();
        getLoggedUser().then((response) => {
          setUser(response.user);
        });
      },
    }
  );

  const handleCookiesAcceptance = () => {
    setCookie(
      "restart_consent",
      {
        essentialCookiesAccepted: true,
        preferencesCookiesAccepted: true,
        statisticsCookiesAccepted: true,
        marketingCookiesAccepted: true,
      },
      {
        path: "/",
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Currently 30 days for expirations
      }
    );
    closeBanner();
    if (user) acceptCookieMutation.mutate();
  };

  return (
    <>
      <CookieSettings
        settingsOpen={settingsOpen}
        close={() => setSettingsOpen(false)}
        closeBanner={closeBanner}
      />

      <div
        className={`fixed z-40 lg:bottom-0 bottom-14 items-center left-0 w-full bg-Grey-light-3 p-4 px-8 block lg:flex  justify-between transition-transform ${
          showBanner ? "translate-y-0" : "translate-y-full hidden"
        }`}
      >
        <div className="w-full lg:w-2/3 flex">
          <div>
            {t("banner")}{" "}
            <a href="/cookie-policy" className="underline cursor-pointer">
              {t("data-privacy")}
            </a>
          </div>
          <div className="w-10 h-10">
            <CloseIcon
              className="cursor-pointer block lg:hidden "
              onClick={closeBanner}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/3 flex lg:justify-end py-2 items-center">
          <Button
            className="mr-2 w-full lg:w-fit"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => {
              setSettingsOpen(true);

            }}
          >
            {t("settings-button")}
          </Button>
          <Button
            className="mr-4 w-full lg:w-fit"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={handleCookiesAcceptance}
          >
            {t("accept-button")}
          </Button>
          <CloseIcon
            className="cursor-pointer hidden lg:block"
            onClick={closeBanner}
          />
        </div>
      </div>
    </>
  );
}
