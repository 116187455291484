import { useQuery } from "react-query";
import { ICommunityTopic, IDiscourseSearchResult, ISearchResult, SearchResult } from "../../../../../@types";
import { getFeedback, getTopicsContentByIds } from "../../../../../services/discourse.service";
import { useState } from "react";
import { CommunityTopic } from "../../../community/Topic";



export function PostsPreview({posts}: {posts: ISearchResult[]}) {


    const {data: bookmarkedContent} = useQuery(['posts', posts], () => getTopicsContentByIds(posts?.map((ub : ISearchResult) => (ub as IDiscourseSearchResult)?.topic?.id || 0)), {
        onSuccess: (data) => console.log("Got topics: ", data)
    })
    const [ openTopic, setOpenTopic] = useState<number | null>(null);
    const {data: feedback, refetch} = useQuery(['feedback'], ()=> getFeedback())




    return (
        <div>
        {bookmarkedContent?.map((lt: ICommunityTopic, idx: number) => {
            const fb = feedback?.find((f : any) => f.topicId === lt.id);
            return <div onClick={() => setOpenTopic(lt.id)}>
                <CommunityTopic open={openTopic === lt.id} topic={lt} users={[]} feedback={fb} refetchFeedback={refetch} />
            </div>
        })}

    </div>
    )


}