import { axiosInstance } from ".";
import { CoachingCategoryContent } from "../@types";
import { ContentType } from "../enums";
import { getContentById, getContentByProperty } from "./content.service";


export async function getCoachingCategory(platformContentId?: string) {
    if (!platformContentId) return;
    return await getContentById({ platformContentId }) as CoachingCategoryContent;
}

export async function getCoachesFromCategory(platformContentId?: string) {
    if (!platformContentId) return await getContentByProperty({ contentType: ContentType.COACH});
    return ((await getContentById({ platformContentId, projection: 'min' })) as CoachingCategoryContent).coaches.map((c) => c)
}


export async function bookCoaching({fields, coachId, formType} : {fields: any, coachId: string, formType: string}) {
    return (await axiosInstance.post('/credits', { fields, coachId, formType }));
}