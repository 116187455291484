import { ButtonProps, ButtonSize, ButtonVariant } from "./types"
import "./index.css"

const Button = ({ className = "", disabled, onClick, children, type, variant, size }: ButtonProps) => {
    const buttonVariant = 
        variant === ButtonVariant.PRIMARY ? "btn-primary" : 
        variant === ButtonVariant.SECONDARY ? "btn-secondary" : 
        variant === ButtonVariant.GHOST_LIGHT ? "btn-ghost-light" : 
        variant === ButtonVariant.GHOST_DARK ? "btn-ghost-dark" : 
        ""

    const buttonSize = 
        size === ButtonSize.MEDIUM ? "btn-medium" : 
        size === ButtonSize.SMALL ? "btn-small" :
        ""

    const classNames = `btn ${buttonVariant} ${className} ${buttonSize}`

    return (
        <button
            type={type}
            disabled={disabled}
            className={classNames}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button