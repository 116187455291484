import { Dispatch, SetStateAction, useState } from "react"
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
import { useMutation } from "react-query";
import { rejectCoaching } from "../../../services/coaching.service";
import {useTranslation} from 'react-i18next';


export function CoachingRejectPage({ verificationToken }: { verificationToken: string }) {

    const { t } = useTranslation("coaches");
    const [message, setMessage] = useState("");
    const [successfullRequest, setSuccessfullRequest] = useState(false);
    const [error, setError] = useState<string | null>(null); // Add error state


    const rejectMutation = useMutation(['rejectMutation'], () => rejectCoaching({ verificationToken, message }), {
        onSuccess: () => setSuccessfullRequest(true),
        onError: (error: Error) => setError(error.message), 
    });

    return (
        <>
            <h1 className="text-xl mb-5">{t("reject-page.title")}</h1>
            {
                successfullRequest ?
                    <div>{t("reject-page.success")}</div>
                    : <>
                        <textarea value={message} onChange={(e: any) => setMessage(e.target.value)} className="w-full rounded-md h-32"
                            placeholder={t("reject-page.placeholder") || ""} />
                        <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} className="mt-5" onClick={() => rejectMutation.mutate()}>{t("reject-page.reject")}</Button>
                        {error && <div className="text-red-500 mt-2" style={{ color: "#C41429" }}>{t("warning-reject")}</div>}{" "}
          {/* Display error message */}
                    </>
            }

        </>
    )



}