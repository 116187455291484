import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { checkVoucher } from "../../services/vouchers.service";
import { NOT_FOUND_ERRORS, VOUCHER_ERRORS } from "../../errors/errorCodes";

const bootstrapToken = (): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get("token");
};

export function LinkedinCallback({
  storedCode,
}: {
  storedCode: string | null;
}) {
  const token = bootstrapToken();
  const [mutationEnabled, setMutationEnabled] = useState(false);

  const voucherMutation = useMutation(checkVoucher, {
    onSuccess: (data) => {
      if (data) {
        localStorage.removeItem("pendingVoucherCode");
        const { isValid, code, redirectUserToContent } = data;
        if (isValid && !redirectUserToContent) {
          window.location.href = `/profile/settings?code=${code}`;
        } else if (isValid && redirectUserToContent) {
          window.location.href = "/";
        } else {
          window.location.href = "/voucher-error?error";
        }
      }
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message;
      localStorage.removeItem("pendingVoucherCode");
      switch (errorMessage) {
        case NOT_FOUND_ERRORS.NOT_FOUND_VOUCHER:
          window.location.href = "/voucher-error?voucher-not-found";
          break;
        case VOUCHER_ERRORS.VOUCHER_ALREADY_USED:
          window.location.href = "/voucher-error?voucher-already-used";
          break;
        case VOUCHER_ERRORS.VOUCHER_EXPIRED:
          window.location.href = "/voucher-error?voucher-expired";
          break;
        case VOUCHER_ERRORS.VOUCHER_ALREADY_USED_IN_THE_PAST:
          console.error("Error: Voucher already used in the past.");
          window.location.href =
            "/voucher-error?voucher-already-used-in-the-past";
          break;
        default:
          window.location.href = "/voucher-error?error";
          break;
      }
    },
    retry: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorage.setItem("accessToken", token);
        if (storedCode) {
          setMutationEnabled(true);
        } else {
          window.location.href = "/";
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (mutationEnabled && storedCode) {
      voucherMutation.mutate(storedCode);
    }
  }, [mutationEnabled]);

  return null;
}
