import { useMutation } from "react-query";
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
import { useState } from "react";
import { invoiceCoaching } from "../../../services/coaching.service";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

export function CoachingInvoicePage({
  verificationToken,
}: {
  verificationToken: string;
}) {
  const { t } = useTranslation("coaches");

  const [date, setDate] = useState<Date>(new Date());
  const [successfullRequest, setSuccessfullRequest] = useState(false);
  const [error, setError] = useState<string | null>(null); // Add error state

  const invoiceMutation = useMutation(
    ["invoiceMutation"],
    () => invoiceCoaching({ verificationToken, date: date.toISOString() }),
    {
      onSuccess: () => setSuccessfullRequest(true),
      onError: (error: Error) => setError(error.message), 
    }
  );

  return (
    <>
      <h1 className="text-xl mb-5">{t("invoice-page.title")}</h1>
      {successfullRequest ? (
        <div>{t("invoice-page.success")}</div>
      ) : (
        <>
          <div className="mb-3">
            <span>{t("invoice-page.message")}</span>
          </div>
          <div>
            <ReactDatePicker
              id="date-picker"
              maxDate={new Date()}
              selected={date}
              onChange={(d) => setDate(d || new Date())}
              dateFormat="dd/MM/yyyy"
              placeholderText={t("select-a-date") || "Select a date"}
              className="border rounded-lg px-4 py-3.5 text-base"
            />
          </div>
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            className="mt-5"
            onClick={() => invoiceMutation.mutate()}
          >
            {t("invoice-page.invoice")}
          </Button>
          {error && <div className="text-red-500 mt-2" style={{ color: "#C41429" }}>{t("warning-invoice")}</div>}{" "}
          {/* Display error message */}
        </>
      )}
    </>
  );
}
