import {
  ArticleContent,
  AssessmentContent,
  ExperienceContent,
  MilestoneContent,
} from "../../../../../../@types";
import { useContentActions } from "../../../../../../hooks/useContentActions";
import { ReactComponent as DoneInactive } from "../../../../../../assets/done_check_inactive.svg";
import { ReactComponent as DoneIrrelevant } from "../../../../../../assets/done_check_irrelevant.svg";

import { ReactComponent as BookmarkInactive } from "../../../../../../assets/bookmark_inactive.svg";
import { ReactComponent as DoneActive } from "../../../../../../assets/done_check_active.svg";
import { ReactComponent as BookmarkActive } from "../../../../../../assets/bookmark_active.svg";
import { ContentType } from "../../../../../../enums";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { useContext } from "react";
import { CircularProgress } from "@mui/material";

export const GenericTeaserDisplay = ({
  content,
  onClick,
  hideActions,
}: {
  content: ArticleContent | AssessmentContent | ExperienceContent;
  onClick: () => void;
  hideActions?: boolean;
}) => {
  const {
    contentProgress,
    handleBookmark,
    handleDoneClick,
    handleRollbackDoneClick,
  } = useContentActions({ content });
  const { configurations } = useContext(AuthContext);;
  const renderBookmarkActions = configurations?.includesBookmarks;

  const getDoneCheckmark = () => {
    if (contentProgress?.markedIrrelevant) return <DoneIrrelevant />;
    return (
      <>
        {contentProgress?.progressValue == 100 ? (
          <DoneActive
            onClick={handleRollbackDoneClick}
            className="cursor-pointer mr-1"
          />
        ) : (
          <DoneInactive
            onClick={handleDoneClick}
            className="cursor-pointer mr-1"
          />
        )}
      </>
    );
  };

  if (!content) {
    // Render loading state while coachDetails are being fetched
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }
  

  return (
    <div onClick={onClick} className="flex flex-col items-start mb-2">
      <div className="flex flex-col sm:flex-row mb-4 w-full">
        {content?.image && (
          <div className="w-full mb-4 sm:mb-0 sm:mr-4 sm:w-72 h-fit rounded-lg overflow-hidden">
            <img src={content?.image} alt="article-image" />
          </div>
        )}
        <div className="w-full">
          <h2 className="font-bold text-xl mb-1">{content?.title}</h2>
          <h3 className="overflow-hidden">
            {content?.teaserText?.slice(0, 170)}...
          </h3>
        </div>
      </div>
      <div className="flex w-full justify-between mb-4 items-center">
        <div>
          <div className="text-sm font-medium text-Grey-dark">
            {content?.authors?.map((a) => `Von ${a.givenName} ${a.surname}`)}
          </div>
          <div className="text-sm text-Grey-dark">
            {content.contentType !== ContentType.EXPERIENCE
              ? content?.effortEstimate || "No effort estimation"
              : null}
          </div>
        </div>
        {hideActions ? null : (
          <div className="flex p-2">
            {getDoneCheckmark()}
            {renderBookmarkActions && (
              <>
                {contentProgress?.bookmarked ? (
                  <BookmarkActive
                    onClick={handleBookmark}
                    className="cursor-pointer"
                  />
                ) : (
                  <BookmarkInactive
                    onClick={handleBookmark}
                    className="cursor-pointer"
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>

      <hr className="border-xs border-zinc-300 w-full mb-4" />
    </div>
  );
};
