import { useQuery } from "react-query";
import { getCurrentAssessmentAnswers } from "../../../../../../services/selfAssessment.service";
import { AssessmentContent, IContentWithProgress, Question } from "../../../../../../@types";
import { getUserContentAndProgressTree } from "../../../../../../services/content.service";
import Button from "../../../../../../components/button";
import { ButtonVariant } from "../../../../../../components/button/types";
import { useContentAndProgress } from "../../../../../../contexts/UserProgressContext";
import { useNavigate } from "react-router-dom";
import { makeUrlFromTitle } from "../../../../../../utils";
import { useTranslation } from "react-i18next";


function AssessmentQuestionPreview({ question, selfAssessmentState }: { question: Question, selfAssessmentId: string, selfAssessmentState: any, refreshAnswers: Function, refreshUserProgressState: any }) {

    const {t} = useTranslation("content");

    const answer = selfAssessmentState?.answers?.filter((a: any) => a.questionId === question.id)[0]?.answer;


    return (
        <>
            <div className="flex justify-between items-center mt-3">
                <div className="w-1/2">
                    {question.text}
                </div>
                <div className="w-1/2 flex justify-between font-bold ml-6">
                    {t(`answers.${answer}`)}
                </div>
            </div>
            <hr className="mt-3" />
        </>
    )
}


export function AssessmentPreview({ content }: { content: AssessmentContent }) {


    const { data: selfAssessmentState, refetch } = useQuery(['self-assessment', content], () => getCurrentAssessmentAnswers(content.id));
    const { refetch: refreshUserProgressState } = useQuery(['userContentProgress', content], () => getUserContentAndProgressTree({ platformContentId: content.id }));
    
    const { t } = useTranslation("myArea"); 
    
    const navigate = useNavigate();

    const { phases, milestones, setOpenedContent } = useContentAndProgress();

    const handleArticleClick = () => {
        // Need to activate correspondent milestone and phase
        const milestone = milestones?.filter((ms: IContentWithProgress) => ms.content?.childContentIds.includes(content.id))[0] || null;
        const phase = phases?.filter((phase: IContentWithProgress) => phase.content?.childContentIds.includes(milestone?.content.platformContentId || ''))[0] || null;
        navigate(`/phase/${phase?.content.platformContentId}/milestone/${milestone?.content.platformContentId}/content/${content.id}/${makeUrlFromTitle(content?.title)}`);
        setOpenedContent(content.id);
    }



    return (
        <div className = "AssessmentBlock">
            <div className="mt-8">
                <div className="flex w-full items-center justify-between mb-5">
                    <div className="w-3/5">
                        <h1 className="text-lg text-Grey-dark font-bold">{content?.title}</h1>
                    </div>
                    <Button onClick={handleArticleClick} className="px-2" variant={ButtonVariant.SECONDARY}>{t("assessments.go-back-to-content")}</Button>
                </div>
            </div>

            {(content as AssessmentContent).questions?.map((q: Question) =>
                <AssessmentQuestionPreview key={q.id} refreshUserProgressState={refreshUserProgressState} selfAssessmentState={selfAssessmentState} refreshAnswers={refetch} question={q} selfAssessmentId={content.id} />)}


        </div>
    )



}