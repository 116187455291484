import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next"; // Assuming you're using react-i18next
import { MyAreaTitle } from "../components";
import { useQuery } from "react-query";
import { getUserGuide } from "../../../../../services/guide.service";
import { GuideDetails } from "../../../../../@types";
import { CircularImage } from "../../../../../components/circularImage";
import Button from "../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/button/types";

function GuideHeader({ guideDetails }: { guideDetails: GuideDetails }) {
  const { t } = useTranslation("myArea");

  return (
    <div className="flex w-full bg-Yellow-light rounded-md flex-col md:flex-row items-center md:items-start">
      <div className="w-[160px] h-[160px] overflow-hidden p-2">
        <CircularImage src={`https:${guideDetails?.profilePicture}`} />
      </div>
      <div className="p-3">
        <h1 className="text-lg font-semibold">
          {`${guideDetails?.givenName} ${guideDetails?.surname}`.toUpperCase()}
        </h1>

        <div className="flex items-center my-2 text-Grey-dark">
          {t("guide.description")}
        </div>
        <a href={guideDetails?.calendlyUrl} target="_blank">
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            className="mr-5 px-3 mt-1"
            onClick={() => null}
          >
            <span className="font-medium">{t("guide.schedule-meeting")}</span>
          </Button>
        </a>
      </div>
    </div>
  );
}

export function Guide() {
  const { t } = useTranslation("myArea");

  const { data: guide } = useQuery<GuideDetails>(["guide"], getUserGuide);

  const tabs = useMemo(() => {
    const baseTabs = ["overview"];
    return baseTabs;
  }, []);

  if (!guide) {
    return (
      <div>
        <div className="hidden lg:flex">
          <MyAreaTitle title={t("guide.title")} />
        </div>
        <div>{t("guide.noGuide")}</div>
      </div>
    );
  }
  return (
    <div>
      <div className="hidden lg:flex">
        <MyAreaTitle title={t("guide.title")} />
      </div>
      <div>
        <GuideHeader guideDetails={guide as GuideDetails} />
        <div className="flex w-full mt-6">
          {tabs.map((tab) => {
            return (
              <div key={tab}>
                <div className="px-4 py-3 cursor-pointer">
                  <span className="p-2 pb-0 flex font-bold">
                    {t(`guide.${tab}`)}
                  </span>
                </div>
                <div className="h-[2px] bg-Yellow-default w-full" />
              </div>
            );
          })}
        </div>
        <hr />
        <div className="mt-4">
          <div className="bg-Grey-light-3 rounded-md p-6">
            <h1 className="font-bold text-Grey-dark text-lg">
              {t(`guide.about-me`)}
            </h1>
            <div
              dangerouslySetInnerHTML={{ __html: guide?.aboutMe || "" }}
            ></div>
          </div>
        </div>
        <div className="mt-4">
          <h1 className="font-bold text-Grey-dark text-lg mb-2">
            {t(`guide.meetings`)}
          </h1>
          <hr />
          {guide?.meetingDates?.map((meeting) => (
            <>
              <div className="my-2 font-bold text-Grey">
                {meeting.split("T")[0]}
              </div>
              <hr />
            </>
          ))}

          <div className="flex w-full justify-end mt-4">
            <a href={guide?.calendlyUrl} target="_blank">
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                className="px-3 mt-1"
                onClick={() => null}
              >
                <span className="font-medium">
                  {t("guide.schedule-meeting")}
                </span>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
