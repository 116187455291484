import { endOfWeek, startOfWeek } from "date-fns";
import { useState } from "react";



export function useWeeklyJournalState() {

    const [weeklyPlanId, setWeeklyPlanId] = useState<number | null>(null);
    const [startDate, setStartDate] = useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = useState(endOfWeek(new Date()));
    const [achievements, setAchievements] = useState<string>("")
    const [insights, setInsights] = useState<string>("")
    const [improvements, setImprovements] = useState<string>("")
    const [weeklyGoals, setWeeklyGoals] = useState<any[]>([]);
    const [newWeeklyGoal, setNewWeeklyGoal] = useState("");
    const [productivityRate, setProductivityRate] = useState<number | null>(null);
    const [gratitudeRate, setGratitudeRate] = useState<number | null>(null);
    const [happinessRate, setHappinessRate] = useState<number | null>(null);

    const handleDateChange = ([newStartDate]: any[]) => {
        if (newStartDate !== startDate) {
            setStartDate(startOfWeek(newStartDate));
            setEndDate(endOfWeek(newStartDate));
        }
    };


    const setJournal = (data: any) => {
        if (data) {
            setWeeklyPlanId(data.id);
            setProductivityRate(data?.productivityRate);
            setGratitudeRate(data?.gratitudeRate);
            setHappinessRate(data?.happinessRate);
            setAchievements(data?.achievements || "");
            setInsights(data?.insights || "");
            setImprovements(data?.improvements || "");
            setWeeklyGoals(data?.weeklyGoals || []);
        } else {
            setWeeklyPlanId(null);
            setProductivityRate(data?.productivityRate);
            setGratitudeRate(data?.gratitudeRate);
            setHappinessRate(data?.happinessRate);
            setAchievements("");
            setInsights("");
            setImprovements("");
            setWeeklyGoals([]);
        }
    }

    return {
        weeklyPlanId,
        startDate,
        endDate,
        achievements,
        setAchievements,
        insights,
        setInsights,
        improvements,
        setImprovements,
        productivityRate,
        setProductivityRate,
        gratitudeRate,
        setGratitudeRate,
        happinessRate,
        setHappinessRate,
        setJournal,
        handleDateChange,
        weeklyGoals,
        setWeeklyGoals,
        newWeeklyGoal,
        setNewWeeklyGoal
    }





}