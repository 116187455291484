import OAuthButton from ".";



export function LinkedInOAuthButton({onClick} : {onClick: () => void}) {

    // <img width="40px" src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="Linkedin logo" />

    return (
        <OAuthButton
            providerLogo={null}
            providerName="LinkedIn"
            onClick={onClick}
        />
    )

}