/* React imports */
import { useEffect, useState } from "react";

/* Third party imports */
import { QueryClient, QueryClientProvider } from "react-query";

/* Local imports */

import { AuthProvider } from "./contexts/AuthContext";

import { AppContainer } from "./AppContainer";

const queryClient = new QueryClient();

function App() {
  return (
    <div id="App" className="font-figtree h-screen w-full">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppContainer />
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
