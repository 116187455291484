import { ChangeEvent, useEffect, useState } from 'react';
import { ReactComponent as SearcIcon } from '../../../assets/search.svg';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useQuery } from 'react-query';
import { searchContent } from '../../../services/content.service';
import { useTranslation } from 'react-i18next';
import { SearchDropdown } from './components';





export function SearchBar({ className }: { className?: string }) {

  const { t, i18n } = useTranslation("content");

  const [searchPrompt, setSearchPrompt] = useState("");

  const { data: searchResults } = useQuery([searchPrompt], () => {
    if (searchPrompt.length > 0) {
      return searchContent(searchPrompt, i18n.language);
    }
  });

  const debouncedOnChange = debounce((value: string) => {
    // Make request to backend with value
    setSearchPrompt(value);
  }, 500);

  // Throttled onChange handler that fires every 500ms
  const throttledOnChange = throttle((value: string) => {
    // Make request to backend with value
    setSearchPrompt(value);
  }, 500);

  // Effect hook to cleanup debounced onChange handler
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleSearchPromptChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedOnChange(e.target.value);
    throttledOnChange(e.target.value);
  }

  return (
    <div className={`relative flex justify-center items-center ${className}`}>
      <SearcIcon style={{ zIndex: "10" }} className='mr-[-33px] fill-Grey' />
      <input value={searchPrompt} onChange={handleSearchPromptChange} className="w-full pl-[44px] border placeholder-Grey rounded-full pr-4 py-2" placeholder={t("search") || "Search"} />
      <SearchDropdown searchResults={searchResults} searchPrompt={searchPrompt} />
    </div>
  )
}



