export enum ContentType {
    JOURNEY = 'journey',
    PHASE = 'journeyPhase',
    MILESTONE = 'milestone',
    ARTICLE = 'article',
    ASSESSMENT= 'assessment',
    BUSINESSINFO = 'businessInfo',
    TOOL = 'tool',
    COACHING = 'coaching',
    COACH = 'coach',
    AUTHOR = 'author',
    POST = 'post',
    CONTAINER = 'container',
    COACHING_CATEGORY = 'coachingCategory',
    COACH_REVIEW = 'coachReview',
    EXPERIENCE = 'experience'
}

