import { useCallback, useContext } from "react";
import { IContentWithProgress } from "../@types";
import { useContentAndProgress } from "../contexts/UserProgressContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";



export function useSkipMilestone() {

    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AuthContext);;
    const journeyId = user?.userDetails.journeyId
    const { activeMilestone, currentPhaseMilestones, milestones, phases, activePhase } = useContentAndProgress();

    const jumpToNextPhase = () => {
        if(!phases) return;
        const currentPhaseIndex = phases?.findIndex((p: IContentWithProgress) => p.id === activePhase?.id);
        if(currentPhaseIndex == undefined) return;
        if(currentPhaseIndex + 1 === phases?.length) return; 
        const nextPhase = phases[currentPhaseIndex + 1];

        const firstMilestone = milestones?.filter((ms: IContentWithProgress) => nextPhase.content.childContentIds.includes(ms.content.platformContentId))
            .sort((ms1: IContentWithProgress, ms2: IContentWithProgress) => ms1.content.orderForProgress[journeyId ?? '']- ms2.content.orderForProgress[journeyId ?? '']).filter((ms) => ms.progressValue !== 100 && !ms.markedIrrelevant)[0];

        navigate(`/phase/${nextPhase.content.platformContentId}/milestone/${firstMilestone?.content.platformContentId}`);
    }

    const skipMilestone = useCallback(() => {
        if (!currentPhaseMilestones) return;
        const currentMilestoneIndex = currentPhaseMilestones?.findIndex((ms: IContentWithProgress) => ms?.id === activeMilestone?.id);
        if (currentMilestoneIndex == undefined) return;

        let milestonessToSkip = 1;
        for (let i = currentMilestoneIndex + 1; i < currentPhaseMilestones.length ; i++) {
            const nextMilestoneToCheck = currentPhaseMilestones[i];
            if (nextMilestoneToCheck.markedIrrelevant) {
                milestonessToSkip++;
            } else if (nextMilestoneToCheck.progressValue == 100) {
                milestonessToSkip++;
            }
        }

        if (currentMilestoneIndex + milestonessToSkip == currentPhaseMilestones?.length) {
            jumpToNextPhase()
            return;
        };
        const nextMilestone = currentPhaseMilestones[currentMilestoneIndex + milestonessToSkip];

        if (!nextMilestone) return;

        navigate(`${location?.pathname.split('/').slice(0, 3).join('/')}/milestone/${nextMilestone.content?.platformContentId}`);

    }, [currentPhaseMilestones, activeMilestone])

    return skipMilestone;


}