import { Goal, MoodSection, TextAreaSection } from "../common";
import { ReactComponent as LeftArrow } from "../../../../assets/left-arrow-calendar.svg";
import { ReactComponent as RightArrow } from "../../../../assets/rigth-arrow-calendar.svg";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useDailyJournal } from "./useDailyJournal";

export function DailyJournal() {
  const { t } = useTranslation("journal");

  const {
    setPreviouslySelectedDate,
    selectedDate,
    setSelectedDate,
    moodSelected,
    setMoodSelected,
    achievements,
    setAchievements,
    insights,
    setInsights,
    improvements,
    setImprovements,
    ideas,
    setIdeas,
    gratitude,
    setGratitude,
    newDailyGoal,
    setNewDailyGoal,
    dailyGoals,
    addDailyGoalMutation,
    updateDailyGoalMutation,
    deleteDailyMutation,
    createOrUpdateJournalMutation,
  } = useDailyJournal();

  const addDay = useCallback(
    (): Date => new Date(selectedDate.setDate(selectedDate.getDate() + 1)),
    [selectedDate]
  );
  const removeDay = useCallback(
    (): Date => new Date(selectedDate.setDate(selectedDate.getDate() - 1)),
    [selectedDate]
  );
  const maxLength = 256;
  return (
    <>
      {/* Date picker section */}
      <div className="w-full sm:w-1/2 lg:w-1/3 mb-3">
        <div className="flex items-center justify-between">
          <LeftArrow
            className="mr-5 cursor-pointer"
            onClick={async () => {
              setPreviouslySelectedDate(selectedDate.toISOString());
              setSelectedDate(removeDay());
            }}
          />
          <ReactDatePicker
            id="date-picker"
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date || new Date(Date.now()));
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText={t("select-a-date") || "Select a date"}
            className="border rounded-lg px-4 py-3.5 text-base justify-stretch w-full text-center"
          />
          <RightArrow
            className="ml-5 cursor-pointer"
            onClick={() => {
              setPreviouslySelectedDate(selectedDate.toISOString());
              setSelectedDate(addDay());
            }}
          />
        </div>
      </div>

      {/* Mood section */}
      <div className="p-6 bg-Grey-light-3 mb-9">
        <p className="text-lg text-Grey-dark font-bold mb-4 ">
          {t("mood-title-daily")}
        </p>
        <MoodSection moodSelected={moodSelected} onSelect={setMoodSelected} />
      </div>

      <p className="text-lg text-Grey-dark font-bold mb-4 ">
        {t("daily-goals")}
      </p>

      {/* <p className="mb-3">{t("add-goal-title")}</p> */}

      <div>
        {dailyGoals.map((d) => (
          <Goal
            key={d.id}
            currentDate={selectedDate}
            goal={d}
            updateMutation={updateDailyGoalMutation}
            deleteMutation={deleteDailyMutation}
          />
        ))}

        <div className="flex items-center w-full mt-4">
          <input
            onChange={(e) => {
              const newGoal = e.target.value;
              if (newGoal.length <= maxLength) {
                setNewDailyGoal(newGoal);
              }
            }}
            value={newDailyGoal}
            className="border rounded-lg px-4 py-2 w-full "
            placeholder={t("add-goal-input-placeholder") || ""}
          />{" "}
          <button
            disabled={newDailyGoal.length == 0}
            onClick={() => addDailyGoalMutation.mutate({ title: newDailyGoal })}
            className={`border rounded-lg ${
              newDailyGoal.length == 0
                ? "text-Grey-dark bg-Grey-light-2"
                : "bg-Yellow-default text-black font-bold"
            } px-4 py-2 ml-3 whitespace-nowrap`}
          >
            {t("add-daily-goal-button")}
          </button>
        </div>
        {newDailyGoal.length === maxLength && (
          <p className="text-red-500 mt-2" style={{ color: "#C41429" }}>
            {t("warning-goal-limit-characters")}
          </p>
        )}
        <div className="mb-9"></div>
      </div>

      <div>
        <TextAreaSection
          onMouseLeaveAction={() => createOrUpdateJournalMutation.mutate()}
          placeholder={t("achievements-placeholder")}
          title={t("achievements")}
          text={achievements}
          onChangeText={setAchievements}
          maxLength={1024}
        />
        <TextAreaSection
          onMouseLeaveAction={() => createOrUpdateJournalMutation.mutate()}
          placeholder={t("insights-placeholder")}
          title={t("insights")}
          text={insights}
          onChangeText={setInsights}
          maxLength={1024}
        />
        <TextAreaSection
          onMouseLeaveAction={() => createOrUpdateJournalMutation.mutate()}
          placeholder={t("improvements-placeholder")}
          title={t("improvements")}
          text={improvements}
          onChangeText={setImprovements}
          maxLength={1024}
        />
        <TextAreaSection
          onMouseLeaveAction={() => createOrUpdateJournalMutation.mutate()}
          placeholder={t("ideas-placeholder")}
          title={t("ideas")}
          text={ideas}
          onChangeText={setIdeas}
          maxLength={1024}
        />
        <TextAreaSection
          onMouseLeaveAction={() => createOrUpdateJournalMutation.mutate()}
          placeholder={t("gratitude-placeholder")}
          title={t("gratitude")}
          text={gratitude}
          onChangeText={setGratitude}
          maxLength={1024}
        />
      </div>
    </>
  );
}
