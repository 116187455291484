import { useState } from "react";
import { CoachingInvoicePage } from "./CoachingInvoicePage";
import { CoachingRejectPage } from "./CoachingRejectPage";
import { CoachingExpertInvoicePage } from "./CoachingExpertInvoicePage";

enum BookedCoachingPages {
    INVOICE = "invoicepage",
    EXPERT_INVOICE = "expert-invoicepage",
    REJECT = "rejectpage",
}

export function BookedCoaching() {

    const bootstarppedURL = window.location.href.split("//")[1];

    const location = bootstarppedURL.split("/")[2]?.split("?")[0];
    const token = bootstarppedURL.split("/")[2]?.split("=")[1];

    const getPage = () => {
        if (location == BookedCoachingPages.INVOICE) return <CoachingInvoicePage verificationToken={token}  />
        else if (location == BookedCoachingPages.REJECT) return <CoachingRejectPage verificationToken={token} />
        else if (location == BookedCoachingPages.EXPERT_INVOICE) return <CoachingExpertInvoicePage verificationToken={token} />
        return null;
    }

    return (
        <div className="flex flex-col w-full mt-10">
            <div className="border p-5 rounded-md">
                {getPage()}
            </div>
        </div>)


}