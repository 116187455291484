import { Form, Formik } from "formik";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { CustomFieldWithError } from "../../../../../components/forms/CustomFieldWithError";
import Button from "../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/button/types";
import { useMutation } from "react-query";
import { updateUserDetails } from "../../../../../services/onboarding.service";
import { useTranslation } from "react-i18next";
import { MyAreaTitle } from "../components";
import { UserAvatar } from "./UserAvatar";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import { getLocaleFromLanguage } from "../../../../../services/content.service";
import { useContext, useState } from "react";

export function PersonalInformation() {
  const { user, setUser, configurations } = useContext(AuthContext);;
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { t } = useTranslation("myArea");

  const updateUserDetailsMutation = useMutation(updateUserDetails, {
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
    },
  });

  const shouldRenderBirthday = configurations?.includesBirthday;
  const shouldRenderAvatar = configurations?.includesCommunity;

  const monthsLocal = getLocaleFromLanguage();
  const localeMapping = {
    de: de,
    "en-US": enUS,
  };

  const convertedLocale = localeMapping[monthsLocal];

  return (
    <div className="mb-5">
      <div className="hidden lg:flex">
        <MyAreaTitle title={t("profile.title")} />
      </div>

      <div className="flex flex-col lg:flex-row w-full">
        {shouldRenderAvatar && (
          <div className="w-full lg:w-1/4 rounded-full flex h-fit justify-center pt-5">
            <UserAvatar />
          </div>
        )}
        <div className="w-full lg:w-3/4 lg:px-6">
          <Formik
            initialValues={{
              username: user?.username || "",
              firstName: user?.userDetails?.firstName || "",
              lastName: user?.userDetails?.lastName || "",
              email: user?.email || "",
              birthday: user?.userDetails?.birthday
                ? new Date(user.userDetails.birthday)
                    .toISOString()
                    .substr(0, 10)
                : "",
            }}
            validationSchema={yup.object({
              username: yup
                .string()
                .required("Username is required")
                .matches(/^[\w.-]+$/, `${t("no-space-username-warning")}`),
              // ... other validation schemas ...
            })}
            onSubmit={(values) => {
              const userDetails = {
                firstName: values.firstName || null,
                lastName: values.lastName || null,
                username: values.username || null,
                birthday: values.birthday || null,
              };

              updateUserDetailsMutation.mutate(
                {
                  userDetails,
                },
                {
                  onSuccess: (response) => {
                    setUser(response);
                    setIsFormDirty(false); // Reset the form's dirty state
                  },
                  onError: (error) => {
                    console.log("Error: ", error);
                  },
                }
              );
            }}
          >
            {({ values, setFieldValue, errors, touched }) => {
              return (
                <Form
                  className="mt-4 md:grid md:grid-cols-2 md:gap-3 lg:grid-cols-none"
                  onChange={() => setIsFormDirty(true)}
                >
                  <div className="w-full lg:w-full">
                    <label htmlFor="username">{t("profile.nickname")}</label>
                    <div>
                      <CustomFieldWithError
                        className="w-full h-10 mt-1 border-black rounded-lg"
                        type="username"
                        id="username"
                        name="username"
                        error={errors.username || null}
                        touched={!!touched.username}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 lg:mt-4 lg:w-full">
                    <label htmlFor="firstName">{t("profile.first-name")}</label>
                    <div>
                      <CustomFieldWithError
                        className="w-full h-10 mt-1 border-black rounded-lg"
                        type="firstName"
                        id="firstName"
                        name="firstName"
                        error={errors.firstName || null}
                        touched={!!touched.firstName}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 lg:mt-4 lg:w-full">
                    <label htmlFor="lastName">{t("profile.last-name")}</label>
                    <div>
                      <CustomFieldWithError
                        className="w-full h-10 mt-1 border-black rounded-lg"
                        type="lastName"
                        id="lastName"
                        name="lastName"
                        error={errors.lastName || null}
                        touched={!!touched.lastName}
                      />
                    </div>
                  </div>

                  {shouldRenderBirthday && (
                    <div className="w-full mt-4 md:mt-0 lg:mt-4 lg:w-full">
                      <label htmlFor="date-picker">
                        {t("profile.birthday")}
                      </label>
                      <div>
                        <DatePicker
                          id="date-picker"
                          selected={
                            values.birthday ? new Date(values.birthday) : null
                          }
                          onChange={(date) => {
                            setFieldValue(
                              "birthday",
                              date?.toISOString().substr(0, 10)
                            );
                            setIsFormDirty(true);
                          }}
                          dateFormat="dd/MM/yyyy"
                          placeholderText={
                            t("select-a-date") || "Select a date"
                          }
                          className="w-full h-10 mt-1 border-black rounded-lg"
                          locale={convertedLocale}
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-full mt-4 md:mt-0 lg:mt-4 lg:w-full">
                    <label htmlFor="email">{t("profile.email")}</label>
                    <div>
                      <CustomFieldWithError
                        disabled={true}
                        className="w-full h-10 mt-1 border-black rounded-lg"
                        type="email"
                        id="email"
                        name="email"
                        error={errors.email || null}
                        touched={!!touched.email}
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    className={`mt-6 w-full ${
                      isFormDirty ? "" : "bg-gray-200"
                    }`}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                    disabled={!isFormDirty}
                  >
                    {t("profile.save")}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}
