import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import ReactGA from "react-ga4";

export function useGoogleAnalytics() {
  const { user } = useContext(AuthContext);;
  const statisticsCookiesAccepted = user?.cookieSettings?.statisticsCookiesAccepted;

  useEffect(() => {
    if (statisticsCookiesAccepted) {
      // Enable tracking
      ReactGA.initialize(`${process.env.REACT_APP_ANALYTICS_KEY}`);
      ReactGA.send("pageview"); // Send the initial pageview
      delete (window as any)[`ga-disable-${process.env.REACT_APP_ANALYTICS_KEY}`];
    
    } else {
      // Disable tracking
      (window as any)[`ga-disable-${process.env.REACT_APP_ANALYTICS_KEY}`] = true;
    }
  }, [statisticsCookiesAccepted]);
}
