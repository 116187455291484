import { useCallback, useEffect, useState } from "react";
import { addDailyGoal, createDailyPlan, deleteDailyGoal, getDailyGoals, getDailyPlan, updateDailyGoal, updateDailyPlan } from "../../../../services/journal.service";
import { useMutation, useQuery } from "react-query";
import { formatDate } from "../../../../utils";
import { useDailyGoals } from "./useDailyGoals";


export function useDailyJournal() {

    const [initiated, setInitiated] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
    const [previouslySelectedDate, setPreviouslySelectedDate] = useState<string | null>(null);
    const [dailyPlanId, setDailyPlanId] = useState<number | null>(null);
    const [moodSelected, setMoodSelected] = useState<any>(0)
    const [achievements, setAchievements] = useState<string>("")
    const [insights, setInsights] = useState<string>("")
    const [improvements, setImprovements] = useState<string>("")
    const [ideas, setIdeas] = useState<string>("")
    const [gratitude, setGratitude] = useState<string>("")
    const [dailyGoals, setDailyGoals] = useState<any[]>([]);
    const [newDailyGoal, setNewDailyGoal] = useState("");

    const setJournal = (data: any) => {
        if (data) {
            setDailyPlanId(data.id);
            setMoodSelected(data.rate);
            setAchievements(data?.achievements || "");
            setInsights(data?.insights || "");
            setImprovements(data?.improvements || "");
            setIdeas(data?.ideas || "");
            setGratitude(data?.gratitude || "");
            setDailyGoals(data?.dailyGoals || []);
        } else {
            setDailyPlanId(null);
            setMoodSelected(null);
            setAchievements("");
            setInsights("");
            setImprovements("");
            setIdeas("");
            setGratitude("");
            setDailyGoals([]);
        }
    }
    useEffect(() => {
        setInitiated(true);
    }, [])


    useEffect(() => {
        createOrUpdateJournalMutation.mutate() // Here day is not the correct one...
        return () => {
            createOrUpdateJournalMutation.mutate()
        }
    }, [selectedDate])

    useEffect(() => {
        if (moodSelected)
            createOrUpdateJournalMutation.mutate()
    }, [moodSelected])

    const { refetch: refetchGoals } = useQuery(['dailyJournal', selectedDate], () => getDailyPlan(formatDate(selectedDate)), {
        onSuccess: (data) => { setJournal(data) }
    });


    const createOrUpdateDailyJournal = async () => {
        if (dailyPlanId) {
            updateDailyPlan(dailyPlanId, formatDate(new Date(previouslySelectedDate || "")), { rate: moodSelected, achievements, insights, improvements, ideas, gratitude });
        } else {
            createDailyPlan(formatDate(new Date(previouslySelectedDate || "")), { rate: moodSelected, achievements, insights, improvements, ideas, gratitude });
        }
    };


    const createOrUpdateJournalMutation = useMutation(['dailyPlan'], createOrUpdateDailyJournal, {
        onSuccess: () => setPreviouslySelectedDate(selectedDate.toISOString())
    });


    const {
        addDailyGoalMutation,
        updateDailyGoalMutation,
        deleteDailyMutation
    } = useDailyGoals({
        dailyPlanId,
        refetchGoals,
        setNewDailyGoal,
        selectedDate
    })

    return {
        dailyPlanId,
        previouslySelectedDate,
        setPreviouslySelectedDate,
        selectedDate,
        setSelectedDate,
        moodSelected,
        setMoodSelected,
        achievements,
        setAchievements,
        insights,
        setInsights,
        improvements,
        setImprovements,
        ideas,
        setIdeas,
        gratitude,
        setGratitude,
        newDailyGoal,
        setNewDailyGoal,
        dailyGoals,
        createOrUpdateJournalMutation,
        addDailyGoalMutation,
        updateDailyGoalMutation,
        deleteDailyMutation
    }



}