/* Third party imports */
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import { ButtonSize, ButtonVariant } from "../../../components/button/types";
/* Local imports */
import { SubmitStepProps } from "./types";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useQuery } from "react-query";
import { getContentById } from "../../../services/content.service";
import { BusinessInfoContent } from "../../../@types";
import { CircularProgress } from "@mui/material";

export default function SubmitStep({
  onNext,
  selectedOption,
  availableJourneyIds,
}: SubmitStepProps) {
  const { t, i18n } = useTranslation("onboarding");
  const { configurations } = useContext(AuthContext);
  const stepRenderContent = configurations?.onboardingProductInfo[0];

  console.log(i18n.language);

  const queryKey = ["content", i18n.language];
  const {
    data: content,
    isLoading: isContentLoading,
    refetch,
  } = useQuery(
    queryKey,
    async () => {
      const result = await getContentById({
        platformContentId: stepRenderContent,
      });
      return result as BusinessInfoContent | null;
    },
    {
      onError: (error: any) => {
        if (error.response && error.response.status === 403) {
          console.error("Forbidden error occurred:", error);
          window.location.href = "/forbidden"; // Navigate to the error page
        } else if (error.response && error.response.status === 404) {
          console.error("Not Found error occurred:", error);
          window.location.href = "/notfound"; // Navigate to the not found page
        } else {
          // Handle other errors
          console.log("An error occurred:", error);
        }
      },
    }
  );
  useEffect(() => {
    refetch();
  }, [i18n.language]);

  return (
    <>
      {isContentLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress style={{ color: "#DCAA00" }} />
        </div>
      ) : (
        <Formik initialValues={{}} onSubmit={onNext}>
          {() => (
            <div className="w-full pb-10">
              <div className="flex flex-col">
                <h1 className="text-xl font-bold leading-7 mb-1">
                  {content?.title}
                </h1>
                <Form className="w-full  mt-6">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content?.renderedContent || "",
                    }}
                  ></div>

                  <Button
                    className="w-full mt-6"
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                  >
                    {t("stepSubmit.next")}
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      )}
    </>
  );
}
