import RegeneratePartnerCVCheck from "./RegeneratePartnerCvCheck";


export function RegeneratePartnerCVCheckPrivateWrap() {

    return (
        <div className="flex w-full justify-center">
            <div className="w-1/3">
                <RegeneratePartnerCVCheck/>
            </div>
        </div>
    )


}