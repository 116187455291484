import { useEffect, useRef, useState } from "react";
import { ITopicPost } from "../../../../../@types";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { flagTopic } from "../../../../../services/discourse.service";
import { ReactComponent as MoreOptions } from "../../../../../assets/more_options.svg";
import { useTranslation } from "react-i18next";
import { useOutsideClickDetector } from "../../../../../hooks/useOutsideClickDetector";
export function PostUserIdentification({
  avatar,
  username,
  post,
}: {
  avatar: string;
  username: string;
  post: ITopicPost;
}) {
  const getAvatarUrl = (avatar: string) => {
    if (!avatar) {
      return "";
    }

    if (avatar.startsWith("https")) {
      return avatar;
    } else {
      return `https://restartcareer.discourse.group${avatar}`;
    }
  };

  const avatarUrl = getAvatarUrl(avatar);
  const [moreOptions, setMoreOptions] = useState<number | null>(null);
  const [isPostReported, setIsPostReported] = useState(false);
  const [reportError, setReportError] = useState<string | null>(null);
  const { t } = useTranslation("community");
  const reportMutation = useMutation((postId: number) => flagTopic(postId));

  const moreOptionsRef = useRef<any>(null);

  useOutsideClickDetector(moreOptionsRef, () => setMoreOptions(null));

  const flagPost = (postId: number) => {
    reportMutation.mutate(postId, {
      onSuccess: () => {
        setIsPostReported(true); // Set the reported state to true on successful report
        setReportError(null);
      },
      onError: (error: any) => {
        if (error.response?.status === 403) {
          setIsPostReported(false);
          setReportError(t("report-error"));
        }
      },
    });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex justify-start items-center">
        <img width={40} src={avatarUrl} className="mr-2.5 rounded-full" />
        <div className="font-bold">{username}</div>
      </div>
      <div className="flex justify-between items-center">
        <div className="relative align-self-center">
          <div
            ref={moreOptionsRef}
            onClick={() => setMoreOptions(post.id)}
            className={`${moreOptions == post.id ? "border-opacity-100" : "border-opacity-0"
              } border-black cursor-pointer rounded-md p-2 border-2`}
          >
            <MoreOptions />
          </div>
          {moreOptions == post.id ? (
            <div
              ref={moreOptionsRef}
              className="absolute left-[-28px] top-[50px] lg:left-[50px] lg:top-0 bg-Grey-light-3 rounded-md p-2 px-3"
            >
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isPostReported) {
                    flagPost(post?.id ?? 0);
                  }
                }}
                style={{ color: isPostReported ? "#C41429" : "inherit" }}
              >
                {isPostReported ? t("reported") : t("report")}
              </div>
              {reportError && <p style={{ color: "#C41429" }}>{reportError}</p>}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function Post({
  post,
  editPost,
}: {
  post: ITopicPost;
  editPost: () => void;
}) {
  const location = useLocation();

  const postId = parseInt(location.pathname.split("/")[4]);

  console.log("Post id: ", postId);

  const ref = useRef<any>(null);

  useEffect(() => {
    if (post.id === postId) {
      const element = ref?.current;

      // ref?.current?.scrollIntoView({ behavior: "smooth" });
      if (element) {
        const viewportHeight = window.innerHeight;
        const elementTop = element.getBoundingClientRect().top;
        const scrollPosition = elementTop - (viewportHeight / 2) + (element.offsetHeight / 2);

        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });

        setTimeout(() => {
          element.style.backgroundColor = "#FFDA6F";
          element.style.opacity = 0.9;
          element.style.borderRadius = '8px';
          setTimeout(() => {
            element.style.backgroundColor = "white";
            element.style.opacity = 1;
            element.style.borderRadius = 0;
          }, 1000);
        }, 500);
      }
    }

  }, [])


  return (
    <div className="" ref={ref}>
      <div>
        <PostUserIdentification
          username={post.username}
          avatar={post.avatar_template}
          post={post}
        />

        <div
          className="ml-[50px]"
          dangerouslySetInnerHTML={{ __html: post?.cooked || "" }}
        />
        {/* IS EDIT REQUIREMENT? NOT IN THE DESIGN */
        /* user?.username === post.username ? <Button className="bg-Yellow-default" onClick={editPost}>Edit post</Button> : null */}
      </div>
    </div>
  );
}
