import { useQuery } from "react-query";
import { useLocation } from "react-router-dom"
import { fetchTopicById, getFeedback } from "../../../../services/discourse.service";
import { CommunityTopic } from "../Topic";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function CommunityThread() {

    const { t } = useTranslation("community");
    const location = useLocation();
    const topicId = parseInt(location.pathname.split('/')[2]);
    const [topicFeedback, setTopicFeedback] = useState<any>(null);
    const { data: topicData } = useQuery(['topic'], () => fetchTopicById(topicId));

    const { data, refetch } = useQuery(['feedback'], () => getFeedback(), {
        onSuccess: (data) => {
            setTopicFeedback(data?.find((f: any) => f.topicId === topicId))
        }
    })


    return (
        <div className="flex flex-col items-center px-4">
            <div className=" w-full lg:w-1/2">
                <h1 className="text-Yellow-default text-lg font-semibold my-4 md:my-9 ">{t("threads-title")}</h1>


                {topicData ? <CommunityTopic open={true} topic={topicData} users={[]} feedback={topicFeedback} refetchFeedback={refetch} /> : null}
            </div>
        </div>
    )
}