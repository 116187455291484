import { axiosInstance } from ".";
import { CreditDetails } from "../pages/public/coachings/CoachingExpertInvoicePage";

export async function getUserCredits(
  amount: number
): Promise<{ credits: any[]; all: boolean }> {
  return (await axiosInstance.get(`/credits?amount=${amount}`)).data;
}

export async function getUsedCreditsAmount() {
  return (await axiosInstance.get(`/credits/used`)).data;
}

export async function rejectCoaching({
  message,
  verificationToken,
}: {
  message: string;
  verificationToken: string;
}) {
  try {
    return await axiosInstance.post(`/credits/reject/${verificationToken}`, {
      message,
    });
  } catch (error) {
    throw error; // Re-throw the error to trigger onError
  }
}

export async function invoiceCoaching({
  date,
  verificationToken,
  usedCredits,
}: {
  date: string;
  verificationToken: string;
  usedCredits?: number;
}) {
  try {
    return await axiosInstance.post(`/credits/invoice/${verificationToken}`, {
      redeemDate: date,
      usedCredits,
    });
  } catch (error) {
    throw error; // Re-throw the error to trigger onError
  }
}

export async function fetchCreditDetailsByToken({ verificationToken }: { verificationToken: string }): Promise<CreditDetails> {
  try {
   
      const response = await axiosInstance.get(`/credits/invoice/details?verificationToken=${verificationToken}`);


      const { id, minuteCost, creditsPerCoaching } = response.data;

      return {
          id,
          minuteCost,
          creditsPerCoaching
      };
  } catch (error) {
      throw error; // Re-throw the error if necessary
  }
}



export async function cancelCoaching({
  creditId,
  message,
}: {
  creditId: number;
  message: string;
}) {
  return await axiosInstance.post(`/credits/cancel`, { creditId, message });
}

export async function regeneratePartnerCVCheck(s3key: string) {
  try {
    return await axiosInstance.get(`/credits/regenerate-partner-cv-check-email?s3key=${s3key}`);
  } catch (error) {
    throw error;
  }
}

