import { useQuery } from "react-query";
import { ArticleContent, CoachContent, CoachingContent, FormattedContent } from "../../../../../@types";
import { getMultipleContentByIds } from "../../../../../services/content.service";
import { GenericTeaserDisplay } from "../../../home/milestones/milestonesContentList/GenericTeaserDisplay";
import { CoachingTeaserDisplay } from "../../../home/milestones/milestonesContentList/CoachingTeaserDisplay";
import { ExternalContentTeaser } from "../../../../../components/externalContentTeaser";



export function CoachingsPreview({coachings} : {coachings : FormattedContent[]}) {

    return (
        <>
            {coachings.map((a: FormattedContent) => <ExternalContentTeaser content={a as CoachingContent}  />)}
        </>
    )

}