import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom"
import { getContentById } from "../../../../services/content.service";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthorContent, CoachContent } from "../../../../@types";
import { CircularImage } from "../../../../components/circularImage";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ReactComponent as LeftArrow } from "../../../../assets/left-arrow-calendar.svg";
import { Overview } from "../../coaches/coach/overview";
import { CoachProductedContent } from "../../coaches/coach/content";


function AuthorTabs({ coachDetails, activeTab, contentId }: { coachDetails: AuthorContent | null, activeTab: string, contentId: string }) {

    const { t } = useTranslation('coaches');
    const navigate = useNavigate();

  

    const tabs = useCallback(() => {
        const baseTabs = ['overview'];
        if (coachDetails?.content?.length) baseTabs.push('content')
        return baseTabs;
    }, [coachDetails]);

    return (
        <>
            <div className="flex w-full mt-6">
                {tabs().map((tab) => {
                    return (<div key={tab}>
                        <div className="px-4 py-3 cursor-pointer" onClick={() => navigate(`/authors/${contentId}/${tab}`)}>
                            <span className="p-2 pb-0 flex">
                                {t(tab)}
                            </span>
                        </div>
                        {tab == activeTab ? <div className="h-[2px] bg-Yellow-default w-full" /> : null}
                    </div>
                    )
                })}
            </div>
            <hr />
        </>
    )


}


function AuthorHeader({ coachData }: { coachData: AuthorContent | null }) {


    return (
        <>
            <div className="flex w-full bg-Yellow-light rounded-md flex-col md:flex-row items-center md:items-start">
                <div className="w-[160px] h-[160px] overflow-hidden p-2">
                    <CircularImage src={`https:${coachData?.profilePicture}`} />
                </div>
                <div className="p-3">
                    <h1 className="text-lg font-semibold">{`${coachData?.title ? coachData?.title : ''} ${coachData?.givenName} ${coachData?.surname}`.toUpperCase()}</h1>
                

                </div>
            </div>
        </>)

}


export function Author() {

    const { t } = useTranslation("coaches");

    const navigate = useNavigate();

    const location = useLocation();

    const contentId = location.pathname.split('/')[2];

    const activeTab = location.pathname.split('/')[3];

    const [authorDetails, setAuthorDetails] = useState<AuthorContent | null>(null);

    useQuery(['author', contentId], () => getContentById({ platformContentId: contentId }), {
        onSuccess: (data: AuthorContent) => {
            setAuthorDetails(data as AuthorContent);
        },
        onError: (error: any) => {
            if (error.response && error.response.status === 403 ) {
              console.error('Forbidden error occurred:', error);
              navigate('/forbidden'); // Navigate to the error page
            } else if (error.response && error.response.status === 404) {
              console.error('Not Found error occurred:', error);
              navigate('/notfound')
            }else {
              // Handle other errors
              console.log("An error occurred:", error);
            }
          },
    })

    const renderCoachTab = () => {
        if (activeTab === 'overview') return <Overview coachDetails={authorDetails} />
        if (activeTab === 'content') return <CoachProductedContent coachDetails={authorDetails} />
    }


    return (
        <div className="w-full lg:w-4/6 px-4">
            <div className="lg:hidden flex w-full items-center mb-4">
                <LeftArrow className="" onClick={() => navigate('/coaches')}/>
                <p className="text-lg text-Yellow-default w-full text-center">COACHES</p>
            </div>
            <AuthorHeader coachData={authorDetails} />
            <AuthorTabs coachDetails={authorDetails} activeTab={activeTab} contentId={contentId} />
            {renderCoachTab()}
        </div>
    )


}