import { useMutation, useQuery } from "react-query";
import { getUserContentAndProgressTree, takeActionAgainstContent } from "../services/content.service";
import { ArticleContent, FormattedContent, IContentWithProgress, Question } from "../@types";
import { useContentAndProgress } from "../contexts/UserProgressContext";
import { useCallback, useEffect, useState } from "react";
import { submitAnswer } from "../services/selfAssessment.service";



export function useContentActions({content, questionId, callback}: { content: FormattedContent | Question, questionId?: string, callback?: any}) {

    const [contentProgress, setContentProgress] = useState<IContentWithProgress | null>(null);

    const { updateUserProgressTree, activeMilestoneContent } = useContentAndProgress();


    const { refetch : refreshUserProgressState } = useQuery(['userContentProgress', content], () => getUserContentAndProgressTree({platformContentId: content?.id}), {
        onSuccess: (data: IContentWithProgress) => {
            setContentProgress(data);
            updateUserProgressTree();
        }
    })
    useEffect(()=> {
        refreshUserProgressState();
    },[activeMilestoneContent])

    const contentAction = useMutation(takeActionAgainstContent, {
        onSuccess: () => refreshUserProgressState()
    })

    const answerMutation = useMutation(['submitAnswer'],
        (mutationArgs: { answer: 'Yes' | 'Maybe' | 'No' }) => submitAnswer({ selfAssessmentId: content.id, answer: { questionId: questionId || "", answer: mutationArgs.answer } }),
        {
            onSuccess: () => {
                callback();
                refreshUserProgressState();
            }
        }
    )

    const answerQuestion = (answer: 'Yes' | 'Maybe' | 'No') => {
        answerMutation.mutate({ answer })
    }
   
    const handleBookmark = useCallback((e: any) => {
        e.stopPropagation();
        const articleId = contentProgress?.content?.id; 
        if(!articleId) {
            return;
        }
        contentAction.mutate({ contentId: articleId, action: 'Bookmark' })
    }, [contentProgress]);

    const handleLike = useCallback((e : any) => {
        e.stopPropagation();
        const articleId = contentProgress?.content?.id;
        if(!articleId) return;
        contentAction.mutate({ contentId: articleId, action: 'Like' })
    }, [contentProgress])

    const handleDislike = useCallback((e : any) => {
        e.stopPropagation();
        const articleId = contentProgress?.content?.id;
        if(!articleId) return;
        contentAction.mutate({ contentId: articleId, action: 'Dislike' })
    }, [contentProgress])

    const handleDoneClick = useCallback((e: any) => {
        e.stopPropagation();
        contentAction.mutate({ contentId: contentProgress?.content?.id || contentProgress?.id || 0, action: 'Finish' })
    }, [contentProgress])

    const handleRollbackDoneClick = useCallback((e : any) => {
        e.stopPropagation();
        contentAction.mutate({ contentId: contentProgress?.content?.id || contentProgress?.id || 0, action: 'Unfinish' })
    }, [contentProgress])


    return {contentProgress, answerQuestion, handleBookmark, handleDoneClick, handleRollbackDoneClick, handleLike, handleDislike};



}