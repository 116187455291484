import { useTranslation } from "react-i18next";



export function SeparationBar() {

    const {t} = useTranslation("authentication");
    return (
        <div className="flex justify-center items-center mt-4">
            <div className="border-t border-gray-300 flex-grow mr-3" />
            <div className="text-gray-500">{t('or')}</div>
            <div className="border-t border-gray-300 flex-grow ml-3" />
        </div>
    );
}