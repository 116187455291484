import { useEffect, useState } from "react";
import Login from "./Login";
import InfoPage from "./InfoPage";
import Signup from "./Signup";
import ResetPasswordRequest from "./ResetPasswordRequest";
import PasswordReset from "./PasswordReset";
import TermsOfService from "./TermsOfService";
import { LinkedinCallback } from "./LinkedinCallback";
import { LinkedinSignup } from "./LinkedinSignup";
import { EmailVerified } from "./EmailVerified";
import { useTranslation } from "react-i18next";
import { EmailVerificationSent } from "./EmailVerificationSent";
import { ISignupData } from "../../@types";
import { goToWindow } from "../../utils";
import { PublicNavbar } from "../../components/publicNavbar";
import { PublicFooter } from "../../components/publicFooter";
import PrivacyPolicy from "./PrivacyPolicy";
import About from "./About";
import Support from "./Support";
import Imprint from "./Imprint";
import { BookedCoaching } from "./coachings";
import { SubmitCv } from "./submitCv";
import CookiePolicy from "./CookiePolicy";
import RegeneratePartnerCVCheck from "./RegeneratePartnerCvCheck";
import Unauthorized from "../private/Notfound";

export enum PUBLIC_PAGES {
  LOGIN = "login",
  SIGNUP = "signup",
  VERIFICATION_SUCCESSFULL = "verification-successful",
  VERIFICATION_EMAIL_SENT = "verification-email-sent",
  RESET_PASSWORD_EMAIL_SENT = "reset-password-email-sent",
  PASSWORD_RESET_REQUEST = "password-reset-requested",
  PASSWORD_RESET = "password-reset",
  LINKEDIN_SIGNIN = "linkedin-signin",
  LINKEDIN_SIGNUP = "linkedin-signup",
  TERMS_OF_SERVICE = "terms-of-service",
  PRIVACY_POLICY = "privacy-policy",
  ABOUT = "about",
  SUPPORT = "support",
  IMPRINT = "imprint",
  COACHING = "coaching",
  SUBMIT_CV = "submit-cv",
  COOKIE_POLICY = "cookie-policy",
  REGENERATEPARTNERCVCHECK = "regenerate-partner-cv-check-email",
}

export const getLocation = (page: PUBLIC_PAGES | null) => {
  const bootstarppedURL = window.location.href.split("//")[1];

  const location = bootstarppedURL.split("/")[1];

  if (
    location.includes(PUBLIC_PAGES.VERIFICATION_SUCCESSFULL) &&
    page == null
  ) {
    return PUBLIC_PAGES.VERIFICATION_SUCCESSFULL;
  } else if (location.includes(PUBLIC_PAGES.SUBMIT_CV) && page == null) {
    return PUBLIC_PAGES.SUBMIT_CV;
  } else if (location.includes(PUBLIC_PAGES.LOGIN) && page == null) {
    return PUBLIC_PAGES.LOGIN;
  } else if (location.includes(PUBLIC_PAGES.LINKEDIN_SIGNUP) && page == null) {
    return PUBLIC_PAGES.LINKEDIN_SIGNUP;
  } else if (location.includes(PUBLIC_PAGES.SIGNUP) && page == null) {
    return PUBLIC_PAGES.SIGNUP;
  } else if (location.includes(PUBLIC_PAGES.LINKEDIN_SIGNIN) && page == null) {
    return PUBLIC_PAGES.LINKEDIN_SIGNIN;
  } else if (location.includes(PUBLIC_PAGES.PASSWORD_RESET) && page == null) {
    return PUBLIC_PAGES.PASSWORD_RESET;
  } else if (location.includes(PUBLIC_PAGES.LINKEDIN_SIGNUP) && page == null) {
    return PUBLIC_PAGES.LINKEDIN_SIGNUP;
  } else if (location.includes(PUBLIC_PAGES.TERMS_OF_SERVICE) && page == null) {
    return PUBLIC_PAGES.TERMS_OF_SERVICE;
  } else if (location.includes(PUBLIC_PAGES.PRIVACY_POLICY) && page == null) {
    return PUBLIC_PAGES.PRIVACY_POLICY;
  } else if (location.includes(PUBLIC_PAGES.COOKIE_POLICY) && page == null) {
    return PUBLIC_PAGES.COOKIE_POLICY;
  } else if (location.includes(PUBLIC_PAGES.ABOUT) && page == null) {
    return PUBLIC_PAGES.ABOUT;
  } else if (location.includes(PUBLIC_PAGES.SUPPORT) && page == null) {
    return PUBLIC_PAGES.SUPPORT;
  } else if (location.includes(PUBLIC_PAGES.IMPRINT) && page == null) {
    return PUBLIC_PAGES.IMPRINT;
  } else if (location.includes(PUBLIC_PAGES.COACHING) && page == null) {
    return PUBLIC_PAGES.COACHING;
  } else if (
    location.includes(PUBLIC_PAGES.REGENERATEPARTNERCVCHECK) &&
    page == null
  ) {
    return PUBLIC_PAGES.REGENERATEPARTNERCVCHECK;
  } else if (!page) {
    return PUBLIC_PAGES.LOGIN;
  }

  return null;
};

export default function Public() {
  const [page, setPage] = useState<PUBLIC_PAGES | null>(null);
  const [registeredUser, setRegisteredUser] = useState<ISignupData | null>(
    null
  );
  const { t } = useTranslation("authentication");

  useEffect(() => {
    const newLocation = getLocation(page);
    if (newLocation && newLocation !== page) {
      setPage(newLocation);
    }
  }, [page]);

  const renderPageByState = () => {
    if (page === PUBLIC_PAGES.LOGIN)
      return (
        <Login
          goToResetPassword={() => setPage(PUBLIC_PAGES.PASSWORD_RESET_REQUEST)}
          goToSignup={() => setPage(PUBLIC_PAGES.SIGNUP)}
        />
      );
    if (page === PUBLIC_PAGES.SIGNUP)
      return (
        <Signup
          setRegisteredUser={setRegisteredUser}
          goToLogin={() => {
            setPage(PUBLIC_PAGES.LOGIN);
            goToWindow("/");
          }}
          goToSignUp={() => setPage(PUBLIC_PAGES.SIGNUP)}
          goToVerificationEmailSent={() =>
            setPage(PUBLIC_PAGES.VERIFICATION_EMAIL_SENT)
          }
        />
      );
    if (page === PUBLIC_PAGES.PASSWORD_RESET_REQUEST)
      return (
        <ResetPasswordRequest
          goBack={() => setPage(PUBLIC_PAGES.LOGIN)}
          onPasswordResetSuccessfulRequest={() =>
            setPage(PUBLIC_PAGES.RESET_PASSWORD_EMAIL_SENT)
          }
        />
      );
    if (page === PUBLIC_PAGES.PASSWORD_RESET)
      return (
        <PasswordReset
          onSuccessfulReset={() => {
            goToWindow("/");
            setPage(PUBLIC_PAGES.LOGIN);
          }}
        />
      );

    if (page === PUBLIC_PAGES.LINKEDIN_SIGNIN) {
      const storedCode = localStorage.getItem("pendingVoucherCode");
      return <LinkedinCallback storedCode={storedCode} />;
    }
    if (page === PUBLIC_PAGES.LINKEDIN_SIGNUP) return <LinkedinSignup />;
    if (page === PUBLIC_PAGES.VERIFICATION_SUCCESSFULL)
      return <EmailVerified />;
    if (page === PUBLIC_PAGES.VERIFICATION_EMAIL_SENT)
      return <EmailVerificationSent registeredUser={registeredUser} />;

    if (page === PUBLIC_PAGES.RESET_PASSWORD_EMAIL_SENT)
      return (
        <InfoPage
          title={t("password-reset.email-sent-title")}
          message={t("password-reset.email-sent-message")}
        />
      );

    if (page === PUBLIC_PAGES.ABOUT) return <About />;
    if (page === PUBLIC_PAGES.TERMS_OF_SERVICE) return <TermsOfService />;
    if (page === PUBLIC_PAGES.PRIVACY_POLICY) return <PrivacyPolicy />;
    if (page === PUBLIC_PAGES.COOKIE_POLICY) return <CookiePolicy />;
    if (page === PUBLIC_PAGES.SUPPORT) return <Support />;
    if (page === PUBLIC_PAGES.IMPRINT) return <Imprint />;
    if (page === PUBLIC_PAGES.COACHING) return <BookedCoaching />;
    if (page === PUBLIC_PAGES.SUBMIT_CV) return <SubmitCv />;
    if (page === PUBLIC_PAGES.REGENERATEPARTNERCVCHECK)
      return <RegeneratePartnerCVCheck />;
  };

  return (
    <div className="h-full flex flex-col">
      <PublicNavbar />
      <div className="flex flex-1 w-full justify-center">
        <div className="flex w-full md:mt-4 sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/3">
          {renderPageByState()}
        </div>
      </div>
      <PublicFooter />
    </div>
  );
}
