import { useTranslation } from "react-i18next";
import { MyAreaTitle } from "../components";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { Field, Form, Formik } from "formik";
import Button from "../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/button/types";
import { useMutation, useQuery } from "react-query";
import {
  fetchCareerOptions,
  updateUserDetails,
} from "../../../../../services/onboarding.service";
import { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { UserDetails } from "../../../onboarding/types";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import { getLocaleFromLanguage } from "../../../../../services/content.service";

export function CareerInformation() {
  const { user, setUser, configurations } = useContext(AuthContext);;
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { t } = useTranslation("myArea");
  const { t: onboardingT } = useTranslation("onboarding");

  const [industries, setIndustries] = useState([]);
  const [careerLevels, setCareerLevels] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [salary, setSalary] = useState([]);

  const shouldRenderCareerInformation =
    configurations?.includesCareerInformation;
  const shouldRenderCareerExpectations =
    configurations?.includesCareerExpectations;
  const shouldRenderCareerPreferences =
    configurations?.includesCareerPreferences;

  useQuery(["getCareerOptions"], fetchCareerOptions, {
    onSuccess: (data) => {
      setIndustries(data?.industries);
      setCareerLevels(data?.careerLevels);
      setDisciplines(data?.disciplines);
      setSalary(data?.salary);
    },
  });

  const handleSubmit = async (payload: UserDetails) => {
    const transformedPayload: UserDetails = Object.fromEntries(
      Object.entries(payload).map(([key, value]) => [
        key,
        value === "" ? null : value,
      ])
    );
    try {
      // handle success
      await submitNewUserDetails.mutate({ userDetails: transformedPayload });
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const submitNewUserDetails = useMutation(updateUserDetails, {
    onSuccess: (response) => {
      setUser(response);
      setIsFormDirty(false);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
    },
  });

  const initialValues = {
    lastPosition: user?.userDetails?.lastPosition || "",
    careerLevel: user?.userDetails?.careerLevel || "",
    industry: user?.userDetails?.industry || "",
    discipline: user?.userDetails?.discipline || "",
    timingLastDayAtWork: user?.userDetails?.timingLastDayAtWork
      ? new Date(user?.userDetails?.timingLastDayAtWork)
      : null,
    timingAchieveRestart: user?.userDetails?.timingAchieveRestart
      ? new Date(user?.userDetails?.timingAchieveRestart)
      : null,
    desiredPosition: user?.userDetails?.desiredPosition || "",
    desiredCareerLevel: user?.userDetails?.desiredCareerLevel || "",
    desiredDiscipline: user?.userDetails?.desiredDiscipline || "",
    desiredIndustry: user?.userDetails?.desiredIndustry || "",
    desiredSalary: user?.userDetails?.desiredSalary || "",
  };

  const monthsLocal = getLocaleFromLanguage();
  const localeMapping = {
    de: de,
    "en-US": enUS,
  };

  const convertedLocale = localeMapping[monthsLocal];

  return (
    <div className="mb-5">
      <div className="hidden lg:flex">
        <MyAreaTitle title={t("career-information.title")} />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, handleChange, setFieldValue }) => {
          return (
            <Form>
              {shouldRenderCareerInformation && (
                <>
                  <h2 className="text-Grey-dark font-bold mb-2">
                    {t("career-information.career")}
                  </h2>
                  <div className="flex flex-col w-full">
                    <label className="text-base mb-1">
                      {onboardingT("stepCareer.lastPosition")}
                    </label>
                    <Field
                      as="input"
                      type="text"
                      id="last-position"
                      name="lastPosition"
                      placeholder={onboardingT(
                        "stepCareer.lastPositionPlaceHolder"
                      )}
                      className="border rounded-lg px-4 py-3.5 text-base mb-4"
                      value={values.lastPosition}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFormDirty(true); // Set the form as dirty when the input changes
                      }}
                    />
                    <label className="text-base mb-1">
                      {onboardingT("stepCareer.careerLevel")}
                    </label>

                    <Field
                      as="select"
                      id="career-select"
                      name="careerLevel"
                      className="border rounded-lg  px-4 py-3.5  text-base mb-4"
                      value={values.careerLevel}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                      }}
                    >
                      <option value="" disabled>
                        {onboardingT("stepCareer.please-select")}
                      </option>
                      {careerLevels?.map((careerLevel: any) => (
                        <option
                          key={careerLevel?.key}
                          value={careerLevel?.value}
                        >
                          {onboardingT(`careerLevel.${careerLevel?.value}`)}
                        </option>
                      ))}
                    </Field>
                    <label className="text-base mb-1">
                      {onboardingT("stepCareer.industry")}
                    </label>

                    <Field
                      as="select"
                      id="industry-select"
                      name="industry"
                      className="border rounded-lg px-4 py-3.5  text-base  mb-4"
                      value={values.industry}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                      }}
                    >
                      <option value="" disabled>
                        {onboardingT("stepCareer.please-select")}
                      </option>
                      {industries?.map((industry: any) => (
                        <option key={industry?.key} value={industry?.value}>
                          {onboardingT(`industries.${industry?.value}`)}
                        </option>
                      ))}
                    </Field>
                    <label className="text-base mb-1">
                      {onboardingT("stepCareer.discipline")}
                    </label>
                    <Field
                      as="select"
                      id="discipline-select"
                      name="discipline"
                      className="border rounded-lg  px-4 py-3.5  text-base"
                      value={values.discipline}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                      }}
                    >
                      <option value="" disabled>
                        {onboardingT("stepCareer.please-select")}
                      </option>
                      {disciplines?.map((discipline: any) => (
                        <option key={discipline?.key} value={discipline?.value}>
                          {onboardingT(`disciplines.${discipline?.value}`)}
                        </option>
                      ))}
                    </Field>
                  </div>
                </>
              )}
              {shouldRenderCareerExpectations && (
                <>
                  <h2 className="text-Grey-dark font-bold mb-2 mt-3">
                    {onboardingT("stepCareer.careerInformationExpectations")}
                  </h2>
                  <div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="date-picker" className="text-base mb-1">
                        {onboardingT("stepNeeds.lastDay")}
                      </label>
                      <DatePicker
                        id="date-picker"
                        selected={values?.timingLastDayAtWork}
                        onChange={(date) => {
                          setFieldValue("timingLastDayAtWork", date);
                          setIsFormDirty(true);
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={t("select-a-date") || "Select a date"}
                        className="border rounded-lg px-4 py-3.5 text-base w-full mb-4"
                        locale={convertedLocale}
                      />
                      <label htmlFor="date-picker" className="text-base mb-1">
                        {onboardingT("stepNeeds.timeToRestart")}
                      </label>
                      <DatePicker
                        id="date-picker"
                        selected={values?.timingAchieveRestart}
                        onChange={(date) => {
                          setFieldValue("timingAchieveRestart", date);
                          setIsFormDirty(true);
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={t("select-a-date") || "Select a date"}
                        className="border rounded-lg px-4 py-3.5 text-base w-full mb-4"
                        locale={convertedLocale}
                      />
                    </div>
                  </div>
                </>
              )}

              {shouldRenderCareerPreferences && (
                <>
                  <h2 className="text-Grey-dark font-bold mb-2 mt-3">
                    {onboardingT(
                      "stepPreferences.careerInformationPreferences"
                    )}
                  </h2>
                  <div>
                    <div className="flex flex-col w-full">
                      <label className="text-base mb-1">
                        {onboardingT("stepPreferences.desiredPosition")}
                      </label>
                      <Field
                        as="input"
                        type="text"
                        id="desired-position"
                        name="desiredPosition"
                        placeholder={onboardingT(
                          "stepPreferences.desiredPositionPlaceHolder"
                        )}
                        className="border rounded-lg px-4 py-3.5 text-base mb-4"
                        value={values.desiredPosition}
                        onChange={(e: any) => {
                          handleChange(e);
                          setIsFormDirty(true); // Set the form as dirty when the input changes
                        }}
                      />

                      <label className="text-base mb-1">
                        {onboardingT("stepPreferences.desiredCareerLevel")}
                      </label>

                      <Field
                        as="select"
                        id="desiredCareer-select"
                        name="desiredCareerLevel"
                        className="border rounded-lg  px-4 py-3.5  text-base mb-4"
                        value={values.desiredCareerLevel}
                        onChange={(e: any) => {
                          handleChange(e);
                          setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                        }}
                      >
                        <option value="" disabled>
                          {onboardingT("stepCareer.please-select")}
                        </option>
                        {careerLevels?.map((careerLevel: any) => (
                          <option
                            key={careerLevel?.key}
                            value={careerLevel?.value}
                          >
                            {onboardingT(`careerLevel.${careerLevel?.value}`)}
                          </option>
                        ))}
                      </Field>

                      <label className="text-base mb-1">
                        {onboardingT("stepPreferences.desiredIndustry")}
                      </label>

                      <Field
                        as="select"
                        id="desiredIndustry-select"
                        name="desiredIndustry"
                        className="border rounded-lg px-4 py-3.5  text-base  mb-4"
                        value={values.desiredIndustry}
                        onChange={(e: any) => {
                          handleChange(e);
                          setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                        }}
                      >
                        <option value="" disabled>
                          {onboardingT("stepCareer.please-select")}
                        </option>
                        {industries?.map((industry: any) => (
                          <option key={industry?.key} value={industry?.value}>
                            {onboardingT(`industries.${industry?.value}`)}
                          </option>
                        ))}
                      </Field>

                      <label className="text-base mb-1">
                        {onboardingT("stepPreferences.desiredDiscipline")}
                      </label>
                      <Field
                        as="select"
                        id="desiredDiscipline-select"
                        name="desiredDiscipline"
                        className="border rounded-lg  px-4 py-3.5  text-base mb-4"
                        value={values.desiredDiscipline}
                        onChange={(e: any) => {
                          handleChange(e);
                          setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                        }}
                      >
                        <option value="" disabled>
                          {onboardingT("stepCareer.please-select")}
                        </option>
                        {disciplines?.map((discipline: any) => (
                          <option
                            key={discipline?.key}
                            value={discipline?.value}
                          >
                            {onboardingT(`disciplines.${discipline?.value}`)}
                          </option>
                        ))}
                      </Field>

                      <label className="text-base mb-1">
                        {onboardingT("stepPreferences.desiredSalary")}
                      </label>
                      <Field
                        as="select"
                        id="desiredSalary-select"
                        name="desiredSalary"
                        className="border rounded-lg  px-4 py-3.5  text-base mb-4"
                        value={values.desiredSalary}
                        onChange={(e: any) => {
                          handleChange(e);
                          setIsFormDirty(true); // Set the form as dirty when the dropdown/select input changes
                        }}
                      >
                        <option value="" disabled>
                          {onboardingT("stepCareer.please-select")}
                        </option>
                        {salary?.map((salary: any) => (
                          <option key={salary?.key} value={salary?.value}>
                            {onboardingT(`salary.${salary?.value}`)}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </>
              )}

              <Button
                type="submit"
                className={`mt-6 w-full ${isFormDirty ? "" : "bg-gray-200"}`}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                disabled={!isFormDirty}
              >
                {t("profile.save")}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
