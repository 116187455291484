import { axiosInstance } from ".";
import { IUser } from "../@types";
import { ContentType } from "../enums";
import { UpdateVariables } from "../pages/private/onboarding/types";
import { getContentByProperty } from "./content.service";

export async function getEnum(name: string): Promise<any> {
    try {
        const response: any = await axiosInstance.get(`/onboarding/enums/${name}`);
        return Object.entries(response.data).map(([key, value]) => ({ key, value }));
    } catch (error: any) {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 404) {
                console.error(`Enum '${name}' not found`);
            } else {
                console.error(`API error: ${status} - ${data}`);
            }
        } else if (error.request) {
            console.error(`Network error: ${error.request}`);
        } else {
            console.error(`Error: ${error.message}`);
        }
    }
}

export async function updateUserDetails(variables: UpdateVariables) : Promise<IUser | undefined> {

    const { userDetails } = variables
    try {
        const response = await axiosInstance.patch('/onboarding', userDetails);
        return response.data;
    } catch (error: any) {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 404) {
                console.error(`Endpoint not found`);
            } else {
                console.error(`API error: ${status} - ${data}`);
            }
        } else if (error.request) {
            console.error(`Network error: ${error.request}`);
        } else {
            console.error(`Error: ${error.message}`);
        }
    }
}

export async function fetchCareerOptions() {
    const careerLevels = await getEnum("career-level");
    const industries = await getEnum("industries");
    const disciplines = await getEnum("disciplines");
    const salary = await getEnum("salary")
    return {careerLevels, industries, disciplines, salary};
}


export async function getJourneys() {
    return await getContentByProperty({ contentType: ContentType.JOURNEY});

}