
import { DragAndDropUploader } from "../../../../../../../../../components/DragAndDropUploader";
import Button from "../../../../../../../../../components/button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../../../../../components/button/types";
import { useTranslation } from "react-i18next";

export function CVUploader({
  setCVToUpload,
  onNext,
  onBack,
  cvToUpload,
}: {
  setCVToUpload: (val: any) => void;
  onNext: () => void;
  onBack: () => void;
  cvToUpload: any;
}) {
  const { t } = useTranslation("myArea");

  return (
    <>
      <DragAndDropUploader singleFile filesSetter={setCVToUpload} currentFile={cvToUpload} />
      <div className="flex items-center justify-between py-2">
        <Button
          className="w-full mr-2"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onBack}
        >
          {t(`assessments.cv-check.back-button`)}
        </Button>
        <Button
          className="w-full ml-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={onNext}
          disabled={cvToUpload === null || cvToUpload.length === 0}
        >
          {t(`assessments.cv-check.next-button`)}
        </Button>
      </div>
    </>
  );
}
